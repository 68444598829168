import { render, staticRenderFns } from "./score.vue?vue&type=template&id=462508e2&scoped=true"
import script from "./score.vue?vue&type=script&lang=js"
export * from "./score.vue?vue&type=script&lang=js"
import style0 from "./score.vue?vue&type=style&index=0&id=462508e2&prod&scoped=true&lang=scss"
import style1 from "./score.vue?vue&type=style&index=1&id=462508e2&prod&lang=scss"
import style2 from "./score.vue?vue&type=style&index=2&id=462508e2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "462508e2",
  null
  
)

export default component.exports