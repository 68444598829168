import { render, staticRenderFns } from "./join-master.vue?vue&type=template&id=bba26268&scoped=true"
import script from "./join-master.vue?vue&type=script&lang=js"
export * from "./join-master.vue?vue&type=script&lang=js"
import style0 from "./join-master.vue?vue&type=style&index=0&id=bba26268&prod&scoped=true&lang=scss"
import style1 from "@/assets/style/home/copy-trade.css?vue&type=style&index=1&id=bba26268&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bba26268",
  null
  
)

export default component.exports