<template>
  <div>
    <div class="mainImg">
      <img src="@/assets/style/img/account/newslogo2.png" v-if="clientWidth < 1400" style="height: 50px;">
    </div>
    <svg width="0" height="0" style="position: absolute; z-index: -1;" xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink">
      <symbol viewBox="0 0 56.693 56.693" id="soc-fb" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.43 21.739h-7.645v-5.014c0-1.883 1.248-2.322 2.127-2.322h5.395V6.125l-7.43-.029c-8.248 0-10.125 6.174-10.125 10.125v5.518h-4.77v8.53h4.77v24.137h10.033V30.269h6.77l.875-8.53z"/>
      </symbol>
      <symbol viewBox="0 0 56.7 56.7" id="soc-inst" xmlns="http://www.w3.org/2000/svg">
        <path d="M28.2 16.7c-7 0-12.8 5.7-12.8 12.8s5.7 12.8 12.8 12.8S41 36.5 41 29.5s-5.8-12.8-12.8-12.8zm0 21c-4.5 0-8.2-3.7-8.2-8.2s3.7-8.2 8.2-8.2 8.2 3.7 8.2 8.2-3.7 8.2-8.2 8.2z"/>
        <circle cx="41.5" cy="16.4" r="2.9"/>
        <path d="M49 8.9c-2.6-2.7-6.3-4.1-10.5-4.1H17.9c-8.7 0-14.5 5.8-14.5 14.5v20.5c0 4.3 1.4 8 4.2 10.7 2.7 2.6 6.3 3.9 10.4 3.9h20.4c4.3 0 7.9-1.4 10.5-3.9 2.7-2.6 4.1-6.3 4.1-10.6V19.3c0-4.2-1.4-7.8-4-10.4zm-.6 31c0 3.1-.9 5.6-2.7 7.3-1.8 1.7-4.3 2.6-7.3 2.6H18c-3 0-5.5-.9-7.3-2.6C8.9 45.4 8 42.9 8 39.8V19.3c0-3 .9-5.5 2.7-7.3 1.7-1.7 4.3-2.6 7.3-2.6h20.6c3 0 5.5.9 7.3 2.7 1.7 1.8 2.7 4.3 2.7 7.2v20.6z"/>
      </symbol>
      <symbol viewBox="0 0 56.693 56.693" id="soc-tw" xmlns="http://www.w3.org/2000/svg">
        <path d="M54.082 15.55a20.14 20.14 0 01-5.805 1.59 10.13 10.13 0 004.444-5.591 20.231 20.231 0 01-6.418 2.454 10.093 10.093 0 00-7.377-3.192c-5.581 0-10.107 4.525-10.107 10.107 0 .792.09 1.563.262 2.302-8.4-.42-15.848-4.444-20.832-10.56a10.058 10.058 0 00-1.369 5.082c0 3.506 1.785 6.6 4.497 8.412a10.07 10.07 0 01-4.578-1.264l-.001.128c0 4.896 3.484 8.98 8.108 9.91a10.143 10.143 0 01-4.565.173c1.287 4.015 5.019 6.937 9.44 7.019A20.277 20.277 0 017.23 46.446c-.816 0-1.62-.048-2.411-.14a28.606 28.606 0 0015.493 4.54c18.59 0 28.756-15.4 28.756-28.757 0-.437-.009-.874-.028-1.307a20.518 20.518 0 005.042-5.232z"/>
      </symbol>
      <symbol viewBox="0 0 20 20" id="soc-yt" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 2.3C.172 2.3 0 3.174 0 10s.172 7.7 10 7.7 10-.874 10-7.7-.172-7.7-10-7.7zm3.205 8.034l-4.49 2.096c-.393.182-.715-.022-.715-.456V8.026c0-.433.322-.638.715-.456l4.49 2.096c.393.184.393.484 0 .668z"/>
      </symbol>
    </svg>
    <div class="mSelect" >
      <img src="@/assets/style/img/account/newslogo2.png" class="mSelectImg" v-if="clientWidth >= 1400" style="height: 50px;">
      <div class="mSelectItem">
        <div class="title">{{ $t('lang.message.companies') }}</div>
        <div class="msg" v-for="(item, index) in data1" :key="index" ><a :href="item.src" style="color: #000000">{{ item.label }}</a></div>
      </div>
      <div class="mSelectItem">
        <div class="title">{{ $t('lang.message.community') }}</div>
        <div class="msg" v-for="(item, index) in data2" :key="index"><a :href="item.src" style="color: #000000">{{ item.label }}</a></div>
      </div>
      <div class="mSelectItem">
        <div class="title">{{ $t('lang.home.aboutUs') }}</div>
        <div class="msg" v-for="(item, index) in data3" :key="index"><a :href="item.src" style="color: #000000">{{ item.label  }}</a></div>
      </div>
      <div class="mSelectItem">
        <div class="title">{{ $t('lang.other.socity') }}</div>
        <div style="display: flex;justify-content: space-between">
        <div class="msg">
          <a
              :href="fb"
              target="_blank"
              rel="noopener"
              class="footer__list-link"

          >
          <img src="@/assets/style/img/account/facebookicon.png">
          </a>
        </div>
        <div class="msg">
          <a
              :href="twi"
              target="_blank"
              rel="noopener"
              class="footer__list-link"
          >
          <img src="@/assets/style/img/account/twittericon.png">
          </a>
        </div>
        <div class="msg">
          <a
              :href="youtube"
              target="_blank"
              rel="noopener"
              class="footer__list-link"
          >
          <img src="@/assets/style/img/account/youtubeicon.png">
          </a>
        </div>
        </div>
      </div>
    </div>


<!--    <p class="message">
      {{$t('lang.message.title')}}
      <br>
      <br>
      {{$t('lang.message.p1')}}
      <br>
      <br>
      {{$t('lang.message.p2')}}
      <br>
      <br>
      {{$t('lang.message.p3')}}
      <br>
    </p>-->
  </div>
</template>

<script>
import {getaddress} from "@/api/home";
import {getToken} from '@/api/auth'
export default {
  name: "message",
  data() {
    return {
      clientWidth: document.body.clientWidth,
      youtube:'',
      twi:'',
      fb:'',
      ins:'',


      /*data1:[
        {label:this.$t('lang.message.VIP'),src:"https://www.cloudbridgeau.com/index.php/en/indexvip/"},
        {label:this.$t('lang.message.active'),src:"https://www.cloudbridgeau.com/index.php/en/indexdayplan/"},
      ],*/
     /* data2:[
        {label:this.$t('lang.message.out'),src:"https://www.cloudbridgeau.com/index.php/en/indexvip/"},
        {label:this.$t('lang.message.contract'),src:"https://www.cloudbridgeau.com/index.php/en/indexdayplan/"},
        {label:this.$t('lang.message.goods'),src:"https://www.cloudbridgeau.com/index.php/en/indexvip/"},
        {label:this.$t('lang.message.CFD'),src:"https://www.cloudbridgeau.com/index.php/en/indexdayplan/"},
        {label: this.$t('lang.message.money'),src:"https://www.cloudbridgeau.com/index.php/en/indexvip/"},
        {label:this.$t('lang.message.time'),src:"https://www.cloudbridgeau.com/index.php/en/indexdayplan/"},
        {label:this.$t('lang.message.count'),src:"https://www.cloudbridgeau.com/index.php/en/indexvip/"},
        {label:this.$t('lang.message.inAndOut'),src:"https://www.cloudbridgeau.com/index.php/en/indexdayplan/"},
        {label:this.$t('lang.message.begin'),src:"https://www.cloudbridgeau.com/index.php/en/indexvip/"},
      ]*/
    }
  },
  computed: {
    data1() {
      if(this.$cookies.get("lang")==="zh-CN") {
        return [
          {label: this.$t('lang.other.dl'), src: getToken()!==undefined?dataurlhost.outhref.url+"/agentPlan"+'/?a='+getToken():dataurlhost.outhref.url+"/agentPlan"},
          {label: this.$t('lang.message.friend'), src: getToken()!==undefined?dataurlhost.outhref.url+"/friend"+'/?a='+getToken():dataurlhost.outhref.url+"/friend"},
        ]
      }else{
        return [
          {label: this.$t('lang.other.dl'), src: getToken()!==undefined?dataurlhost.outhref.url+"/agentPlan"+'/?a='+getToken():dataurlhost.outhref.url+"/agentPlan"},
          {label: this.$t('lang.message.friend'), src: getToken()!==undefined?dataurlhost.outhref.url+"/friend"+'/?a='+getToken():dataurlhost.outhref.url+"/friend"},
        ]
      }
    },
    data2() {
      if(this.$cookies.get("lang")==="zh-CN") {
        return [
          {label: this.$t('lang.home.center'), src: getToken()!==undefined?dataurlhost.apihost.url+"/crm/client/profit?lang=2"+'/?a='+getToken():dataurlhost.apihost.url+"/crm/client/profit?lang=2"},
          {label: this.$t('lang.message.news'), src: getToken()!==undefined?dataurlhost.outhref.url+"/news"+'/?a='+getToken():dataurlhost.outhref.url+"/news"},

        ]
      }else{
        return [
          {label: this.$t('lang.home.center'), src: getToken()!==undefined?dataurlhost.apihost.url+"/crm/client/profit?lang=1"+'/?a='+getToken():dataurlhost.apihost.url+"/crm/client/profit?lang=1"},
          {label: this.$t('lang.message.news'), src: getToken()!==undefined?dataurlhost.outhref.url+"/news"+'/?a='+getToken():dataurlhost.outhref.url+"/news"},
        ]
      }
    },
    data3() {
      if(this.$cookies.get("lang")==="zh-CN") {
        return [
          {label: this.$t('lang.other.help'), src: getToken()!==undefined?dataurlhost.outhref.url+"/helpCenter"+'/?a='+getToken():dataurlhost.outhref.url+"/helpCenter"},
          // {label: this.$t('lang.other.privicy'), src: getToken()!==undefined?dataurlhost.outhref.url+"/clause"+'/?a='+getToken():dataurlhost.outhref.url+"/clause"},
          // {label: this.$t('lang.other.privicy'), src: getToken()!==undefined?dataurlhost.outhref.url+"/pdf/ys.pdf"+'/?a='+getToken():dataurlhost.outhref.url+"/pdf/ys.pdf"},
          
        ]
      }else{
        return [
          {label: this.$t('lang.other.help'), src: getToken()!==undefined?dataurlhost.outhref.url+"/helpCenter"+'/?a='+getToken():dataurlhost.outhref.url+"/helpCenter"},
          // {label: this.$t('lang.other.privicy'), src: getToken()!==undefined?dataurlhost.outhref.url+"/pdf/ys.pdf"+'/?a='+getToken():dataurlhost.outhref.url+"/pdf/ys.pdf"},
        ]
      }
    },
    data4() {
      return [
        this.$t('lang.message.study'),
        this.$t('lang.message.news'),
        this.$t('lang.message.com'),
      ]
    },
    data5() {
      return [
        this.$t('lang.message.proxy'),
        this.$t('lang.message.friend'),
      ]
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.clientWidth = document.body.clientWidth;
    })

    getaddress().then(data=>{
      // alert(JSON.stringify)
      var data = data.data
      this.youtube=data.data.youtube;
      this.twi=data.data.twitter;
      this.fb=data.data.facebook;
      this.ins=data.data.instagram;
      
    })
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/style/home/message.scss'

</style>
<style>
.footer__list-link {
  display: inline-block;
  margin-bottom: 8px;
  text-decoration: none;
}
.footer__list-link:link, .footer__list-link:visited {
  color: var(--color-back, #32374a);
}
.footer__social-icon {
  width: 20px;
  height: 20px;
  fill: #b3b3b3;
  transition: .2s fill;
}

</style>
