import request from '@/api/request'

// 列表数据获取
export function getList(data) {
    return request({
        url: '/crm/followConsumer/consumerList',
        method: 'get',
        params: data
    })
}

// 正在复制列表数据获取
export function getNowList() {
    return request({
        url: '/crm/followConsumer/consumerFollowList',
        method: 'get',
    })
}

// 列表详情第一块信息数据获取
export function getDetail(id) {
    return request({
        url: '/crm/followConsumer/getSignal?id='+id,
        method: 'get',

    })
}

// 跟单表现数据获取
export function getExpression(data) {
    return request({
        url: '/crm/followConsumer/getExpression',
        method: 'get',
        params: data
    })
}

// 账户详情数据获取
export function getAccount(id) {
    return request({
        url: '/crm/followConsumer/getAccount?id='+id,
        method: 'get',
    })
}

// 交易历史数据获取
export function getOrder(data) {
    return request({
        url: '/crm/signal/trade',
        method: 'get',
        params: data
    })
}

// 佣金订单数据获取
export function comOrder(data) {
    return request({
        url: '/crm/signal/orders',
        method: 'get',
        params: data
    })
}

//限价数据获取
export function getMtserver(data) {
    return request({
        url: '/crm/signal/orders',
        method: 'get',
        params: data
    })
}

//结余
export function getBlance(data) {
    return request({
        url: '/crm/signal/balances',
        method: 'get',
        params: data
    })
}



// 折线图
export function getLineChart(data) {
    return request({
        url: '/crm/followConsumer/getLineChart',
        method: 'get',
        params: data
    })
}

//验证码

export function md5login(data) {
    return request({
        url: '/code',
        method: 'get',
        params: data
    })
}

//登录

export function login(data,text) {
    return request({
        url: '/auth/login?googleCode='+text,
        method: 'post',
        data: data
    })
}
//用户信息

export function getinfo(data) {
    return request({
        url: '/crm/getInfo',
        method: 'get',
        params: data
    })
}

//用户账户下拉框

export function selectAccountList(data) {
    return request({
        url: '/crm/cash/mtaccount/selectAccountListByFollow',
        method: 'get',
        params: data
    })
}

//用户谷歌验证码

export function followAdd(data){
    return request({
        url: '/crm/followConsumer/add',
        method: 'post',
        params: data
    })
}

// 结束跟单
export function stopFollow(data){
    return request({
        url: '/crm/followConsumer/stopFollow',
        method: 'post',
        params: data
    })
}


// fb 地址
export function getaddress(){
    return request({
        url: '/crm/user/login/socialNetworking',
        method: 'get',
    })
}

