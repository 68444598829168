<template>
  <div>
    <el-table
        :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)"
        style="width: 100%"
        :empty-text="$t('lang.other.nodata')">
      <el-table-column
          prop="order"
          :label="$t('lang.balance.orderid')">
      </el-table-column>
      <el-table-column
          prop="openTime"
          width="180"
          :formatter="formatTime"
          :label="$t('lang.balance.opentime')">
      </el-table-column>
      <el-table-column
          prop="direction"
          :formatter="formatType"
          :label="$t('lang.balance.type')">
      </el-table-column>
      <el-table-column
          prop="volume"
          :label="$t('lang.close.hand')">
      </el-table-column>
      <el-table-column
          prop="symbol"
          :label="$t('lang.close.symbol')">
      </el-table-column>
      <el-table-column
          prop="openPrice"
          :label="$t('lang.close.openprice')">
        <template slot-scope="scope">
          {{scope.row.openPrice===""||scope.row.openPrice===null?"":"$"+scope.row.openPrice}}
        </template>
      </el-table-column>
      <el-table-column
          prop="closePrice"
          :label="$t('lang.close.nowprice')">
        <template slot-scope="scope">
          {{scope.row.closePrice===""||scope.row.closePrice===null?"":"$"+scope.row.closePrice}}
        </template>
      </el-table-column>
      <el-table-column
          prop="commission"
          :label="$t('lang.close.comission')">
        <template slot-scope="scope">
          {{scope.row.commission==""||scope.row.commission==null?"":"$"+scope.row.commission}}
        </template>
      </el-table-column>
      <el-table-column
          prop="storage"
          :label="$t('lang.close.storage')">
        <template slot-scope="scope">
          {{scope.row.storage===""||scope.row.storage===null?"":"$"+scope.row.storage}}
        </template>
      </el-table-column>
      <el-table-column
          prop="profit"
          :label="$t('lang.close.profit')">
        <template slot-scope="scope">
          {{scope.row.profit===""||scope.row.price===null?"":"$"+scope.row.profit}}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
                   style="margin-top: 20px"
                   :current-page="currentPage"
                   :page-sizes="[1,5,10,20]"
                   :page-size="pageSize"
                   layout="prev, pager, next"
                   :total="tableData.length">
    </el-pagination>
  </div>
</template>

<script>
import { getOrder} from "@/api/home";
export default {
  name: "closed",
  props:['id'],
  data() {
    return {
      zlid:this.id,
      orderType:2,
      currentPage: 1, // 当前页码
      total: 20, // 总条数
      pageSize: 10, // 每页的数据条数
      typedata:["buy","sell","buy limit","sell limit","buy stop","sell stop","balance","credit"],
      tableData: [
        {
          ddh: '5301',
          kcsj: '2022-12-1 13:09:17',
          lx: 'buy',
          ss: '1.00',
          jypz: 'XAUUSD',
          pcsj: '2022-12-1 13:09:17',
          pcjg: '1775',
          sxf: '10.00',
          kcf: '5.23',
          hl: '暂无'
        },
      ]
    }
  },
  methods:{
    //每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {

      this.currentPage = 1;
      this.pageSize = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {

      this.currentPage = val;
    },

    //时间格式转换
    formatTime(row, column) {
      if(!row[column.property]) return ""
       let offset = -new Date().getTimezoneOffset() * 60 * 1000;
       const date = new Date(row[column.property] - offset)
       var Y = date.getFullYear() + "-";
       var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
       var D = date.getDate() + " ";
       var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours() ) + ":";
       var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
       var s = (date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds() );
       return Y + M + D + h + m + s;
    },
    formatType(row,column){
      const type=row[column.property]
      return this.typedata[type]

    },
    gettime(t){
      var _time=new Date(t * 1000);
      var   year=_time.getFullYear();//2017
      var   month=_time.getMonth()+1;//7
      var   date=_time.getDate();//10
      var   hour=_time.getHours();//10
      var   minute=_time.getMinutes();//56
      var   second=_time.getSeconds();//15
      return   year+"-"+month+"-"+date+"   "+hour+":"+minute+":"+second;//这里自己按自己需要的格式拼接
    },
    getData() {
      getOrder({login:this.zlid,status:1}).then(res=>{
        res.data.rows.forEach((i,x)=>{
          i.volume = i.volume/100
          // i.closeTime = this.gettime(i.closeTime)
        })
        this.tableData=res.data.rows
      })
    }
  },
  mounted(){
    /*this.zlid = this.$route.query.id;*/
    


  },
  watch: {
    id: function (newData, oldData) {
      this.zlid = newData;
    }
  }
}
</script>

<style scoped>
.pagination {
  text-align: center;
  margin-top: 30px;
}
</style>
