<template>
  <div>
    <div class="card" v-for="(item, index) in tableData" :key="index">
      <div class="item">
        <div>
          <div class="itemName">{{ $t('lang.balance.orderid') }}</div>
          <div class="itemValue">{{ item.order }}</div>
        </div>
      </div>
      <div class="item">
        <div>
          <div class="itemName">{{ $t('lang.balance.type') }}</div>
          <div class="itemValue">{{ item.cmd  }}</div>
        </div>
      </div>
      <div class="item">
        <div>
          <div class="itemName">{{ $t('lang.close.hand') }}</div>
          <div class="itemValue">{{ item.volume }}</div>
        </div>
      </div>
      <div class="item">
        <div>
          <div class="itemName">{{ $t('lang.close.symbol') }}</div>
          <div class="itemValue">{{ item.symbol }}</div>
        </div>
      </div>
      <div class="item">
        <div>
          <div class="itemName">{{ $t('lang.close.closeprice') }}</div>
          <div class="itemValue">{{ item.closePrice==""||item.closePrice==null?"":"$"+item.closePrice }}</div>
        </div>
      </div>
      <div class="item">
        <div>
          <div class="itemName">{{ $t('lang.close.comission') }}</div>
          <div class="itemValue">{{ item.commission==""||item.commission==null?"":"$"+item.commission }}</div>
        </div>
      </div>
      <div class="item">
        <div>
          <div class="itemName">{{ $t('lang.close.storage') }}</div>
          <div class="itemValue">{{ item.storage==""||item.storage==null?"":"$"+item.storage }}</div>
        </div>
      </div>
      <div class="item">
        <div>
          <div class="itemName">{{ $t('lang.close.profit') }}</div>
          <div class="itemValue">{{ item.price==""||item.price==null?"":"$"+item.price }}</div>
        </div>
      </div>
      <div class="longItem">
        <div>
          <div class="itemName">{{ $t('lang.balance.opentime') }}</div>
          <div class="itemValue">{{ item.openTime }}</div>
        </div>
      </div>
      <div class="longItem">
        <div>
          <div class="itemName">{{ $t('lang.close.closetime') }}</div>
          <div class="itemValue">{{ item.closeTime }}</div>
        </div>
      </div>
    </div>

    <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
                   style="margin-top: 20px"
                   :current-page="currentPage"
                   :page-sizes="[1,5,10,20]"
                   :page-size="pageSize"
                   layout="prev, pager, next"
                   :total="total">
    </el-pagination>
  </div>

</template>

<script>
import { getOrder} from "@/api/home";
export default {
  name: "closedCard",
  props:['id'],
  data() {
    return {
      zlid:this.id,
      orderType:1,
      currentPage: 1, // 当前页码
      total: 20, // 总条数
      pageSize: 5, // 每页的数据条数
      typedata:["buy","sell","buy limit","sell limit","buy stop","sell stop","balance","credit"],
      tableData: [
        {

        },

      ],
      form:{
        mt5Login:this.zlid,orderType:this.orderType,pageSize:this.pageSize,pageNum:this.currentPage
      }
    }
  },
  methods:{
    //每页条数改变时触发 选择一页显示多少行
    getlist(){
       var form ={
         mt5Login:this.zlid,orderType:this.orderType,pageSize:this.pageSize,pageNum:this.currentPage
      }
    getOrder(form).then(res=>{
      res.data.rows.forEach((i,x)=>{
        i.openTime = this.gettime(i.openTime)
        i.closeTime = this.gettime(i.closeTime)
        i.volume = i.volume/100
        if(i.cmd==2 || i.cmd==4 || i.cmd==5)
        {
          i.commission=""
          i.storage=""
          i.profit=""
        }
        else if(i.cmd==6 || i.cmd==7)
        {
          i.openPrice=""
          i.volume=""
          i.symbol=""
          i.closeTime=""
          i.closePrice=""
          i.commission=""
          i.storage=""
        }
        i.cmd = this.typedata[i.cmd]

      })
      this.tableData=res.data.rows
      this.total=res.data.total
    })
    },
    handleSizeChange(val) {

      this.currentPage = 1;
      this.pageSize = val;
      this.getlist()
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {

      this.currentPage = val;
      this.getlist()
    },

    //时间格式转换
    formatTime(row, column) {
      const date = new Date(row[column.property]*1000)
      var Y = date.getFullYear() + "-";
      var M =
          (date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m =
          (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
          ":";
      var s = (date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds() );
      return Y + M + D + h + m + s;
    },

    gettime(t){
      // var _time=new Date(t* 1000);
      // var   year=_time.getFullYear();//2017
      // var   month=_time.getMonth()+1;//7
      // var   date=_time.getDate();//10
      // var   hour=_time.getHours();//10
      // var   minute=_time.getMinutes();//56
      // var   second=_time.getSeconds();//15
      // return   year+"-"+month+"-"+date+"   "+hour+":"+minute+":"+second;//这里自己按自己需要的格式拼接
      if(!t) return ""
       let offset = -new Date().getTimezoneOffset() * 60 * 1000;
       const date = new Date(t*1000 - offset)
       var Y = date.getFullYear() + "-";
       var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
       var D = date.getDate() + " ";
       var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours() ) + ":";
       var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
       var s = (date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds() );
       return Y + M + D + h + m + s;
    },
    getData() {
      getOrder({login:this.zlid,status:0}).then(res=>{
        res.data.rows.forEach((i,x)=>{
          i.volume = i.volume/100
          // i.closeTime = this.gettime(i.closeTime)
        })
        this.tableData=res.data.rows
        for(var i=0;i<this.tableData.length;i++)
        {
          if(this.tableData[i].cmd==2 || this.tableData[i].cmd==4 || this.tableData[i].cmd==5)
          {
            this.tableData[i].commission=""
            this.tableData[i].storage=""
            this.tableData[i].profit=""
          }
          else if(this.tableData[i].cmd==6 || this.tableData[i].cmd==7)
          {
            this.tableData[i].openPrice=""
            this.tableData[i].volume=""
            this.tableData[i].symbol=""
            this.tableData[i].closePrice=""
            this.tableData[i].closeTime=""
            this.tableData[i].commission=""
            this.tableData[i].storage=""
          }
        }
      })
    }
  },

  mounted(){
    
  },
  watch:{
    id:function (newData,oldData){
      this.zlid=newData;
      getOrder({login:this.zlid,status:0}).then(res=>{
       res.data.rows.forEach((i,x)=>{
        i.volume = i.volume/100
        // i.closeTime = this.gettime(i.closeTime)
       
      })
      this.tableData=res.data.rows

      for(var i=0;i<this.tableData.length;i++)
      {
        if(this.tableData[i].cmd==2 || this.tableData[i].cmd==4 || this.tableData[i].cmd==5)
        {
          this.tableData[i].commission=""
          this.tableData[i].storage=""
          this.tableData[i].profit=""
        }
        else if(this.tableData[i].cmd==6 || this.tableData[i].cmd==7)
        {
          this.tableData[i].openPrice=""
          this.tableData[i].volume=""
          this.tableData[i].symbol=""
          this.tableData[i].closePrice=""
          this.tableData[i].closeTime=""
          this.tableData[i].commission=""
          this.tableData[i].storage=""
        }
      }
    })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/style/home/detailTabs.scss';
</style>

