<template>
  <div ref="chart" class="g-full-chart" :style="clientWidth > 1000? 'height: 500px' : 'height: 300px'"/>
</template>

<script>
export default {
  name: "activity",
  data() {
    return {
      clientWidth: document.body.clientWidth
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.clientWidth = document.body.clientWidth;
    })
    this.initChart()
  },
  methods: {
    initChart() {
      let echarts = require('echarts');
      let myChart = echarts.init(this.$refs.chart)
      let option = {
        tooltip: {
          trigger: 'axis'
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '1%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            smooth: true
          }
        ]
      };
      option && myChart.setOption(option);
    }
  }
}
</script>

<style scoped>
.g-full-chart {
  /*height: 500px;*/
  width: 100%;
}
</style>
