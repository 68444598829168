<template>
  <div class="container">
    <el-main class="main">
      <el-table
          class="table"
          v-if="clientWidth > 1000"
          :data="tableData1"
          @row-click="rowClick"
          style="width: 100%">
        <el-table-column align="center" width="70">
          <template slot-scope="scope">
            <el-image
                style="width: 50px; height: 50px; border-radius: 50%"
                :src="scope.row.signalHead"
                fit="fit"></el-image>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="traders"
            :label="$t('lang.score.traders')">
          <template slot-scope="scope">
            <div>{{scope.row.signalName}}</div>
            <i class="el-icon-s-custom" style="color: rgb(0,170,50)"></i>
            <!--            <span style="font-size: 10px; color: #908e8e; margin-left: 3px" >{{scope.row.riskLevelType}}</span>-->
            <span style="font-size: 10px; color: #908e8e; margin-left: 3px" >{{scope.row.riskLevelType==1?$t('lang.score.newBlood'):scope.row.riskLevelType==2?$t('lang.score.gUp'):scope.row.riskLevelType==3?$t('lang.score.success'):scope.row.riskLevelType==4?$t('lang.score.expert'):$t('lang.score.legend')}}</span>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="agentVal"
            :label="$t('lang.score.agency')">
        </el-table-column>
        <el-table-column
            align="center"
            prop="riskScore"
            :label="$t('lang.score.risk')">
          <template slot-scope="scope">
            <div :class="`riskscore${scope.row.riskScore}`">{{scope.row.riskScore}} risk</div>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="profitRate"
            :label="$t('lang.score.gain')">
          <template slot-scope="scope">
            <div style="color: rgb(0,170,50);">{{(scope.row.profitRate*100).toFixed(2)}}%</div>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="cycle"
            :label="$t('lang.score.cycle')">
          <template slot-scope="scope">
            <div>{{scope.row.cycle==""||scope.row.cycle==null?$t('lang.score.null'):scope.row.cycle}}</div>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="remainCycle"
            :label="$t('lang.score.remainingCycle')">
        </el-table-column>
        <el-table-column
            align="center"
            prop="totalAmount"
            :label="$t('lang.score.remaining')">
          <template slot-scope="scope">
            <div>{{scope.row.totalAmount==""||scope.row.totalAmount==null?$t('lang.score.nolimit'):scope.row.totalAmount}}</div>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="remainAmount"
            :label="$t('lang.score.amount')">
          <template slot-scope="scope">
            <div>{{scope.row.remainAmount==""||scope.row.remainAmount==null?$t('lang.score.nolimit'):scope.row.remainAmount}}</div>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="totalHarvest"
            :label="$t('lang.score.profit')">
          <template slot-scope="scope">
            <div style="border-bottom: 2px solid rgb(0,170,50);text-align: right;overflow: hidden;display: flex;
    flex-direction: column;justify-content: space-between"><span>{{scope.row.totalHarvest==null?"$"+0:"+$"+scope.row.totalHarvest}}</span><span>{{scope.row.totalLose==null?"$"+0:"-$"+scope.row.totalLose}}</span></div>

          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="followNum"
            :label="$t('lang.score.copiers')">
          <template slot-scope="scope">
            <div>{{scope.row.followNum==""||scope.row.followNum==null?0:scope.row.followNum}}</div>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="followVal"
            :formatter="formatType"
            :label="$t('lang.score.commission')">

        </el-table-column>


        <template slot="empty" style="text-align: center">
          <img src="@/assets/style/img/bg-1.png" alt="" width="100%">
          <span style="bottom: 0;text-align: center;display: block;font-size: 15px;color: rgb(166 204 243)">{{this.title==1?this.$t('lang.other.not'):this.$t('lang.other.notlogin1')}}</span>
        </template>


      </el-table>
      <div v-else style="margin-top: 30px;">
        <el-card class="box-card" v-for="(item, index) in tableData1" :key="index"  style="border: unset;">
          <div @click="mobilerouter(item.id,item.isfollow)" style="padding: 20px;border-radius: 20px;border: 1px solid rgb(38 194 254);position: relative;overflow:hidden">
            <div slot="header" class="clearfix cardTitle">
              <div class="baseMsg">
                <el-image
                    style="width: 50px; height: 50px; border-radius: 50%"
                    :src="item.signalHead"
                    fit="fit"></el-image>
                <div class="baseName">

                  <div style="font-size: 16px; margin-bottom: 1px">{{item.signalName}}</div>
                  <i class="el-icon-s-custom" style="color: rgb(0,170,50);"></i>
                  <!--                  <span style="font-size: 14px;color: #908e8e; margin-left: 3px">{{item.riskLevelType}}</span>-->
                  <span style="font-size: 14px;color: #908e8e; margin-left: 3px">{{item.riskLevelType==1?$t('lang.score.newBlood'):item.riskLevelType==2?$t('lang.score.gUp'):item.riskLevelType==3?$t('lang.score.success'):item.riskLevelType==4?$t('lang.score.expert'):$t('lang.score.legend')}}</span>
                </div>
              </div>
              <el-tag :class="`riskscore${item.riskScore} risk`" effect="dark">{{ item.riskScore }} risk</el-tag>
            </div>
            <div class="content">
              <div class="item_top" style="text-align: center">
                <div class="top_wrap">
                  <div class="title">{{ $t('lang.score.gain') }}</div>
                  <div class="value riskblack" v-if="item.profitRate==0" style="color: gray">{{(item.profitRate*100).toFixed(2)}}%</div>
                  <div class="value riskblack" v-if="item.profitRate>0" style="color: greenyellow">+{{(item.profitRate*100).toFixed(2)}}%</div>
                  <div class="value riskblack" v-if="item.profitRate<0" style="color: red">-{{(item.profitRate*100).toFixed(2)}}%</div>
                </div>
                <div class="top_wrap">
                  <div class="title">{{ $t('lang.score.copiers') }}</div>
                  <div class="value riskblack">{{item.followNum==null?0:item.followNum}}</div>
                </div>
                <div class="top_wrap">
                  <div class="title">{{ $t('lang.score.commission') }}</div>
                  <!--                  <div class="value riskblack">{{item.followVal}} /{{$t('lang.other.hand')}}</div>-->
                  <div class="value riskblack">{{ item.followType===1? item.followVal+"/"+$t('lang.other.byhand'):item.followType===2?item.followVal+"%/"+$t('lang.other.byprofit'):item.followType===3?item.followVal+"%/"+$t('lang.other.byleft'):item.followVal+"%/"+$t('lang.other.bylock')}}</div>

                </div>
              </div>
              <div class="item_content" style="text-align: center">
                <div class="item" style="width: 33%">
                  <div class="title">{{ $t('lang.score.cycle') }}</div>
                  <div class="value">{{item.cycleTime==null ? $t('lang.score.null') : item.cycleTime +$t('lang.other.day')}}</div>
                </div>
                <div class="item" style="width: 33%">

                </div>
                <div class="item" style="width: 33%">
                  <div class="title">{{ $t('lang.score.remainingCycle') }}</div>
                  <div class="value riskgay">{{item.remainCycle == null ? $t('lang.score.null') : item.remainCycle+$t('lang.other.day')}}</div>
                </div>
                <div class="item" style="width: 33%">
                  <div class="title">{{ $t('lang.score.remaining') }}</div>
                  <div class="value">{{item.totalAmount==null ? $t('lang.score.nolimit'):"$"+item.totalAmount}}</div>
                </div>
                <div class="item" style="width: 33%">

                </div>
                <div class="item" style="width: 33%">
                  <div class="title">{{ $t('lang.score.amount') }}</div>
                  <div class="value">{{item.remainAmount==null ? $t('lang.score.nolimit'):"$"+item.remainAmount}}</div>
                </div>
              </div>
            </div>
            <div class="item lastitem">
              <div>

                <div class="value">
                  <span>{{ $t('lang.score.agency') }}</span>
                  <span>{{item.agentVal}}</span>
                </div>
              </div>
            </div>
          </div>
        </el-card>
        <div style="position: relative" v-if="bg">
        <img src="@/assets/style/img/bg-1.png" style="width: 100%" />
          <span style="bottom: 0;text-align: center;display: block;font-size: 15px;color: rgb(166 204 243)">{{this.title==1?this.$t('lang.other.not'):this.$t('lang.other.notlogin1')}}</span>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import {getList, getNowList} from "@/api/home";
import axios from "axios";

export default {
  name: "follow",
  data() {
    return {
      title:"",
      bg:false,
      currentPage: 1, // 当前页码
      total: 20, // 总条数
      pageSize: 10, // 每页的数据条数
      totalpage:0,
      tablelist:[],
      form: {
        pageSize: 10,
        pageNum: this.currentPage,
        signalName:'',
        rangeTime:'',
        riskLevelType:'',
        minBalance:''
      },
      tableData1: [
      ],
      value: '1',
      value2: '1',
      value3: '1',


      clientWidth: document.body.clientWidth
    }
  },
  created() {
    this.getNowList()
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.clientWidth = document.body.clientWidth;
    })


  },

  computed: {
    scoreOptions(){
      return[
        {
          value: '1',
          label: this.$t('lang.score.risk')
        },
        {
          value: '2',
          label: this.$t('lang.score.winner')
        },
        {
          value: '3',
          label: this.$t('lang.score.welcome')
        }
      ]},
    timeOptions(){
      return[
        {
          value: '1',
          label: this.$t('lang.score.weeks2')
        },
        {
          value: '2',
          label: `${this.$t('lang.score.month1')}`
        },
        {
          value: '3',
          label: this.$t('lang.score.month3')
        },
        {
          value: '4',
          label: this.$t('lang.score.month6')
        },
        {
          value: '5',
          label:  this.$t('lang.score.all')
        },
      ]
    },
    levelOptions(){
      return[
        {
          value: '5',
          label: this.$t('lang.score.legend')
        },
        {
          value: '4',
          label: this.$t('lang.score.expert')
        },
        {
          value: '3',
          label: this.$t('lang.score.success')
        },
        {
          value: '2',
          label: this.$t('lang.score.gUp')
        },
        {
          value: '1',
          label: this.$t('lang.score.newBlood')
        },

      ]
    }
  },
  methods: {
    formatType(row,column){
      const value=row[column.property]
      const type=row["followType"]
      return  type===1? value+"/"+this.$t('lang.other.byhand'):type===2?value+"%/"+this.$t('lang.other.byprofit'):type===3?value+"%/"+this.$t('lang.other.byleft'):value+"%/"+this.$t('lang.other.bylock')
    },

    //每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {

      this.currentPage = val;
    },
    mobilerouter(row,row1){
      this.$router.push({path: '/detail',query:{id:row,isfollow:row1}})
    },
    rowClick(row) {

      this.$router.push({path: '/detail',query:{id:row.id,isfollow:row.isfollow}})
    },
    onSearch() {

    },
    getNowList() {
      getNowList().then(res => {
        if(res.data.data=="")
        {
          this.bg=true
          this.title=1
        }else if(res.data.code==401)
        {
          this.bg=true
          this.title=0
        }
        else{
          this.bg=false
          this.tableData1 = res.data.data
          /*console.log(this.tableData1,"正在复制")*/
          for(var i=0;i<this.tableData1.length;i++)
          {
            this.tableData1[i].isfollow = 1
          }
        }
      }).catch(()=>{
        this.bg=true
        this.title=1
      })
    },
    datachange(){
      this.getNowList(this.form)
    },
    reset() {

    },
    xfcolor(i){
      this.$refs[`refcolor${i}`].style.color="#0077e1"
    },
    xbcolor(i){
      this.$refs[`refcolor${i}`].style.color="#828ca0"

    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/style/home/score.scss';

</style>
<style scoped>
/deep/.el-table {
  min-height: 360px;
  /*background: url("@/assets/style/img/bg-1.png");*/
}
</style>
<style lang="scss">
.riskscore1,.riskscore2{
  background-color: #0077e1; color: #ffffff; border-radius: 20px
}
.riskscore3,.riskscore4{
  background-color: #0077e1; color: #ffffff; border-radius: 20px
}
.riskscore5,.riskscore6{
  background-color: #0077e1; color: #ffffff; border-radius: 20px
}
.riskgreen{
  color: rgb(0,170,50);
}
.riskgay{
  color: #999;
}
.riskblack{
  color: #333;
}
.lfform{width: 580px;display: flex;flex-wrap: wrap;}
.form{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}
.formItem{position: relative;cursor: pointer;width: 50%;
  p{
    position: absolute;
    z-index: 10;
    background: #fff;
    font-size: 12px;
    top: -20px;
    left: 20px;
    color: #828ca0;
    padding: 0 10px;
  }
  .el-input__inner{
    border-radius: inherit;
    padding: 0 13px;
    border: 2px solid #e7ecf1;
    transition: border-color .3s ease;
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #0b0b16;
    min-height: 56px;
    width: 260px;
    border-radius: 16px;
    height: 60px;
    line-height: 60px;
    padding-left: 30px;
    &:focus{
      border-color:#0077e1
    }

  }
}
@media only screen and (max-width: 999px) {

  .formItem,.lfform,.rfform{
    width: 100%;
  }
  .formItem{
    .el-input__inner,.el-select{width: 100%;}
  }
  .el-card{border-left: none;border-right: none;}
  .el-tabs__header{margin: 0 35px!important;}
  .el-card__header{padding: 15px 35px !important;}
  .el-card__body{padding: 0 30px!important;}
  .item_top{
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #EEF4FB;
    .top_wrap{
      width: 33%;
      &:nth-child(2){
        text-align: center;
      }
      &:nth-child(3){
        text-align: center;
      }
    }
    .title{
      color:#333;
      margin-bottom: 5px;
      font-size: 14px;
    }
    .value{
      font-size: 12px;

    }
  }
  .el-select-dropdown__list{
    padding-bottom:30px;
  }
  .item_content{
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    .item{
      width: 33%;
      &:nth-child(2n){
        text-align: center;
      }
      .title{
        color:#333;
        font-size: 14px;
        margin-bottom: 5px;
      }
      .value{
        font-size: 13px;
        color: #777;
      }
    }
  }
  .content{width: 100%;
    background: #F8FAFC;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;}
  .lastitem{
    margin-top:6px;
    width: 100%;
    padding-top: 20px;
    /*height: 46px;*/
    .title{
      color:#666666;
      margin-bottom: 6px;
      font-size: 13px;
    }
    .value{
      font-size: 12px;
    }
    span:first-child{
      float:left
    }
    span:last-child{
      float:right
    }
  }
}
</style>
