<template>
  <el-form class="form">
    <el-form-item class="formItem">
      <h2 class="loginTitle">{{$t('lang.home.login')}}</h2>
    </el-form-item>
    <el-form-item class="formItem">
      <el-input v-model="form.name" :placeholder="$t('lang.home.email')"></el-input>
    </el-form-item>
    <el-form-item class="formItem">
      <el-input v-model="form.name" :placeholder="$t('lang.home.password')"></el-input>
    </el-form-item>
    <el-form-item class="formItem">
      <a href="" class="forgetPassword">{{$t('lang.home.forgetPassword')}}</a>
    </el-form-item>
    <div class="formItemCode">
      <el-input v-model="form.name" :placeholder="$t('lang.home.code')" class="inputCode"></el-input>
      <div class="code">{{$t('lang.home.code')}}</div>
    </div>
    <el-button type="primary" round class="loginBtn">{{$t('lang.home.login2')}}</el-button>
    <el-button type="primary" plain round class="signBtn" @click="register">{{$t('lang.home.register')}}</el-button>
  </el-form>
</template>

<script>
export default {
  name: "register",
  data() {
    return {
      form: {}
    }
  },
  methods: {
    register() {
      this.$emit('register')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/style/home/homeLogin.scss'


</style>
