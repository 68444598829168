import Vue from 'vue'
import axios from 'axios'
import { getToken,removeToken, getCookey } from '@/api/auth'
import Cookies from 'js-cookie'
import {Message} from 'element-ui'
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
const i18n = new VueI18n({
	locale: getCookey(), // 语言标识
	messages: {
		'zh-EN': require('../assets/lng/en'), // 英文语言包
		'zh-CN': require('../assets/lng/cn'), // 中文语言包
		'zh-JP': require('../assets/lng/jp'), // 日语语言包
		'zh-DE': require('../assets/lng/de'), // 德语语言包
		'zh-FR': require('../assets/lng/fr'), // 法语语言包
		'zh-SP': require('../assets/lng/sp'), // 西班牙语言包
		'zh-THA': require('../assets/lng/tha'), // 泰语语言包
		'zh-RU': require('../assets/lng/ru'), // 俄语语言包
	}
})
i18n.locale = getCookey()


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    
    baseURL: dataurlhost.apihost.url,
     //baseURL: ' http://192.168.1.67:81/crm/',
    // baseURL: ' http://127.0.0.1:8080/crm/',
    // 超时120s
    timeout: 2*60*1000
})
// request拦截器
service.interceptors.request.use(config => {
  if (getToken()) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  var tplang
  if(Cookies.get("lang")==="zh-EN")
      {
        Cookies.set('crmlang','en')
        tplang='en'
       }else if(Cookies.get("lang")==="zh-CN")
      {
        Cookies.set('crmlang','zh')
        tplang='zh'
      }else if(Cookies.get("lang")==="zh-RU")
      {
        Cookies.set('crmlang','ru')
        tplang='ru'
       
      }else if(Cookies.get("lang")==="zh-SP")
      {
        Cookies.set('crmlang','sp')
        tplang='sp'
     
      }else if(Cookies.get("lang")==="zh-DE")
      {
        Cookies.set('crmlang','de')
        tplang='de'
        
      }else if(Cookies.get("lang")==="zh-FR")
      {
        Cookies.set('crmlang','fr')
        tplang='fr'
       
      }else if(Cookies.get("lang")==="zh-JP")
      {
        Cookies.set('crmlang','jp')
        tplang='jp'
      
      }else if(Cookies.get("lang")==="zh-THA")
      {
        Cookies.set('crmlang','ta')
        tplang='ta'
      
      }
    config.headers['Lang'] = tplang // 让每个请求携带自定义token 请根据实际情况自行修改
  
    return config
  }, error => {
    Promise.reject(error)
})

service.interceptors.response.use(res => {

  const code = res.data.code || 200;
  if(code === 401 && res.data.msg.includes('认证失败')){
    Message({
      message: i18n.t('lang.home.quitMsg'),
      type: 'error'
    })
    removeToken();
    setTimeout(() => {
      window.location.reload();
    }, 800);
  }
    // console.log(res.data);
/*      var messageSuccess =res.data.msg;
    if(messageSuccess==""||messageSuccess==null||messageSuccess==undefined){
      return res.data;
    } else{
      messageSuccess =res.data.msg.split("|");
      if(messageSuccess.length>1){
        res.data.msg=messageSuccess[lang-1];
      }
    }*/
    return res
},
error => {

  return Promise.reject(error)
}
)
export default service
