import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import defaultimg from "@/assets/image/cloudbridge3.png"
import VueCookies from 'vue-cookies'

Vue.prototype.axios = axios;

Vue.use(ElementUI);
Vue.use(VueI18n);
Vue.use(VueAxios, axios)
Vue.use(VueCookies);

Vue.config.productionTip = false

Vue.directive('errorimg' ,{
	//执行时机：绑定了当前指令的元素的所有属性和事件
	inserted(el){
		//获取的是真是的dom元素，可以使用dom的所有事件
		el.onerror = function(){
			//将默认图片赋值给el.src属性
			el.src = defaultimg
		}
	},
	//如果有分页，会在第二页的不会渲染，这个时候我们就换个函数
})
const i18n = new VueI18n({
	locale: 'zh-CN', // 语言标识
	messages: {
		'zh-CN': require('./assets/lng/cn'), // 中文语言包
		'zh-EN': require('./assets/lng/en'), // 英文语言包
		'zh-RU': require('./assets/lng/ru'), // 俄语语言包
		'zh-SP': require('./assets/lng/sp'), // 西班牙语言包
		'zh-DE': require('./assets/lng/de'), // 德语语言包
		'zh-FR': require('./assets/lng/fr'), // 法语语言包
		'zh-JP': require('./assets/lng/jp'), // 日语语言包
		'zh-THA': require('./assets/lng/tha'), // 泰语语言包
	}
});


axios.get('/config.json').then(res => {
	//定义全局组件baseConfig(变量名，随意取)，res.data就是config.json中的对象
	Vue.prototype.baseConfig = JSON.parse(JSON.stringify(res.data))
	new Vue({
		render: h => h(App),
		router,
		i18n
	}).$mount('#app')

})
