export const lang = {
    home: {
        countLogin: 'Connexion au compte',
        transaction: 'Plateformes de négociation',
        traders: 'Meta Trader 4',
        tradersOnline: 'Meta Trader 4 - Trading en ligne',
        plan: 'Programmes recommandés',
        copy: 'Copy Trading',
        aboutCopier: 'Pour les photocopies',
        joinCopy: 'Rejoignez le commerce de la copie',
        getLevel: 'Évaluations de Master Trader',
        aboutTeacher: 'Pour les maîtres-commerçants',
        beTeacher: 'Devenir un maître trader',
        msg: 'Information',
        clause: 'Modalités et conditions',
        aboutUs: 'A propos de nous',
        news: 'Nouvelles de l\'entreprise',
        problem: 'Questions fréquemment posées',
        score: 'tactique',
        follow: 'Réplication',
        clause2: 'Modalités et conditions',
        login: 'Connexion',
        email: 'Boîte aux lettres',
        password: 'Mot de passe',
        forgetPassword: 'Vous avez oublié votre mot de passe ?',
        code: 'Code de vérification',
        login2: 'Connexion',
        register: 'Inscription',
        google: 'Google Captcha',
        qlgoogle: 'Veuillez entrer le code d\'authentification Google',
        center: 'Centre personnel',
        quit: 'Déconnexion',
        quitMsg: 'La connexion est désactivée, veuillez vous connecter à nouveau',
    },
    message: {
        choose: 'Pourquoi choisir',
        changeMarket: 'Marchés d\'échange',
        changeTool: 'Plateformes et outils de négociation',
        community: 'BlaFX',
        companies: 'Partenaires',
        supervise: 'Ma supervision',
        VIP: 'Service Premium VIP',
        active: 'Programme pour les négociants actifs',
        out: 'Trading de devises',
        contract: 'CFDs sur indices',
        goods: 'Produits de base',
        CFD: 'CFDs sur indices de devises（CFD）',
        money: 'Spreads, swaps et commissions',
        time: 'Heures de négociation',
        count: 'Comptes d\'opérations',
        inAndOut: 'Dépôt et retrait',
        begin: 'Commencez maintenant',
        MetaTrader: 'MetaTrader 4',
        MetaTraderChange: 'MetaTrader 4-Commerce en ligne',
        study: 'Apprendre à commercer',
        news: 'Informations économiques',
        com: 'Communauté des adeptes de BlaFX',
        proxy: 'Services aux agents',
        friend: 'Programme de référencement',
        title: 'Les services que nous proposons comprennent la négociation de produits de gré à gré et de produits de change et comportent le risque de pertes supérieures à l\'injection initiale de capital. Ces produits peuvent ne pas convenir à tous les investisseurs. Assurez-vous de bien comprendre les risques encourus et demandez conseil à un tiers si nécessaire.',
        p1: 'Avertissement concernant les risques : la négociation des CFD et du Forex comporte des risques. Il ne convient pas à tout le monde et vos pertes peuvent dépasser de loin votre investissement initial. Vous n\'aurez pas la propriété ou des droits sur l\'actif sous-jacent. Les performances passées ne sont pas indicatives des performances futures et les lois fiscales sont susceptibles d\'être modifiées. Les informations figurant sur ce site web sont de nature générale et ne tiennent pas compte de vos objectifs personnels, de votre situation financière ou de vos besoins, ni de ceux de vos clients. Veuillez lire notre avis de divulgation des risques et les autres documents juridiques afin de vous assurer que vous comprenez parfaitement les risques avant de prendre toute décision de trading. Nous vous encourageons à demander un avis indépendant.',
        p2: 'BlaFX Capital PTY LTD est situé à Suite 280 156 Burwood Road Bureood,NSW 2134 Australie, licencié et régulé par l\'Australian Securities Commission. Les informations contenues dans ce site web et les produits et services offerts ne sont pas destinés à être commercialisés auprès de quiconque dans quelque pays ou juridiction que ce soit.',
        p3: ' 2020 BlaFX Capital PTY LTD| Company Registration Number 661 715 966 | AFS - 001298638'
    },
    score: {
        invested: 'Avez-vous investi ？',
        signUp: 'Inscrivez-vous et commencez à copier n\'importe quel trader Master Trader afin d\'obtenir des rendements plus élevés et plus stables pour vos investissements.',
        score: 'Notes d\'experts',
        traders: 'Traders',
        agency: 'commission\nagence%',
        risk: 'Evaluation des risques',
        gain: 'Rendement',
        cycle: 'Cycle de\nverrouillage',
        remainingCycle: 'Cycles\nrestants',
        remaining: 'Magnitude brute',
        amount: 'Crédit\nrestant',
        profit: 'Gains et\npertes',
        copiers: 'Réplicateurs',
        commission: 'commission de\ncopie %',
        winner: 'Premier lauréat',
        welcome: 'Les traders les plus populaires',
        weeks2: '2 semaines',
        month1: '1 mois',
        month3: '3 mois',
        month6: '6 mois',
        all: 'Tous',
        legend: 'Saga',
        expert: 'Experts',
        success: 'Successeurs',
        gUp: 'Grandir',
        newBlood: 'Nouveau venu',
        showFrist: 'Premier à montrer',
        rangTime: 'Calendrier',
        mixBalance: 'Investissement minimum',
        mixknow: 'Expertise minimale',
        nickName: 'Surnom :',
        null: 'non',
        nolimit: 'illimité'
    },
    detail: {
        riskScore: 'Evaluation des risques',
        equity: 'Valeur nette du compte',
        followVal: 'Commission de copie',
        regDate: 'Heures de négociation',
        followsetting: 'Paramètres de suivi',
        mintz: 'Investissement minimum',
        tradingStrategyString: 'Notes de stratégie',
        tradingStrategyString1: 'Description du cas de politique',
        followway: 'Stratégie de copie',
        bx: 'Performance',
        onemonth: '1 mois',
        threemonth: '3 mois',
        sixmonth: '6 mois',
        all: 'Tous',
        profit: 'Profit',
        copiers: 'Réplicateurs',
        profitandloss: 'Gains et pertes',
        accountdetail: 'Détails du compte',
        folatprofit: 'Bénéfice flottant',
        balance: 'Balance',
        givegold: 'Subventions',
        lever: 'Effet de levier',
        riskmanage: 'Gestion des risques',
        maxprofit: 'Perte flottante maximale',
        maxmax: 'Durée maximale du retracement',
        active: 'Événements',
        transferhistory: 'Historique des transactions',
        historyorder: 'Commandes historiques',
        holdorder: 'Ordres de position',
        leftoperration: 'Opérations de solde',
        limitorder: 'Ordres à cours limité',
        minfollow: 'Quantité minimale de copie',
        nr: 'Disons que le solde du compte de signal est de 100 000 USD, le solde de votre compte de copie est de 10 000 USD, le compte de signal passe 10 commandes et votre compte de copie place 1 lot. Disons que le solde du compte de signal est de 20 000 USD, le solde de votre compte de copie est de 200 000 USD, le compte de signal passe 1 commande de lot et votre compte place 10 lots. La formule de calcul est la suivante : taille du lot de votre commande de copie = solde de votre compte de copie÷ solde du compte source de signal x taille du lot du compte source de signal',
        nr1: 'Le rapport de balance 1:1 est utilisé pour calculer automatiquement la position de la position à copier',
        manageFollow: 'Gestion des commandes',
        followMsg: 'Une fois que vous avez fermé votre ordre, vous devez fermer manuellement toutes les positions dans la source de signaux que vous avez copiée s’il y a des transactions ouvertes.',
        careful: 'Veuillez noter',
        endFollow: 'Clôture d’une commande',
        cancel: 'Annuler',
        confirm: 'Confirmation',
        remind: 'Rappel',
        inCash: 'Dépôt',
        outCash: 'Retrait',
        desc1: 'Si vous voulez suivre le signal avec une taille de position plus importante, veuillez',
        desc2: 'Si vous voulez suivre un signal avec une position plus petite, veuillez',
        modalTitle: 'Instructions pour le suivi des commandes',
        modalHtml: `<div>
            1. BlaFX propose une fonction de copiage. Lorsque vous décidez de copier un ou plusieurs traders ou de mettre en œuvre une stratégie particulière, vous devez prendre en compte votre situation financière globale, y compris votre planification financière, et comprendre que l'utilisation de la fonction de copiage est hautement spéculative et que vous pouvez être exposé à des pertes plus importantes que le montant utilisé pour suivre le trader. Les risques associés à la fonction de copiage comprennent, sans s'y limiter, les opérations de négociation automatisées dans le cadre desquelles votre compte peut commencer et terminer des opérations sans intervention manuelle de votre part.<br /><br />
            2. l'exécution automatisée des transactions et d'autres opérations de trading connexes peuvent être effectuées sur votre compte sans que vous ayez à intervenir manuellement pour ouvrir et fermer des positions<br /><br />
            3. le service de gestion des investissements de BlaFX est à votre disposition et c'est votre décision de suivre un trader particulier ou plusieurs traders et de suivre une stratégie de trading particulière. En prenant de telles décisions, vous avez pris en compte votre situation financière globale, y compris la planification financière, et vous comprenez que l'utilisation de la fonction de copie de trading est hautement spéculative et que vous pouvez subir des pertes qui sont significativement plus importantes que le montant utilisé pour suivre le trader.<br /><br />
            4. BlaFX et les employés et agents de ses filiales ne sont pas des conseillers en investissement ou des conseillers financiers. Si vous prenez une décision d'investissement sur la base des informations fournies sur notre site web ou accessibles par la fonction de copy trading, vous le faites à vos propres risques et BlaFX, ses affiliés, ses employés et ses agents ne peuvent être tenus responsables de toute perte.<br /><br />
            5. vous devez faire preuve de discernement avant de prendre une décision d'investissement. Vous devez juger de manière indépendante si un investissement, une stratégie ou tout autre produit ou service répond à vos besoins à la lumière de vos propres objectifs d'investissement et de votre situation personnelle et financière.<br /><br />
            6. L'utilisation que nous faisons des performances passées d'un trader, des ratios de risque, des statistiques et de toutes les informations relatives à ce trader affichées sur notre site web et nos applications n'est pas une indication des performances futures et doit être considérée comme hypothétique.<br /><br />
            7. Les performances passées et les taux de risque doivent être considérés comme des performances hypothétiques. Les performances hypothétiques ont de nombreuses limites inhérentes. Il n'y a aucune représentation ou garantie qu'un compte atteindra ou est susceptible d'atteindre un gain ou une perte similaire à la performance passée ou au ratio de risque indiqué. Les gains/pertes réels en pourcentage peuvent varier d'un investisseur à l'autre en fonction d'un certain nombre de facteurs, y compris, mais sans s'y limiter, les soldes des comptes (dépôts et retraits) au moment de la négociation, le comportement du marché, la configuration du compte de l'investisseur et la performance réelle de la personne auprès de laquelle l'ordre est passé.<br /><br />
            8. Vous comprenez et acceptez les risques associés au service de suivi des ordres, y compris, mais sans s'y limiter, les opérations de négociation automatisées dans le cadre desquelles votre compte peut commencer et terminer la négociation sans intervention manuelle de votre part, le risque de coûts élevés associés à la latence des transactions de suivi des ordres et le risque de défaillance du service de suivi des ordres. Vous êtes seul responsable de toutes les pertes que vous subissez du fait de l'exécution automatisée d'opérations utilisant le service de suivi d'ordres.
        </div>`,
        modalBtn1: 'Annulation',
        modalBtn2: 'Accepter',
    },
    clause: {
        caluse1: 'BETHLE ASTER COPY TRADING TERMES ET CONDITIONS',
        caluse2: `BETHLE ASTER (ci-après dénommé "BlaFX") copy trading (ci-après dénommé "service") est un service qui permet aux commerçants (ci-après dénommés "copieurs") de copier les transactions d'autres commerçants ( ci-après dénommés "copieurs") et peuvent être activés en sélectionnant "Démarrer la réplication".`,
        caluse3: "1. Le Copieur s'engage à faire ce qui suit pour demander le Service",
        caluse4: '1.1. Être enregistré et connecté en tant que client sur le site BETHLE ASTER.',
        caluse5: "Veuillez noter que le premier compte MT que vous ouvrez par défaut lorsque vous créez un compte est le portefeuille de commissions par défaut, qui ne peut que négocier, déposer et transférer des fonds, mais pas suivre les ordres. Vous pouvez créer jusqu'à 5 comptes MT et chaque nouveau compte MT pourra passer des commandes, des retraits, des transferts et des transactions de manière indépendante.",
        caluse: 'Rappel spécial',
        caluse121: "1.2.1. S'il existe un compte qui négocie sur un compte en attente ou autogéré, les autres fonctions de financement connexes de ce compte seront restreintes, aucun transfert, retrait et nouvelle opération en attente ou autogéré ne sera effectué, et ne sera repris si ce compte est fermé pour des transactions en attente ou autogérées. Bien sûr, vos autres comptes MT qui ne négocient en aucune façon ne sont pas affectés par cela.",
        caluse122: "1.2.2. BETHLE ASTER s'efforce toujours de réduire le risque de non-décision de nos utilisateurs. Si votre compte MT a une position propriétaire, vous devez clôturer votre commande propriétaire avant de pouvoir passer une commande de suivi. Si votre compte MT a une position de suivi. Vous devez arrêter de suivre les ordres et fermer votre position manuellement avant de pouvoir trader sur votre compte MT.",
        caluse6: '1.3. Lancez la copie.',
        caluse7: '2. Le copieur est autorisé à faire ce qui suit',
        caluse8: "2.1. Copiez n'importe quel nombre de Masters (la zone de copie répertorie tous les abonnements en cours).",
        caluse9: "2.2. Vous pouvez clôturer manuellement une transaction avec un seul ordre à tout moment dans la zone Suivre l'ordre (sauf pour les sources de signal avec des périodes de verrouillage).",
        caluse10: '2.3. Désabonnez-vous du Master et arrêtez de copier les trades du Master en cliquant sur "Arrêter la copie". Pour vous désabonner, le Copier doit avoir fermé tous les trades. Lors de la désinscription, tous les fonds investis auprès du Maître et les bénéfices reviennent au Portefeuille du Copieur.',
        caluse11: '2.4. Définissez la taille de la proportion de copie pour chaque Master particulier. Cette option est expliquée en détail dans la sous-clause 4.4. des présentes conditions générales.',
        caluse12: "2.5. Ajoutez des fonds de soutien pour protéger l'investissement des mouvements inattendus du marché. Ces fonds sont utilisés pour soutenir la stratégie commerciale de Copier lorsque le marché fluctue et n'influencent pas directement les bénéfices.",
        caluse13: "2.6. En cas de participation aux opérations d'investissement de chaque Master Trader particulier, le calcul du montant investi par le Réplicateur est fixé au prorata des fonds du Réplicateur. Ceci est explicitement expliqué à l'article 4, alinéa 5 des Conditions générales.",
        caluse14: '3. Le Service est disponible sur la plateforme Metatrader 4.',
        caluse15: '4. Ouverture de la routine de transactions copiées',
        caluse16: '4.1. Le copieur ne copie que les transactions qui ont été ouvertes par le maître après la souscription du maître au sein du service.',
        caluse17: '4.2. Les ordres stop loss/take profit ne seront pas visibles dans la zone de copie, mais si ces ordres sont déclenchés sur le compte du maître, les transactions copiées sont également fermées.',
        caluse18: '4.3. Lors de la souscription à un Master, le Copieur précise le montant des fonds à déduire du Wallet et à investir auprès du Master sélectionné. Ces fonds et vos bénéfices reviendront dans votre portefeuille lorsque vous arrêterez de copier le maître.',
        caluse19: '4.4.Le réplicateur réplique les transactions du Master Trader au prorata des fonds du compte suivant et du compte Signal Source Master Trader souscrit.',
        caluse20: "4.4.1. Dans l'option ratio, le volume de trading est calculé sur la base du ratio de levier et de la valeur nette du trader Trader Master et du suiveur. La formule utilisée est la suivante.",
        caluse21: "Volume (Abonnés) = Net (Abonnés) / Net (Master Trader) × Effet de levier (Abonnés) / Effet de levier (Master Trader) × Volume (Master Trader). Par exemple, un Master Trader a une valeur nette de 2 000 $ et un effet de levier de 1:200. Un Follower a une valeur nette de 200 $ et un effet de levier de 1:500. 1 lot est ouvert sur le Compte Maître. Ensuite, le volume de la transaction suivante est de 200/2000 x 500/200 x 1 = 0,25 lots.",
        caluse22: "4.5. Le ratio de levier des copieurs est fixé à 1:100. Les copieurs souhaitant l'ajuster doivent contacter le service clientèle de BETHLE ASTER.",
        caluse24: '4.6. Une fois le service activé, les transactions seront copiées sur le compte du copieur, que le propriétaire du compte soit connecté ou non.',
        caluse25: "4.7. L'ordre du Copieur est exécuté suite à l'ordre (Achat ou Vente) effectué sur le compte du Maître. Lorsque le maître passe une commande, le signal d'ouverture de commande se déclenche dans le compte du copieur. L'ordre du Copieur est exécuté au prix du marché. Le même mécanisme déclenche la clôture de l'ordre. Par conséquent, les prix d'exécution de ces ordres peuvent différer. De plus, le nombre de copieurs suivant ce maître peut affecter le temps d'exécution.",
        caluse26: '5. Limites appliquées',
        caluse27: "5.1. Le volume minimum de la transaction copiée est de 0,01 lot, le volume maximum d'une transaction copiée est de 100 lots.",
        caluse28: '5.2. Le volume minimum de commandes copiées est de 0,01 lot. Cependant, les ordres copiés avec un volume inférieur à 0,005 lot seront refusés, tandis que les transactions copiées à partir de 0,005 lot et plus seront arrondies à 0,01 lot.',
        caluse29: '5.3 Le volume de toute commande est arrondi au centième décimal le plus proche (le deuxième chiffre après la virgule). Par exemple, si le copieur copie une commande de 0,324 lots, la commande sera arrondie à 0,32 lots. Inversement, si le Copieur copie une commande de 0,325 lots, la commande sera arrondie à 0,33 lots.',
        caluse30: '5.4. Si le volume de transaction copié est supérieur à 100 lots après le calcul, la transaction ne sera pas ouverte sur le compte Copier.',
        caluse31: "6. Si le maître modifie l'équité (en effectuant un dépôt ou un retrait) ou l'effet de levier, toutes les transactions copiées conservent leur volume initial sur le compte du copieur.",
        caluse32: '7. Toutes les conditions de trading (effet de levier, swaps, spreads) des copieurs sont similaires à celles des comptes BETHLE ASTER MT4.',
        caluse33: '8. Un compte Master Trader doit être de type MT4 Micro.',
        caluse34: '9. Le Service a le droit de faire ce qui suit.',
        caluse35: '9.1. Restreindre le nombre de comptes maîtres que les maîtres peuvent créer à tout moment sans notification préalable à sa seule discrétion.',
        caluse36: '9.2. Désabonner le copieur du maître sans notification préalable.',
        caluse37: '9.3. Réduisez la commission fixée par le Master Trader et limitez sa valeur maximale pour tous ses comptes principaux sans notification préalable ni explication.',
        caluse38: '9.4. Modifier ces Termes et Conditions à tout moment sans préavis au Copieur ou Master Trader. Ces modifications entrent en vigueur au moment de leur publication sur le site du Service dans les présentes Conditions Générales.',
        caluse39: `9.5. Surveillez l'activité du Master Trader sur la plate-forme, marquez le compte du Master Trader avec l'avertissement "Activité suspecte" si le Master Trader manipule ses statistiques, et excluez ce compte du Master Rating filtré par défaut (le gardera disponible pour les copieurs qui changent leurs paramètres de filtre en conséquence).`,
        caluse40: "9.6 Modifier ou supprimer le surnom et (ou) la photo de l'utilisateur du Maître sans préavis si le Service soupçonne raisonnablement que ce Maître copie ou imite délibérément le surnom et (ou) la photo d'un autre Maître, ce qui peut conduire à une représentation malhonnête du Maître.",
        caluse41: '10. Le master trader détermine le montant de la commission pour les ordres suivants. La commission peut aller de 0% à 50% du bénéfice de la commande passée. La commission sera versée au porte-monnaie électronique du trader principal après la clôture de chaque transaction.',
        caluse42: `11. Le montant de la commission que le Copieur verse au Maître est défini au moment où le Copieur appuie sur "Démarrer la copie". Si le Maître modifie le montant de la commission, cela n'affecte pas le montant dû au titre de cet abonnement au Maître.`,
        caluse43: '12. Les statistiques de trading du Copieur ne peuvent être consultées que par le Copieur.',
        caluse45: '13. Les statistiques de trading du Master sont accessibles au public.',
        caluse46: "14. Les copieurs n'ont pas accès au terminal de négociation. Toutes les actions avec leurs abonnements et échanges sont effectuées dans la zone Copieur.",
        caluse47: '15. Si le Service soupçonne raisonnablement que le Copieur a enfreint les règles de dépôt et de retrait énoncées dans le Contrat client ou la législation du pays de résidence du Copieur, le Service est en droit de suspendre la fourniture des services à ce Copieur.',
        caluse48: "16. N'oubliez pas que le maître peut faire des transactions à la fois rentables et perdantes.",
        caluse49: "BETHLE ASTER se réserve le droit d'interprétation finale de ces termes et conditions.",
        caluse50: '',
        caluse51: '',
        caluse52: '',
        caluse53: '',
        caluse54: '',
        caluse55: '',
        caluse56: '',
        caluse57: '',
        caluse58: '',
        caluse67: '',
        caluse68: '',
        caluse69: '',
        caluse70: '',
        caluse71: '',
        caluse72: '',
    },
    join: {
        copy: 'Profiter en reproduisant le succès des traders professionnels',
        wenan: 'Le Copytrading de BlaFX-FX offre aux clients la possibilité de copier automatiquement les stratégies des meilleurs traders sans avoir à passer de longues heures à développer leurs propres stratégies de trading. Faites votre choix parmi les meilleurs gourous du trading forex et diversifiez votre portefeuille de trading.',
        join: 'Ajouter Copytrading',
        become: 'Ou devenir un maître trader',
        way: 'Comment cela fonctionne',
        creat: 'Créer un compte et effectuer un dépôt',
        simpleway: 'Inscrivez-vous en une seule étape, puis utilisez la méthode de paiement de votre choix pour effectuer votre dépôt dans votre portefeuille. Si vous disposez déjà de fonds sur votre propre compte de négociation BlaFX-FX, vous pouvez utiliser les transferts internes pour ajouter des fonds de votre compte à votre portefeuille. Le solde de votre portefeuille indique les fonds que vous n\'avez pas investis.',
        followbest: 'Suivez les meilleurs traders',
        followandcopy: 'Trouvez le gourou que vous souhaitez suivre et cliquez sur "Copier". Leurs positions seront copiées automatiquement. Le réglage du pourcentage de dépôt vous aidera à gérer votre portefeuille. Essayez de copier différentes stratégies et décidez de la meilleure !',
        moandprofit: 'Suivi et rentabilité！',
        copycopy: 'Il n\'y a pas de limite au nombre de Master Traders que vous pouvez copier, ce qui vous permet de créer un portefeuille équilibré et diversifié de transactions et de gagner un revenu régulier. Vous avez également un contrôle total sur l\'ensemble du processus et pouvez revoir/arrêter la copie des transactions à tout moment. Vous pouvez consulter les données de trading détaillées du Master Trader copié dans votre zone de copie.',
        followprofitbest: 'Suivez les traders les plus rentables',
        noneed: 'Pas besoin d\'être un expert du forex',
        manyways: 'Des rendements stables grâce à un portefeuille diversifié',
        new: 'Ce nouvel outil aide les traders à obtenir des rendements plus réguliers en copiant des traders plus expérimentés.',
        learnbest: 'Apprenez auprès des meilleurs traders',
        easytoin: 'Faciliter la tâche des nouveaux venus sur le marché des changes',
        spread: 'Portefeuille diversifié',
        choosebig: 'Choisissez un maître trader et suivez un grand groupe d\'experts.',
        enjoyfast: 'Profitez d\'un traitement rapide des commandes',
        infive: 'Les ordres sont exécutés dans les 5 minutes suivant l\'ouverture de la position.',
        inhand: 'Garder tout sous contrôle',
        anytimestop: 'Vous pouvez arrêter de copier, vous abonner ou clôturer une transaction à tout moment.',
        easyway: ' Prêt à fonctionner en quelques étapes simples',
        noneedother: 'Aucune validation supplémentaire n\'est requise.',
        safe: 'Investissement et retrait des fonds sûrs et rapides',
        mangmain: 'Une variété d\'options de paiement classiques.',
        alluseful: 'Profitez de tous les avantages de la reproduction des transactions dans une application mobile.',
        foces: 'Concentrez-vous sur vos investissements grâce à l\'application Copytrading BlaFX-FX facile à utiliser.',
        manage: 'Contrôlez votre portefeuille et vos investissements en déplacement',
        follow: 'Suivez les grands traders et leurs performances en temps réel pour investir intelligemment.',
        seerisk: 'Voyez comment votre argent est investi et gérez les risques en temps réel.',
        howto: 'Comment choisir un maître-négociant à suivre ?',
        firstcheck: 'Commencez par vérifier les données de l\'opérateur principal. Il comprend les scores de risque, les rendements, les profits et les pertes, le nombre de followers, les commissions, l\'historique des commandes et d\'autres statistiques pour vous aider à prendre une décision éclairée. Pour commencer à suivre une commande, approvisionnez votre compte.',
        anyuseful: 'Quelles sont les options de suivi disponibles ?',
        ways: 'Lorsque vous vous abonnez au Trader, vous devez spécifier un pourcentage d\'appel et décider d\'ajouter ou non des fonds de soutien. Lorsque vous appuyez sur Start Call, les fonds sont déduits de votre portefeuille et l\'appel commence. Vous pouvez vous abonner à un nombre illimité de Master Traders en même temps.',
        did: 'Facturez-vous une commission pour le suivi des commandes ?',
        didas: 'En tant que votre courtier, nous ne facturons pas de commissions. Vous payez uniquement une commission au Master Trader, qui est prédéterminée et spécifiée individuellement. Les statistiques de chaque Master Trader indiquent le montant de la commission.',
        howstop: 'Comment arrêter de suivre un Master Trader？',
        anytimecancle: 'Vous pouvez annuler votre abonnement au service Trader Trader et cesser de suivre les ordres à tout moment. Lorsque vous annulez votre abonnement, tout l\'argent investi dans Trader Trader et les bénéfices que vous avez réalisés grâce à vos ordres suiveurs seront crédités dans votre portefeuille.',
        howprofit: 'Comment retirer les bénéfices ？',
        wallet: 'Vous pouvez retirer de votre porte-monnaie. Mettez fin à votre abonnement à Trader Trader et transférez votre investissement et vos bénéfices sur votre porte-monnaie. Veuillez noter que si vous mettez fin à votre abonnement à Trader Trader avec une perte, vous ne pourrez pas récupérer votre investissement initial. Le montant perdu sera déduit de votre investissement initial.',
        getprofit: 'Devenez un maître trader et obtenez des rendements réguliers',
        becomaster: 'Devenir un maître trader',
        rules: 'Modalités et conditions'
    },
    balance: {
        orderid: 'Numéro de commande',
        opentime: 'Heure d\'ouverture',
        balantime:'Temps de solde',
        type: 'Type',
        profit: 'Avantages',
    },
    close: {
        openprice: 'Prix d\'ouverture',
        hand: 'Nombre de mains',
        symbol: 'Commerce des variétés',
        closetime: 'Temps de clôture',
        closeprice: 'Prix de clôture',
        comission: 'Frais de manutention',
        storage: 'Frais d\'inventaire',
        profit: 'Avantages',
        nowprice: 'Prix actuels'
    },
    joinmaster: {
        good: 'Vous êtes doué pour le commerce ? Amenez les gens à suivre vos transactions et à gagner ainsi de l\'argent.',
        one: 'Traitez normalement tout en gagnant un revenu supplémentaire grâce aux autres traders qui vous copient. Votre profil de Master Trader affiche les performances quotidiennes et mensuelles de vos transactions - faites-vous connaître et attirez de nouveaux adeptes !',
        become: 'Devenir un maître trader',
        runway: 'Comment cela fonctionne',
        creat: 'Créer un compte Master Trader',
        click: 'Cliquez sur l\'espace Master Trader et créez un compte Master Trader - ouvrez un nouveau compte ou attribuez un compte existant à votre compte Master Trader.',
        accountsetting: 'Configuration du compte',
        beready: 'Préparez votre compte Master Trader pour les réplicateurs à suivre : fixez le montant de votre commission et décrivez votre stratégie.',
        use: 'Utilisez le Coin du trader pour consulter les données détaillées de vos transactions, modifier les paramètres de votre compte et voir combien de commissions vous avez gagné.',
        offer: 'Le Copytrading de BlaFX-FX vous offre une source de revenus supplémentaire : ouvrez un compte de Master Trader, décrivez votre stratégie, puis mettez en place des commissions pour que d\'autres copient vos transactions.',
        different: 'Créer différents comptes Master Trader pour différentes stratégies',
        detail: 'Suivez les détails de vos commandes dans l\'espace Master Trader et gagnez des commissions Master Trader.',
        enjoy: 'Profitez d\'une variété d\'avantages sans payer de commissions supplémentaires à BlaFX-FX.',
        android: 'Vous êtes dans la section Master Trader de l\'application de trading BlaFX-FX pour Android.',
        mobile: 'Créer un compte Master Trader dans Mobile',
        anywhere: 'Visualisez les statistiques et les commissions de vos réplicateurs où que vous soyez.',
        now: 'Gestion sur place des conditions de réplication de votre nouvel adepte',
        how: ' Comment devenir un Master Trader ?',
        get: 'Tout client de BlaFX-FX possédant un compte MT4 peut devenir un Master Trader. Vous pouvez postuler au service clientèle en déposant 100 000 $ et en négociant pendant trois mois avec un bénéfice stable.',
        howmanage: 'Comment puis-je ajuster le montant de la commission que je facture aux réplicateurs ?',
        go: 'Allez dans votre espace Master Trader, consultez les paramètres, ajustez la commission à l\'aide du curseur, puis enregistrez. Les nouvelles commissions ne s\'appliqueront qu\'aux réplicateurs qui s\'abonneront après que l\'ajustement aura été effectué. Pour tous les autres réplicateurs, le montant de la commission restera le même.',
        time: 'Quand recevrai-je une commission de mon réplicateur ?',
        american: 'Le paiement est effectué chaque dimanche à 18 heures, heure américaine.',
        when: 'Quand la commission est-elle facturée au réplicateur ?',
        comission: 'Une commission est prélevée chaque samedi sur les commandes fermées.',
        whencanget: 'Quand pourrai-je recevoir ma commission ?',
        wallet: 'Nous transférons les commissions sur un porte-monnaie électronique. À partir de votre portefeuille, vous pouvez ajouter des fonds à n\'importe lequel de vos comptes de négociation ou retirer des fonds.',
        copy: 'Reproduisez automatiquement les stratégies des meilleurs traders sans avoir à passer de longues heures à développer vos propres stratégies de trading.',
        or: 'Ou devenir un réplicateur',
    },
    other: {
        signalaccount: 'Comptes de signaux',
        signalname: 'Surnom du signal',
        wallet: 'Fonds de portefeuille',
        hold: 'Montant de l\'aide',
        need0: '0% de l\'investissement requis',
        zj: 'Résumé',
        follow: 'Multiplicateur de commande',
        followmoney: 'Coûts de suivi',
        begin: 'Début de l\'ordre suivant',
        hand: 'Main',
        more: '25 ou plus',
        choose: 'Veuillez sélectionner',
        notlogin: 'Vous n’êtes pas encore connecté, veuillez d’abord vous connecter',
        byhand: 'Taille du lot',
        byprofit: 'bénéfice',
        byleft: 'balance',
        bylock: 'Couverture',
        day: 'ciel',
        sure: 'Confirmer',
        not: 'Vous n’avez pas encore copié le trading',
        receiptaccount: 'Ouvrir un compte',
        balance: 'Solde disponible',
        nodata: 'Pas encore de données',
        notlogin1: 'Vous n’êtes pas connecté',
        socity: 'Socialisation',
        dl: 'Programme de l’Agence',
        help: 'Centre d’aide',
        privicy: 'Politique de confidentialité',
        nochoose: 'Aucune option',
        nonum: 'Vous n’avez pas ouvert de compte de copie valide'
    },
    explain: {
        hand: 'Facturation sur la base d\'un tarif fixe. En supposant 1 $ par lot, si vous placez 10 lots sur votre compte de suiveurs, 10 $ seront prélevés de votre compte vers le compte de la source du signal.',
        left: 'Les frais sont basés sur un pourcentage du solde du compte des adeptes. En supposant une charge de 0,2 % du solde, le solde de votre compte suiveur est de 10 000 $ et 20 $ seront prélevés de votre compte vers le compte de la source du signal.',
        profit: 'Les frais sont pris en pourcentage du bénéfice sur la commande copiée, sans frais pour aucun profit. Disons que 5% du profit est facturé, et si votre compte de commande copié fait un profit de 1000 $ sur la commande, 50 $ seront prélevés de votre compte vers le compte source du signal.',
        lock: 'Les frais sont basés sur un pourcentage du bénéfice total pendant la période de blocage, aucun bénéfice n\'est facturé. En supposant une charge de 8% sur le profit total, le profit total de votre compte de suivi pendant la période de blocage serait de 2000 $ et 160 $ devraient être pris de votre compte vers le compte Signal Source.',
        fake: 'Hypothèses : recommandation de niveau 1 : 30 %, recommandation de niveau 2 : 20 %, recommandation de niveau 3 : 10 %, A recommande B, B recommande C, C recommande D. Si l\'utilisateur D passe une commande qui génère une commission de suivi de 100 $, alors A reçoit 10 $, B reçoit 20 $ et C reçoit 30 $.',
        said: 'Chez BlaFX, de nombreux adeptes reversent plus de 70 % des bénéfices de leur commission de copie, et certains atteignent même 90 % !',
        byhand: 'A la main',
        byleft: 'Par solde',
        byprofit: 'Par profit',
        bylock: 'Bénéfice par période de blocage'
    },
    common: {
        title: 'BlaFX Opérations de suivi',
        update: 'Le site Web est en cours de mise à niveau, veuillez comprendre pour tout inconvénient',
        back: 'rendre',
        nosm: 'Veuillez compléter l’authentification par nom réel avant de le faire',
        fail: 'L’opération a échoué',
        rz: 'Aller à la certification',
        ok:'opération sest déroulée avec succès.'
    }
}
