import Vue from 'vue'
import Router from 'vue-router'

import home from "@/views/home";
import detail from "@/views/detail";
import join from "@/views/join";
import joinmaster from "@/views/join-master";
Vue.use(Router);


export default new Router({
    mode: 'history',
    routes: [
        {path: '/', redirect: 'home'},
        {
            path : '/home',
            name : 'home',
            component: home
        },
        {
            path : '/detail',
            name : 'detail',
            component: detail
        },
        {
            path : '/join',
            name : 'join',
            component: join
        },
        {
            path : '/join-master',
            name : 'joinmaster',
            component: joinmaster
        },
    ],
    scrollBehavior(to,from,savadposition){
        return{x:0,y:0}
    }
})
