export const lang = {
    home: {
        countLogin: 'Konto-Anmeldung',
        transaction: 'Handelsplattformen',
        traders: 'Meta Trader 4',
        tradersOnline: 'Meta Trader 4 - Online-Handel',
        plan: 'Empfohlene Programme',
        copy: 'Copy Trading',
        aboutCopier: 'Für Fotokopien',
        joinCopy: 'Treten Sie dem Kopiergeschäft bei',
        getLevel: 'Master Trader\'s Bewertungen',
        aboutTeacher: 'Für Master Traders',
        beTeacher: 'Werden Sie ein Master Trader',
        msg: 'Informationen',
        clause: 'Bedingungen und Konditionen',
        aboutUs: 'Über uns',
        news: 'Unternehmensnachrichten',
        problem: 'Häufig gestellte Fragen',
        score: 'Taktik',
        follow: 'Kopierend',
        clause2: 'Bedingungen und Konditionen',
        login: 'Anmeldung',
        email: 'Briefkasten',
        password: 'Passwort',
        forgetPassword: 'Haben Sie Ihr Passwort vergessen?',
        code: 'Verifizierungscode',
        login2: 'Anmeldung',
        register: 'Anmeldung',
        google: 'Google Captcha',
        qlgoogle: 'Bitte Google-Authentifizierungscode eingeben',
        center: 'Persönliches Zentrum',
        quit: 'Abmelden',
        quitMsg: 'Login ist deaktiviert, bitte melden Sie sich erneut an',
    },
    message: {
        choose: 'Warum wählen',
        changeMarket: 'Handelsmärkte',
        changeTool: 'Handelsplattformen und -instrumente',
        community: 'BlaFX',
        companies: 'Partner',
        supervise: 'Meine Beaufsichtigung',
        VIP: 'Erstklassiger VIP-Service',
        active: 'Aktives Trader-Programm',
        out: 'Devisenhandel',
        contract: 'Index CFDs',
        goods: 'Rohstoffe',
        CFD: 'Währungsindex-CFDs（CFD）',
        money: 'Spreads, Swaps und Provisionen',
        time: 'Handelszeiten',
        count: 'Handelskonten',
        inAndOut: 'Einzahlung und Abhebung',
        begin: 'Jetzt loslegen',
        MetaTrader: 'MetaTrader 4',
        MetaTraderChange: 'MetaTrader 4-Online-Handel',
        study: 'Lernen Sie zu handeln',
        news: 'Wirtschaftliche Informationen',
        com: 'BlaFX Follower-Gemeinschaft',
        proxy: 'Agentendienste',
        friend: 'Freundschaftswerbung',
        title: 'Die von uns angebotenen Dienstleistungen umfassen den Handel mit OTC- und Devisenprodukten und bergen das Risiko von Verlusten, die größer sind als die ursprüngliche Kapitaleinlage. Diese Produkte sind möglicherweise nicht für jeden Anleger geeignet. Bitte vergewissern Sie sich, dass Sie die damit verbundenen Risiken vollständig verstehen und lassen Sie sich gegebenenfalls von einem Dritten beraten.',
        p1: 'Risikowarnung: Der Handel mit CFDs und Devisen ist mit Risiken verbunden. Es ist nicht für jeden geeignet, und Ihre Verluste können Ihre ursprüngliche Investition weit übersteigen. Sie haben kein Eigentum oder Rechte an dem zugrunde liegenden Vermögenswert. Die Wertentwicklung in der Vergangenheit ist kein Indikator für die künftige Wertentwicklung, und die Steuergesetze können sich ändern. Die Informationen auf dieser Website sind allgemeiner Natur und berücksichtigen nicht Ihre oder die persönlichen Ziele, finanziellen Verhältnisse oder Bedürfnisse Ihrer Kunden. Bitte lesen Sie unsere Risikohinweise und andere rechtliche Dokumente, um sicherzustellen, dass Sie die Risiken vollständig verstehen, bevor Sie Handelsentscheidungen treffen. Wir empfehlen Ihnen, sich unabhängig beraten zu lassen.',
        p2: 'BlaFX Capital PTY LTD hat seinen Sitz in Suite 280 156 Burwood Road Bureood,NSW 2134 Australien und ist von der Australian Securities Commission lizenziert und reguliert. Die Informationen auf dieser Website und die angebotenen Produkte und Dienstleistungen sind nicht dazu bestimmt, an irgendjemanden in irgendeinem Land oder einer Gerichtsbarkeit vermarktet zu werden.',
        p3: ' © 2020 BlaFX Capital PTY LTD| Registrierungsnummer des Unternehmens 661 715 966 | AFS - 001298638'
    },
    score: {
        invested: 'Haben Sie investiert?',
        signUp: 'Melden Sie sich an und beginnen Sie, jeden Master Trader zu kopieren, um höhere und stabilere Renditen für Ihre Investitionen zu erzielen.',
        score: 'Bewertungen von Experten',
        traders: 'Händler',
        agency: 'Affiliate\nProvisionen %',
        risk: 'Risiko\nScoring',
        gain: 'Ausbeute',
        cycle: 'Sicherungsfrist',
        remainingCycle: 'Verbleibender\nZyklus',
        remaining: 'Brutto\nMagnitude',
        amount: 'Verbleibend Guthaben',
        profit: 'Gewinne\nund\nVerluste',
        copiers: 'Replikator',
        commission: 'Kopieraufträge %',
        winner: 'Spitzenreiter',
        welcome: 'Beliebteste Trader',
        weeks2: '2 Wochen',
        month1: '1 Monat',
        month3: '3 Monate',
        month6: '6 Monate',
        all: 'Alle',
        legend: 'Saga',
        expert: 'Experten',
        success: 'Nachfolger',
        gUp: 'Aufwachsen',
        newBlood: 'Neuling',
        showFrist: 'Als erstes wird gezeigt',
        rangTime: 'Zeitlicher Rahmen',
        mixBalance: 'Mindestinvestition',
        mixknow: 'Minimales Fachwissen',
        nickName: 'Spitzname',
        null: 'nicht',
        nolimit: 'grenzenlos'
    },
    detail: {
        riskScore: 'Risiko-Scoring',
        equity: 'Netto-Kontowert',
        followVal: 'Kopierauftrag',
        regDate: 'Handelszeiten',
        followsetting: 'Einstellungen nachverfolgen',
        mintz: 'Mindestinvestition',
        tradingStrategyString: 'Anmerkungen zur Strategie',
        tradingStrategyString1: 'Beschreibung des Policenfalls',
        followway: 'Strategie kopieren',
        bx: 'Leistung',
        onemonth: '1 Monat',
        threemonth: '3 Monate',
        sixmonth: '6 Monate',
        all: 'Alle',
        profit: 'Gewinn',
        copiers: 'Replikatoren',
        profitandloss: 'Gewinne und Verluste',
        accountdetail: 'Details zum Konto',
        folatprofit: 'Fließender Gewinn',
        balance: 'Bilanz',
        givegold: 'Zuschüsse',
        lever: 'Hebelwirkung',
        riskmanage: 'Risikomanagement',
        maxprofit: 'Maximaler Schwebeverlust',
        maxmax: 'Maximale Retracement-Dauer',
        active: 'Veranstaltungen',
        transferhistory: 'Geschichte des Handels',
        historyorder: 'Historische Orden',
        holdorder: 'Aufträge positionieren',
        leftoperration: 'Operationen ausgleichen',
        limitorder: 'Limitierte Aufträge',
        minfollow: 'Mindestkopiermenge',
        nr: 'Nehmen wir an, der Saldo des Signalkontos beträgt 100.000 USD, Ihr Kopierkonto 10.000 USD, das Signalkonto gibt 10 Bestellungen auf und Ihr Kopierkonto platziert 1 Lot. Nehmen wir an, der Saldo des Signalkontos beträgt 20.000 USD, Ihr Kopierkontostand beträgt 200.000 USD, das Signalkonto gibt 1 Lot-Bestellung auf und Ihr Konto platziert 10 Lots. Die Berechnungsformel lautet: Ihre Kopierauftrags-Losgröße = Ihr Kopierkontostand÷ Signalquellenkontostand x Signalquellenkonto-Losgröße        ',
        nr1: 'Das Bilanzverhältnis 1:1 wird verwendet, um automatisch die Position der Position zum Kopieren zu berechnen',
        manageFollow: 'Verwaltung von Aufträgen',
        followMsg: 'Sobald Sie Ihren Auftrag geschlossen haben, müssen Sie alle Positionen in der kopierten Signalquelle manuell schließen, falls es noch offene Geschäfte gibt.',
        careful: 'Bitte beachten Sie',
        endFollow: 'Abschluss eines Auftrags',
        cancel: 'Abbrechen',
        confirm: 'Konfirmation',
        remind: 'Erinnerung',
        inCash: 'Einzahlung',
        outCash: 'Rücknahme',
        desc1: 'Wenn Sie den Signalen mit einer größeren Position folgen möchten, wenden Sie sich bitte an',
        desc2: 'Wenn Sie einem Signal mit einer kleineren Position folgen möchten, gehen Sie bitte wie folgt vor',
        modalTitle: 'Anweisungen für die Auftragsverfolgung',
        modalHtml: `<div>
            1. BlaFX bietet eine Copy-Trading-Funktion. Wenn Sie sich entscheiden, einen oder mehrere Trader zu kopieren oder eine bestimmte Strategie zu implementieren, müssen Sie müssen Sie Ihre gesamte finanzielle Situation, einschließlich Ihrer Finanzplanung, berücksichtigen und verstehen, dass die Nutzung der Copy-Trading-Funktion sehr riskant ist. Sie können hohe Verluste erleiden, die über den Betrag hinausgehen, den Sie für das Folgen eines Händlers eingesetzt haben. Die mit der Copy-Trading-Funktion verbundenen Risiken sind unter anderem Nicht beschränkt auf automatisierte Handelsoperationen, bei denen Ihr Konto ohne Ihr manuelles Eingreifen Trades starten und beenden kann.<br /><br />
            2. automatisierte Handelsausführung und andere damit zusammenhängende Handelsoperationen können auf Ihrem Konto abgeschlossen werden, ohne dass Sie manuell eingreifen müssen, um Positionen zu eröffnen und zu schließen.<br /><br />
            3. der BlaFX Investment Management Service steht Ihnen zur Verfügung und Sie entscheiden, ob Sie einem bestimmten Trader oder mehreren Tradern folgen und oder einer bestimmten Handelsstrategie folgen wollen. Bei diesen Entscheidungen haben Sie Ihre gesamte finanzielle Situation berücksichtigt, einschließlich der Finanzplanung, und Sie verstehen, dass die Nutzung von Copy Trading Sie sind sich bewusst, dass die Nutzung der Copy-Trading-Funktion hochspekulativ ist und dass Sie Verluste erleiden können, die deutlich höher sind als der Geldbetrag, den Sie verloren hätten, wenn Sie diesem Händler gefolgt wären. Sie sind sich bewusst, dass die Nutzung des Copy-Trading hochspekulativ ist und dass Sie Verluste erleiden können, die wesentlich höher sind als der Betrag, den Sie für die Verfolgung des Handels eingesetzt haben.<br /><br />
            4.Die Mitarbeiter und Vertreter von BlaFX und seiner Tochtergesellschaften sind keine Anlage- oder Finanzberater. Die Mitarbeiter und Vertreter von BlaFX und der mit ihr verbundenen Unternehmen sind keine Anlage- oder Finanzberater. Wenn Sie auf der Grundlage der auf unserer Website zur Verfügung gestellten oder über die Copy Trading-Funktion abgerufenen Informationen eine Anlageentscheidung treffen, tun Sie dies auf eigenes Risiko und sind für alle Anlageentscheidungen verantwortlich. BlaFX, seine Tochtergesellschaften, Mitarbeiter und Vertreter sind nicht für Verluste verantwortlich.<br /><br />
            5. Sie sollten Ihre eigenen Investitionsentscheidungen treffen. Sie sollten Ihr eigenes unabhängiges Urteil darüber fällen, ob eine Investition, eine Strategie oder ein anderes Produkt oder eine Dienstleistung Ihren eigenen Bedürfnissen im Hinblick auf Ihre Investitionsziele und persönlichen und finanziellen Umstände entspricht.<br /><br />
            6. Wir verwenden die bisherige Leistung eines Händlers, das Risikoverhältnis, Statistiken und alle Informationen über diesen Händler, die auf unserer Website und App angezeigt werden. Alle Informationen über einen Händler, die auf unserer Website und in unseren Anwendungen angezeigt werden, sind kein Hinweis auf die zukünftige Performance und sollten als hypothetisch betrachtet werden.<br /><br />
            7. Die Wertentwicklung und die Risikoraten der Vergangenheit sollten als hypothetische Wertentwicklung betrachtet werden. Hypothetische Performance hat viele inhärente Beschränkungen. Es sollte keine Zusicherung oder Garantie gegeben werden, dass Es kann keine Zusicherung oder Garantie dafür gegeben werden, dass ein Konto einen Gewinn oder Verlust erzielen wird oder wahrscheinlich erzielen wird, der der vergangenen Wertentwicklung oder dem angegebenen Risikoverhältnis entspricht. Der tatsächliche prozentuale Gewinn/Verlust kann für jeden Anleger unterschiedlich sein. Die tatsächlichen prozentualen Gewinne/Verluste können von Anleger zu Anleger variieren und hängen von einer Reihe von Faktoren ab, einschließlich, aber nicht beschränkt auf: den Kontostand zum Zeitpunkt des Handels dem Kontostand zum Zeitpunkt des Handels (Einzahlungen und Abhebungen), dem Marktverhalten, den Kontoeinstellungen des Anlegers und der tatsächlichen Leistung der Person, bei der der Auftrag erteilt wird.<br /><br />
            8. Sie verstehen und akzeptieren die Risiken, die mit dem Order-Following-Service verbunden sind, einschließlich, aber nicht beschränkt auf automatisierte Handelsoperationen, bei denen Ihr Konto den Handel ohne Ihr manuelles Eingreifen beginnen und beenden kann, das Potenzial für hohe Kosten, die mit der Latenzzeit von Order-Following-Transaktionen verbunden sind, und das Potenzial für einen Ausfall des Order-Following-Service. Sie tragen die alleinige Verantwortung für alle Verluste, die Ihnen durch die automatisierte Ausführung von Geschäften unter Nutzung des Order-Following-Dienstes entstehen.
        </div>`,
        modalBtn1: 'Stornierung',
        modalBtn2: 'Vereinbaren Sie',
    },
    clause: {
        caluse1: 'BETHLE ASTER COPY TRADING TERMS AND CONDITIONS',
        caluse2: 'BETHLE ASTER (im Folgenden als "BlaFX" bezeichnet) Copy Trading (im Folgenden als "Service" bezeichnet) ist ein Service, der es Händlern (im Folgenden als "Kopierer" bezeichnet) ermöglicht, Transaktionen von anderen Händlern (im Folgenden als "Kopierer" bezeichnet) zu kopieren und kann durch die Auswahl von "Replikation starten" aktiviert werden.',
        caluse3: '1. Der Kopierer verpflichtet sich, Folgendes zu tun, um den Dienst in Anspruch zu nehmen',
        caluse4: '1.1 Als Kunde auf der BETHLE ASTER Website registriert und eingeloggt sein.',
        caluse5: 'Bitte beachten Sie, dass das erste MT-Konto, das Sie standardmäßig eröffnen, wenn Sie sich für ein Konto anmelden, die Standard-Kommissions-Brieftasche ist, mit der Sie nur handeln, Geld einzahlen und überweisen, aber keine Aufträge ausführen können. Sie können bis zu 5 MT-Konten einrichten und jedes neue MT-Konto wird in der Lage sein, Aufträge zu erteilen, Abhebungen und Überweisungen vorzunehmen und unabhängig zu handeln.',
        caluse: ' Special reminder',
        caluse121: '1.2.1 Wenn es ein Konto gibt, das auf einem schwebenden Konto oder in Eigenregie gehandelt wird, werden andere damit zusammenhängende Finanzierungsfunktionen dieses Kontos eingeschränkt, es werden keine Überweisungen, Abhebungen und neue schwebende oder in Eigenregie durchgeführte Trades vorgenommen und erst wieder aufgenommen, wenn dieses Konto für schwebende oder in Eigenregie durchgeführte Trades geschlossen wird. Selbstverständlich sind Ihre anderen MT-Konten, die in keiner Weise gehandelt werden, davon nicht betroffen.',
        caluse122: '1.2.2 BETHLE ASTER ist stets bestrebt, das Risiko von Nicht-Entscheidungen unserer Nutzer zu reduzieren. Wenn Ihr MT-Konto eine Eigenhandelsposition hat, müssen Sie Ihre Eigenhandelsorder schließen, bevor Sie eine Folgeorder platzieren können. Wenn Ihr MT-Konto eine Folgeposition hat. Sie müssen die Folgeaufträge stoppen und Ihre Position manuell schließen, bevor Sie mit Ihrem MT-Konto handeln können.',
        caluse6: '1.3 Kopiervorgang einleiten.',
        caluse7: '2. Der Kopierer ist berechtigt, Folgendes zu tun',
        caluse8: '2.1 Eine beliebige Anzahl von Mastern kopieren (der Kopierbereich listet alle aktuellen Abonnements auf).',
        caluse9: '2.2 Im Bereich Follow the Order können Sie jederzeit einen Handel mit einer einzelnen Order manuell schließen (außer bei Signalquellen mit Sperrfristen).',
        caluse10: '2.3 Melden Sie sich vom Master ab und hören Sie auf, die Geschäfte des Masters zu kopieren, indem Sie auf Kopieren stoppen klicken.um sich abzumelden, muss der Kopierer alle Geschäfte geschlossen haben. Nach der Abmeldung kehren alle beim Master investierten Mittel und der Gewinn in die Brieftasche des Kopierers zurück.',
        caluse11: '2.4 Legen Sie die Größe des Kopieranteils für jeden einzelnen Master fest. Diese Option wird in Unterpunkt 4.4. dieser Allgemeinen Geschäftsbedingungen ausführlich erläutert.',
        caluse12: "2.5 Fügen Sie Unterstützungsfonds hinzu, um die Investition vor unerwarteten Marktbewegungen zu schützen. Diese Mittel werden verwendet, um die Handelsstrategie von Copier zu unterstützen, wenn der Markt schwankt, und haben keinen direkten Einfluss auf den Gewinn.",
        caluse13: "2.6 Im Falle einer Beteiligung an den Anlagegeschäften des jeweiligen Master-Händlers wird die Berechnung des vom Replikator investierten Betrags im Verhältnis zu den Mitteln des Replikators festgelegt. Dies wird in Artikel 4, Absatz 5 der Allgemeinen Geschäftsbedingungen ausdrücklich erläutert.",
        caluse14: '3. Der Dienst ist auf der Plattform Metatrader 4 verfügbar.',
        caluse15: '4. Eröffnung der Routine für kopierte Trades',
        caluse16: "4.1 Der Kopierer kopiert nur die Geschäfte, die vom Master nach der Anmeldung für den Master im Rahmen des Dienstes eröffnet wurden.",
        caluse17: "4.2 Stop-Loss-/Take-Profit-Orders sind im Kopierbereich nicht sichtbar, aber wenn diese Orders auf dem Konto des Masters ausgelöst werden, werden die kopierten Geschäfte ebenfalls geschlossen.",
        caluse18: '4.3 Bei der Zeichnung eines Masters legt der Kopierer den Betrag fest, der von der Wallet abgezogen und bei dem ausgewählten Master investiert wird. Diese Gelder und Ihr Gewinn kehren zu Ihrer Wallet zurück, wenn Sie das Kopieren des Masters beenden.',
        caluse19: '4.4 Der Replikator repliziert die Trades des Master Traders im Verhältnis zum Guthaben des folgenden Kontos und des abonnierten Signal Source Master Trader Kontos.',
        caluse20: '4.4.1 Bei der Ratio-Option wird das Handelsvolumen auf der Grundlage der Leverage Ratio und des Nettovermögens des Master Traders und des Followers berechnet. Die verwendete Formel lautet wie folgt.',
        caluse21: 'Volumen (Follower) = Netto(Follower) / Netto(Master Trader) × Leverage(Follower) / Leverage(Master Trader) × Volumen(Master Trader). Ein Master Trader hat zum Beispiel ein Nettovermögen von 2.000 $ und einen Leverage von 1:200. Ein Follower hat ein Nettowert von 200 $ und einen Hebel von 1:500. Auf dem Master-Konto wird 1 Lot eröffnet. Das Volumen des Folgehandels beträgt dann 200/2000 x 500/200 x 1 = 0,25 Lots',
        caluse22: "4.5 Das Leverage-Verhältnis der Kopierer ist auf 1:100 festgelegt. Die Kopierer, die dies ändern möchten, müssen sich an den BETHLE ASTER Kundendienst wenden.",
        caluse24: "4.6 Sobald der Dienst aktiviert ist, werden die Geschäfte auf das Konto des Kopierers kopiert, unabhängig davon, ob der Besitzer des Kontos angemeldet ist oder nicht.",
        caluse25: "4.7 Der Auftrag des Kopierers wird im Anschluss an den Auftrag (Kauf oder Verkauf) auf dem Konto des Masters ausgeführt.  Wenn der Master eine Order platziert, wird das Signal zur Eröffnung einer Order im Konto des Kopierers ausgelöst. Der Auftrag des Kopierers wird zum Marktpreis ausgeführt. Der gleiche Mechanismus löst die Schließung des Auftrags aus. Daher können die Ausführungspreise dieser Aufträge unterschiedlich sein. Außerdem kann die Anzahl der Kopierer, die diesem Master folgen, die Ausführungszeit beeinflussen",
        caluse26: '5. Limits applied',
        caluse27: '5.1 Das Mindestvolumen des kopierten Handels ist 0,01 Lot, das maximale Volumen eines kopierten Handels ist 100 Lots.',
        caluse28: '5.2 Das Mindestvolumen des kopierten Auftrags beträgt 0,01 Lots. Kopierte Aufträge mit einem Volumen unter 0,005 Lots werden jedoch abgelehnt, während kopierte Abschlüsse ab 0,005 Lots auf 0,01 Lots gerundet werden.',
        caluse29: '5.3 Das Volumen eines Auftrags wird auf die nächste Hundertstelstelle nach dem Komma gerundet (die zweite Stelle nach dem Komma). Wenn der Kopierer beispielsweise einen Auftrag über 0,324 Lots kopiert, wird der Auftrag auf 0,32 Lots abgerundet. Umgekehrt, wenn der Kopierer einen Auftrag über 0,325 Lots kopiert, wird der Auftrag auf 0,33 Lots aufgerundet.',
        caluse30: '5.4 Wenn das kopierte Handelsvolumen nach der Berechnung größer als 100 Lots ist, wird der Handel nicht auf dem Konto des Kopierers eröffnet.',
        caluse31: "6. Wenn der Master das Eigenkapital (durch Einzahlung oder Abhebung) oder den Hebel verändert, behalten alle kopierten Geschäfte ihr ursprüngliches Volumen auf dem Konto des Kopierers bei.",
        caluse32: "7. Alle Handelsbedingungen (Leverage, Swaps, Spreads) des Kopierers entsprechen denen der BETHLE ASTER MT4 Konten.",
        caluse33: '8. Ein Master Trader Konto muss vom Typ MT4 Micro sein.',
        caluse34: '9. Der Dienst ist berechtigt, Folgendes zu tun',
        caluse35: '9.1 Die Anzahl der Master-Konten, die der Master jederzeit ohne vorherige Benachrichtigung erstellen kann, nach eigenem Ermessen einschränken.',
        caluse36: '9.2 Den Kopierer ohne vorherige Benachrichtigung vom Master abmelden.',
        caluse37: '9.3 Die vom Master-Händler festgelegte Provision reduzieren und ihren Höchstwert für alle ihre oder seine Master-Konten begrenzen, ohne vorherige Benachrichtigung und ohne Angabe von Gründen.',
        caluse38: '9.4 Diese Allgemeinen Geschäftsbedingungen jederzeit ohne vorherige Benachrichtigung des Kopierers oder des Master-Händlers zu ändern. Solche Änderungen treten in dem Moment in Kraft, in dem sie auf der Website des Dienstes in diesen Bedingungen veröffentlicht werden',
        caluse39: "9.5 Die Aktivitäten des Master-Händlers auf der Plattform zu überwachen, das Konto des Master-Händlers mit der Warnung 'Verdächtige Aktivität' zu kennzeichnen, wenn der Master-Händler seine Statistiken manipuliert, und dieses Konto standardmäßig von der gefilterten Master-Bewertung auszuschließen (es bleibt für die Kopierer verfügbar, die ihre Filtereinstellungen entsprechend ändern)",
        caluse40: "9.6 Den Nickname und (oder) das Benutzerbild des Masters ohne vorherige Ankündigung ändern oder löschen, wenn der Dienst den begründeten Verdacht hat, dass dieser Master absichtlich den Nickname und (oder) das Bild eines anderen Masters kopiert oder imitiert, was zu einer unehrlichen Darstellung des Masters führen kann",
        caluse41: "10. Der Master-Händler bestimmt die Höhe der Provision für folgende Aufträge. Die Provision kann zwischen 0% und 50% des Gewinns aus dem erteilten Auftrag liegen. Die Provision wird nach Abschluss eines jeden Geschäfts an die elektronische Brieftasche des Master-Händlers gezahlt",
        caluse42: '11.Der Provisionsbetrag, den der Kopierer an den Master zahlt, wird in dem Moment festgelegt, in dem der Kopierer auf "Kopieren starten" drückt; wenn der Master den Provisionsbetrag ändert, hat dies keine Auswirkungen auf den Betrag, der im Rahmen dieses Abonnements an den Master zu zahlen ist',
        caluse43: "12. Die Handelsstatistiken des Kopierers können nur vom Kopierer eingesehen werden.",
        caluse45: "13. Die Handelsstatistiken des Masters sind für die Öffentlichkeit zugänglich.",
        caluse46: "14. Die Kopierer haben keinen Zugriff auf das Handelsterminal. Alle Aktionen mit ihren Abonnements und Geschäften werden im Kopierbereich durchgeführt",
        caluse47: "15. Wenn der Dienst den begründeten Verdacht hat, dass der Kopierer gegen die in der Kundenvereinbarung festgelegten Einzahlungs- und Abhebungsregeln oder gegen die Gesetzgebung des Landes, in dem der Kopierer seinen Wohnsitz hat, verstoßen hat, ist der Dienst berechtigt, die Erbringung der Dienstleistungen für diesen Kopierer auszusetzen",
        caluse48: '16. Bitte beachten Sie, dass der Master sowohl gewinnbringende als auch verlustbringende Geschäfte machen kann',
        caluse49: 'BETHLE ASTER behält sich das Recht der endgültigen Auslegung dieser Bedingungen vor.',





        caluse50: 'Ist der Nettowert des Folgekontos niedriger als der Zuschussbetrag, wird der Zuschuss gestrichen',
        caluse51: 'Follower können den Bonus manuell im Follower-Bereich stornieren.',
        caluse52: 'Der Zuschuss wird aufgehoben, wenn der Follower aufhört, dem Master Trader zu folgen.',
        caluse53: 'Nach der Streichung kann das Stipendium nicht erneut beantragt oder reaktiviert werden.',
        caluse54: 'Der Dienst kann den Antrag eines Followers auf Gewährung eines Zuschusses jederzeit ohne vorherige Ankündigung oder Angabe von Gründen ablehnen.',
        caluse55: 'Der Dienst kann jederzeit ohne Vorankündigung gekündigt werden.',
        caluse56: 'Wenn der Betrag der persönlichen Mittel, die der Follower zum Zeitpunkt der Entnahme/des internen Transfers in den Master Trader investiert hat, geringer oder gleich dem Betrag des Zuschusses ist, wird der Zuschuss gestrichen.',
        caluse57: 'Jede Situation, die nicht in diesen Bedingungen beschrieben ist, unterliegt der endgültigen Auslegung durch den Dienst.',
        caluse58: 'Der Dienst behält sich das Recht vor, diese Aktion ohne Vorankündigung in den Dienstnachrichten zu ändern, zu aktualisieren oder zu streichen.',
        caluse67: 'Die Handelsstatistiken der Follower sind nur für den Follower selbst einsehbar.',
        caluse68: 'Die Handelsstatistiken des Master Traders sind für die Öffentlichkeit zugänglich.',
        caluse69: 'Follower haben keinen Zugang zum Handelsterminal. Alle Operationen der Follower für Abonnements und Handel werden im \'Follower\'-Bereich\' abgewickelt.',
        caluse70: 'Der Dienst behält sich das Recht vor, die Erbringung von Dienstleistungen für einen Follower einzustellen, wenn der Dienst den begründeten Verdacht hat, dass der Follower gegen die in der Kundenvereinbarung festgelegten Einzahlungs- und Auszahlungsregeln oder gegen die Vorschriften des Landes, in dem der Follower ansässig ist, verstößt.',
        caluse71: 'Bitte beachten Sie, dass Master-Trader-Geschäfte gewinnbringend oder verlustbringend sein können.',
        caluse72: 'BlaFX behält sich das Recht der endgültigen Auslegung dieser Bedingungen vor.',
    },
    join: {
        copy: 'Profitieren Sie, indem Sie den Erfolg professioneller Händler nachahmen',
        wenan: 'BlaFX -FX Copytrading bietet Kunden die Möglichkeit, die Strategien führender Trader automatisch zu kopieren, ohne dass sie lange Zeit damit verbringen müssen, ihre eigenen Handelsstrategien zu entwickeln. Treffen Sie Ihre Wahl unter den besten Forex-Handelsgurus und diversifizieren Sie Ihr Handelsportfolio.',
        join: 'Copytrading hinzufügen',
        become: 'Oder werden Sie Master Trader',
        way: 'Wie es funktioniert',
        creat: 'Ein Konto erstellen und eine Einzahlung vornehmen',
        simpleway: 'Registrieren Sie sich in einem einfachen Schritt und verwenden Sie dann eine beliebige Zahlungsmethode, um Ihre Einzahlung auf Ihre Geldbörse zu tätigen. Wenn Sie bereits über Guthaben auf Ihrem eigenen BlaFX-FX-Handelskonto verfügen, können Sie interne Überweisungen nutzen, um Guthaben von Ihrem Konto auf Ihr Wallet zu übertragen. Ihr Kontostand zeigt die Gelder an, die Sie nicht investiert haben.',
        followbest: 'Folgen Sie den besten Händlern',
        followandcopy: 'Suchen Sie den Guru, dem Sie folgen möchten, und klicken Sie auf "Kopieren". Ihre Positionen werden automatisch kopiert. Die Einstellung des Einzahlungsprozentsatzes hilft Ihnen bei der Verwaltung Ihres Portfolios. Versuchen Sie, verschiedene Strategien zu kopieren und entscheiden Sie sich für die beste!',
        moandprofit: 'Überwachung und Rentabilität！',
        copycopy: 'Die Anzahl der Master Trader, die Sie kopieren können, ist unbegrenzt, so dass Sie ein ausgewogenes und diversifiziertes Handelsportfolio aufbauen und ein regelmäßiges Einkommen erzielen können. Außerdem haben Sie die volle Kontrolle über den gesamten Prozess und können das Kopieren von Geschäften jederzeit wiederholen oder stoppen. Sie können die detaillierten Handelsdaten des kopierten Master Traders in Ihrem Kopierbereich einsehen.',
        followprofitbest: 'Folgen Sie den profitabelsten Händlern',
        noneed: 'Man muss kein Forex-Experte sein',
        manyways: 'Stabile Erträge durch ein diversifiziertes Portfolio',
        new: 'Dieses neue Tool hilft Händlern, konsistentere Renditen zu erzielen, indem sie erfahrenere Händler kopieren.',
        learnbest: 'Lernen Sie von den besten Händlern',
        easytoin: 'Einsteigern den Einstieg in den Devisenmarkt erleichtern',
        spread: 'Diversifiziertes Portfolio',
        choosebig: 'Wählen Sie einen Master Trader und folgen Sie einer großen Gruppe von Experten.',
        enjoyfast: 'Genießen Sie eine schnelle Auftragsabwicklung',
        infive: 'Die Aufträge werden innerhalb von 5 Minuten nach Eröffnung der Position ausgeführt.',
        inhand: 'Alles unter Kontrolle haben',
        anytimestop: 'Sie können das Kopieren, Abonnieren oder Schließen einer Transaktion jederzeit beenden.',
        easyway: ' In wenigen einfachen Schritten einsatzbereit',
        noneedother: 'Eine zusätzliche Validierung ist nicht erforderlich.',
        safe: 'Sichere und schnelle Anlage und Abhebung von Geldern',
        mangmain: 'Eine Vielzahl gängiger Zahlungsmöglichkeiten.',
        alluseful: 'Genießen Sie alle Vorteile der Replikation von Transaktionen in einer mobilen App',
        foces: 'Konzentrieren Sie sich auf das Investieren mit der einfach zu bedienenden BlaFX-FX Copytrading-App',
        manage: 'Kontrollieren Sie Ihr Portfolio und Ihre Investitionen von unterwegs aus',
        follow: 'Verfolgen Sie Master-Trader und ihre Echtzeit-Performance für intelligente Investitionen',
        seerisk: 'Sehen Sie, wie Ihr Geld angelegt ist, und verwalten Sie das Risiko in Echtzeit',
        howto: 'Wie wähle ich einen Master Trader aus, dem ich folgen möchte?',
        firstcheck: 'Beginnen Sie mit der Überprüfung der Master-Trader-Daten des Händlers. Sie enthält Risikobewertungen, Renditen, Gewinn und Verlust, Anzahl der Follower, Provisionen, Auftragsverlauf und andere Statistiken, die Ihnen helfen, eine fundierte Entscheidung zu treffen. Um eine Bestellung zu verfolgen, müssen Sie Ihr Konto aufladen.',
        anyuseful: 'Welche Möglichkeiten der Nachbetreuung gibt es？',
        ways: 'Wenn Sie den Trader abonnieren, müssen Sie den Prozentsatz des Abrufs angeben und entscheiden, ob Sie Unterstützungsgelder hinzufügen möchten oder nicht. Wenn Sie auf "Anruf starten" drücken, wird das Geld von Ihrer Brieftasche abgezogen und der Anruf beginnt. Sie können eine unbegrenzte Anzahl von Master Traders gleichzeitig abonnieren.',
        did: 'Berechnen Sie eine Provision für die Ausführung von Aufträgen?',
        didas: 'Als Ihr Makler berechnen wir keine Provisionen. Sie zahlen lediglich eine Provision an den Master Trader, die im Voraus festgelegt und individuell bestimmt wird. Die Statistiken für jeden Master Trader zeigen, wie hoch die Provisionen sind.',
        howstop: 'Wie kann ich aufhören, einem Master Trader zu folgen?',
        anytimecancle: 'Sie können Ihr Trader-Abonnement jederzeit kündigen und keine Aufträge mehr verfolgen. Wenn Sie Ihr Abonnement kündigen, werden das gesamte in Trader Trader investierte Geld und die Gewinne, die Sie mit Ihren Folgeaufträgen erzielt haben, Ihrer Brieftasche gutgeschrieben.',
        howprofit: 'Wie kann ich meine Gewinne abheben?',
        wallet: 'Sie können von Ihrer Brieftasche abheben. Beenden Sie Ihr Abonnement bei Trader Trader und überweisen Sie Ihre Investitionen und Gewinne auf Ihr Portemonnaie. Bitte beachten Sie, dass Sie, wenn Sie Ihr Abonnement von Trader Trader mit einem Verlust beenden, nicht in der Lage sind, Ihre ursprüngliche Investition zurückzugewinnen. Der verlorene Betrag wird von Ihrer ursprünglichen Investition abgezogen.',
        getprofit: 'Werden Sie Master Trader und erzielen Sie konstante Erträge',
        becomaster: 'Werden Sie Master Trader',
        rules: 'Bedingungen und Konditionen'
    },
    balance: {
        orderid: 'Bestellnummer',
        opentime: 'Öffnungszeit',
        balantime:'Saldozeit',
        type: 'Typ',
        profit: 'Vorteile',
    },
    close: {
        openprice: 'Eröffnungspreis',
        hand: 'Anzahl der Hände',
        symbol: 'Handel mit Sorten',
        closetime: 'Abschlusszeit',
        closeprice: 'Closeout-Preise',
        comission: 'Bearbeitungsgebühr',
        storage: 'Inventarisierungsgebühren',
        profit: 'Vorteile',
        nowprice: 'Aktuelle Preise'
    },
    joinmaster: {
        good: 'Sind Sie gut im Handel? Bringen Sie Menschen dazu, Ihren Geschäften zu folgen und damit Geld zu verdienen.',
        one: 'Handeln Sie normal und verdienen Sie gleichzeitig ein zusätzliches Einkommen von anderen Händlern, die Sie kopieren. Ihr Master Trader-Profil zeigt die tägliche und monatliche Performance Ihrer Trades - werben Sie und gewinnen Sie neue Follower!',
        become: 'Werden Sie Master Trader',
        runway: 'Wie es funktioniert',
        creat: 'Ein Master Trader-Konto erstellen',
        click: 'Klicken Sie auf den Master Trader-Bereich und erstellen Sie ein Master Trader-Konto - eröffnen Sie ein neues Konto oder weisen Sie Ihrem Master Trader-Konto ein bestehendes Konto zu',
        accountsetting: 'Konto einrichten',
        beready: 'Bereiten Sie Ihr Master-Trader-Konto für die Nachahmer vor: Legen Sie die Höhe Ihrer Provision fest und beschreiben Sie Ihre Strategie.',
        use: 'In der Trader\'s Corner können Sie detaillierte Daten zu Ihren Geschäften einsehen, Ihre Kontoeinstellungen ändern und sehen, wie viel Provision Sie verdient haben.',
        offer: 'BlaFX-FX Copytrading bietet Ihnen eine zusätzliche Einnahmequelle: Eröffnen Sie ein Master Trader-Konto, beschreiben Sie Ihre Strategie und richten Sie dann Provisionen für andere ein, die Ihre Trades kopieren.',
        different: 'Erstellen Sie verschiedene Master-Trader-Konten für verschiedene Strategien',
        detail: 'Verfolgen Sie die Details Ihrer Aufträge im Master Trader-Bereich und verdienen Sie Master Trader-Provisionen',
        enjoy: 'Genießen Sie eine Vielzahl von Vorteilen, ohne zusätzliche Provisionen an BlaFX-FX zu zahlen',
        android: 'Sie befinden sich im Master Trader-Bereich der BlaFX-FX-Handelsapp für Android',
        mobile: 'Ein Master Trader-Konto in Mobile erstellen',
        anywhere: 'Statistiken und Provisionen für Ihre Replikatoren anzeigen, wo immer Sie sind',
        now: 'Vor-Ort-Verwaltung der Replikationsbedingungen Ihres neuen Followers',
        how: ' Wie man ein Master Trader wird？',
        get: 'Jeder BlaFX-FX-Kunde mit einem MT4-Konto kann ein Master Trader werden. Sie können sich beim Kundendienst bewerben, indem Sie 100.000 $ einzahlen und drei Monate lang mit einem stabilen Gewinn handeln.',
        howmanage: 'Wie kann ich die Höhe der Provision für Replikatoren anpassen?',
        go: 'Gehen Sie zu Ihrem Master-Trader-Bereich, sehen Sie sich die Einstellungen an, passen Sie die Provision mit dem Schieberegler an und speichern Sie dann. Die neuen Provisionen gelten nur für Replikatoren, die sich nach der Anpassung anmelden. Für alle anderen Replikatoren bleibt die Höhe der Provision gleich.',
        time: 'Wann bekomme ich die Provision für meinen Replikator？',
        american: 'Die Zahlung erfolgt jeden Sonntag um 18 Uhr US-Zeit.',
        when: 'Wann wird die Provision dem Replikator in Rechnung gestellt?',
        comission: 'Die Kommission wird jeden Samstag für abgeschlossene Aufträge berechnet.',
        whencanget: 'Wann kann ich meine Provision erhalten?',
        wallet: 'Wir überweisen die Provisionen an eine elektronische Geldbörse. Von Ihrer Brieftasche aus können Sie Geld auf eines Ihrer Handelskonten einzahlen oder abheben.',
        copy: 'Reproduzieren Sie automatisch die Strategien führender Trader, ohne lange Zeit mit der Entwicklung eigener Handelsstrategien zu verbringen.',
        or: 'Oder ein Replikator werden',
    },
    other: {
        signalaccount: 'Signal-Konten',
        signalname: 'Signal-Spitzname',
        wallet: 'Brieftaschenfonds',
        hold: 'Höhe der Unterstützung',
        need0: '0% der erforderlichen Investitionen',
        zj: 'Zusammenfassung',
        follow: 'Multiplikator für die Bestellung',
        followmoney: 'Kosten für Folgemaßnahmen',
        begin: 'Start der Bestellung nach',
        hand: 'Hand',
        more: '25 oder mehr',
        choose: 'Bitte wählen Sie',
        notlogin: 'Sie sind noch nicht eingeloggt, bitte loggen Sie sich zuerst ein',
        byhand: 'Losgröße',
        byprofit: 'Gewinn',
        byleft: 'Gleichgewicht',
        bylock: 'Hedging',
        day: 'Himmel',
        sure: 'Bestätigen',
        not: 'Sie haben den Handel noch nicht kopiert',
        receiptaccount: 'Eröffnen Sie ein Konto',
        balance: 'Verfügbares Guthaben',
        nodata: 'Noch keine Daten',
        notlogin1: 'Sie sind nicht eingeloggt',
        socity: 'Geselligkeit',
        dl: 'Agenturprogramm',
        help: 'Hilfe-Center',
        privicy: 'Datenschutzrichtlinie',
        nochoose: 'Keine Optionen',
        nonum: 'Sie haben kein gültiges Kopierkonto eröffnet'
    },
    explain: {
        hand: 'Wird auf der Grundlage einer festen Gebühr berechnet. Wenn Sie 10 Lots auf Ihrem Follower-Konto platzieren und dabei von $1 pro Lot ausgehen, werden $10 von Ihrem Konto auf das Konto der Signalquelle überwiesen.',
        left: 'Die Gebühren basieren auf einem Prozentsatz des Guthabens auf dem Konto des Followers. Bei einer angenommenen Gebühr von 0,2 % des Guthabens beträgt der Kontostand Ihres Follower-Kontos 10.000 $ und 20 $ werden von Ihrem Konto auf das Konto der Signalquelle übertragen.',
        profit: 'Die Kosten werden als Prozentsatz des Gewinns aus dem kopierten Auftrag berechnet, ohne dass kein Gewinn berechnet wird. Nehmen wir an, 5% des Gewinns werden belastet, und wenn Ihr kopiertes Bestellkonto einen Gewinn von $ 1000 für die Bestellung erzielt, werden $ 50 von Ihrem Konto auf das Signalquellenkonto abgebucht.',
        lock: 'Es wird ein Prozentsatz des Gesamtgewinns innerhalb der Sperrfrist berechnet, ein Gewinn wird nicht berechnet. Unter der Annahme, dass eine Gebühr von 8% auf den Gesamtgewinn erhoben wird, würde der Gesamtgewinn Ihres Folgekontos während der Sperrfrist $2000 betragen und $160 müssten von Ihrem Konto auf das Signal Source-Konto übertragen werden.',
        fake: 'Annahmen: Empfehlungsgrad 1: 30%, Empfehlungsgrad 2: 20%, Empfehlungsgrad 3: 10%, A wirbt B, B wirbt C, C wirbt D. Wenn Benutzer D eine Bestellung aufgibt, die eine Folgeprovision von $100 einbringt, erhält A $10, B $20 und C $30.',
        said: 'Bei BlaFX geben viele der Follower mehr als 70 % ihres Gewinns aus den Kopierprovisionen zurück, einige erreichen sogar 90 %!',
        byhand: 'Von Hand',
        byleft: 'Durch Gleichgewicht',
        byprofit: 'Mit Gewinn',
        bylock: 'Gewinn nach Sperrfrist'
    },
    common: {
        title: 'BlaFX Follow-on Trading',
        update: 'Die Website wird aktualisiert, bitte haben Sie Verständnis für etwaige Unannehmlichkeiten',
        back: 'Rückgabe',
        nosm: 'Bitte führen Sie zuvor die Authentifizierung mit echtem Namen durch.',
        fail: 'Der Vorgang ist fehlgeschlagen',
        rz: 'Zur Zertifizierung',
        ok:'Die Operation war erfolgreich.'
    }
}
