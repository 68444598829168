export const lang = {
    home: {
        countLogin: 'Acceso a la cuenta',
        transaction: 'Plataformas de negociación',
        traders: 'Meta Trader 4',
        tradersOnline: 'Meta Trader 4 - Comercio en línea',
        plan: 'Programas recomendados',
        copy: 'Copy Trading',
        aboutCopier: 'Para fotocopias',
        joinCopy: 'Únete al negocio de la copia',
        getLevel: 'Calificaciones de Master Trader',
        aboutTeacher: 'Para Master Traders',
        beTeacher: 'Conviértase en un maestro del comercio',
        msg: 'Información',
        clause: 'Condiciones generales',
        aboutUs: 'Quiénes somos',
        news: 'Noticias de empresa',
        problem: 'Preguntas frecuentes',
        score: 'táctica',
        follow: 'Replicar',
        clause2: 'Condiciones generales',
        login: 'Entrar',
        email: 'Buzón',
        password: 'Contraseña',
        forgetPassword: '¿Ha olvidado su contraseña?',
        code: 'Código de verificación',
        login2: 'Entrar',
        register: 'Inscripción',
        google: 'Google Captcha',
        qlgoogle: 'Introduzca el código de autenticación de Google',
        center: 'Centro Personal',
        quit: 'Cerrar sesión',
        quitMsg: 'El inicio de sesión está deshabilitado, vuelva a iniciar sesión',
    },
    message: {
        choose: '¿Por qué elegir',
        changeMarket: 'Mercados comerciales',
        changeTool: 'Mercados comerciales',
        community: 'BlaFX',
        companies: 'Socios',
        supervise: 'Mi supervisión',
        VIP: 'Servicio VIP Premium',
        active: 'Programa Active Trader',
        out: 'Divisas',
        contract: 'CFD sobre índices',
        goods: 'Materias primas',
        CFD: 'Contratos por diferencia (CFD) indexados a divisas',
        money: 'Diferenciales, swaps y comisiones',
        time: 'Horario comercial',
        count: 'Cuentas comerciales',
        inAndOut: 'Depósito y retirada',
        begin: 'Empezar ahora',
        MetaTrader: 'MetaTrader 4',
        MetaTraderChange: 'MetaTrader 4-Comercio en línea',
        study: 'Aprender a operar',
        news: 'Información económica',
        com: 'Comunidad de seguidores de BlaFX',
        proxy: 'Servicios para agentes',
        friend: 'Programa "Recomienda a un amigo',
        title: 'Los servicios que ofrecemos incluyen la negociación de productos OTC y de divisas y conllevan el riesgo de pérdidas superiores a la aportación inicial de capital. Estos productos pueden no ser adecuados para todos los inversores, por lo que le rogamos que se asegure de que comprende plenamente los riesgos que entrañan y solicite asesoramiento a un tercero en caso necesario.',
        p1: 'Advertencia de riesgo: Operar con CFD y divisas conlleva riesgos. No es adecuado para todo el mundo y sus pérdidas pueden superar con creces su inversión inicial. No tendrá propiedad ni derechos sobre el activo subyacente. Los resultados pasados no son indicativos de resultados futuros y la legislación fiscal está sujeta a cambios. La información contenida en este sitio web es de carácter general y no tiene en cuenta sus objetivos personales ni la situación o necesidades financieras de su cliente. Por favor, lea nuestro Aviso de Divulgación de Riesgos y otros documentos legales para asegurarse de que comprende plenamente los riesgos antes de tomar cualquier decisión comercial. Le animamos a buscar asesoramiento independiente.',
        p2: 'BlaFX Capital PTY LTD se encuentra en Suite 280 156 Burwood Road Bureood,NSW 2134 Australia。 Autorizada y regulada por la Comisión Australiana de Valores. La información contenida en este sitio web y los productos y servicios ofrecidos no están destinados a ser comercializados en ningún país o jurisdicción.',
        p3: ' © 2020 BlaFX Capital PTY LTD| Número de registro de la empresa 661 715 966 | AFS - 001298638'
    },
    score: {
        invested: '¿Ha invertido?',
        signUp: 'Regístrese y empiece a copiar a cualquier trader de Master Trader para añadir rendimientos más altos y estables a sus inversiones.',
        score: 'Valoraciones de expertos',
        traders: 'Proveedores',
        agency: 'Comisión\nde\nagencia%',
        risk: 'Puntuación del riesgo',
        gain: 'Ganancias',
        cycle: 'Ciclo de\nbloqueo',
        remainingCycle: 'Ciclos\nrestantes',
        remaining: 'Magnitud\nbruta',
        amount: 'Crédito\nrestante',
        profit: 'Ganancias y pérdidas',
        copiers: 'Replicador',
        commission: 'Comisión\nde\ncopia %',
        winner: 'Máximo ganador',
        welcome: 'Comerciantes más populares',
        weeks2: '2 semanas',
        month1: '1 mes',
        month3: '3 meses',
        month6: '6 meses',
        all: 'Todos',
        legend: 'Saga',
        expert: 'Expertos',
        success: 'Sucesores',
        gUp: 'Crecer',
        newBlood: 'Novato',
        showFrist: 'Primero en mostrar',
        rangTime: 'Plazos',
        mixBalance: 'Inversión mínima',
        mixknow: 'Conocimientos mínimos',
        nickName: 'Apodo',
        null: 'no',
        nolimit: 'ilimitado'
    },
    detail: {
        riskScore: 'Puntuación del riesgo',
        equity: 'Valor neto de la cuenta',
        followVal: 'Comisión de copia',
        regDate: 'Horario comercial',
        followsetting: 'Ajustes de seguimiento',
        mintz: 'Inversión mínima',
        tradingStrategyString: 'Notas estratégicas',
        tradingStrategyString1: 'Descripción del caso de directiva',
        followway: 'Estrategia de copia',
        bx: 'Rendimiento',
        onemonth: '1 mes',
        threemonth: '3 meses',
        sixmonth: '6 meses',
        all: 'Todos',
        profit: 'Beneficios',
        copiers: 'Replicadores',
        profitandloss: 'Ganancias y pérdidas',
        accountdetail: 'Datos de la cuenta',
        folatprofit: 'Beneficio flotante',
        balance: 'Saldo',
        givegold: 'Subvenciones',
        lever: 'Aproveche',
        riskmanage: 'Gestión de riesgos',
        maxprofit: 'Pérdida flotante máxima',
        maxmax: 'Duración máxima del retroceso',
        active: 'Eventos',
        transferhistory: 'Historial comercial',
        historyorder: 'Órdenes históricas',
        holdorder: 'Órdenes de posición',
        leftoperration: 'Operaciones de balance',
        limitorder: 'Órdenes límite',
        minfollow: 'Cantidad mínima de copias',
        nr: 'Digamos que el saldo de la cuenta de señal es de 100,000 USD, el saldo de su cuenta de copia es de 10,000 USD, la cuenta de señal realiza 10 pedidos y su cuenta de copia coloca 1 lote. Digamos que el saldo de la cuenta de señal es de 20,000 USD, el saldo de su cuenta de copia es de 200,000 USD, la cuenta de señal realiza un pedido de 1 lote y su cuenta coloca 10 lotes. La fórmula de cálculo es: tamaño de lote de su orden de copia = saldo de su cuenta de copia÷ saldo de la cuenta de origen de señal x tamaño de lote de la cuenta de origen de señal',
        nr1: 'La relación de equilibrio 1:1 se utiliza para calcular automáticamente la posición de la posición para copiar',
        manageFollow: 'Gestión de pedidos',
        followMsg: 'Una vez que haya cerrado su orden, tendrá que cerrar manualmente todas las posiciones en la fuente de señal que copió si hay alguna operación abierta.',
        careful: 'Atención',
        endFollow: 'Cerrar un pedido',
        cancel: 'Cancelar',
        confirm: 'Confirmación',
        remind: 'Recordatorio',
        inCash: 'Depósito',
        outCash: 'Retirada',
        desc1: 'Si desea seguir la señal con un tamaño de posición mayor, por favor',
        desc2: 'Si desea seguir una señal con una posición más pequeña, por favor',
        modalTitle: 'Instrucciones para el seguimiento del pedido',
        modalHtml: `<div>
            1. BlaFX ofrece la opción de copiar operaciones. Cuando decida copiar a uno o más operadores o implementar una estrategia en particular, debe considerar su situación financiera general, incluyendo la planificación financiera, y entender que el uso de la función de copiar operaciones es altamente especulativo y que puede estar expuesto a pérdidas mayores que la cantidad utilizada para seguir al operador. Los riesgos asociados a la función de Copy Trading incluyen, entre otros, operaciones de trading automatizadas en las que su cuenta puede iniciar y finalizar operaciones sin su intervención manual.<br /><br />
            2. La ejecución automatizada de operaciones y otras operaciones relacionadas pueden ser completadas en su cuenta sin su intervención manual para abrir y cerrar posiciones.<br /><br />
            3. El Servicio de Gestión de Inversiones BlaFX está a su opción y es su decisión seguir un operador en particular o múltiples operadores y seguir una estrategia de negociación en particular. Al tomar tales decisiones, usted ha considerado su situación financiera general, incluyendo la planificación financiera, y entiende que el uso de la función de copia de operaciones es altamente especulativo y que puede incurrir en pérdidas que son significativamente mayores que la cantidad utilizada para seguir al operador.<br /><br />
            4. Los empleados y agentes de BlaFX y sus afiliados no son asesores financieros o de inversión. Si usted toma una decisión de inversión basada en la información proporcionada en nuestro sitio web o a la que accede a través de la función de copia de operaciones, lo hace bajo su propio riesgo y BlaFX, sus afiliados, sus empleados y agentes no serán responsables de ninguna pérdida.<br /><br />
            5. Usted debe hacer su propio juicio antes de tomar una decisión de inversión. Usted debe hacer su propio juicio independiente en cuanto a si una inversión, estrategia o cualquier otro producto o servicio satisface sus propias necesidades a la luz de sus propios objetivos de inversión y circunstancias personales y financieras.<br /><br />
            6. El uso que hacemos de los resultados pasados de un operador, los índices de riesgo, las estadísticas y toda la información sobre ese operador que aparece en nuestro sitio web y aplicaciones no es indicativo de resultados futuros y debe tratarse como hipotético.<br /><br />
            7. El rendimiento pasado y los índices de riesgo deben considerarse rendimiento hipotético. El rendimiento hipotético tiene muchas limitaciones inherentes. No debe haber ninguna representación o garantía de que cualquier cuenta logrará o es probable que logre una ganancia o pérdida similar al rendimiento pasado o el ratio de riesgo indicado. Los porcentajes reales de ganancias/pérdidas pueden variar de un inversor a otro en función de una serie de factores que incluyen, entre otros, los saldos de las cuentas (depósitos y retiradas) en el momento de la negociación, el comportamiento del mercado, la configuración de la cuenta del inversor y el rendimiento real de la persona con la que se realiza la orden.<br /><br />
            8. Usted comprende y acepta los riesgos asociados al servicio de seguimiento de órdenes, incluidos, entre otros, las operaciones de negociación automatizadas en las que su cuenta puede comenzar y finalizar la negociación sin su intervención manual, los posibles costes elevados asociados a la latencia de las operaciones de seguimiento de órdenes y el posible fallo del servicio de seguimiento de órdenes. Usted será el único responsable de todas y cada una de las pérdidas que sufra como consecuencia de la ejecución automatizada de operaciones mediante el servicio de seguimiento de órdenes.
        </div>`,
        modalBtn1: 'Anulación',
        modalBtn2: 'Acordar',
    },
    clause: {
        caluse1: 'TÉRMINOS Y CONDICIONES DE COMERCIO DE COPIA DE BETHLE ASTER',
        caluse2: 'BETHLE ASTER (en adelante, "BlaFX"), el copy trading (en adelante, "servicio") es un servicio que permite a los comerciantes (en adelante, "copiadores") copiar transacciones de otros comerciantes ( en lo sucesivo denominado "copiadoras") y puede activarse seleccionando "Iniciar replicación".',
        caluse3: '1. El Copiador se compromete a hacer lo siguiente para solicitar el Servicio',
        caluse4: '1.1. Estar registrado e identificado como cliente en la web de BETHLE ASTER.',
        caluse5: 'Tenga en cuenta que la primera cuenta de MT que abre de manera predeterminada cuando se registra para obtener una cuenta es la billetera de comisiones predeterminada, que solo puede operar, depositar y transferir fondos, pero no seguir órdenes. Puede crear hasta 5 cuentas MT y cada nueva cuenta MT podrá realizar pedidos, retiros, transferencias y operaciones de forma independiente.',
        caluse: 'recordatorio especial',
        caluse121: '1.2.1. Si hay una cuenta que está negociando en una cuenta pendiente o autodirigida, se restringirán otras funciones de financiación relacionadas de esta cuenta, no se realizarán transferencias, retiros y nuevas transacciones pendientes o autodirigidas, y solo se realizarán se reanuda si esta cuenta está cerrada por operaciones pendientes o autodirigidas. Por supuesto, sus otras cuentas de MT que no están operando de ninguna manera no se ven afectadas por esto.',
        caluse122: '1.2.2. BETHLE ASTER siempre se esfuerza por reducir el riesgo de no tomar decisiones por parte de nuestros usuarios. Si su cuenta de MT tiene una posición propietaria, debe cerrar su orden propietaria antes de poder realizar una orden de seguimiento. Si su cuenta MT tiene una posición de seguimiento. Debe dejar de seguir órdenes y cerrar su posición manualmente antes de poder operar en su cuenta MT.',
        caluse6: '1.3. Iniciar la copia.',
        caluse7: '2. El copiador tiene derecho a hacer lo siguiente',
        caluse8: '2.1. Copie cualquier número de Másteres (el Área de copia enumera todas las suscripciones actuales).',
        caluse9: '2.2. Puede cerrar manualmente una operación con una sola orden en cualquier momento en el área Seguir la orden (excepto para las fuentes de señal con períodos de bloqueo).',
        caluse10: "2.3. Cancele la suscripción al Maestro y deje de copiar las operaciones del Maestro haciendo clic en 'Dejar de copiar'. Para cancelar la suscripción, el Copiador debe tener todas las operaciones cerradas. Al cancelar la suscripción, todos los fondos invertidos con el Maestro y las ganancias regresan a la Cartera del copiador.",
        caluse11: '2.4. Establezca el tamaño de la proporción de copia para cada Máster en particular. Esta opción se explica en detalle en la subcláusula 4.4. de estos Términos y Condiciones.',
        caluse12: '2.5. Agregue fondos de apoyo para proteger la inversión de movimientos inesperados del mercado. Estos fondos se utilizan para respaldar la estrategia comercial de Copier cuando el mercado fluctúa y no influyen directamente en las ganancias.',
        caluse13: '2.6. En el caso de participación en las operaciones de inversión de cada Master Trader en particular, el cálculo del monto invertido por el Replicador se establece en proporción a los fondos del Replicador. Esto se explica explícitamente en el Artículo 4, inciso 5 de los Términos y Condiciones.',
        caluse14: '3. El Servicio está disponible en la plataforma Metatrader 4.',
        caluse15: '4. Rutina de apertura de transacciones copiadas',
        caluse16: '4.1. El Copiador solo copia las operaciones que fueron abiertas por el Maestro después de la suscripción del Maestro dentro del Servicio.',
        caluse17: '4.2. Las órdenes Stop Loss/Take Profit no serán visibles en el Área de la copiadora, pero si estas órdenes se activan en la cuenta del Maestro, las operaciones copiadas también se cerrarán.',
        caluse18: '4.3. Tras la suscripción a un Máster, la Copiadora especifica la cantidad de fondos que se deducirán de la Cartera y se invertirán con el Máster seleccionado. Estos fondos y sus ganancias volverán a su Monedero cuando deje de copiar el Maestro.',
        caluse19: '4.4.El replicador replica las operaciones del Master Trader en proporción a los fondos de la siguiente cuenta y la cuenta Signal Source Master Trader suscrita.',
        caluse20: '4.4.1. En la opción de relación, el volumen de negociación se calcula en función de la relación de apalancamiento y el patrimonio neto del comerciante Trader Master y el seguidor. La fórmula utilizada es la siguiente.',
        caluse21: 'Volumen (Seguidores) = Neto (Seguidores) / Neto (Master Trader) × Apalancamiento (Seguidores) / Apalancamiento (Master Trader) × Volumen (Master Trader). Por ejemplo, un Master Trader tiene un patrimonio neto de $2000 y un apalancamiento de 1:200. Un Seguidor tiene un valor neto de $200 y un apalancamiento de 1:500. Se abre 1 lote en la Cuenta Maestra. Entonces, el volumen de la operación de seguimiento es 200/2000 x 500/200 x 1 = 0,25 lotes.',
        caluse22: '4.5. El índice de apalancamiento de las copiadoras se establece en 1:100. Las copiadoras que deseen ajustarlo deben ponerse en contacto con el servicio de atención al cliente de BETHLE ASTER.',
        caluse24: '4.6. Una vez que se activa el servicio, las transacciones se copiarán en la cuenta del copiador, independientemente de si el propietario de la cuenta ha iniciado sesión o no.',
        caluse25: '4.7. La orden del Copiador se ejecuta siguiendo la orden (Compra o Venta) realizada en la cuenta del Maestro. Cuando el Maestro realiza una orden, la señal para abrir una orden se dispara en la cuenta del Copiador. La orden del Copier se ejecuta al precio de mercado. El mismo mecanismo activa el cierre de la orden. Por lo tanto, los precios de ejecución de estas órdenes pueden diferir. Además, el número de copiadores que siguen a este maestro puede afectar el tiempo de ejecución.',
        caluse26: '5. Límites aplicados',
        caluse27: '5.1. El volumen mínimo de la operación copiada es de 0,01 lotes, el volumen máximo de una operación copiada es de 100 lotes.',
        caluse28: '5.2. El volumen mínimo de pedido copiado es de 0,01 lotes. Sin embargo, se rechazarán las órdenes copiadas con un volumen inferior a 0,005 lotes, mientras que las transacciones copiadas de 0,005 lotes y superiores se redondearán a 0,01 lotes.',
        caluse29: '5.3 El volumen de cualquier pedido se redondea al centésimo punto decimal más cercano (el segundo dígito después del decimal). Por ejemplo, si la copiadora copia un pedido de 0,324 lotes, el pedido se redondeará a 0,32 lotes. Viceversa, si la copiadora copia un pedido de 0,325 lotes, el pedido se redondeará a 0,33 lotes.',
        caluse30: '5.4. Si el volumen de la operación copiada es superior a 100 lotes después de realizar el cálculo, la operación no se abrirá en la cuenta del copiador.',
        caluse31: '6. Si el Maestro cambia la equidad (al hacer un depósito o retiro) o el apalancamiento, todas las operaciones copiadas mantienen su volumen inicial en la cuenta del Copiador.',
        caluse32: '7. Todas las condiciones comerciales (apalancamiento, swaps, diferenciales) de la Copiadora son similares a las de las cuentas BETHLE ASTER MT4.',
        caluse33: '8. Una cuenta Master Trader debe ser del tipo MT4 Micro.',
        caluse34: '9. El Servicio tiene derecho a hacer lo siguiente.',
        caluse35: '9.1. Restringir la cantidad de Cuentas Maestras que los Maestros pueden crear en cualquier momento sin previo aviso a su exclusivo criterio.',
        caluse36: '9.2. Dar de baja el Copiador del Máster sin previo aviso.',
        caluse37: '9.3. Reducir la comisión fijada por el Master Trader y limitar su valor máximo para todas sus Cuentas Master sin notificación previa ni explicación alguna.',
        caluse38: '9.4. Modificar estos Términos y Condiciones en cualquier momento sin previo aviso al Copiador o al Master Trader. Dichas modificaciones entran en vigencia en el momento en que se publican en el sitio del Servicio en estos Términos y Condiciones.',
        caluse39: "9.5. Supervise la actividad del Master Trader en la plataforma, marque la cuenta del Master Trader con la advertencia de 'Actividad sospechosa' si el Master Trader manipula sus estadísticas y excluya dicha cuenta de la Calificación maestra filtrada por defecto (la mantendrá disponible para los Copiadores que cambien). su configuración de filtro en consecuencia).",
        caluse40: '9.6 Modifique o elimine el apodo y (o) la imagen de usuario del Maestro sin previo aviso si el Servicio sospecha razonablemente que dicho Maestro copia o imita deliberadamente el apodo y (o) la imagen de otros Maestros, lo que puede conducir a una representación deshonesta del Maestro.',
        caluse41: '10. El master trader determina el importe de la comisión por las siguientes órdenes. La comisión puede oscilar entre el 0% y el 50% del beneficio del pedido realizado. La comisión se pagará al monedero electrónico del comerciante maestro después de que se cierre cada operación.',
        caluse42: "11. El monto de la comisión que la Copiadora paga al Maestro se establece en el momento en que la Copiadora presiona 'Comenzar a copiar'. Si el Maestro cambia el monto de la comisión, no afecta el monto adeudado en virtud de esta suscripción al Maestro.",
        caluse43: '12. Las estadísticas comerciales de la Copiadora solo pueden ser vistas por la Copiadora.',
        caluse45: '13. Las estadísticas comerciales del Maestro están disponibles para el público.',
        caluse46: '14. Los Copiadores no tienen acceso a la terminal comercial. Todas las acciones con sus suscripciones y operaciones se realizan bajo el Área de Copia.',
        caluse47: '15. Si el Servicio sospecha razonablemente que la Copiadora violó las reglas de Depósito y Retiro establecidas en el Acuerdo del Cliente o la legislación del país de residencia de la Copiadora, el Servicio tiene derecho a suspender la prestación de servicios a dicha Copiadora.',
        caluse48: '16. Tenga en cuenta que el Maestro puede realizar operaciones tanto rentables como perdedoras.',
        caluse49: 'BETHLE ASTER se reserva el derecho de interpretación final de estos términos y condiciones.',
        caluse50: '',
        caluse51: '',
        caluse52: '',
        caluse53: '',
        caluse54: '',
        caluse55: '',
        caluse56: '',
        caluse57: '',
        caluse58: '',
        caluse67: '',
        caluse68: '',
        caluse69: '',
        caluse70: '',
        caluse71: '',
        caluse72: '',
    },
    join: {
        copy: 'Obtener beneficios reproduciendo el éxito de los operadores profesionales',
        wenan: 'BlaFX-FX Copytrading Ofrece a los clientes la oportunidad de replicar automáticamente las estrategias de los principales operadores sin tener que dedicar largas horas a desarrollar sus propias estrategias de negociación. Elija entre los mejores gurús del mercado de divisas y diversifique su cartera de inversiones.',
        join: ' Únete a Copytrading',
        become: 'O conviértete en un maestro comerciante',
        way: 'Cómo funciona',
        creat: 'Crear una cuenta y hacer un ingreso',
        simpleway: 'Regístrese en un simple paso y luego utilice cualquier método de pago que desee para procesar su depósito en su monedero. Si ya tiene fondos en su propia cuenta de operaciones BlaFX-FX, puede utilizar las transferencias internas para añadir fondos de su cuenta a su monedero. El saldo de su monedero muestra los fondos que no ha invertido.',
        followbest: 'Siga a los mejores operadores',
        followandcopy: 'Busca el gurú que deseas seguir y haz clic en "Copiar". Sus posiciones se copiarán automáticamente. El ajuste del porcentaje de depósito le ayudará a gestionar su cartera. Prueba a copiar distintas estrategias y decídete por la mejor.',
        moandprofit: 'Control y beneficios',
        copycopy: 'No hay límite en el número de Master Traders que puedes copiar, por lo que puedes crear una cartera de operaciones equilibrada y diversificada y obtener unos ingresos constantes. También tiene pleno control sobre todo el proceso y puede revisar/detener la copia de operaciones en cualquier momento. Puede ver los datos de negociación detallados del Master Trader copiado en su área de copiado.',
        followprofitbest: 'Siga a los operadores más rentables',
        noneed: 'No hace falta ser un experto en forex',
        manyways: 'Rentabilidad estable de una cartera diversificada',
        new: 'Esta nueva herramienta ayuda a los operadores a obtener rendimientos más constantes copiando a operadores más experimentados.',
        learnbest: 'Aprenda de los mejores operadores',
        easytoin: 'Facilitar el acceso al mercado de divisas a los recién llegados',
        spread: 'Cartera diversificada',
        choosebig: 'Elija un operador maestro y siga a un gran grupo de expertos.',
        enjoyfast: 'Disfrute de una rápida tramitación de pedidos',
        infive: 'Las órdenes se ejecutan en los 5 minutos siguientes a la apertura de la posición.',
        inhand: 'Todo bajo control',
        anytimestop: 'Puede dejar de copiar, suscribirse o cerrar una transacción en cualquier momento.',
        easyway: ' Listo para funcionar en unos sencillos pasos',
        noneedother: 'No requiere validación adicional。',
        safe: 'Inversión y retirada de fondos seguras y rápidas',
        mangmain: 'Una amplia selección de los principales métodos de pago。',
        alluseful: 'Disfrute de todas las ventajas de replicar las transacciones en una aplicación móvil',
        foces: 'Céntrese en invertir con la sencilla aplicación BlaFX-FX Copytrading',
        manage: 'Controle su cartera y sus inversiones sobre la marcha',
        follow: 'Siga a los grandes operadores y sus resultados en tiempo real para invertir con inteligencia',
        seerisk: 'Vea cómo se invierte su dinero y gestione el riesgo en tiempo real',
        howto: '¿Cómo elijo a un maestro comerciante al que seguir?',
        firstcheck: 'Empiece por comprobar los datos del Trader Maestro. Incluye puntuaciones de riesgo, rendimientos, pérdidas y ganancias, número de seguidores, comisiones, historial de órdenes y otras estadísticas que le ayudarán a tomar una decisión informada. Para empezar a seguir un pedido, ingrese fondos en su cuenta.',
        anyuseful: 'Qué opciones de seguimiento existen？',
        ways: 'Cuando se suscriba al Trader, deberá especificar el porcentaje de órdenes a seguir y decidir si desea o no aumentar sus fondos de apoyo. Cuando pulsas iniciar llamada, los fondos se deducen de tu monedero y comienza la llamada. Puedes suscribirte a un número ilimitado de Master Traders al mismo tiempo.',
        did: '¿Cobran comisión por seguir pedidos?',
        didas: 'Como su corredor, no cobramos comisiones. Usted sólo paga una comisión al Master Trader, que está predeterminada y especificada individualmente. Las estadísticas de cada Master Trader muestran a cuánto asciende la comisión.',
        howstop: 'Cómo dejar de seguir a un Master Trader？',
        anytimecancle: 'Puede cancelar su suscripción a Trader Trader y dejar de seguir órdenes en cualquier momento. Cuando cancele su suscripción, todo el dinero invertido en Trader Trader y los beneficios que haya obtenido de sus órdenes de seguimiento se abonarán en su cartera.',
        howprofit: 'Cómo retirar beneficios？',
        wallet: 'Puedes retirar dinero de tu monedero. Finalice su suscripción a Trader Trader y transfiera su inversión y beneficios a su cartera. Tenga en cuenta que si finaliza su suscripción a Trader Trader con pérdidas, no podrá recuperar su inversión inicial. El importe perdido se deducirá de su inversión inicial.',
        getprofit: 'Conviértase en un maestro del trading y obtenga beneficios constantes',
        becomaster: 'Conviértase en un maestro del trading',
        rules: 'Condiciones generales'
    },
    balance: {
        orderid: 'Número de pedido',
        opentime: 'Hora de apertura',
        balantime:'Tiempo de saldo',
        type: 'Tipo',
        profit: 'Beneficios',
    },
    close: {
        openprice: 'Precio de apertura',
        hand: 'Número de manos',
        symbol: 'Variedades comerciales',
        closetime: 'Tiempo de cierre',
        closeprice: 'Precios de liquidación',
        comission: 'Gastos de tramitación',
        storage: 'Tasas de inventario',
        profit: 'Beneficios',
        nowprice: 'Precios actuales'
    },
    joinmaster: {
        good: '¿Se le da bien el comercio? Consigue que la gente siga tus operaciones y así ganar dinero.',
        one: 'Opere normalmente mientras obtiene ingresos extra de otros operadores que le copian. Tu perfil de Master Trader muestra el rendimiento diario y mensual de tus operaciones: ¡promociona y atrae nuevos seguidores!',
        become: 'Conviértase en un maestro del trading',
        runway: 'Cómo funciona',
        creat: 'Crear una cuenta Master Trader',
        click: 'Haga clic en el área Master Trader y cree una cuenta Master Trader: abra una cuenta nueva o asigne una cuenta existente a su cuenta Master Trader.',
        accountsetting: 'Configuración de la cuenta',
        beready: 'Prepare su cuenta Master Trader para que los replicadores la sigan: fije el importe de su comisión y describa su estrategia.',
        use: 'Utilice el Rincón del Trader para ver datos detallados sobre sus operaciones, cambiar la configuración de su cuenta y ver cuántas comisiones ha ganado.',
        offer: 'BlaFX-FX Copytrading Le proporciona una fuente adicional de ingresos: abra una cuenta Master Trader, describa su estrategia y luego establezca comisiones para que otros copien sus operaciones.',
        different: 'Crear diferentes cuentas Master Trader para diferentes estrategias',
        detail: 'Siga los detalles de sus órdenes en el área Master Trader y gane comisiones Master Trader',
        enjoy: 'Disfrute de diversas ventajas sin pagar comisiones adicionales a BlaFX-FX',
        android: 'Se encuentra en la sección Master Trader de la aplicación BlaFX-FX para Android',
        mobile: 'Crear una cuenta Master Trader en Mobile',
        anywhere: 'Consulta las estadísticas y comisiones de tus replicadores estés donde estés',
        now: 'Gestión in situ de las condiciones de réplica de su nuevo seguidor',
        how: ' Cómo convertirse en un maestro del trading？',
        get: 'Cualquier cliente de BlaFX-FX con una cuenta MT4 puede convertirse en Master Trader. Puede solicitar el Servicio de Atención al Cliente depositando 100.000 $ y operando durante tres meses con un beneficio estable.',
        howmanage: '¿Cómo puedo ajustar el importe de la comisión que cobro a los replicadores?',
        go: 'Vaya a su área Master Trader, vea la configuración, ajuste la comisión utilizando el control deslizante y, a continuación, guarde. Las nuevas comisiones sólo se aplicarán a los replicadores que se suscriban una vez realizado el ajuste. Para todos los demás replicadores, el importe de la comisión seguirá siendo el mismo.',
        time: '¿Cuándo me pagan la comisión de mi replicador?',
        american: 'Pago todos los domingos a las 18.00 (hora de EE.UU.)。',
        when: '¿Cuándo se carga la comisión al replicador?',
        comission: 'La comisión se cobra todos los sábados en los pedidos cerrados.',
        whencanget: '¿Cuándo puedo cobrar mi comisión?',
        wallet: 'Transferiremos las comisiones a un monedero electrónico. Desde tu monedero, puedes añadir fondos a cualquiera de tus cuentas de trading o retirar fondos.',
        copy: 'Reproduzca automáticamente las estrategias de los principales operadores sin necesidad de dedicar largas horas a desarrollar sus propias estrategias de negociación',
        or: 'O convertirse en un replicante',
    },
    other: {
        signalaccount: 'Cuentas de señales',
        signalname: 'Apodo de la señal',
        wallet: 'Fondos de cartera',
        hold: 'Importe de la ayuda',
        need0: '0% de la inversión necesaria',
        zj: 'Resumen',
        follow: 'Multiplicador de pedidos',
        followmoney: 'Gastos de seguimiento',
        begin: 'Inicio del pedido siguiente',
        hand: 'Mano',
        more: '25 o más',
        choose: 'Por favor seleccione',
        notlogin: 'Aún no ha iniciado sesión, inicie sesión primero',
        byhand: 'Tamaño del lote',
        byprofit: 'beneficio',
        byleft: 'equilibrar',
        bylock: 'Cobertura',
        day: 'cielo',
        sure: 'Confirmar',
        not: 'Aún no has copiado el trading',
        receiptaccount: 'Abrir una cuenta',
        balance: 'Saldo disponible',
        nodata: 'Aún no hay datos',
        notlogin1: 'No ha iniciado sesión',
        socity: 'socializador',
        dl: 'Programa de Agencia',
        help: 'Centro de ayuda',
        privicy: 'Política de privacidad',
        nochoose: 'Sin opciones',
        nonum: 'No ha abierto una cuenta de copia válida'
    },
    explain: {
        hand: 'Se cobra a tanto alzado. Asumiendo $1 por lote, si usted coloca 10 lotes en su cuenta de seguidores, $10 serán tomados de su cuenta a la cuenta de la fuente de la señal.',
        left: 'Los gastos se basan en un porcentaje del saldo de la cuenta de seguidores. Suponiendo un cargo del 0,2% del saldo, el saldo de su cuenta de seguidores es de 10.000 $ y se descontarán 20 $ de su cuenta a la cuenta de la fuente de la señal.',
        profit: 'Los cargos se toman como un porcentaje de las ganancias en el pedido copiado, sin cargos por ningún beneficio. Digamos que se cobra el 5% de las ganancias, y si su cuenta de pedido copiada obtiene una ganancia de $ 1000 en el pedido, se tomarán $ 50 de su cuenta a la cuenta de origen de la señal.',
        lock: 'Los cargos se basan en un porcentaje del beneficio total durante el periodo de bloqueo, no se cobra ningún beneficio. Suponiendo un cargo del 8% sobre el beneficio total, el beneficio total de su cuenta de seguimiento durante el periodo de bloqueo sería de 2000 $ y habría que retirar 160 $ de su cuenta a la cuenta de Signal Source.',
        fake: 'Supuestos: Nivel 1 de recomendación: 30%, Nivel 2 de recomendación: 20%, Nivel 3 de recomendación: 10%, A recomienda a B, B recomienda a C, C recomienda a D. Si el usuario D realiza un pedido que genera una comisión de seguimiento de 100 $, A recibe 10 $, B recibe 20 $ y C recibe 30 $.',
        said: 'En BlaFX, muchos de los seguidores devuelven más del 70% de los beneficios de sus comisiones por copia, ¡y algunos incluso llegan al 90%!',
        byhand: 'A mano',
        byleft: 'Por equilibrio',
        byprofit: 'Por beneficio',
        bylock: 'Beneficios por periodo de bloqueo'
    },
    common: {
        title: 'BlaFX Operaciones de seguimiento',
        update: 'El sitio web se está actualizando, por favor entienda cualquier inconveniente',
        back: 'devolución',
        nosm: 'Complete la autenticación de nombre real antes de hacerlo',
        fail: 'Error en la operación',
        rz: 'Ir a certificación',
        ok: 'La operación fue un éxito.'
    }
}
