export const lang = {
    home: {
        countLogin: '账户登录',
        transaction: '交易平台',
        traders: 'MetaTrader4',
        tradersOnline: 'MetaTrader4-在线交易',
        plan: '推荐计划',
        copy: '跟单交易',
        aboutCopier: '对于复印件',
        joinCopy: '加入跟单交易',
        getLevel: '交易大师的评级',
        aboutTeacher: '对于交易大师',
        beTeacher: '成为交易大师',
        msg: '信息',
        clause: '条款与细则',
        aboutUs: '关于我们',
        news: '公司新闻',
        problem: '常见问题',
        score: '策略',
        follow: '正在跟单',
        clause2: '条款与条件',
        login: '登录',
        email: '邮箱',
        password: '密码',
        forgetPassword: '忘记密码？',
        code: '验证码',
        login2: '登录',
        register: '注册',
        google: '谷歌验证码',
        qlgoogle: '请输入谷歌验证码',
        center: '个人中心',
        quit: '退出登录',
        quitMsg: '登录失效，请重新登录',
    },
    message: {
        choose: '为何选择',
        changeMarket: '交易市场',
        changeTool: '交易平台与工具',
        community: 'BlaFX',
        companies: '合作伙伴',
        supervise: '我的监管',
        VIP: '高级VIP服务',
        active: '活跃交易者计划',
        out: '外汇交易',
        contract: '指数差价合约',
        goods: '大宗商品',
        CFD: '货币指数差价合约（CFD）',
        money: '点差、掉期和佣金',
        time: '交易时间',
        count: '交易账户',
        inAndOut: '入金和出金',
        begin: '立即开始',
        MetaTrader: 'MetaTrader 4',
        MetaTraderChange: 'MetaTrader 4-在线交易',
        study: '学习交易',
        news: '经济资讯',
        com: 'BlaFX跟单社区',
        proxy: '代理商服务',
        friend: '好友推荐计划',
        title: '我们提供的服务包括场外交易产品和外汇产品的交易，并存在亏损大于初始注资金额的风险。这些产品不一定适合每一位投资者，请确保您完全了解所涉及的风险，必要时可向第三方征询意见。',
        p1: '风险警告：交易差价合约和外汇存在风险。它并不适合所有人，您的损失可能会远远超出您的初始投资。您不会拥有标的资产的所有权或权利。过去的表现并不代表未来的表现，并且税法可能会发生变化。本网站上的信息是一般性信息，并未考虑您或您客户的个人目标、财务状况或需求。请在制定任何交易决定之前，阅读我们的风险披露通知和其他法律文件，确保您完全了解风险。我们鼓励您寻求独立的建议。',
        p2: 'BlaFX Capital PTY LTD位于Suite 280 156 Burwood Road Bureood,NSW 2134 Australia。 获得澳大利亚证券委员会颁发的牌照和并接受其监管。 本网站上的信息和提供的产品和服务并非要推销给任何国家或司法管辖区的任何人。',
        p3: ' © 2020 BlaFX Capital PTY LTD| 公司注册号661 715 966 | AFS - 001298638'
    },
    score: {
        invested: '您投资了吗？',
        signUp: '注册，开始跟单任意操盘大师交易者，为您的投资增加更高更稳定的收益。',
        score: '筛选',
        traders: '交易者',
        agency: '代理佣金%',
        risk: '风险评分',
        gain: '收益率',
        cycle: '锁仓周期',
        remainingCycle: '剩余周期',
        remaining: '总额度',
        amount: '剩余额度',
        profit: '收益与损失',
        copiers: '跟单者',
        commission: '跟单佣金%',
        remaining: '可用跟单额度',
        amount: '剩余额度',
        profit: '收益与损失',
        copiers: '跟单者',
        commission: '佣金',
        winner: '头号赢家',
        welcome: '最受欢迎的交易者',
        weeks2: '2个星期',
        month1: '1个月',
        month3: '3个月',
        month6: '6个月',
        all: '全部',
        legend: '传奇',
        expert: '专家',
        success: '成功者',
        gUp: '成长型',
        newBlood: '新手',
        showFrist: '最先显示对象',
        rangTime: '时间范围',
        mixBalance: '最低投资',
        mixknow: '最低专业知识',
        nickName: '昵称',
        null: '无',
        nolimit: '无限'
    },
    detail: {
        riskScore: '风险评分',
        equity: '账户净值',
        followVal: '跟单佣金',
        regDate: '交易时间',
        followsetting: '跟单设置',
        mintz: '最低投资',
        tradingStrategyString: '策略说明',
        tradingStrategyString1: '策略事例说明',
        followway: '跟单策略',
        bx: '表现',
        onemonth: '1个月',
        threemonth: '3个月',
        sixmonth: '6个月',
        all: '全部',
        profit: '盈利',
        copiers: '跟单者',
        profitandloss: '收益与损失',
        accountdetail: '账户详情',
        folatprofit: '浮盈',
        balance: '余额',
        givegold: '赠金',
        lever: '杠杆',
        riskmanage: '风险管理',
        maxprofit: '最大浮亏',
        maxmax: '最大回撤久期',
        active: '活动',
        transferhistory: '交易历史',
        historyorder: '历史订单',
        holdorder: '持仓订单',
        leftoperration: '结余操作',
        limitorder: '限价订单',
        minfollow: '最低跟单金额',
        nr: '假设信号账户余额为10万美金，您的跟单账户余额为1万美金，信号账户下单10手，您的跟单账户下单1手。 假设信号账户余额为2万美金，您的跟单账户余额为20万美金，信号账户下单1手，您的帐户下单10手。计算公式为：你的跟单下单手数=你的跟单账户余额÷信号源账户余额x信号源账户下单手数',
        nr1: '采用余额比例1:1自動計算持倉倉位進行跟單',
        manageFollow: '管理跟单',
        followMsg: '结束跟单后，您跟单的信号源如有未平仓的交易，需要您手动平仓该信号源所有持仓。',
        careful: '请注意',
        endFollow: '结束跟单',
        cancel: '取消',
        confirm: '确认',
        remind: '提醒',
        inCash: '入金',
        outCash: '出金',
        desc1: '若您想以更多的持仓量跟随信号请',
        desc2: '若您想以更少的持仓量跟随信号请',
        modalTitle: '跟单须知',
        modalHtml: `<div>
            1、BlaFX提供跟单交易功能。在决定跟单某个或多个交易者或实施特定的策略时，您
            必须考虑包括财务规划在内的您的整体财务状况，并且了解使用跟单交易功能具有极高的投
            机性，您可能承受超过用于跟单交易者的金额的较大损失。跟单交易功能相关的风险包括但
            不限于自动交易操作，即您的帐户在没有您手动干预的情况下，可以开始和结束交易。<br /><br />
            2、自动交易执行等相关交易操作无需经您人工作业即可在您帐户中完成建仓与平仓交易。<br /><br />
            3、BlaFX投资管理服务由您自行选择，您自行决定跟单特定交易者或多名交易者以及
            或遵从特定交易策略。在作出此等决定时，您已经考虑了包括财务规划在内的自身整体财务
            状况，并且了解使用跟单交易功能具有极高的投机性，您可能招致的损失比用以跟单此交易
            者的金额还要大得多。<br /><br />
            4、我们所提供的跟单交易功能仅供参考。BlaFX及其关系企业的员工和代理人并非投
            资或金融顾问。如果您根据我们网站上提供的资料或透过跟单交易功能获取的资料作出投资
            决定，您应自行承担风险，BlaFX及其关联企业、其员工及代理人不承担任何损失。<br /><br />
            5、您应自主研判再作出投资决定。您应根据自身投资目标和个人及财务状况自行独立判断
            投资、策略、或任何其他产品及服务是否符合您自身需要。<br /><br />
            6、我们使用交易者过往的表现、风险率、统计资料以及在我们网站及应用上显示的关于该
            名交易者的所有资料，都不代表未来业绩，应视此为假设情况。<br /><br />
            7、过往的表现以及风险率应被视为假设的业绩。假设业绩有许多内在局限。不得陈述或保
            证任何帐户将可或者可能取得与过往业绩或所显示风险率相似之收益或亏损。各投资者的实
            际收益/亏损百分比都可能有所不同，取决于多个因素，包括而不限于：进行交易时的帐户
            余额（入金和出金）、市场行为、投资者的帐户设置以及跟单对象的实际表现。<br /><br />
            8、您了解并接受跟单交易服务相关的风险，包括但不限于自动交易操作，即您的账户在没
            有您手动干预的情况下可以开始和结束交易，跟单交易的延时性可能产生高额成本，跟单交
            易服务可能出现失败的情况。因您使用跟单交易服务自动执行操作而遭受的任何及全部损
            失，您应自行承担全部责任。
        </div>`,
        modalBtn1: '取消',
        modalBtn2: '同意',
    },
    clause: {
        caluse1: 'BETHLE ASTER 跟单交易条款和条件',
        caluse2: 'BETHLE ASTER（以下简称 "BlaFX"）跟单交易（以下简称 "服务"）是一项允许交易者（以下简称 "跟单者"）从其他交易者（以下简称 "跟单者"）跟单交易的服务，可以通过选择 "开始跟单 "来激活。',
        caluse3: '1. 复印者承诺做到以下几点来申请本服务',
        caluse4: '1.1 要在BETHLE ASTER网站上注册并登录为客户。',
        caluse5: '请注意，你在注册账户时默认开立的第一个MT账户是默认的佣金钱包，它只能交易和存款、转账，但不能跟单。你最多可以创建5个MT账户，每个新的MT账户都可以独立下单、提款、转账和交易。',
        caluse: ' 特别提醒',
        caluse121: '1.2.1 如果有一个账户正在进行挂牌交易或自营交易，那么这个账户的其他相关资金功能将受到限制，不能进行转账、取款和新的挂牌交易或自营交易，只有在这个账户关闭挂牌交易或自营交易时才会恢复。当然，你的其他没有任何交易的MT账户也不会受此影响。',
        caluse122: '1.2.2 BETHLE ASTER 始终致力于减少我们用户不做决定的风险。如果您的MT账户有自营头寸，您需要先关闭自营订单，才能下跟单。如果您的MT账户有一个跟单头寸。您需要停止跟单并手动平仓，然后才能在您的MT账户上交易。',
        caluse6: '1.3启动跟单。',
        caluse7: '2. 跟单者有权做以下事情',
        caluse8: '2.1 跟单任何数量的母版（跟单器区域列出所有当前的订阅）。',
        caluse9: '2.2你可以在任何时候在 "跟单 "区域用单个订单手动关闭交易（有锁定期的信号源除外）。',
        caluse10: '2.3 通过点击 "停止跟单"，取消对主账户的订阅，并停止跟单主账户的交易。取消订阅后，所有投资于主账户的资金和利润都会回到跟单者的钱包中。',
        caluse11: '2.4 为每个特定的主件设置跟单比例的大小。这个选项在本条款和条件的第4.4款中有详细解释。',
        caluse12: "2.5 增加支持资金，以保护投资免受意外的市场波动影响。这些资金用于在市场波动时支持Copier的交易策略，并不直接影响利润。",
        caluse13: "2.6 在参与每个特定的主交易商的投资业务的情况下，跟单者的投资金额的计算是按照跟单者的资金比例设定的。这在条款和条件的第4条第5项中有明确解释。",
        caluse14: '3. 该服务可在Metatrader 4平台上使用。',
        caluse15: '4. 打开跟单的交易程序',
        caluse16: '4.1 跟单器只跟单主控人在服务范围内为主控人认购后所开的交易。',
        caluse17: "4.2 止损/获利订单在跟单器区域将不可见，但如果这些订单在主账户上被触发，跟单的交易也会被关闭。",
        caluse18: '4.3 在认购大师时，跟单者指定从钱包中扣除的资金数额，并投资于选定的大师。这些资金和你的利润将在你停止跟单主的时候回到你的钱包里。',
        caluse19: '4.4 跟单器按照以下账户和认购的信号源主交易员账户的资金比例，跟单主交易员的交易。',
        caluse20: '4.4.1 在比率选项中，交易量是根据杠杆比率和操盘手主力交易者和跟随者的净资产来计算的。使用的公式如下。',
        caluse21: '交易量（追随者）=净值（追随者）/净值（主交易员）×杠杆（追随者）/杠杆（主交易员）×交易量（主交易员）。例如，一个主交易员的净资产为2000美元，杠杆率为1:200。一个追随者的净资产为200美元，杠杆率为1:500。在主账户上开设1手。那么，跟随者的交易量为200/2000 x 500/200 x 1 = 0.25手。',
        caluse22: "4.5 复印机的杠杆率被设定为1:100。愿意调整它的复印机需要联系BETHLE ASTER的客户支持。",
        caluse24: "4.6 一旦该服务被激活，交易将被跟单到跟单者的账户中，无论账户所有者是否登录。",
        caluse25: "4.7 跟单者的订单在主账户的订单（买或卖）之后执行。 当主账户下单时，在跟单者的账户中触发了开单信号。跟单者的订单按市场价格执行。同样的机制触发了订单的关闭。因此，这些订单的执行价格可能不同。此外，跟随该大师的跟单者的数量会影响执行时间。",
        caluse26: '5. 适用的限制',
        caluse27: '5.1 跟单交易的最小量是0.01手，跟单交易的最大量是100手。',
        caluse28: '5.2 最小跟单的订单量是0.01手。然而，跟单的订单量低于0.005手将被拒绝，而0.005手以上的跟单交易将被四舍五入为0.01手。',
        caluse29: '5.3 任何订单的数量都被四舍五入到最接近的小数点后一百位（小数点后的第二个数字）。例如，如果跟单者跟单了一个0.324手的订单，该订单将被四舍五入为0.32手。反之亦然，如果跟单者跟单0.325手的订单，该订单将被四舍五入为0.33手。',
        caluse30: '5.4 如果计算后跟单的交易量大于100手，那么该交易将不会在跟单者账户上打开。',
        caluse31: "6. 如果主人改变了资产（通过存款或取款）或杠杆，所有跟单的交易在跟单者的账户上保持其初始数量。",
        caluse32: "7. 跟单者的所有交易条件（杠杆、掉期、点差）与BETHLE ASTER MT4账户的条件相似。",
        caluse33: '8. 主交易员账户必须是MT4微型类型。',
        caluse34: '9. 该处有权采取以下措施',
        caluse35: '9.1 限制大师们可以在任何时候创建主账户的数量，而无需事先通知，由其全权决定。',
        caluse36: '9.2 在没有事先通知的情况下，将复印机从主人那里退订。',
        caluse37: '9.3 在没有事先通知或提供任何解释的情况下，减少主交易员设定的佣金，并限制其所有主账户的最高价值。',
        caluse38: ' 9.4 在任何时候修改这些条款和条件，而无需事先通知跟单者或主交易商。这些修订在这些条款和条件中公布在服务网站上的那一刻起生效',
        caluse39: "9.5 监控主交易员在平台上的活动，如果主交易员操纵他们的统计数字，则用 '可疑活动'警告标记主交易员的账户，并默认将该账户从主评级过滤中排除（将为相应改变其过滤设置的跟单者保留它）。",
        caluse40: "9.6 如果本服务有理由怀疑该大师故意跟单或模仿其他大师的昵称和（或）图片，并可能导致大师的不诚实表现，则无需事先通知而修改或删除大师的昵称和（或）用户图片。",
        caluse41: "10. 主交易员决定跟随订单的佣金数额。佣金的范围是所下订单的利润的0%到50%。佣金将在每笔交易结束后支付给主交易员的电子钱包。",
        caluse42: '11. 复印机支付给主控方的佣金金额是在复印机按下开始复印的那一刻设置的。如果主控方改变佣金金额，它不会影响本订阅项下应付给主控方的金额。',
        caluse43: "12. 跟单者的交易统计数据只能由跟单者查看。",
        caluse45: "13. 大师的交易统计数据可供公众查阅。",
        caluse46: "14. 跟单者不能进入交易终端。他们的订阅和交易的所有行动都是在跟单者区域进行的。",
        caluse47: "15. 如果本服务有理由怀疑复印者违反了客户协议中规定的存款和提款规则或复印者居住国的立法，本服务有权暂停向该复印者提供服务。",
        caluse48: '16.请注意，大师既可以做盈利的交易，也可以做亏损的交易。',
        caluse49: 'BETHLE ASTER保留对这些条款和条件的最终解释权。',




        caluse50: '如果跟单账户净值小于赠金数额，赠金将被取消',
        caluse51: '跟单者可以在跟单专区手动取消赠金。',
        caluse52: '当跟单者停止跟单操盘大师交易者时，赠金将被取消。',
        caluse53: '取消后，赠金不能再次申请或重新激活。',
        caluse54: '本服务可随时拒绝跟单者的赠金申请，而无需事先通知或提供做出该决定的理由。',
        caluse55: '本服务可随时取消跟单者的赠金，无需另行通知',
        caluse56: '如果跟单者在提款/内部转账时使投资于操盘大师交易者的个人资金金额小于或等于赠金金额，赠金将被取消。',
        caluse57: '任何未在本条款中描述的情况，本服务有最终解释权。',
        caluse58: '本服务保留更改、更新或取消此促销的权利，无需在服务新闻中通知。',
        caluse67: '跟单者的交易统计仅跟单者可以查看。',
        caluse68: '操盘大师的交易统计对公众公开使用。',
        caluse69: '跟单者不能访问交易终端。 跟单者对订阅和交易的所有操作都在‘跟单者专区’办理。',
        caluse70: '如果本服务有合理原因怀疑跟单者违反了客户协议设定的入金和出金规则或跟单者居住国的法规，本服务有权停止向此跟单者提供服务。',
        caluse71: '请注意，操盘大师的交易可能盈利也可能亏损。',
        caluse72: 'BlaFX公司保留对本条款和条件最终解释权',
    },
    join: {
        copy: '通过跟单专业交易者的成功获利',
        wenan: 'BlaFX -FX Copytrading为客户提供机会，自动跟单领先交易者的策略，而无须花费漫长的时间制定自己的交易策略。 从最出色的外汇交易大师中做出选择，分散您的交易组合。',
        join: '加入Copytrading',
        become: '或者成为操盘大师',
        way: '运作方式',
        creat: '创建账户，办理入金',
        simpleway: '只需简单一步即可注册，然后使用您愿意的任何支付方式向您的钱包办理入金。如果您在自己的BlaFX -FX交易账户中已有资金，可以使用内部转账，将资金从账户添加到您的钱包。您的钱包结余显示的是您没有投资的资金。',
        followbest: '跟随最佳交易者',
        followandcopy: '找到您希望追随的大师，然后点击‘跟单’。 其持仓将会自动跟单。 入金百分比设置将帮助您管理投资组合。 尝试跟单不同的策略，从中决定最好的一个！',
        moandprofit: '监控和获利！',
        copycopy: '您可以跟单的操盘大师数量不受限制，因此，您可以创建均衡和多样化的交易组合，获得稳定的收入。 您还可以完全控制整个流程，在任何既定的时间修订/停止跟单交易。 您可以在您的跟单者区域查看跟单操盘大师交易者的详细交易数据。',
        followprofitbest: '跟随盈利最高的交易者',
        noneed: '无需成为外汇专家',
        manyways: '多元化组合的稳定收益',
        new: '这个新的工具能够帮助交易者通过跟单更有经验的交易者，赚取更稳定的收益。',
        learnbest: '学习最出色的交易者',
        easytoin: '让新手进入外汇市场变得十分容易',
        spread: '分散投资组合',
        choosebig: '选择大师交易者，跟随大批专家。',
        enjoyfast: '享受快速的订单执行',
        infive: '在建仓之后，订单在5分钟内执行。',
        inhand: '让一切在掌控之中',
        anytimestop: '您可以随时停止跟单、订阅或关闭某项交易。',
        easyway: ' 简单几个步骤即可以运行',
        noneedother: '无需额外的验证。',
        safe: '安全、快速的投资和出金',
        mangmain: '多种主流支付方式选择。',
        alluseful: '享有在移动app中跟单交易的全部优势',
        foces: '通过使用方便的BlaFX -FX Copytrading app，专注于投资',
        manage: '在移动中管控您的组合与投资',
        follow: '追踪操盘大师交易者和他们的实时业绩，实现明智投资',
        seerisk: '查看您的资金如何投资并实时管理风险',
        howto: '我如何选择一个操盘大师交易者来跟单？',
        firstcheck: '首先检查操盘大师交易者的数据。它包括风险评分，收益，盈亏，跟单人数，佣金，订单历史，和其他统计数据，以帮助您做出明智的决定。要开始跟单，请为您的帐户提供资金。',
        anyuseful: '有哪些可用的跟单选项？',
        ways: '当你订阅操盘大师交易者时，你需要指定跟单比例并决定是否增加支持资金。当你按下开始跟单时 ，资金就会从你的钱包中扣除，然后开始跟单。你可以同时订阅不限数量的操盘大师交易者。',
        did: '你们要收取跟单的佣金吗？',
        didas: '作为你的经纪商，我们不收取佣金。你只需向操盘大师交易者支付佣金，这是预先确定并单独指定的。每个操盘大师交易者的统计数据显示佣金的多少。',
        howstop: '如何停止跟单操盘大师交易者？',
        anytimecancle: '你可以随时取消操盘大师交易者的订阅并停止跟单。当您取消订阅时，所有投资于操盘大师交易者的资金和您从跟单中获得的利润都将记入您的钱包。',
        howprofit: '我如何提取利润？',
        wallet: '你可以从钱包提取。结束您对操盘大师交易者的订阅，将您的投资和利润转移到钱包。请注意，如果您以亏损结束订阅操盘大师交易者，您将无法收回初始投资。损失金额将从你的初始投资中扣除。',
        getprofit: '成为操盘大师，获得稳定的收益',
        becomaster: '成为操盘大师',
        rules: '条款和条规'
    },
    balance: {
        orderid: '订单号',
        opentime: '开仓时间',
        balantime:'结余时间',
        type: '类型',
        profit: '获利',
    },
    close: {
        openprice: '开仓价格',
        hand: '手数',
        symbol: '交易品种',
        closetime: '平仓时间',
        closeprice: '平仓价格',
        comission: '手续费',
        storage: '库存费',
        profit: '获利',
        nowprice: '当前价格'
    },
    joinmaster: {
        good: '擅长交易？让别人跟单你的交易从而赚钱。',
        one: '一边正常交易，一边从其他跟单您的交易者身上赚取额外收益。 您的操盘大师资料会展示您交易的每日和每月绩效——推广和吸引新的追随者！',
        become: '成为操盘大师',
        runway: '运作方式',
        creat: '创建操盘大师账户',
        click: '点击操盘大师区域，创建操盘大师账户—— 开启新的或分配已有账户到您的操盘大师账户',
        accountsetting: '账户设置',
        beready: '准备好您的操盘大师账户，让跟单者追随：设定您的佣金额，并且描述您的策略。',
        use: '使用操盘大师专区查看您交易的详细数据，更改账户设置，查看您所赚取的佣金金额。',
        offer: 'BlaFX-FX Copytrading为您提供额外的收入来源：开设操盘大师交易者账户，描述您的策略，然后设定让其他人跟单您交易的佣金。',
        different: '为不同的策略创建不同的操盘大师账户',
        detail: '在操盘大师区域追踪您订单的详细数据，赚取操盘大师佣金',
        enjoy: '享受各种好处，而无需向BlaFX-FX支付额外佣金',
        android: '您在安卓版BlaFX-FX交易App的操盘大师专区',
        mobile: '在移动中创建操盘大师账户',
        anywhere: '无论您身在何处，都可查看您跟单者的统计数据和佣金',
        now: '现场管理您的新跟随者的跟单条件',
        how: ' 如何成为操盘大师交易者？',
        get: '任何拥有MT4 账户的BlaFX-FX客户都可以成为操盘大师 交易者。 入金十万美金并交易三个月稳定获利即可向客服申请。',
        howmanage: '如何调整我向跟单者收取的佣金金额？',
        go: '前往您的操盘大师区域，查看设置，利用滑块调整佣金，然后保存。新的佣金只适用于在调整后订阅的跟单者。 对于所有其他跟单者，佣金金额将维持不变。',
        time: '我何时从我的跟单者获得佣金支付？',
        american: '美国时间每周星期日下午6点付款。',
        when: '什么时候向跟单者收取佣金？',
        comission: '佣金是每周六对已平仓的订单收取的。',
        whencanget: '什么时候可以获得佣金？',
        wallet: '我们会将佣金转至电子钱包。从您的钱包，您可以将资金添加到您的任何交易账户，或者出金。',
        copy: '自动跟单领先交易者的策略，而无须花费漫长的时间制定您自己的交易策略',
        or: '或成为跟单者',
    },
    other: {
        signalaccount: '信号账户',
        signalname: '信号昵称',
        wallet: '钱包资金',
        hold: '支持金额',
        need0: '所需投资的0%',
        zj: '总结',
        follow: '跟单倍数',
        followmoney: '跟单费用',
        begin: '开始跟单',
        hand: '手',
        more: '25或更多',
        choose: '请选择',
        notlogin: '您还没登录，请先登录',
        byhand: '手数',
        byprofit: '利润',
        byleft: '余额',
        bylock: '锁仓',
        day: '天',
        sure: '确认',
        not: '您还没有跟单交易',
        receiptaccount: '开通账户',
        balance: '可用余额',
        nodata: '暂无数据',
        notlogin1: '您还没有登录',
        socity: '社交',
        dl: '代理商计划',
        help: '帮助中心',
        privicy: '隐私政策',
        nochoose: '无选项',
        nonum: '您还未开通有效跟单账号'

    },
    explain: {
        hand: '按固定费用收取。假设$1/手，您的跟单账户下单10手，需要从您的账户抽取$10给信号源账户。',
        left: '按跟单账户余额比例收取。假设按余额的0.2%收取费用，您的跟单账户余额为$10000，需要从您的账户抽取$20给信号源账户。',
        profit: '按跟单订单利润的百分比收取，不盈利不收取。假设按利润的5%收取费用，您的跟单账户该笔订单盈利为$1000，需要从您的账户抽取$50给信号源账户。',
        lock: '按锁仓期内的总利润的百分比收取，不盈利不收取。假设按总利润的8%收取费用，您的跟单账户锁仓期内的总利润为$2000，需要从您的账户抽取$160给信号源账户。',
        fake: '假设：一级推荐：30%，二级推荐：20%，三级推荐：10%，A推荐B，B推荐C，C推荐D。D用户下单产生跟单佣金$100，则A获得$10，B获得$20，C获得$30。',
        said: '在BlaFX，很多跟单大师把他的跟单佣金利润返还70%以上，甚至有的达到90%',
        byhand: '按手数',
        byleft: '按余额',
        byprofit: '按利润',
        bylock: '按锁仓期的盈利'
    },
    common: {
        title: 'BlaFX 跟单交易',
        update: '网页正在升级，为您带来不便请谅解',
        back: '返回',
        nosm: '请完成实名认证以后再进行此操作',
        fail: '操作失败',
        rz: '去认证',
        ok:'操作成功'
    }
}
