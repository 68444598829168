export default {
    data() {
        return {
            menuData: [
                {
                    label: '交易平台',
                    children: [
                        {label: '元交易者4'},
                        {label: '元交易者4-在线交易',}
                    ]
                },
                {
                    label: '推荐计划',
                },
                {
                    label: '复制交易',
                    children: [
                        {
                            label: '对于复印机',
                            children: [
                                {label: '加入复制交易'},
                                {label: '交易大师的评级'}
                            ]
                        },
                        {
                            label: '对于交易大师',
                            children: [
                                {label: '成为交易大师'}
                            ]
                        },
                        {
                            label: '信息',
                            children: [
                                {label: '条款与细则'}
                            ]
                        }
                    ]
                },
            ]
        }
    }
}
