export const lang =  {
    home: {
        countLogin: 'ACCOUNT LOGIN',
        transaction: 'TRADING PLATFORMS',
        traders: 'METATRADER 4',
        tradersOnline: 'METATRADER 4-ONLINE TRADING',
        plan: 'REFERRAL PROGRAM',
        copy: 'COPYTRADING',
        aboutCopier: 'FOR COPIERS',
        joinCopy: 'Join copy trading',
        getLevel: 'Master Trader\'s rating',
        aboutTeacher: 'FOR MASTER TRADERS',
        beTeacher: 'Become a Master Trader',
        msg: 'INFORMATION',
        clause: 'Terms and Conditions',
        aboutUs: 'About us',
        news: 'Company news',
        problem: 'FAQ',
        score: 'tactics',
        follow:'Replicating',
        clause2: 'TERMS AND CONDITIONS',
        login: 'Login',
        email: 'Email',
        password: 'Password',
        forgetPassword: 'FORGOT PASSWORD？',
        code: 'identifying code',
        login2: 'LOGIN',
        register: 'REGISTER',
        google:'Google Captcha',
        qlgoogle:'Please enter Google Authentication Code',
        center:'Personal Center',
        quit:'Logout'
    },
    message: {
        choose: 'Why',
        changeMarket: 'Trading markets',
        changeTool: 'Trading platforms and tools',
        community: 'Cloud Bridge Community',
        companies: 'Partners',
        supervise: 'My regulation',
        VIP: 'Premium VIP service',
        active: 'Active Trader Program',
        out: 'Forex trading',
        contract: 'Index CFDs',
        goods: 'Commodities',
        CFD: 'CFDs on currency indices',
        money: 'Spreads, swaps and commissions',
        time: 'Trading hours',
        count: 'Trading account',
        inAndOut: 'Deposits and withdrawals',
        begin: 'Get started now',
        MetaTrader: 'MetaTrader 4',
        MetaTraderChange: 'MetaTrader 4-Online trading',
        study: 'Learn to trade',
        news: 'Market news',
        com: 'Cloud Bridge Documentary Community',
        proxy: 'Agent services',
        friend: 'Friend referral program',
        title: 'The services we provide include trading in OTC products and foreign exchange products, and there is a risk that the loss will be greater than the initial capital injection. These products may not be suitable for every investor, so please ensure that you fully understand the risks involved and seek advice from third parties if necessary.',
        p1: 'Risk Warning: Trading CFDs and Forex involves risks. It is not for everyone, and your losses can far exceed your initial investment. You do not own ownership or rights in the underlying assets. Past performance is not indicative of future performance, and tax laws are subject to change. The information on this website is of a general nature and does not take into account your or your clients\' personal objectives, financial situation or needs. Please read our Risk Disclosure Notice and other legal documents before making any trading decision to ensure that you fully understand the risks. We encourage you to seek independent advice.',
        p2: 'Cloud Bridge Capital PTY LTD is located at Suite 280 156 Burwood Road Bureood, NSW 2134 Australia. Licensed and regulated by the Australian Securities Commission. The information on this website and the products and services offered are not intended for marketing to anyone in any country or jurisdiction.',
        p3: ' © 2020 Cloud Bridge Capital PTY LTD| Company registration number 661 715 966 | AFS - 001298638'
    },
    score: {
        invested: 'Have you invested?',
        signUp: 'Sign up, start copying any Master trader and claim a 50% bonus on your investment profits.',
        score: 'Expert Ratings',
        traders: 'Traders',
        agency: 'Agency\nbrokerage%',
        risk: 'Risk score',
        gain: 'Gain',
        cycle: 'Cycle',
        remainingCycle: 'Remaining cycle',
        remaining: 'Gross\nmagnitude',
        amount: 'Remaining amount',
        profit: 'Profit and\nloss',
        copiers: 'Copiers',
        commission: 'Copy\nbrokerage%',
        winner: 'Top gainers',
        welcome: 'Most popular',
        weeks2: '2 weeks',
        month1: '1 month',
        month3: '3 months',
        month6: '6 months',
        all: 'All time',
        legend: 'legend',
        expert: 'expert',
        success: 'High achiever',
        gUp: 'Growing talent',
        newBlood: 'Newcomer',
        showFrist:'Display the object first',
        rangTime:'Time Range',
        mixBalance:'Minimum Investment',
        mixknow:'Minimum expertise',
        nickName:'Nickname',
        null:'not',
        nolimit:'boundless'
    },
    detail:{
        riskScore:'Risk Score',
        equity:'Account Net Worth',
        followVal:'Copy commission',
        regDate:'Trading Hours',
        followsetting:'Follower Settings',
        mintz:'Minimum Investment',
        tradingStrategyString:'Strategy Description',
        tradingStrategyString1:'Policy case description',
        followway:'Copy strategy',
        bx:'Performance',
        onemonth:'1 month',
        threemonth:'3 Months',
        sixmonth:'6 Months',
        all:'All',
        profit:'Profit',
        copiers:'Replicator',
        profitandloss:'Gains and losses',
        accountdetail:'Account Details',
        folatprofit:'Floating profit',
        balance:'Balance',
        givegold:'Grants',
        lever:'Leverage',
        riskmanage:'Risk Management',
        maxprofit:'Maximum Floating Loss',
        maxmax:'Maximum Retracement Duration',
        active:'Events',
        transferhistory:'Trading History',
        historyorder:'Historical Orders',
        holdorder:'Position orders',
        leftoperration:'Balance Operations',
        limitorder:'Limit Order',
        minfollow:'Minimum copy amount',
        nr:'The mode of following orders is based on the ratio of the balance of the following account to the balance of the signal account to determine the proportion of orders placed. Then the signal account orders a few hands, the followers account in accordance with this ratio multiplied by a few hands. Suppose the signal account balance is $100,000, your followers account balance is $10,000, the signal account orders 10 lots, your followers account orders 1 lot. Assuming that the signal account balance is $20,000 and your followers account balance is $200,000, the signal account will place 1 lot and your account will place 10 lots.',
        nr1:'The balance ratio 1:1 is used to automatically calculate the position of the position for copying',
        manageFollow:'Managing orders',
        followMsg: 'After you close the order, you will need to manually close all positions in the signal source you copied if there are open trades.',
        careful: 'Please note',
        endFollow: 'Ending an order',
        cancel: 'Cancel',
        confirm: 'Confirm',
        remind: 'Reminder',
        inCash: 'Deposit',
        outCash: 'Withdrawal',
        desc1: 'If you want to follow the signal with a larger position size, please',
        desc2: 'If you want to follow the signal with a smaller position, please',
        modalTitle: 'Notes on copying',
        modalHtml: `<div>
            1. BlaFX provides copy trading function. When deciding to copy one or more traders or implement a specific strategy, you
            It's important to consider your overall financial situation, including financial planning, and understand that using the copy trading feature is extremely high
            Mechanical, you may sustain a large loss in excess of the amount used for copying traders. The risks associated with the copy trading feature include but are not limited to:
            Not limited to automated trading operations, i.e. your account can start and close trades without your manual intervention.<br /><br />
            2. Automatic transaction execution and other related trading operations can complete position opening and closing transactions in your account without your manual operation.<br /><br />
            3. BlaFX investment management services are at your own choice, and you decide to copy specific traders or multiple traders as well
            Or follow a specific trading strategy. In making these decisions, you have considered your overall finances, including financial planning
            situation, and understand that using the copy trading function is extremely speculative and you may incur more losses than you can use to copy this trade
            The amount is much larger.<br /><br />
            4. The copy trading function we provide is for reference only. Employees and agents of BlaFX and its affiliates are not investors
            Capital or financial advisors. If you make an investment based on the information provided on our website or obtained through the copy trading feature
            Decides that you shall do so at your own risk and BlaFX and its affiliates, its employees and agents shall not be liable for any losses.<br /><br />
            5. You should make your own investment decision. You should make your own independent judgment based on your own investment objectives and personal and financial situation
            Whether the investment, strategy, or any other product or service meets your needs.<br /><br />
            6. We use the trader's past performance, risk ratio, statistical information and information displayed on our website and application
            All information provided by traders is not indicative of future performance and should be considered hypothetical.<br /><br />
            7. Past performance and risk ratio should be considered as hypothetical performance. Assume that performance has many inherent limitations. No representations or warranties
            Certify that any account will or may make gains or losses similar to past performance or risk ratios shown. The reality of each investor
            The profit/loss percentages may vary depending on a number of factors, including but not limited to: the account at the time of the transaction
            Balance (deposits and withdrawals), market behavior, the investor's account settings, and the actual performance of the person to copy.<br /><br />
            8. You understand and accept the risks associated with copy trading services, including but not limited to automated trading operations, that is, your account is not available
            With your manual intervention, you can start and close trades, and the delay of copy trading may incur high costs
            Easy service may fail. Any and all damages suffered as a result of your automated operation using the Copy Trading Service
            If not, you shall bear all responsibility for it.
        </div>`,
        modalBtn1: 'Cancel',
        modalBtn2: 'Agree',
    },
    clause:{
        caluse1:'Cloud Bridge Copytrading Terms and Conditions',
        caluse2:'Cloud Bridge Copytrading (hereinafter referred to as \'the Service\') is a service that allows traders (hereinafter referred to as \'copiers\') to copy the trades of other traders (hereinafter referred to as \'master traders\'), which you can activate by selecting \'Start Copying\'.',
        caluse3:'To apply for this service, followers are required to complete the following actions.',
        caluse4:'Complete registration and login as a customer on the Cloud Bridge website',
        caluse5:'additional funds to the purse.',
        caluse:' Please note that the first MT account is opened the default commission wallet and can only trade and deposit and transfer funds, but not follow orders. Users can create several new MT accounts at any time and each new account can follow orders, withdrawals and transfers. Active trading is also possible.Special reminder：',
        caluse121:'If an account is in the process of placing a pending account or active trade, other relevant funding functions of this account will be restricted, no transfers, no withdrawals, and no new pending or active trades will be made, and will only resume if this account is closed for pending or active trades. Of course, your other MT accounts that are not trading in any way are not affected by this.',
        caluse122:' Order following account and active trading cannot be executed at the same time, if there is a position to follow, the system prompts to close the position first before            can follow the order. If you have a follow order to trade independently, the system prompts you to stop the follow order before you can trade independently.\n',
        caluse6:'Enabling replication',
        caluse7:'The reproducer is entitled to perform the following operations.',
        caluse8:'Copy any number of Master Trader traders (the Followers section lists all currently subscribed Master Traders)',
        caluse9:'You can manually close a trade of a follow order at any time in the Follow Order Area.',
        caluse10:'By clicking on \'Stop Following\', you can cancel your subscription to the Trader and stop copying the Trader\'s trades. To cancel the subscription, the copier needs to close all trades. After cancellation of the subscription, all the funds and profits invested with the Master Trader are returned to the replicator\'s wallet.',
        caluse11:'Set the percentage of orders to follow for each specific Trader Master trader. This option is described in detail in paragraph 4.4 of the Terms and Conditions.',
        caluse12:'Additional support funds to protect investments from unexpected market fluctuations. These funds are used to support the trading strategies of followers in times of market volatility and do not directly affect profitability.',
        caluse13:'Set one of two options for the calculation of the replicator\'s investment volume (full size 1 to 1 replication or in proportion to the replicator\'s funds) in the context of the investment operations of each particular master trader involved. Both options are explicitly explained in article 4, subparagraph 5, of the Terms and Conditions.',
        caluse14:'This service is available on the Metatrader 4 platform.',
        caluse15:'To open the replication transaction routine.',
        caluse16:' A Replicator who subscribes to a Master Trader through the Service will only replicate trades opened by that Master Trader.\n',
        caluse17:'Stop Loss / Take Profit orders are not visible in the copier\'s area, but if an order is triggered on a Trader\'s Master account, the copied trade will also be closed.',
        caluse18:' After subscribing to a Master Trader, the replicator specifies the amount that will be deducted from the wallet to invest using the selected Master Trader. These funds and your profits will be returned to your wallet when you stop copying a Trader Master.',
        caluse19:'Followers can choose to copy the trades of the Master Trader in equal (x 1), double (x 2), triple (x 3) or any other ratio. When subscribing to the Master Trader select the mode of followers and the required investment to start.\n',
        caluse20:' Older versions of the Cloud Bridge Order Following Mobile App (installed before December 25, 2022, and not updated) allow the User to select a type of scaled order following.',
        caluse21:'In the ratio option, the trading volume is calculated based on the leverage ratio and net worth of the Master Trader and the Followers. The formula used is as follows.',
        caluse22:'Volume (Followers) = Net(Followers)/Net(Master Trader) ×         Leverage(Followers)/Bar  Leverage(Master Trader) × Volume(Master Trader).Example: Master Trader has a net worth of $2000 and a leverage of 1:200. The Follower account has a net worth of $200 and a leverage of 1:500. 1 lot trade is opened on the Master account. Then, the volume of followers trade is 200/2000 x 500/200 x 1 = 0.25 lots.',
        caluse24:'The leverage ratio for replicators is set at 1:100. Replicators who wish to adjust their trading volume need to contact Cloud Bridge\'s customer service.',
        caluse25:' Immediately after the Service is activated, transactions will be copied to the Follower\'s account, regardless of whether the owner of the account is logged in or not.',
        caluse26:'A replicator\'s order is executed on his account in accordance with the order (buy or sell) of the master trader. When the Master Trader places an order, the follower\'s account triggers a signal to open a position, and such orders of the follower are executed at the market price. The same mechanism is triggered when an order is closed. Therefore, the execution price of these orders may vary. In addition, the number of followers copying this Master Trader will affect the execution time.\n',
        caluse27:'Applicable restrictions.',
        caluse28:'The minimum lot size for a copy trade is 0.01 and the maximum lot size for a copy trade is 100.',
        caluse29:'The minimum lot size for copy trades is 0.01. However, copy orders with a lot size below 0.005 will be rejected, while copy orders with a lot size above 0.005 will be rounded up to 0.01.',
        caluse30:'The volume of any order will be rounded up to the nearest hundredth decimal place (second decimal place). For example, if a follower copies an order of 0.324 lots, the order will be rounded up to 0.32 lots. And vice versa, if the copier copies an order of 0.325 lots, the order will be rounded up to 0.33 lots.',
        caluse31:'If after performing calculations it is found that the volume of copied orders is greater than 100 lots, then this order will not be copied.',
        caluse32:'If the net value of the account of the Master Trader (by processing deposits or withdrawals) or the leverage changes, all trades copied on the replicator\'s account retain the initial volume.',
        caluse33:'The full trading conditions for replicators (leverage, swaps, spreads) are similar to those of MT4 micro accounts.',
        caluse34:'The Master Trader account must be an MT4 Micro type account.',
        caluse35:'The services are authorized to take the following matters.',
        caluse36:'Restrictions on the number of Trader Master accounts created at the discretion of Trader Master traders at any time without prior notice.',
        caluse37:'Unsubscribe from copying of the Master Trader without prior notice.',
        caluse38:'Reduce the commission set by the Master Trader and limit the maximum value of all his accounts with the Master Trader without prior notice or any explanation provided by.',
        caluse39:'These terms and conditions may be modified at any time without prior notice to the copyist or Trader. These modifications are effective when they are posted on the Service\'s website in the Terms and Conditions.',
        caluse40:'Monitor the behavior of Master Trader traders on the platform, and if the Master Trader manipulates their statistics, flag the Master Trader\'s account with a "suspicious behavior" warning and exclude the account from the default filtered Master Rating (replicators who have changed their filter rules will still be visible).',
        caluse41:'The Service reserves the right to modify or delete a Master Trader\'s nickname and/or user image without prior notice if it reasonably suspects that the Master Trader has intentionally copied or imitated the nickname and/or image of another person, which may result in a dishonest representation by the Master Trader.',
        caluse42:'The master trader determines the amount of commission for following orders. Commissions range from 0% to 50% of the followers\' earnings. Commissions accumulated during the week will be paid to the Master Trader\'s e-wallet on Sunday.',
        caluse43:'The amount of commission paid by the follower to the Master Trader is set when the follower presses the "Start Follow" button. If a Master Trader changes the commission amount, this does not affect the expiration amount under this Master Trader subscription.',
        caluse45:'Follow trade bonus',
        caluse46:'The amount of the grant is 50% of the initial investment of the Follow the Trades Trader.',
        caluse47:'This grant can only be claimed once during the promotional period for a specific Master Trader.',
        caluse48:'The grant cannot be used for ongoing investments.',
        caluse49:'The grant is not withdrawable and cannot be considered as part of the follower\'s investment.',
        caluse50:' If the net value of the follow-on account is less than the grant amount, the grant will be cancelled',
        caluse51:'Followers can manually cancel the bonus in the Followers Area.',
        caluse52:'The grant will be cancelled when the follower stops following the Master Trader.',
        caluse53:'After cancellation, the grant cannot be reapplied or reactivated.',
        caluse54:'The Service may reject a follower\'s request for a grant at any time without prior notice or providing a reason for such decision.',
        caluse55:' The Service may cancel a follower\'s grant at any time and without notice',
        caluse56:'If the amount of personal funds invested in the Master Trader by the follower at the time of withdrawal/internal transfer is less than or equal to the amount of the grant, the grant will be cancelled.',
        caluse57:'Any circumstances not described in these Terms shall be subject to the final           interpretation of the Service.',
        caluse58:'The Service reserves the right to change, update or cancel this promotion without notice in the Service News.',
        caluse67:'The trade statistics of the follower can be viewed only by the follower.',
        caluse68:'Trading statistics of the Master Trader are open for public use.',
        caluse69:'Followers do not have access to the trading terminal. All operations of followers for subscriptions and trades are handled in the \'Followers\' Area\'.',
        caluse70:'The Service reserves the right to discontinue providing services to an order follower if the Service has reasonable grounds to suspect that the follower is violating the deposit and withdrawal rules set forth in the Client Agreement or the regulations of the follower\'s country of residence.',
        caluse71:'Please note that Master Trader trades can be profitable or loss-making.',
        caluse72:'Cloudbridge reserves the right of final interpretation of these terms and conditions.',
    },
    join:{
        copy:'Profit by replicating the success of professional traders',
        wenan:'CloudBridge-FX Copytrading offers clients the opportunity to automatically copy the strategies of leading traders without having to spend long hours developing their own trading strategies. Make your choice from the best forex trading gurus and diversify your trading portfolio.',
        join:'Add Copytrading',
        become:'Or become a master trader',
        way:'How it works',
        creat:'Create an account and make a deposit',
        simpleway:'Sign up in one simple step and then use any payment method you wish to process a deposit to your wallet. If you already have funds in your own CloudBridge-FX trading account, you can use internal transfers to add funds from your account to your wallet. Your wallet balance shows the funds that you have not invested.',
        followbest:'Follow the best traders',
        followandcopy:'Find the guru you wish to follow and click \'Copy\'. Their positions will be copied automatically. The deposit percentage setting will help you manage your portfolio. Try copying different strategies and decide on the best one!',
        moandprofit:'Monitor and profit!',
        copycopy:'There is no limit to the number of Trader Masters you can copy, so you can create a balanced and diversified portfolio of trades and earn a steady income. You also have full control over the entire process and can revise/stop copying trades at any given time. You can view detailed trading data of copied Master Trader in your replicator area.',
        followprofitbest:'Follow the most profitable traders',
        noneed:'No need to be a forex expert',
        manyways:'Stable returns from a diversified portfolio',
        new:'This new tool helps traders to earn more consistent returns by copying more experienced traders.',
        learnbest:'Learn from the best traders',
        easytoin:'Making it easy for newbies to enter the Forex market',
        spread:'Diversified portfolio',
        choosebig:'Choose a master trader and follow a large group of experts.',
        enjoyfast:'Enjoy fast order fulfillment',
        infive:'Orders are executed within 5 minutes after the position is opened.',
        inhand:'Keeping everything under control',
        anytimestop:'You can stop copying, subscribe or close a transaction at any time.',
        easyway:' Ready to run in a few simple steps',
        noneedother:'No additional validation required',
        safe:'Safe and fast investment and withdrawal of funds',
        mangmain:'A variety of mainstream payment options.',
        alluseful:'Enjoy all the advantages of replicating transactions in a mobile app',
        foces:'Focus on investing with the easy-to-use CloudBridge-FX Copytrading app',
        manage:'Control your portfolio and investments on the move',
        follow:'Track master traders and their real-time performance for smart investing',
        seerisk:'See how your money is invested and manage risk in real time',
        howto:'How do I choose a master trader to follow？',
        firstcheck:'First check the Trader Master Trader data. It includes risk scores, returns, profit/loss, number of followers, commissions, order history, and other statistics to help you make an informed decision. To start following orders, please fund your account.',
        anyuseful:'What are the available follow up options？',
        ways:'When you subscribe to Trader Master Trader, you need to specify the percentage of followers and decide whether to increase the support funds. When you press Start Follow, the funds will be deducted from your wallet and the follow up will begin. You can subscribe to an unlimited number of Master Traders at the same time.',
        did:'Do you charge commissions for following orders？',
        didas:'As your broker, we do not charge commissions. You only pay a commission to the Master Trader, which is predetermined and individually specified. The statistics of each Master Trader show how much the commission is.',
        howstop:'How to stop following Master Trader？',
        anytimecancle:'You can cancel your Trader Trader subscription and stop following orders at any time. When you cancel your subscription, all the money invested in Trader Trader and the profits you have made from your followers will be credited to your wallet.',
        howprofit:'How do I withdraw my profits？',
        wallet:'You can withdraw from your wallet. Ending your subscription to Trader Trader transfers your investment and profits to your wallet. Please note that if you end your subscription to Trader Trader with a loss, you will not be able to recover your initial investment. The amount of the loss will be deducted from your initial investment.',
        getprofit:'Become a master trader and get a steady income',
        becomaster:'Become a master trader',
        rules:'Terms and Conditions'
    },
    balance:{
        orderid:'Order Number',
        opentime:'Opening time',
        balantime:'Balance time',
        type:'Type',
        profit:'Profitability',
    },
    close:{
        openprice:'Opening price',
        hand:'Number of hands',
        symbol:'Trading Varieties',
        closetime:'Closeout time',
        closeprice:'Closeout Price',
        comission:'Handling fee',
        storage:'Inventory fee',
        profit:'Profitability',
        nowprice:'Current Price'
    },
    joinmaster:{
        good:'Good at trading? Get people to follow your trades and thus make money.',
        one:'Trade normally while earning extra income from other traders who copy you. Your Master Trader profile shows the daily and monthly performance of your trades - promote and attract new followers!',
        become:'Become a master trader',
        runway:'How it works',
        creat:'Create a Master Trader account',
        click:'Click on the Master Trader area and create a Master Trader account - open a new or assign an existing account to your Master Trader account',
        accountsetting:'Account Settings',
        beready:'Prepare your Master Trader account for replicators to follow: set your commission amount and describe your strategy.',
        use:'Use the Trader\'s Corner to view detailed data about your trades, change your account settings and see the amount of commissions you have earned.',
        offer:'CloudBridge-FX Copytrading offers you an additional source of income: open a Master Trader account, describe your strategy and then set up commissions for others to copy your trades.',
        different:'Create different Trader Master accounts for different strategies',
        detail:'Track the detailed data of your orders in the Master Trader area and earn Master Trader commissions',
        enjoy:'Enjoy various benefits without paying additional commissions to CloudBridge-FX',
        android:'You are in the Master Trader section of the CloudBridge-FX Trading App for Android',
        mobile:'Create a Master Trader account in Mobile',
        anywhere:'View your replicator statistics and commissions wherever you are',
        now:'On-site management of your new follower\'s replication conditions',
        how:' How to become a master trader？',
        get:'Any CloudBridge-FX client with an MT4 account can become a Master Trader. You can apply to Customer Service by depositing $100,000 and trading for three months with a stable profit.',
        howmanage:'How do I adjust the amount of commission I charge to replicators？',
        go:'Go to your Master Trader area, view the settings, adjust the commissions using the slider and save. The new commissions will only apply to replicators who subscribe after the adjustment. For all other replicators, the commission amount will remain the same.',
        time:'When do I get paid commissions from my replicators？',
        american:'Payment every Sunday at 6pm US time',
        when:'When to charge commission to replicators？',
        comission:'Commission is charged every Saturday for closed orders',
        whencanget:'When can I get the commission？',
        wallet:'We will transfer commissions to an electronic wallet. From your wallet, you can add funds to any of your trading accounts, or withdraw funds.',
        copy:'Automatically replicate the strategies of leading traders without spending long hours developing your own trading strategies',
        or:'Or become a replicator',
    },
    other:{
        signalaccount:'Signal Account',
        signalname:'Signal nickname',
        wallet:'Wallet Funds',
        hold:'Support Amount',
        need0:'0% of the required investment',
        zj:'Summary',
        follow:'Order Multiplier',
        followmoney:'Follow up costs',
        begin:'Start order following',
        hand:'Hand',
        more:'25 or more',
        choose:'Please select',
        notlogin:'You are not logged in yet, please log in first',
        byhand:'Lot size',
        byprofit:'profit',
        byleft:'balance',
        bylock:'Hedging',
        day:'days',
        sure:'Confirm',
        not:'You haven\'t copied trading yet',
        receiptaccount:'Open an account',
        balance:'Available balance',
        nodata:'No data yet',
        notlogin1:'You are not logged in',
        socity:'socializing',
        dl:'Agency Program',
        help:'Help Center',
        privicy:'Privacy Policy'
    },
    explain:{
        hand:'Fixed fees are charged. Assuming $1 per lot, if you place 10 lots on your followers account, $10 will be taken from your account to the source account.',
        left:'Charges are proportional to the balance of the followers account. Assuming a fee of 0.2% of the balance, your followers account balance is $10,000 and you need to take $20 from your account to the signal source account.',
        profit:'Charges are taken as a percentage of the profit on the copied order, with no charges for no profit. Let\'s say 5% of profit is charged, and if your copied order account makes a profit of $1000 on the order, $50 will be taken from your account to the signal source account.',
        lock:'The fee is charged as a percentage of the total profit during the lock period, no profit is charged. Suppose 8% of the total profit is charged, and the total profit of your followers\' account during the lock period is $2000, $160 will be taken from your account to the signal source account.',
        fake:'Assumptions: Level 1 referral: 30%, Level 2 referral: 20%, Level 3 referral: 10%, A refers B, B refers C, C refers D. If D user places an order and generates a follow up commission of $100, then A gets $10, B gets $20 and C gets $30.',
        said:'At Cloud Bridge, many followers give back more than 70% of their copy commission profits, and some even reach 90%!',
        byhand:'By hand',
        byleft:'By Balance',
        byprofit:'By profit',
        bylock:'Profit by lock-in period'
    },
    common: {
        title: 'BlaFX Copytrading',
        update: 'The website is being upgraded, please understand for any inconvenience',
        back: 'Back',
        nosm: 'Please complete real-name authentication before doing so',
        fail: 'The operation failed',
        rz: 'Go to certification',
        ok:'The operation was successful.'
    }
}
