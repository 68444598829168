<template>
    <el-dialog :title="$t('lang.detail.modalTitle')" class="modal-box" :visible="show" :show-close="false" :close-on-click-modal="false" width="100%">
        <div class="main">
            <div v-html="$t('lang.detail.modalHtml')" style="height: 50vh;max-height: 400px;overflow-y: auto; margin-bottom: 20px;"></div>
            <div class="btns">
                <el-button size="large" style="margin-right: 30px;color: #999;" @click="show = false">{{$t('lang.detail.modalBtn1')}}</el-button>
                <el-button type="primary" size="large" @click="hideModal">{{$t('lang.detail.modalBtn2')}}</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            show: false,
        }
    },
    methods: {
        init () {
            this.show = true
        },
        hideModal () {
            this.show = false;
            window.localStorage.setItem('hideModal', true);
            this.$emit('nextStep');
        },
        preventDefault (e) {
            e = e || window.event;
            if (e.preventDefault) {
                evt.preventDefault();
                evt.stopPropagation();
            }
        }
    }
}
</script>

<style lang="scss">
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}
.modal-box {
    max-width: 568px;
    margin: 0 auto !important;
    .el-dialog__header {
        margin-right: 0;
    }
    .el-dialog__body {
        padding: 0 20px 30px;
    }
    .main {
        div {
            font-size: 14px;
            color: #000;
        }
        .btns {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 20px;
            .el-button {
                margin: 0;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .modal-box {
        max-width: 100%;
        width: calc(100% - 32px);
    }
}
</style>