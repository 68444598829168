<template>
  <div data-v-424602f9="" data-v-82d45128=""
    class="ct-modal sweet-modal-overlay theme-copy-trade sweet-modal-clickable is-visible startClose" id="tankuang"
    tabindex="0" style="/* display: none; */">
    <div class="sweet-modal theme-copy-trade has-title has-content is-mobile-fullscreen is-visible">
      <button @click="startClose" class="sweet-box-actions ct-modal__close" style="border: #c2c9d8;"></button>
      <!---->

      <div class="sweet-title">
        <!---->
        <!---->
        <section data-v-f43414c2="" data-v-424602f9="" class="master-info">
          <img data-v-f43414c2="" :src="datainfo.signalHead" class="master-info__image">
          <div data-v-f43414c2="" class="master-info__info">
            <div data-v-f43414c2="" class="master-info__name text-subtitle-1">

            </div>
            <div data-v-f43414c2="" class="master-info__commission text-caption">
              <div data-v-f43414c2="" class="master-info__commission-value" id="yj">

              </div>
              <div data-v-f43414c2="" class="master-info__commission-text" id="xhnickName_header">
                &nbsp;{{ $t('lang.score.commission') }}
              </div>
            </div>
            <!---->
            <!---->
          </div>
        </section>
      </div>
      <!---->
      <div class="sweet-content">
        <!---->
        <!---->
        <!---->
        <!---->
        <div class="sweet-content-content">
          <section data-v-424602f9="" class="subscription-setup__section _separator">
            <div data-v-424602f9="" class="subscription-setup__wallet">
              <div data-v-424602f9="" class="text-subtitle-2">{{ $t('lang.other.signalaccount') }}</div>
              <div data-v-424602f9="" class="subscription-setup__wallet text-body-2">
                <div data-v-424602f9="" class="subscription-setup__wallet-balance numcolor" id="xhaccount">
                  {{ datainfo.signalLogin }}
                </div>
              </div>
            </div>
          </section>
          <section data-v-424602f9="" class="subscription-setup__section _separator">
            <div data-v-424602f9="" class="subscription-setup__wallet">
              <div data-v-424602f9="" class="text-subtitle-2">{{ $t('lang.other.signalname') }}</div>
              <div data-v-424602f9="" class="subscription-setup__wallet text-body-2">

                <div data-v-424602f9="" class="subscription-setup__wallet-balance" id="xhnickName">
                  {{ datainfo.signalName }}
                </div>
              </div>
            </div>
          </section>
          <section data-v-424602f9="" class="subscription-setup__section _separator">
            <div data-v-424602f9="" class="subscription-setup__wallet"
              style="justify-content: space-between;margin-left:0;position:relative">
              <div data-v-424602f9="" class="text-subtitle-2">{{ $t('lang.other.wallet') }}</div>
              <el-button style="margin-left: 0;" round type="primary" size="mini"
                @click="toInCash(3)">{{ $t('lang.other.receiptaccount') }}</el-button>
              <div data-v-424602f9="" class="subscription-setup__wallet text-body-2"
                style="    position: relative;height:60px;margin-left:0;width: 25%;">
                <el-select id="selectt" @change="datachange" v-model="followLogin" :placeholder="$t('lang.other.choose')"
                  :no-data-text="$t('lang.other.nochoose')">
                  <el-option v-for="(item, ix) in dataselect" :key="ix"
                    :value="item.followLogin">{{ item.followLogin }}</el-option>
                  <!-- <el-option v-if="dataselect.length==0" style="border-radius: 10px;
    border-color: rgb(221, 223, 224);">{{$t('lang.other.nochoose')}}</el-option> -->



                </el-select>
                <div class="positionMenu">

                </div>
              </div>
              <div data-v-424602f9="" class="subscription-setup__wallet-balance moneysle numcolor" id="money"
                style="position: absolute; bottom: -8px;right: 6px;">
                {{ $t('lang.other.balance') }}: $ {{ datamoney }}
              </div>
            </div>
          </section>

          <section data-v-424602f9="" class="subscription-setup__section">
            <div data-v-287891d2="" data-v-424602f9="" class="ct-switcher _green">
              <input data-v-287891d2="" id="extra-value-switcher" name="extra-value-switcher" type="checkbox"
                class="ct-switcher__checkbox">

            </div>
          </section>
          <section data-v-424602f9="" class="subscription-setup__section _input" style="display: none">
            <div data-v-712582dd="" data-v-424602f9="" class="ct-input text-body-1" max="1000000">
              <div data-v-712582dd="" class="ct-input__field">
                <fieldset data-v-712582dd="" class="ct-input__fieldset">
                  <legend data-v-712582dd="" class="ct-input__fieldset-legend text-caption _outline">
                    {{ $t('lang.other.hold') }}
                  </legend>
                </fieldset>
                <label data-v-712582dd="" for="ct-input-la2734yz"
                  class="ct-input__label _outline _prepended text-caption">{{ $t('lang.other.hold') }}</label>
                <div data-v-712582dd="" class="ct-input__prefix">$</div>
                <input data-v-712582dd="" id="ct-input-la2734yz" placeholder="0.01" class="ct-input__area">
                <!---->
              </div>
              <div data-v-712582dd="" class="ct-input-details text-caption _absolute">
                <div data-v-712582dd="" class="ct-input-details__message">
                  <!---->
                </div>
                <div data-v-712582dd="" class="ct-input-details__counter">
                  <div data-v-424602f9="" data-v-712582dd="" style="color: rgb(245, 40, 30)">
                    {{ $t('lang.other.need0') }}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section data-v-424602f9="" class="subscription-setup__section">
            <article data-v-424602f9="" class="ct-dashed-list">
              <h3 class="ct-dashed-list__label text-subtitle-2">{{ $t('lang.other.zj') }}</h3>
              <ul class="ct-dashed-list__items">
                <li class="ct-dashed-list__item">
                  <div class="ct-dashed-list__item-label text-caption">
                    {{ $t('lang.other.follow') }}
                  </div>
                  <div class="ct-dashed-list__item-value text-body-2">
                    Equal ×<b class="numcolor">1</b>
                  </div>
                </li>
                <li class="ct-dashed-list__item">
                  <div class="ct-dashed-list__item-label text-caption">
                    {{ $t('lang.other.followmoney') }}
                  </div>
                  <div class="ct-dashed-list__item-value text-body-2" id="Fees_of_merchandising">
                    <!--                    <b class="numcolor">$10.00</b>/{{ $t('lang.other.hand') }}-->
                    <b class="numcolor"> {{ datainfo.followType === 1 ?
                      datainfo.followVal + "/" + $t('lang.other.byhand') : datainfo.followType === 2 ? datainfo.followVal + "%/" + $t('lang.other.byprofit') : datainfo.followType === 3 ? datainfo.followVal + "%/" + $t('lang.other.byleft') : datainfo.followVal + "%/" + $t('lang.other.bylock') }}
                    </b>

                  </div>
                </li>

              </ul>
            </article>
          </section>

          <section data-v-424602f9="" class="subscription-setup__section _separator">
            <div data-v-424602f9="" class="subscription-setup__wallet">
              <div data-v-424602f9="" class="text-subtitle-2">{{ $t('lang.home.google') }}</div>
              <div data-v-424602f9="" class="subscription-setup__wallet text-body-2" style="
      cursor: pointer;
      height: 30px;
      width: auto;
      border-radius: 10px;
      padding: 0px 20px;
      border: 1px solid #dddfe0;">
                <input type="text" id="google_code" :placeholder="$t('lang.home.qlgoogle')" style="border:none"
                  v-model="googleCode">

              </div>
            </div>
          </section>
          <div class="tips">
            <p class="bold">{{ $t('lang.detail.remind') }}</p>
            <p>{{ $t('lang.detail.desc1') }}<el-button style="margin-left: 10px;" round type="primary" size="mini"
                @click="toInCash(2)">{{ $t('lang.detail.inCash') }}</el-button></p>
            <p>{{ $t('lang.detail.desc2') }}<el-button style="margin-left: 10px;" round plain type="primary" size="mini"
                @click="toInCash(1)">{{ $t('lang.detail.outCash') }}</el-button></p>
          </div>
          <!---->
        </div>
      </div>
      <div class="sweet-buttons">
        <!---->
        <button data-v-61b6048e="" data-v-424602f9="" class="ct-button text-button _primary _full-width"
          data-v-wave-boundary="true" @click="ifsm">
          <!---->
          <!---->
          <span data-v-61b6048e="" class="ct-button__label">{{ $t('lang.other.begin') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { selectAccountList, followAdd, getinfo,getNowList } from "@/api/home";
import { setToken, getToken, removeToken, getCookey, setCookey, removeCookey } from '@/api/auth'
import Cookies from "js-cookie";

export default {
  name: "closed",
  data() {
    return {
      dataselect: [],
      datamoney: 0,
      googleCode: '',
      followLogin: '',
      ifnull: false,
      noasse: false,
      bol: false
    }
  },
  props: ['datainfo'],
  mounted() {
    selectAccountList().then(res => {
      this.dataselect = res.data.data
      if (this.dataselect.length == 0 || this.dataselect == null || this.dataselect == undefined) {
        this.ifnull = true;
      }


      this.datamoney = this.dataselect[0].balance
      this.followLogin = this.dataselect[0].followLogin
    })
    
  },
  methods: {
    datachange(event) {
      this.dataselect.forEach((item, ix) => {
        if (item.followLogin == event) {
          this.datamoney = item.balance
        }
      })
    },
   
    startClose() {
      this.$emit('tcshow', false)
    },
    ifsm() {
      getinfo().then(res => {
        // 2是已实名.
        if (res.data.data.certificationStatus == 2) {
          this.btnup()
        }
        else {
          this.$confirm(this.$t('lang.common.nosm'), this.$t('lang.common.fail'), {
          confirmButtonText:  this.$t('lang.common.rz'),
          cancelButtonText:this.$t('lang.detail.confirm'),
          type: 'warning'
        }).then(() => {
          window.location.href=dataurlhost.apihost.link+"/verification"+'?b='+getToken()+'&lang='+ Cookies.get('crmlang')
        }).catch(() => {
          window.location.href=dataurlhost.apihost.link+"/"+'?b='+getToken()+'&lang='+ Cookies.get('crmlang')
        });
        }
      })

    },

    btnup() {
      if (this.ifnull == true) {
        this.$message({
          message: this.$t('lang.other.nonum'),
          type: 'error',
          customClass: 'zZindex'
        });

      } else {
        followAdd({ id: this.datainfo.id, googleCode: this.googleCode, followLogin: this.followLogin }).then(res => {
          if (res.data.code !== 200) {
            this.$message({
              message: res.data.msg,
              type: 'error',
              customClass: 'zZindex'
            });
            return false
          }
          this.$message({
            message: this.$t('lang.common.ok'),
            type: 'success',
            customClass: 'zZindex'
          });
          this.$emit('tcshow', true)
        })

      }

    },
    toInCash(type) {
      let _this = this
      let newUrl = type ==3 ?'/account' : type==2 ? '/deposit' : '/withdraw'
      var dataurl = dataurlhost.apihost.link 
      newUrl = newUrl + '?b=' + getToken() + '&lang=' + Cookies.get('crmlang') 

      type !== 3 && this.followLogin == '' ? this.$message({
        message: _this.$t('lang.other.receiptaccount'),
        type: 'error',
      }) : window.location.href = dataurl + newUrl
    },

  }
}
</script>
<style scoped lang="scss">
.numcolor {
  color: #004fdf;
  font-weight: normal;
}

.tips {
  padding: 0 24px;

  .bold {
    font-weight: bold;
  }

  p {
    font-size: 14px;
    color: #999;
    padding-bottom: 10px;
  }
}</style>
<style>.zZindex {
  z-index: 30000 !important;
}

.sweet-modal-overlay {
  z-index: 1000 !important;
}</style>