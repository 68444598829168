<template>
  <div>
    <commonhead ref="header"></commonhead>
    <el-container class="appContainer">

      <el-main class="main">

        <el-drawer
            :with-header="false"
            :size="drawSize"
            :visible.sync="showMenu"
            direction="rtl"
            :before-close="handleCloseMenu">
          <div class="msgForm">
            <img src="@/assets/image/picturedw.png" class="cloudImg">
            <el-tree class="msgTree" :data="menuData" @node-click="handleNodeClick"></el-tree>
            <div class="msg" style="margin-top: 10px">{{$t('lang.home.aboutUs')}}</div>
            <div class="msg">{{$t('lang.home.news')}}</div>
            <div class="msg">{{$t('lang.home.problem')}}</div>
          </div>
        </el-drawer>

        <section class="promo-copy-trade-intro -master">
          <div class="pg-wrap promo-copy-trade-intro__inner">
            <div class="promo-copy-trade-intro__text">
              <h1 class="title-t01 promo-copy-trade-intro__title">{{$t('lang.joinmaster.good')}}</h1>

              <p class="promo-copy-trade-intro__descr -desktop-elem">{{$t('lang.joinmaster.one')}}  </p>
              <a
                  class="promo-copy-trade-intro__btn btn-t11 -arr-r autotest-become-a-master-first"
                  :href=this.dataurl
                 
              >
                <span class="btn-t11__inner">{{$t('lang.joinmaster.become')}} </span>
              </a>

            </div>
          </div>
        </section>

        <section class="promo-copy-trade-steps promo-copy-trade-section">
          <div class="pg-wrap">
            <h2 class="promo-copy-trade-steps__title promo-copy-trade-section__title">
                <span class="title-t02__fw400">
                    {{$t('lang.joinmaster.runway')}}
                </span>
            </h2>
            <ul class="promo-copy-trade-steps-list">
              <li class="promo-copy-trade-steps-list__item">
                <div class="promo-copy-trade-steps-list__col">
                  <div class="promo-copy-trade-steps-list__num -desktop-elem">1</div>
                  <div class="promo-copy-trade-steps-list__text">
                    <h3 class="promo-copy-trade-steps-list__title">
                      <div class="promo-copy-trade-steps-list__num -mobile-elem-inline">1.</div>
                      {{$t('lang.joinmaster.creat')}}
                    </h3>
                    <p class="-promo-copy-trade-mobile-pic">

                    </p>
                    <p class="promo-copy-trade-steps-list__descr">{{$t('lang.joinmaster.click')}}  </p>
                  </div>
                </div>
                <div class="promo-copy-trade-steps-list__col -promo-copy-trade-desktop-pic">
                  <img src="../img/copy-become/step-01@1x.png"
                       class="promo-copy-trade-steps-list__pic"
                  />
                </div>
              </li>
              <li class="promo-copy-trade-steps-list__item">
                <div class="promo-copy-trade-steps-list__col">
                  <div class="promo-copy-trade-steps-list__num -desktop-elem">2</div>
                  <div class="promo-copy-trade-steps-list__text">
                    <h3 class="promo-copy-trade-steps-list__title">
                      <div class="promo-copy-trade-steps-list__num -mobile-elem-inline">2.</div>
                      {{$t('lang.joinmaster.accountsetting')}}
                    </h3>
                    <p class="-promo-copy-trade-mobile-pic">
                    </p>
                    <p class="promo-copy-trade-steps-list__descr">{{$t('lang.joinmaster.beready')}}  </p>
                  </div>
                </div>
                <div class="promo-copy-trade-steps-list__col -promo-copy-trade-desktop-pic">
                  <img src="../img/copy-become/step-02@1x.png"
                       class="promo-copy-trade-steps-list__pic"
                  />
                </div>
              </li>
              <li class="promo-copy-trade-steps-list__item">
                <div class="promo-copy-trade-steps-list__col">
                  <div class="promo-copy-trade-steps-list__num -desktop-elem">3</div>
                  <div class="promo-copy-trade-steps-list__text">
                    <h3 class="promo-copy-trade-steps-list__title">
                      <div class="promo-copy-trade-steps-list__num -mobile-elem-inline">3.</div>
                      {{$t('lang.join.moandprofit')}}
                    </h3>
                    <p class="-promo-copy-trade-mobile-pic -centred">
                    </p>
                    <div class="promo-copy-trade-steps-list__descr">
                      <p>{{$t('lang.joinmaster.use')}} </p>
                    </div>
                  </div>
                </div>
                <div class="promo-copy-trade-steps-list__col -promo-copy-trade-desktop-pic">
                  <img src="../img/copy-become/step-03@1x.png"
                       class="promo-copy-trade-steps-list__pic"
                  />
                </div>
              </li>
            </ul>
            <div class="-centred">
              <a
                  class="btn-t18 autotest-become-a-master-second"
                  :href=this.dataurl
                 
              >
                <span class="btn-t18__inner">{{$t('lang.joinmaster.become')}} </span>
              </a>
            </div>
          </div>
        </section>
        <section class="promo-copy-trade-tools promo-copy-trade-section">
          <div class="pg-wrap">
            <h2 class="promo-copy-trade-tools__title -centred">{{$t('lang.joinmaster.offer')}} </h2>
            <ul class="promo-copy-trade-tools-list -bordered">
              <li class="promo-copy-trade-tools-list__item">
                <div class="promo-copy-trade-tools-list__icon-wrapper">
                  <img
                      src="../img/copy-join/decentralizedIdentity-0.svg"
                      width="163" height="112"
                      class="promo-copy-trade-tools-list__icon"
                  />
                </div>
                <p class="promo-copy-trade-tools-list__descr">{{$t('lang.joinmaster.different')}} </p>
              </li>
              <li class="promo-copy-trade-tools-list__item">
                <div class="promo-copy-trade-tools-list__icon-wrapper">
                  <img
                      src="../img/copy-join/worldwide-1.svg"
                      width="163" height="112"
                      class="promo-copy-trade-tools-list__icon"
                      style="width: 190px"
                  />
                </div>
                <p class="promo-copy-trade-tools-list__descr">{{$t('lang.joinmaster.detail')}}   </p>
              </li>
              <li class="promo-copy-trade-tools-list__item">
                <div class="promo-copy-trade-tools-list__icon-wrapper">
                  <img
                      src="../img/copy-join/fast-1.svg"
                      width="163" height="112"
                      class="promo-copy-trade-tools-list__icon"
                  />
                </div>
                <p class="promo-copy-trade-tools-list__descr">{{$t('lang.joinmaster.enjoy')}}  </p>
              </li>
            </ul>
          </div>
        </section>
        <section class="promo-copy-trade-mobile">
          <div class="pg-wrap">
            <div class="promo-copy-trade-mobile__inner">
              <div class="promo-copy-trade-mobile__about">
                <h2 class="promo-copy-trade-mobile__head">{{$t('lang.joinmaster.android')}} </h2>
                <ul class="promo-copy-trade-mobile__list">
                  <li class="promo-copy-trade-mobile__list-item">{{$t('lang.joinmaster.mobile')}} </li>
                  <li class="promo-copy-trade-mobile__list-item">{{$t('lang.joinmaster.anywhere')}} </li>
                  <li class="promo-copy-trade-mobile__list-item">{{$t('lang.joinmaster.now')}} </li>
                </ul>
                <div class="promo-copy-trade-mobile__button-wrapper">
                  <a 
                  href="/nofound"
                  
                  >
                    <img src="../img/android.png" alt="">
                  </a>
                </div>
              </div>
              <div class="promo-copy-trade-mobile__image">
                <img src="../img/copy-become/2-2.png" alt="image" class="promo-copy-trade-mobile__image-file">
              </div>
            </div>
          </div>
        </section>
        <section class="promo-copy-trade-faq promo-copy-trade-section -t01">
          <div class="pg-wrap">
            <h2 class="promo-copy-trade-faq-list__title title-t02 promo-copy-trade-section__title">
                <span class="title-t02__fw400">
                    FAQ
                </span>
            </h2>
            <ul class="promo-copy-trade-faq-list">
              <li class="promo-copy-trade-faq-list__item js-subcontent-list-parent">
                <h3 class="promo-copy-trade-faq-list__cntrl js-subcontent-list-cntrl">
                  {{$t('lang.joinmaster.how')}}
                </h3>
                <div class="promo-copy-trade-faq-list__content">
                  <p>{{$t('lang.joinmaster.get')}}</p>
                </div>
              </li>
              <li class="promo-copy-trade-faq-list__item js-subcontent-list-parent">
                <h3 class="promo-copy-trade-faq-list__cntrl js-subcontent-list-cntrl">
                  {{$t('lang.joinmaster.howmanage')}}
                </h3>
                <div class="promo-copy-trade-faq-list__content">
                  <p>{{$t('lang.joinmaster.go')}}  </p>
                </div>
              </li>
              <li class="promo-copy-trade-faq-list__item js-subcontent-list-parent">
                <h3 class="promo-copy-trade-faq-list__cntrl js-subcontent-list-cntrl">
                  {{$t('lang.joinmaster.time')}}
                </h3>
                <div class="promo-copy-trade-faq-list__content">
                  <p>{{$t('lang.joinmaster.american')}} </p>
                </div>
              </li>
              <li class="promo-copy-trade-faq-list__item js-subcontent-list-parent">
                <h3 class="promo-copy-trade-faq-list__cntrl js-subcontent-list-cntrl">
                  {{$t('lang.joinmaster.when')}}
                </h3>
                <div class="promo-copy-trade-faq-list__content">
                  <p>{{$t('lang.joinmaster.comission')}}</p>
                </div>
              </li>
              <li class="promo-copy-trade-faq-list__item js-subcontent-list-parent">
                <h3 class="promo-copy-trade-faq-list__cntrl js-subcontent-list-cntrl">
                  {{$t('lang.joinmaster.whencanget')}}
                </h3>
                <div class="promo-copy-trade-faq-list__content">
                  <p>{{$t('lang.joinmaster.wallet')}} </p>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section class="promo-copy-trade-section -centred -no-bottom-indent -outer-shadow">
          <div class="pg-wrap">
            <h2 class="promo-copy-trade-tools__title -centred">
                <span class="title-t02__fw400">
                    {{$t('lang.joinmaster.copy')}}
                </span>
            </h2>
            <div class="promo-copy-trade-section__pic -centred -desktop-elem">
              <img src="../img/copy-become/copier@1x.png"
              />
            </div>
            <div class="promo-copy-trade-section__pic -centred -mobile-elem-block">
            </div>
            <a href="/join-master" class="btn-t33 autotest-join-copytrading">
              <span class="btn-t33__inner">{{$t('lang.joinmaster.or')}} </span>
            </a>
          </div>
          <hr class="hr-t02">
          <p class="-centred">
            <a href="/home" traget="_blank" class="promo-copy-trade-section__link addition-link -more">{{$t('lang.join.rules')}} </a>
          </p>
        </section>
      </el-main>
    </el-container>
    <el-footer class="foot">
      <message />
    </el-footer>

    <el-drawer
        :with-header="false"
        :size="drawSize"
        :visible.sync="showLogin"
        direction="rtl"
        :before-close="handleClose">
      <login @register="openRegister" v-if="drawerType === 1"/>
      <register v-if="drawerType === 2"/>
    </el-drawer>
  </div>
</template>

<script>
import score from "@/views/score";
import message from "@/views/message";
import options from "@/views/options";
import clause from "@/views/clause";
import register from "@/views/homeLogin/register";
import login from "@/views/homeLogin/login";
import commonhead from "@/views/common/header";

export default {
  name: "home",
  mixins: [options],
  components: {
    score,
    message,
    clause,
    register,
    login,
    commonhead
  },
  data() {
    return {
      drawerType: 1,
      value: '1',
      options: [
        {
          value: '1',
          label: '中文'
        }, {
          value: '2',
          label: 'English'
        }
      ],
      activeIndex: '0',
      activeName: 'first',
      showLogin: false,
      showMenu: false,
      form: {},
      clientWidth: document.body.clientWidth,
      dataurl:dataurlhost.apihost.link+'/crm/client/follow/singal',
    }
  },
  computed: {
    drawSize() {
      if(this.clientWidth < 500) {
        return '90%'
      } else {
        return 450
      }
    }
  },
  mounted() {
    this.dataurl=this.dataurl+'?lang='+ Cookies.get('crmlang')
    this.$i18n.locale=this.$cookies.get("lang")
    window.addEventListener('resize', () => {
      this.clientWidth = document.body.clientWidth;
      if(this.clientWidth < 500) this.drawSize = '90%'
    })


    $(function() {
      var e;
      $(".js-subcontent-list-cntrl").click(function() {
        e = $(this),
            e.parent(".js-subcontent-list-parent").toggleClass("-open")
      })
    })
  },
  methods: {
    tourl(type){
      this.$refs.header.toDownload(type)
    },
    handleSelect() {
      window.location.href= "http://www.baidu.com";
    },
    handleNodeClick() {

    },
    handleClick() {
    },
    handleClose() {
      this.showLogin = false
      this.drawerType = 1
    },
    handleCloseMenu() {
      this.showMenu = false
    },
    login() {
      this.showLogin = true
    },
    openMenu() {
      this.showMenu = true
    },
    langChange() {
      switch (this.value) {
        case "1":
          this.$i18n.locale = 'zh-CN'
          break;
        case "2":
          this.$i18n.locale = 'zh-EN'
          break;
      }
    },
    openRegister() {
      this.drawerType = 2
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/style/home/home.scss';
</style>
<style scoped src="@/assets/style/home/copy-trade.css">


</style>


