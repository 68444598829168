<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
<style lang="scss">
@import '@/assets/style/home/common.scss';
</style>
<style>
@import '@/assets/style/home/lanrenzhijia.css';
</style>
