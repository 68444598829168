<template>
  <div>
    <commonhead></commonhead>
    <el-container class="appContainer">

      <el-main class="main">

        <el-drawer
            :with-header="false"
            :size="drawSize"
            :visible.sync="showMenu"
            direction="rtl"
            :before-close="handleCloseMenu">
          <div class="msgForm">
            <img src="@/assets/image/picturedw.png" class="cloudImg">
            <el-tree class="msgTree" :data="menuData" @node-click="handleNodeClick"></el-tree>
            <div class="msg" style="margin-top: 10px">{{$t('lang.home.aboutUs')}}</div>
            <div class="msg">{{$t('lang.home.news')}}</div>
            <div class="msg">{{$t('lang.home.problem')}}</div>
          </div>
        </el-drawer>

        <section class="promo-copy-trade-intro -copier">
          <div class="pg-wrap promo-copy-trade-intro__inner">
            <div class="promo-copy-trade-intro__text">
              <h1 class="title-t01 promo-copy-trade-intro__title">{{$t('lang.join.copy')}}</h1>

              <p class="promo-copy-trade-intro__descr -desktop-elem">{{$t('lang.join.wenan')}}</p>
              <a
                  class="promo-copy-trade-intro__btn btn-t11-alt -arr-r autotest-join-copy-trade-button-first"
                  href="https://www.cloudbridgeau.com/cn/index34.html"
                  onclick="(typeof Vue !== 'undefined') && Vue.prototype.$eventBus.$emit('registration-click', {accountType: 'copy-trade-copier' }, event)"
              >
                <span class="btn-t11-alt__inner">{{$t('lang.join.join')}}</span>
              </a>
              <br>
              <a
                  href="/join-master"
                  class="promo-copy-trade-intro__btn-link addition-link -more -t04 autotest-start-as-master"
              >{{$t('lang.join.become')}}</a>
            </div>
          </div>
        </section>

        <div class="promo-copy-trade-banner">
          <div
              class="bonus-banner _welcome"
              onclick="(typeof Vue !== 'undefined') && Vue.prototype.$eventBus.$emit('registration-click', {accountType: 'copy-trade-copier' }, event)">
            <div class="bonus-banner__inner">
              <div class="bonus-banner__name js-welcome-name ">
                {{$t('lang.score.invested')}}
              </div>
              <div class="bonus-banner__about">
                {{$t('lang.score.signUp')}}
              </div>
            </div>
          </div>

        </div>

        <section class="promo-copy-trade-steps promo-copy-trade-section">
          <div class="pg-wrap">
            <div class="promo-copy-trade-steps__inner -copy-intro -bordered">
              <h2 class="promo-copy-trade-steps__title promo-copy-trade-section__title">
                    <span class="title-t02__fw400">
                        {{$t('lang.join.way')}}
                    </span>
              </h2>
              <ul class="promo-copy-trade-steps-list">
                <li class="promo-copy-trade-steps-list__item">
                  <div class="promo-copy-trade-steps-list__col">
                    <div class="promo-copy-trade-steps-list__num -desktop-elem">1</div>
                    <div class="promo-copy-trade-steps-list__text">
                      <h3 class="promo-copy-trade-steps-list__title">
                        <div class="promo-copy-trade-steps-list__num -mobile-elem-inline">1.</div>
                        {{$t('lang.join.creat')}}
                      </h3>

                      <p class="promo-copy-trade-steps-list__descr">{{$t('lang.join.simpleway')}}</p>
                    </div>
                  </div>
                  <div class="promo-copy-trade-steps-list__col -promo-copy-trade-desktop-pic">
                    <img src="../img/copy-join/promo-copy-trade__payments@1x.png"
                         class="promo-copy-trade-steps-list__pic"
                    />
                  </div>
                </li>
                <li class="promo-copy-trade-steps-list__item">
                  <div class="promo-copy-trade-steps-list__col">
                    <div class="promo-copy-trade-steps-list__num -desktop-elem">2</div>
                    <div class="promo-copy-trade-steps-list__text">
                      <h3 class="promo-copy-trade-steps-list__title">
                        <div class="promo-copy-trade-steps-list__num -mobile-elem-inline">2.</div>
                        {{$t('lang.join.followbest')}}
                      </h3>
                      <p class="-promo-copy-trade-mobile-pic">
                        <img src="../img/copy-become/step-01@1x.png"
                             class="promo-copy-trade-steps-list__pic -mobile-elem-inline-block"
                        />
                      </p>
                      <p class="promo-copy-trade-steps-list__descr">{{$t('lang.join.followandcopy')}}</p>
                    </div>
                  </div>
                  <div class="promo-copy-trade-steps-list__col -promo-copy-trade-desktop-pic">
                    <img src="../img/copy-become/step-01@1x.png"
                         class="promo-copy-trade-steps-list__pic"
                    />
                  </div>
                </li>
                <li class="promo-copy-trade-steps-list__item">
                  <div class="promo-copy-trade-steps-list__col">
                    <div class="promo-copy-trade-steps-list__num -desktop-elem">3</div>
                    <div class="promo-copy-trade-steps-list__text">
                      <h3 class="promo-copy-trade-steps-list__title">
                        <div class="promo-copy-trade-steps-list__num -mobile-elem-inline">3.</div>
                        {{$t('lang.join.moandprofit')}}
                      </h3>
                      <p class="-promo-copy-trade-mobile-pic -centred">
                        <img src="../img/copy-join/step-03@1x.png"
                             class="promo-copy-trade-steps-list__pic"
                        />
                      </p>
                      <p class="promo-copy-trade-steps-list__descr">{{$t('lang.join.copycopy')}}</p>
                    </div>
                  </div>
                  <div class="promo-copy-trade-steps-list__col -promo-copy-trade-desktop-pic">
                    <img src="../img/copy-join/step-03@1x.png"
                         class="promo-copy-trade-steps-list__pic"
                    />
                  </div>
                </li>
              </ul>
              <ul class="promo-copy-trade-option">
                <li class="promo-copy-trade-option__item">
                  <div class="promo-copy-trade-option__icon-wrapper">
                    <img
                        src="../img/copy-join/decentralizedIdentity-0.svg"
                        width="163" height="112"
                        class="promo-copy-trade-option__icon"
                    />
                  </div>
                  <div class="promo-copy-trade-option__descr">{{$t('lang.join.followprofitbest')}}</div>
                </li>
                <li class="promo-copy-trade-option__item">
                  <div class="promo-copy-trade-option__icon-wrapper">
                    <img
                        src="../img/copy-join/worldwide-1.svg"
                        width="163" height="112"
                        class="promo-copy-trade-option__icon"
                    />
                  </div>
                  <div class="promo-copy-trade-option__descr">{{$t('lang.join.noneed')}}</div>
                </li>
                <li class="promo-copy-trade-option__item">
                  <div class="promo-copy-trade-option__icon-wrapper">
                    <img
                        src="../img/copy-join/fast-1.svg"
                        width="163" height="112"
                        class="promo-copy-trade-option__icon"
                    />
                  </div>
                  <div class="promo-copy-trade-option__descr">{{$t('lang.join.manyways')}}</div>
                </li>
              </ul>
              <div class="-centred">
                <a
                    class="btn-t18 autotest-join-copy-trade-button-second"
                    href="https://www.cloudbridgeau.com/cn/index34.html"
                    onclick="(typeof Vue !== 'undefined') && Vue.prototype.$eventBus.$emit('registration-click', {accountType: 'copy-trade-copier' }, event)"
                >
                  <span class="btn-t18__inner">{{$t('lang.join.join')}}</span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section class="promo-copy-trade-tools promo-copy-trade-section">
          <div class="pg-wrap">
            <h2 class="promo-copy-trade-tools__title -centred">{{$t('lang.join.new')}}</h2>
            <ul class="promo-copy-trade-tools-list -bordered">
              <li class="promo-copy-trade-tools-list__item">
                <div class="promo-copy-trade-tools-list__icon-wrapper">
                  <img
                      src="../img/copy-join/newUserChecklistCompleteAccount-1.svg"
                      width="163" height="112"
                      class="promo-copy-trade-tools-list__icon"
                  />
                </div>
                <h4 class="promo-copy-trade-tools-list__title">
                  {{$t('lang.join.learnbest')}}
                </h4>
                <p class="promo-copy-trade-tools-list__descr">
                  {{$t('lang.join.easytoin')}}
                </p>
              </li>
              <li class="promo-copy-trade-tools-list__item">
                <div class="promo-copy-trade-tools-list__icon-wrapper">
                  <img
                      src="../img/copy-join/shield-1.svg"
                      width="163" height="112"
                      class="promo-copy-trade-tools-list__icon"
                  />
                </div>
                <h4 class="promo-copy-trade-tools-list__title">
                  {{$t('lang.join.spread')}}
                </h4>
                <p class="promo-copy-trade-tools-list__descr">
                  {{$t('lang.join.choosebig')}}
                </p>
              </li>
              <li class="promo-copy-trade-tools-list__item">
                <div class="promo-copy-trade-tools-list__icon-wrapper">
                  <img
                      src="../img/copy-join/easyToUse-1.svg"
                      width="163" height="112"
                      class="promo-copy-trade-tools-list__icon"
                  />
                </div>
                <h4 class="promo-copy-trade-tools-list__title">
                  {{$t('lang.join.enjoyfast')}}
                </h4>
                <p class="promo-copy-trade-tools-list__descr">
                  {{$t('lang.join.infive')}}
                </p>
              </li>
              <li class="promo-copy-trade-tools-list__item">
                <div class="promo-copy-trade-tools-list__icon-wrapper">
                  <img
                      src="../img/copy-join/earnGraph-0.svg"
                      width="163" height="112"
                      class="promo-copy-trade-tools-list__icon"
                  />
                </div>
                <h4 class="promo-copy-trade-tools-list__title">
                  {{$t('lang.join.inhand')}}
                </h4>
                <p class="promo-copy-trade-tools-list__descr">
                  {{$t('lang.join.anytimestop')}}
                </p>
              </li>
              <li class="promo-copy-trade-tools-list__item">
                <div class="promo-copy-trade-tools-list__icon-wrapper">
                  <img
                      src="../img/copy-join/custodialJourney-0.svg"
                      width="163" height="112"
                      class="promo-copy-trade-tools-list__icon"
                      style="width: 190px"
                  />
                </div>
                <h4 class="promo-copy-trade-tools-list__title">
                  {{$t('lang.join.easyway')}}
                </h4>
                <p class="promo-copy-trade-tools-list__descr">
                  {{$t('lang.join.noneedother')}}。
                </p>
              </li>
              <li class="promo-copy-trade-tools-list__item">
                <div class="promo-copy-trade-tools-list__icon-wrapper">
                  <img
                      src="../img/copy-join/blockchainConnection-0.svg"
                      width="163" height="112"
                      class="promo-copy-trade-tools-list__icon"
                  />
                </div>
                <h4 class="promo-copy-trade-tools-list__title">
                  {{$t('lang.join.safe')}}
                </h4>
                <p class="promo-copy-trade-tools-list__descr">
                  {{$t('lang.join.mangmain')}}
                </p>
              </li>
            </ul>
          </div>
        </section>
        <section class="promo-copy-trade-mobile">
          <div class="pg-wrap">
            <div class="promo-copy-trade-mobile__inner">
              <div class="promo-copy-trade-mobile__about">
                <h2 class="promo-copy-trade-mobile__head">{{$t('lang.join.alluseful')}}</h2>
                <ul class="promo-copy-trade-mobile__list">
                  <li class="promo-copy-trade-mobile__list-item">{{$t('lang.join.foces')}} </li>
                  <li class="promo-copy-trade-mobile__list-item">{{$t('lang.join.manage')}} </li>
                  <li class="promo-copy-trade-mobile__list-item">{{$t('lang.join.follow')}}</li>
                  <li class="promo-copy-trade-mobile__list-item">{{$t('lang.join.seerisk')}} </li>
                </ul>
                <div class="promo-copy-trade-mobile__button-wrapper">

                  <a href="https://www.cloudbridgeau.com/cn/index20.html" class="-text-s02 -gplay promo-copy-trade-mobile__button autotest-get-it-on-google" target="_blank">
                    <img src="../img/android.png" alt="">
                  </a>
                </div>
              </div>
              <div class="promo-copy-trade-mobile__image">
                <img src="../img/copy-become/Website_static.png" alt="image" class="promo-copy-trade-mobile__image-file">
              </div>
            </div>
          </div>
        </section>
        <section class="promo-copy-trade-faq promo-copy-trade-section -t01">
          <div class="pg-wrap">
            <h2 class="promo-copy-trade-faq-list__title title-t02 promo-copy-trade-section__title">
                <span class="title-t02__fw400">
                    FAQ
                </span>
            </h2>
            <ul class="promo-copy-trade-faq-list">
              <li class="promo-copy-trade-faq-list__item js-subcontent-list-parent">
                <h3 class="promo-copy-trade-faq-list__cntrl js-subcontent-list-cntrl">{{$t('lang.join.howto')}} </h3>
                <div class="promo-copy-trade-faq-list__content">
                  <p>{{$t('lang.join.firstcheck')}} </p>
                </div>
              </li>
              <li class="promo-copy-trade-faq-list__item js-subcontent-list-parent">
                <h3 class="promo-copy-trade-faq-list__cntrl js-subcontent-list-cntrl">{{$t('lang.join.anyuseful')}} </h3>
                <div class="promo-copy-trade-faq-list__content">
                  <p>{{$t('lang.join.ways')}} </p>
                </div>
              </li>
              <li class="promo-copy-trade-faq-list__item js-subcontent-list-parent">
                <h3 class="promo-copy-trade-faq-list__cntrl js-subcontent-list-cntrl">{{$t('lang.join.did')}} </h3>
                <div class="promo-copy-trade-faq-list__content">
                  <p>{{$t('lang.join.didas')}} </p>
                </div>
              </li>
              <li class="promo-copy-trade-faq-list__item js-subcontent-list-parent">
                <h3 class="promo-copy-trade-faq-list__cntrl js-subcontent-list-cntrl">{{$t('lang.join.howstop')}} </h3>
                <div class="promo-copy-trade-faq-list__content">
                  <p>{{$t('lang.join.anytimecancle')}} </p>
                </div>
              </li>
              <li class="promo-copy-trade-faq-list__item js-subcontent-list-parent">
                <h3 class="promo-copy-trade-faq-list__cntrl js-subcontent-list-cntrl">{{$t('lang.join.howprofit')}} </h3>
                <div class="promo-copy-trade-faq-list__content">
                  <p>{{$t('lang.join.wallet')}}  </p>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section class="promo-copy-trade-section -centred -no-bottom-indent -outer-shadow">
          <div class="pg-wrap">
            <h2 class="promo-copy-trade-tools__title -centred">
              {{$t('lang.join.getprofit')}}
            </h2>
            <div class="promo-copy-trade-section__pic -centred -desktop-elem">
              <img src="../img/copy-become/copier@1x.png"
              />
            </div>
            <div class="promo-copy-trade-section__pic -centred -mobile-elem-block">

            </div>
            <a
                class="btn-t33 autotest-start-as-master-button"
                href="/join-master"
            >
              <span class="btn-t33__inner">{{$t('lang.join.becomaster')}} </span>
            </a>
          </div>
          <hr class="hr-t02">
          <p class="-centred">
            <a href="/home" traget="_blank" class="promo-copy-trade-section__link addition-link -more">{{$t('lang.join.rules')}}</a>
          </p>
        </section>
      </el-main>
    </el-container>
    <el-footer class="foot">
      <message />
    </el-footer>

    <el-drawer
        :with-header="false"
        :size="drawSize"
        :visible.sync="showLogin"
        direction="rtl"
        :before-close="handleClose">
      <login @register="openRegister" v-if="drawerType === 1"/>
      <register v-if="drawerType === 2"/>
    </el-drawer>
  </div>
</template>

<script>
import score from "@/views/score";
import message from "@/views/message";
import options from "@/views/options";
import clause from "@/views/clause";
import register from "@/views/homeLogin/register";
import login from "@/views/homeLogin/login";
import commonhead from "@/views/common/header";
export default {
  name: "home",
  mixins: [options],
  components: {
    score,
    message,
    clause,
    register,
    login,
    commonhead
  },
  data() {
    return {
      drawerType: 1,
      value: '1',
      options: [
        {
          value: '1',
          label: '中文'
        }, {
          value: '2',
          label: 'English'
        }
      ],
      activeIndex: '0',
      activeName: 'first',
      showLogin: false,
      showMenu: false,
      form: {},
      clientWidth: document.body.clientWidth
    }
  },
  computed: {
    drawSize() {
      if(this.clientWidth < 500) {
        return '90%'
      } else {
        return 450
      }
    }
  },
  mounted() {
    this.$i18n.locale=this.$cookies.get("lang")
    window.addEventListener('resize', () => {
      this.clientWidth = document.body.clientWidth;
      if(this.clientWidth < 500) this.drawSize = '90%'
    })

    $(function() {
      var e;
      $(".js-subcontent-list-cntrl").click(function() {
        e = $(this),
            e.parent(".js-subcontent-list-parent").toggleClass("-open")
      })
    })
  },
  methods: {
    handleSelect() {
      window.location.href= "http://www.baidu.com";
    },
    handleNodeClick() {

    },
    handleClick() {
    },
    handleClose() {
      this.showLogin = false
      this.drawerType = 1
    },
    handleCloseMenu() {
      this.showMenu = false
    },
    login() {
      this.showLogin = true
    },
    openMenu() {
      this.showMenu = true
    },
    langChange() {
      switch (this.value) {
        case "1":
          this.$i18n.locale = 'zh-CN'
          break;
        case "2":
          this.$i18n.locale = 'zh-EN'
          break;
      }
    },
    openRegister() {
      this.drawerType = 2
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/style/home/home.scss';
</style>
<style scoped src="@/assets/style/home/copy-trade.css">


</style>
