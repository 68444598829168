export const lang = {
    home: {
        countLogin: 'Вход в аккаунт',
        transaction: 'Торговые платформы',
        traders: 'Meta Trader 4',
        tradersOnline: 'Meta Trader 4 - Онлайн-трейдинг',
        plan: 'Рекомендуемые программы',
        copy: 'Копировальная торговля',
        aboutCopier: 'Для фотокопий',
        joinCopy: 'Присоединяйтесь к торговле копиями',
        getLevel: 'Рейтинги мастер-трейдера',
        aboutTeacher: 'Для мастеров-трейдеров',
        beTeacher: 'Станьте мастером трейдинга',
        msg: 'Информация',
        clause: 'Положения и условия',
        aboutUs: 'О нас',
        news: 'Новости компании',
        problem: 'Часто задаваемые вопросы',
        score: 'тактика',
        follow: 'Репликации',
        clause2: 'Положения и условия',
        login: 'Вход',
        email: 'Почтовый ящик',
        password: 'Пароль',
        forgetPassword: 'Забыли пароль?',
        code: 'Проверочный код',
        login2: 'Вход',
        register: 'Регистрация',
        google: 'Google Captcha',
        qlgoogle: 'Пожалуйста, введите код аутентификации Google',
        center: 'Персональный центр',
        quit: 'Выйти из системы',
        quitMsg: 'Вход в систему отключен, пожалуйста, войдите снова',
    },
    message: {
        choose: 'Почему стоит выбрать',
        changeMarket: 'Торговые рынки',
        changeTool: 'Торговые платформы и инструменты',
        community: 'BlaFX',
        companies: 'Партнеры',
        supervise: 'Мой надзор',
        VIP: 'VIP-обслуживание премиум-класса',
        active: 'Программа активного трейдера',
        out: 'Торговля на Форекс',
        contract: 'Индексные контракты на разницу цен',
        goods: 'Сырьевые товары',
        CFD: 'Валютные индексированные контракты на разницу (CFD)',
        money: 'Спреды, свопы и комиссии',
        time: 'Торговые часы',
        count: 'Торговые счета',
        inAndOut: 'Депозит и снятие средств',
        begin: 'Начните прямо сейчас',
        MetaTrader: 'MetaTrader 4',
        MetaTraderChange: 'MetaTrader 4-Онлайн-торговля',
        study: 'Научиться торговать',
        news: 'Экономическая информация',
        com: 'Сообщество последователей Облачного моста',
        proxy: 'Агентские услуги',
        friend: 'Схема "направь друга',
        title: 'Услуги, которые мы предлагаем, включают торговлю внебиржевыми и валютными продуктами и несут риск потерь, превышающих первоначальное вложение капитала. Эти продукты могут не подходить для каждого инвестора, поэтому убедитесь, что вы полностью понимаете все связанные с ними риски, и при необходимости обратитесь за советом к третьей стороне.',
        p1: 'Предупреждение о рисках: Торговля CFD и Forex сопряжена с рисками. Он подходит не всем, и ваши потери могут значительно превысить первоначальные инвестиции. У вас не будет права собственности или прав на базовый актив. Прошлые результаты не являются показателем будущих результатов, а налоговое законодательство может меняться. Информация на этом сайте носит общий характер и не учитывает личные цели, финансовую ситуацию или потребности вас или вашего клиента. Пожалуйста, прочитайте наше Уведомление о рисках и другие юридические документы, чтобы убедиться, что вы полностью понимаете риски, прежде чем принимать какие-либо торговые решения. Мы рекомендуем вам обратиться за независимой консультацией.',
        p2: 'BlaFX Capital PTY LTD位于Suite 280 156 Burwood Road Bureood,NSW 2134 Australia。 Лицензирована и регулируется Австралийской комиссией по ценным бумагам. Информация на этом сайте и предлагаемые продукты и услуги не предназначены для продажи кому-либо в какой-либо стране или юрисдикции.',
        p3: ' © 2020 BlaFX Capital PTY LTD| Регистрационный номер компании661 715 966 | AFS - 001298638'
    },
    score: {
        invested: 'Вы инвестировали?',
        signUp: 'Зарегистрируйтесь и начните копировать любого трейдера Master Trader, чтобы увеличить и повысить доходность своих инвестиций.',
        score: 'Экспертные оценки',
        traders: 'Трейдеры',
        agency: 'Комиссия\nагентства %',
        risk: 'Оценка\nриска',
        gain: 'Урожай',
        cycle: 'цикл\nблокировк',
        remainingCycle: 'Оставшийс\nцикл',
        remaining: 'Величина\nбрутто',
        amount: 'Остаток\nкредита',
        profit: 'Потеря\nприбыли',
        copiers: 'Репликатор',
        commission: 'Копирование\nкомиссий%',
        winner: 'Лучший победитель',
        welcome: 'Самые популярные трейдеры',
        weeks2: '2 недели',
        month1: '1 месяц',
        month3: '3 месяца',
        month6: '6 месяцев',
        all: 'Все',
        legend: 'легенда',
        expert: 'Эксперты',
        success: 'Преемники',
        gUp: 'Взросление',
        newBlood: 'Новичок',
        showFrist: 'Первыми показали',
        rangTime: 'Срок',
        mixBalance: 'Минимальные инвестиции',
        mixknow: 'Минимальный опыт',
        nickName: 'Прозвище',
        null: 'не',
        nolimit: 'безграничный'
    },
    detail: {
        riskScore: 'Оценка риска',
        equity: 'Чистая стоимость счета',
        followVal: 'Комиссия за копирование',
        regDate: 'Торговые часы',
        followsetting: 'Последующие настройки',
        mintz: 'Минимальные инвестиции',
        tradingStrategyString: 'Стратегические заметки',
        tradingStrategyString1: 'Описание варианта политики',
        followway: 'Копирование стратегии',
        bx: 'Производительность',
        onemonth: '1 месяц',
        threemonth: '3 месяца',
        sixmonth: '6 месяцев',
        all: 'Все',
        profit: 'Прибыль',
        copiers: 'Репликаторы',
        profitandloss: 'Прибыли и убытки',
        accountdetail: 'Реквизиты счета',
        folatprofit: 'Плавающая прибыль',
        balance: 'Баланс',
        givegold: 'Гранты',
        lever: 'Рычаг',
        riskmanage: 'Управление рисками',
        maxprofit: 'Максимальные плавающие потери',
        maxmax: 'Максимальная продолжительность ретрейсмента',
        active: 'События',
        transferhistory: 'История торговли',
        historyorder: 'Исторические заказы',
        holdorder: 'Должностные инструкции',
        leftoperration: 'Балансовые операции',
        limitorder: 'Лимитные ордера',
        minfollow: 'Минимальное количество копий',
        nr: 'Допустим, баланс сигнального счета составляет 100 000 USD, баланс вашего копировального счета составляет 10 000 USD, сигнальный счет размещает 10 ордеров, а ваш копировальный счет размещает 1 лот. Допустим, баланс сигнального счета составляет 20 000 USD, баланс вашего копировального счета составляет 200 000 USD, сигнальный счет размещает ордер на 1 лот, а ваш счет размещает 10 лотов. Формула расчета: размер лота вашего копировального ордера = баланс вашего счета копирования÷ баланс счета источника сигнала x размер лота счета источника сигнала',
        nr1: 'Соотношение баланса 1:1 используется для автоматического расчета позиции позиции для копирования',
        manageFollow: 'Управление заказами',
        followMsg: 'После того как вы закрыли свой ордер, вам нужно будет вручную закрыть все позиции в источнике сигналов, который вы скопировали, если есть открытые сделки.',
        careful: 'Обратите внимание',
        endFollow: 'Закрытие заказа',
        cancel: 'Отмена',
        confirm: 'Подтверждение',
        remind: 'Напоминание',
        inCash: 'Депозит',
        outCash: 'Вывод средств',
        desc1: 'Если вы хотите следовать сигналу с большим размером позиции, пожалуйста',
        desc2: 'Если вы хотите следовать сигналу с меньшей позицией, пожалуйста',
        modalTitle: 'Инструкции по выполнению заказа',
        modalHtml: `<div>
            1. BlaFX предлагает функцию торговли копиями. Принимая решение о копировании одного или нескольких трейдеров или применении определенной стратегии, вы должны учитывать свое общее финансовое положение, включая финансовое планирование, и понимать, что использование функции копирования торговли является высоко спекулятивным и что вы можете быть подвержены большим потерям, чем сумма, использованная для следования за трейдером. Риски, связанные с функцией Copy Trading, включают, в частности, автоматизированные торговые операции, при которых ваш счет может начинать и заканчивать сделки без вашего ручного вмешательства.<br /><br />
            2. автоматическое исполнение сделок и другие связанные с ними торговые операции могут быть завершены на вашем счете без вашего ручного вмешательства для открытия и закрытия позиций.<br /><br />
            3. Услуга управления инвестициями BlaFX предоставляется по вашему выбору, и это ваше решение - следовать за определенным трейдером или несколькими трейдерами и следовать определенной торговой стратегии. Принимая такие решения, вы учитываете свое общее финансовое положение, включая финансовое планирование, и понимаете, что использование функции торговли копиями является высоко спекулятивным и что вы можете понести убытки, значительно превышающие сумму, использованную для следования за трейдером.<br /><br />
            4. Сотрудники и агенты BlaFX и ее филиалов не являются инвестиционными или финансовыми консультантами. Если вы принимаете инвестиционное решение на основе информации, предоставленной на нашем сайте или доступной через функцию торговли копиями, вы делаете это на свой страх и риск, и BlaFX, ее филиалы, сотрудники и агенты не несут ответственности за любые убытки.<br /><br />
            5. перед принятием инвестиционного решения вы должны вынести собственное суждение. Вы должны вынести собственное независимое суждение о том, соответствует ли инвестиция, стратегия или любой другой продукт или услуга вашим потребностям в свете ваших собственных инвестиционных целей и личных и финансовых обстоятельств.<br /><br />
            6. использование нами прошлых показателей трейдера, коэффициентов риска, статистики и всей информации об этом трейдере, отображаемой на нашем сайте и в приложениях, не является показателем будущих показателей и должно рассматриваться как гипотетическое.<br /><br />
            7. прошлые показатели и коэффициенты риска следует рассматривать как гипотетические показатели. Гипотетические показатели имеют множество присущих им ограничений. Не следует представлять или гарантировать, что какой-либо счет достигнет или может достигнуть прибыли или убытков, аналогичных прошлым показателям или указанному коэффициенту риска. Фактические процентные прибыли/убытки могут варьироваться от инвестора к инвестору в зависимости от ряда факторов, включая, но не ограничиваясь ими, остатки на счетах (депозиты и снятие средств) на момент торговли, поведение рынка, настройку счета инвестора и фактические показатели работы лица, с которым размещен ордер.<br /><br />
            8. вы понимаете и принимаете риски, связанные с услугой сопровождения ордеров, включая, но не ограничиваясь, автоматизированные торговые операции, когда ваш счет может начать и закончить торговлю без вашего ручного вмешательства, возможность высоких затрат, связанных с задержкой операций по сопровождению ордеров, и возможность отказа услуги сопровождения ордеров. Вы несете полную ответственность за все убытки, понесенные вами в результате автоматизированного выполнения операций с использованием службы сопровождения ордеров.
        </div>`,
        modalBtn1: 'Аннулирование',
        modalBtn2: 'Согласие',
    },
    clause: {
        caluse1: 'УСЛОВИЯ ТОРГОВЛИ КОПИЯМИ BETHLE ASTER',
        caluse2: 'BETHLE ASTER (здесь и далее именуемая «BlaFX») торговля копиями (далее именуемая «услуга») — это услуга, которая позволяет трейдерам (далее именуемым «копировщики») копировать транзакции других трейдеров ( здесь и далее именуемые «копировальные устройства»), и их можно активировать, выбрав «Начать репликацию».',
        caluse3: '1. Копировщик обязуется сделать следующее для подачи заявки на Услугу',
        caluse4: '1.1. Зарегистрируйтесь и войдите в систему как клиент на сайте BETHLE ASTER.',
        caluse5: 'Обратите внимание, что первая учетная запись MT, которую вы открываете по умолчанию при регистрации учетной записи, является комиссионным кошельком по умолчанию, который может только торговать, вносить и переводить средства, но не выполнять заказы. Вы можете создать до 5 учетных записей MT, и каждая новая учетная запись MT сможет размещать заказы, снимать средства, переводить и торговать независимо.',
        caluse: 'Специальное напоминание',
        caluse121: '1.2.1. Если есть учетная запись, которая торгует на отложенной учетной записи или самостоятельно, то другие связанные функции финансирования этой учетной записи будут ограничены, не будут осуществляться переводы, снятие средств, а также новые отложенные или самостоятельные сделки, и будут только возобновляется, если этот счет закрыт для отложенных или самостоятельных сделок. Конечно, это не затронет другие ваши учетные записи MT, которые никоим образом не торгуют.',
        caluse122: '1.2.2. BETHLE ASTER всегда стремится снизить риск непринятия решений нашими пользователями. Если ваша учетная запись MT имеет проприетарную позицию, вам необходимо закрыть свой проприетарный ордер, прежде чем вы сможете разместить последующий ордер. Если в вашей учетной записи MT есть последующая позиция. Вам нужно прекратить выполнять заказы и закрыть свою позицию вручную, прежде чем вы сможете торговать на своем счете MT.',
        caluse6: '1.3. Инициировать копирование.',
        caluse7: '2. Копировщик вправе:',
        caluse8: '2.1. Скопируйте любое количество мастеров (в области копирования перечислены все текущие подписки).',
        caluse9: '2.2. Вы можете вручную закрыть сделку одним ордером в любое время в области «Следовать за ордером» (кроме источников сигналов с периодами блокировки).',
        caluse10: '2.3. Откажитесь от Мастера и прекратите копирование сделок Мастера, нажав «Остановить копирование». Чтобы отказаться от подписки, Копир должен закрыть все сделки. При отписке все средства, вложенные в Мастер, и прибыль возвращаются на Кошелек Копировщика.',
        caluse11: '2.4. Установите размер доли копии для каждого конкретного Мастера. Этот вариант подробно описан в подпункте 4.4. настоящих Условий.',
        caluse12: '2.5. Добавьте средства поддержки, чтобы защитить инвестиции от неожиданных движений рынка. Эти средства используются для поддержки торговой стратегии Copier при колебаниях рынка и не влияют напрямую на прибыль.',
        caluse13: '2.6. В случае участия в инвестиционных операциях каждого конкретного Мастер-трейдера расчет суммы, вложенной Репликатором, устанавливается пропорционально средствам Репликатора. Это прямо объясняется в подпункте 5 статьи 4 Условий.',
        caluse14: '3. Сервис доступен на платформе Metatrader 4.',
        caluse15: '4. Процедура открытия скопированных сделок',
        caluse16: '4.1. Копир копирует только те сделки, которые были открыты Мастером после подписки на Мастера в рамках Сервиса.',
        caluse17: '4.2. Ордера стоп-лосс/тейк-профит не будут видны в области копирования, но если эти ордера сработают на Мастер-счете, скопированные сделки также будут закрыты.',
        caluse18: '4.3. При подписке на Мастер Копировщик указывает сумму средств, которые будут списаны с Кошелька и инвестированы в выбранный Мастер. Эти средства и ваша прибыль вернутся в ваш Кошелек, когда вы перестанете копировать Мастера.',
        caluse19: '4.4. Репликатор копирует сделки Главного трейдера пропорционально средствам следующего счета и подписанного счета Главного трейдера источника сигналов.',
        caluse20: '4.4.1. В варианте соотношения объем торгов рассчитывается на основе коэффициента кредитного плеча и собственного капитала трейдера Trader Master и последователя. Используемая формула выглядит следующим образом.',
        caluse21: 'Объем (Подписчики) = Чистый (Подписчики) / Чистый (Мастер-трейдер) × Кредитное плечо (Подписчики) / Кредитное плечо (Мастер-трейдер) × Объем (Мастер-трейдер). Например, у Master Trader есть собственный капитал в размере 2000 долларов США и кредитное плечо 1: 200. Подписчик имеет собственный капитал в размере 200 долларов США и кредитное плечо 1: 500. На Мастер-счете открыт 1 лот. Тогда объем последующей сделки составит 200/2000 х 500/200 х 1 = 0,25 лота.',
        caluse22: '4.5. Коэффициент кредитного плеча копировальных аппаратов установлен на уровне 1:100. Копировщики, желающие настроить его, должны связаться со службой поддержки клиентов BETHLE ASTER.',
        caluse24: '4.6. После активации услуги сделки будут скопированы на счет копировщика независимо от того, вошел ли владелец счета в систему или нет.',
        caluse25: '4.7. Ордер Копировщика исполняется вслед за ордером (Купить или Продать), сделанным на счете Мастера. Когда Мастер выставляет ордер, на счете Копировщика срабатывает сигнал на открытие ордера. Ордер Копировщика исполняется по рыночной цене. Этот же механизм запускает закрытие ордера. Поэтому цены исполнения этих ордеров могут отличаться. Кроме того, количество Копиров, следующих за этим Мастером, может повлиять на время выполнения.',
        caluse26: '5. Применяемые ограничения',
        caluse27: '5.1. Минимальный объем копируемой сделки 0.01 лот, максимальный объем копируемой сделки 100 лот.',
        caluse28: '5.2. Минимальный объем копируемого ордера 0,01 лота. Однако скопированные ордера объемом менее 0,005 лота будут отклонены, а скопированные сделки объемом от 0,005 лота и выше будут округлены до 0,01 лота.',
        caluse29: '5.3 Объем любого ордера округляется до ближайшего сотого знака после запятой (второй знак после запятой). Например, если Копировщик скопирует ордер на 0,324 лота, ордер будет округлен до 0,32 лота. Наоборот, если Копировщик скопирует ордер на 0,325 лота, ордер будет округлен до 0,33 лота.',
        caluse30: '5.4. Если после расчета объем скопированной сделки превышает 100 лотов, то сделка не будет открыта на счете Copier.',
        caluse31: '6. Если Мастер меняет средства (путем внесения или снятия средств) или кредитное плечо, все скопированные сделки сохраняют свой первоначальный объем на счете Копировщика.',
        caluse32: '7. Все торговые условия (кредитное плечо, свопы, спреды) копировщика аналогичны условиям для счетов BETHLE ASTER MT4.',
        caluse33: '8. Счет Master Trader должен быть типа MT4 Micro.',
        caluse34: '9. Сервис вправе делать следующее.',
        caluse35: '9.1. Ограничить количество Мастер-счетов, которые Мастера могут создавать в любое время без предварительного уведомления по своему усмотрению.',
        caluse36: '9.2. Отписать Копира от Мастера без предварительного уведомления.',
        caluse37: '9.3. Уменьшить комиссию, установленную Мастером-трейдером, и ограничить ее максимальное значение для всех ее или его Мастер-счетов без предварительного уведомления или предоставления каких-либо объяснений.',
        caluse38: '9.4. Вносить изменения в настоящие Положения и условия в любое время без предварительного уведомления копировщика или мастер-трейдера. Такие изменения вступают в силу с момента их публикации на сайте Сервиса в настоящих Условиях.',
        caluse39: '9.5. Отслеживайте активность Мастера на платформе, помечайте счет Мастера предупреждением «Подозрительная активность», если Мастер Трейдер манипулирует его статистикой, и исключайте такой счет из Мастер-рейтинга, отфильтрованного по умолчанию (он останется доступным для Копировщиков, которые изменяют их настройки фильтра соответственно).',
        caluse40: '9.6 Изменить или удалить никнейм и (или) изображение пользователя без предварительного уведомления, если Сервис обоснованно подозревает, что такой Мастер умышленно копирует или имитирует никнейм и (или) изображение другого Мастера, что может привести к недобросовестному представлению Мастера.',
        caluse41: '10. Мастер-трейдер определяет размер комиссии за последующие ордера. Комиссия может составлять от 0% до 50% от прибыли от размещенного ордера. Комиссия будет выплачиваться на электронный кошелек мастера-трейдера после закрытия каждой сделки.',
        caluse42: '11. Размер комиссии, которую Копир выплачивает Мастеру, устанавливается в момент, когда Копировщик нажимает «Начать копирование». Если Мастер меняет размер комиссии, это не влияет на сумму, причитающуюся по данной подписке Мастеру.',
        caluse43: '12. Торговую статистику Копировщика может просматривать только Копировщик.',
        caluse45: '13. Торговая статистика Мастера общедоступна.',
        caluse46: '14. Ксероксы не имеют доступа к торговому терминалу. Все действия с их подписками и трейдами производятся в Зоне Копирования.',
        caluse47: '15. В случае наличия у Сервиса обоснованных подозрений в нарушении Копировальным аппаратом правил ввода и вывода средств, установленных Клиентским соглашением или законодательством страны проживания Копировщика, Сервис вправе приостановить оказание услуг такому Копирующему устройству.',
        caluse48: '16. Обратите внимание, что Мастер может совершать как прибыльные, так и убыточные сделки.',
        caluse49: 'BETHLE ASTER оставляет за собой право окончательного толкования этих условий.',
        caluse50: '',
        caluse51: '',
        caluse52: '',
        caluse53: '',
        caluse54: '',
        caluse55: '',
        caluse56: '',
        caluse57: '',
        caluse58: '',
        caluse67: '',
        caluse68: '',
        caluse69: '',
        caluse70: '',
        caluse71: '',
        caluse72: '',
    },
    join: {
        copy: 'Получение прибыли за счет копирования успеха профессиональных трейдеров',
        wenan: 'BlaFX-FX Copytrading Предлагает клиентам возможность автоматически копировать стратегии ведущих трейдеров без необходимости тратить долгие часы на разработку собственных торговых стратегий. Сделайте свой выбор из лучших гуру форекс-трейдинга и диверсифицируйте свой торговый портфель.',
        join: 'Присоединяйтесь к Copytrading',
        become: 'Или стать мастером трейдинга',
        way: 'Как это работает',
        creat: 'Создайте счет и внесите депозит',
        simpleway: 'Зарегистрируйтесь в один простой шаг, а затем используйте любой способ оплаты, чтобы перевести депозит на свой кошелек. Если у вас уже есть средства на вашем собственном торговом счете BlaFX-FX, вы можете использовать внутренние переводы, чтобы добавить средства со своего счета на кошелек. Баланс вашего кошелька показывает средства, которые вы не инвестировали.',
        followbest: 'Следуйте за лучшими трейдерами',
        followandcopy: 'Найдите гуру, за которым вы хотите следить, и нажмите "Копировать". Их позиции будут скопированы автоматически. Настройка процента вклада поможет вам управлять своим портфелем. Попробуйте копировать различные стратегии и выберите лучшую!',
        moandprofit: 'Мониторинг и получение прибыли!',
        copycopy: 'Количество мастеров-трейдеров, которых вы можете копировать, не ограничено, поэтому вы можете создать сбалансированный и диверсифицированный портфель сделок и получать стабильный доход. Вы также полностью контролируете весь процесс и можете в любой момент пересмотреть/остановить копирование сделок. Вы можете просматривать подробные торговые данные скопированного Мастер-трейдера в зоне копирования.',
        followprofitbest: 'Следуйте за самыми прибыльными трейдерами',
        noneed: 'Не нужно быть экспертом Форекс',
        manyways: 'Стабильный доход от диверсифицированного портфеля',
        new: 'Этот новый инструмент помогает трейдерам получать более стабильную прибыль, копируя более опытных трейдеров.',
        learnbest: 'Учитесь у лучших трейдеров',
        easytoin: 'Упрощение работы для новичков на рынке Форекс',
        spread: 'Диверсифицированный портфель',
        choosebig: 'Выберите мастера-трейдера и следуйте за большой группой экспертов.',
        enjoyfast: 'Наслаждайтесь быстрым выполнением заказов',
        infive: 'Ордера исполняются в течение 5 минут после открытия позиции.',
        inhand: 'Держать все под контролем',
        anytimestop: 'Вы можете прекратить копирование, подписку или закрыть транзакцию в любое время.',
        easyway: ' Готовность к работе за несколько простых шагов',
        noneedother: 'Дополнительная валидация не требуется。',
        safe: 'Безопасное и быстрое инвестирование и вывод средств',
        mangmain: 'Широкий выбор основных способов оплаты。',
        alluseful: 'Пользуйтесь всеми преимуществами копирования транзакций в мобильном приложении',
        foces: 'Сосредоточьтесь на инвестировании с помощью простого в использовании приложения BlaFX-FX Copytrading',
        manage: 'Контролируйте свой портфель и инвестиции в пути',
        follow: 'Отслеживайте мастер-трейдеров и их результаты в реальном времени для разумного инвестирования',
        seerisk: 'Видеть, как инвестируются ваши деньги, и управлять рисками в режиме реального времени',
        howto: 'Как мне выбрать мастера-трейдера для подражания?',
        firstcheck: 'Начните с проверки данных главного трейдера. Она включает в себя показатели риска, доходности, прибыли и убытков, количество последователей, комиссионные, историю заказов и другие статистические данные, которые помогут вам принять обоснованное решение. Чтобы начать выполнять заказ, пополните свой счет.',
        anyuseful: 'Каковы возможные варианты последующих действий?',
        ways: 'Когда вы подписываетесь на Trader, вам нужно будет указать процент ордеров, за которыми нужно следить, и решить, нужно ли увеличивать средства поддержки. Когда вы нажимаете кнопку начать звонок, средства списываются с вашего кошелька и начинается звонок. Вы можете одновременно подписаться на неограниченное количество Мастер-трейдеров.',
        did: 'Берете ли вы комиссию за выполнение заказов?',
        didas: 'Как ваш брокер, мы не берем комиссионных. Вы платите только комиссию мастер-трейдеру, которая заранее определена и индивидуальна. Статистика по каждому мастер-трейдеру показывает, сколько составляет комиссия.',
        howstop: 'Как перестать следить за Master Trader?',
        anytimecancle: 'Вы можете в любой момент отменить подписку на Trader Trader и перестать выполнять заказы. Когда вы отмените подписку, все деньги, инвестированные в Trader Trader, и прибыль, которую вы получили от своих ордеров следования, будут зачислены на ваш кошелек.',
        howprofit: 'Как я могу снять свою прибыль?',
        wallet: 'Вы можете вывести средства из своего кошелька. Завершите подписку на Trader Trader и переведите свои инвестиции и прибыль на свой кошелек. Обратите внимание, что если вы закончите подписку на Trader Trader с убытком, вы не сможете вернуть свои первоначальные инвестиции. Потерянная сумма будет вычтена из вашей первоначальной инвестиции.',
        getprofit: 'Станьте мастером трейдинга и получайте постоянный доход',
        becomaster: 'Станьте мастером трейдинга',
        rules: 'Положения и условия'
    },
    balance: {
        orderid: 'Номер заказа',
        opentime: 'Время работы',
        balantime:'Остаток времени',
        type: 'Тип',
        profit: 'Преимущества',
    },
    close: {
        openprice: 'Начальная цена',
        hand: 'Количество рук',
        symbol: 'Торговые сорта',
        closetime: 'Время закрытия',
        closeprice: 'Цены на распродаже',
        comission: 'Плата за обработку',
        storage: 'Плата за инвентаризацию',
        profit: 'Преимущества',
        nowprice: 'Текущие цены'
    },
    joinmaster: {
        good: 'Хорошо разбираетесь в торговле? Заставьте людей следить за вашими сделками и таким образом зарабатывать деньги.',
        one: 'Торгуйте нормально, получая дополнительный доход от других трейдеров, которые копируют вас. Ваш профиль Master Trader показывает ежедневные и ежемесячные результаты ваших сделок - рекламируйте и привлекайте новых последователей!',
        become: 'Станьте мастером трейдинга',
        runway: 'Как это работает',
        creat: 'Создайте учетную запись Master Trader',
        click: 'Нажмите на область Master Trader и создайте счет Master Trader - откройте новый или назначьте существующий счет своим счетом Master Trader',
        accountsetting: 'Настройка счета',
        beready: 'Подготовьте свой счет Master Trader для последующего использования репликаторами: установите размер комиссии и опишите свою стратегию.',
        use: 'Используйте Уголок трейдера для просмотра подробных данных по вашим сделкам, изменения настроек счета и просмотра суммы заработанных вами комиссионных.',
        offer: 'BlaFX-FX Copytrading Предоставляет вам дополнительный источник дохода: откройте счет Master Trader, опишите свою стратегию, а затем установите комиссионные для других, чтобы они копировали ваши сделки.',
        different: 'Создавайте разные счета мастер-трейдера для разных стратегий',
        detail: 'Отслеживайте детали своих заказов в зоне Master Trader и зарабатывайте комиссионные Master Trader',
        enjoy: 'Наслаждайтесь различными преимуществами без уплаты дополнительных комиссионных BlaFX-FX',
        android: 'Вы находитесь в разделе Мастер-трейдер торгового приложения BlaFX-FX для Android',
        mobile: 'Создание учетной записи Master Trader в Mobile',
        anywhere: 'Просматривайте статистику и комиссионные для ваших репликаторов, где бы вы ни находились',
        now: 'Управление на месте условиями репликации вашего нового последователя',
        how: '  Как стать мастером-трейдером?',
        get: 'Стать Мастер-трейдером может любой клиент BlaFX-FX, имеющий счет в MT4. Вы можете обратиться в службу поддержки клиентов, пополнив счет на $100 000 и торгуя в течение трех месяцев со стабильной прибылью.',
        howmanage: 'Как настроить размер комиссии, которую я взимаю с репликаторов',
        go: 'Зайдите в свой кабинет Master Trader, просмотрите настройки, отрегулируйте комиссию с помощью ползунка, а затем сохраните. Новая комиссия будет применяться только к репликаторам, подписавшимся после корректировки. Для всех остальных репликаторов размер комиссии остается прежним.',
        time: 'Когда я получу комиссионные от своего репликатора？',
        american: 'Выплаты каждое воскресенье в 6 вечера по американскому времени。',
        when: 'Когда взимать комиссию с репликаторов？',
        comission: 'Комиссия взимается каждую субботу по закрытым заказам。',
        whencanget: 'Когда вы сможете получить свои комиссионные？',
        wallet: 'Мы будем переводить комиссионные на электронный кошелек. Из своего кошелька вы можете пополнить любой из своих торговых счетов или вывести средства.',
        copy: 'Автоматическое воспроизведение стратегий ведущих трейдеров без необходимости тратить долгие часы на разработку собственных торговых стратегий',
        or: 'Или стать репликатором',
    },
    other: {
        signalaccount: 'Сигнальные счета',
        signalname: 'Сигнальное прозвище',
        wallet: 'Средства в кошельке',
        hold: 'Сумма поддержки',
        need0: '0% от требуемых инвестиций',
        zj: 'Резюме',
        follow: 'Мультипликатор заказов',
        followmoney: 'Последующие расходы',
        begin: 'Начало выполнения заказа после',
        hand: 'Рука',
        more: '25 и более',
        choose: 'Пожалуйста, выберите',
        notlogin: 'Вы еще не вошли в систему, пожалуйста, сначала войдите в систему',
        byhand: 'Размер лота',
        byprofit: 'прибыль',
        byleft: 'равновесие',
        bylock: 'Хеджирование',
        day: 'небо',
        sure: 'Подтверждать',
        not: 'Вы еще не скопировали торговлю',
        receiptaccount: 'Открыть счет',
        balance: 'Доступный баланс',
        nodata: 'Пока нет данных',
        notlogin1: 'Вы не вошли в систему',
        socity: 'Общения',
        dl: 'Агентская программа',
        help: 'Справочный центр',
        privicy: 'Политика конфиденциальности',
        nochoose: 'Нет опций',
        nonum: 'Вы не открыли действительную копию учетной записи'
    },
    explain: {
        hand: 'Взимается на основе фиксированной платы. Исходя из предположения $1 за лот, если вы разместите 10 лотов на своем счете последователей, $10 будет снято с вашего счета на счет источника сигнала.',
        left: 'Сборы основаны на проценте от баланса счета последователей. Если предположить, что списание составляет 0,2% от баланса, то баланс вашего счета последователей составляет $10 000, и с вашего счета на счет источника сигнала будет снято $20.',
        profit: 'Начисления снимаются в соответствии с процентом от прибыли заказа, при прибыли в размере 5%. Например, ваш счет за заказ получит прибыль в размере $1000, а $50 будут сняты с вашего счета на счет источника. При отсутствии прибыли начисления не взимаются.',
        lock: 'Начисления основаны на проценте от общей прибыли за период блокировки, прибыль не взимается. Если предположить, что на общую прибыль начисляется 8%, то общая прибыль вашего последующего счета за период блокировки составит $2000, а $160 нужно будет перевести с вашего счета на счет Signal Source.',
        fake: 'Допущения: реферал первого уровня: 30%, реферал второго уровня: 20%, реферал третьего уровня: 10%, A направляет B, B направляет C, C направляет D. Если пользователь D размещает заказ, который приносит комиссию за последующие действия в размере $100, то A получает $10, B получает $20 и C получает $30.',
        said: 'В BlaFX многие последователи отдают более 70% прибыли от своих комиссионных за копирование, а некоторые даже доходят до 90%!',
        byhand: 'Вручную',
        byleft: 'По балансу',
        byprofit: 'По прибыли',
        bylock: 'Прибыль за период блокировки'
    },
    common: {
        title: 'BlaFX последующая торговля',
        update: 'Веб-сайт обновляется, пожалуйста, поймите любые неудобства',
        back: 'возвращать',
        nosm: 'Пожалуйста, выполните аутентификацию по реальному имени, прежде чем сделать это',
        fail: 'Сбой операции',
        rz: 'Перейти к сертификации',
        ok:'Операция прошла успешно.'
    }
}
