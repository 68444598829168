import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const Cookey = 'lang'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  console.log('setToken', token);
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getCookey() {
  return Cookies.get(Cookey)
}

export function setCookey(cookie) {
  return Cookies.set(Cookey, cookie)
}

export function removeCookey() {
  return Cookies.remove(Cookey)
}