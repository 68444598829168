<template>
  <div>
    <commonhead></commonhead>
    <el-container class="appContainer">
      <!-- <el-header class="header1">
        <i class="el-icon-s-operation menuBtn"
           @click="openMenu" v-if="clientWidth < 1400"></i>
        <div class="empty" v-if="clientWidth < 1400"></div>
        <img src="@/assets/image/picturedw.png" class="cloudImg">
        <div class="empty"></div>
        <el-button type="primary" round class="sign" @click="login" v-if="clientWidth > 1400">{{$t('lang.home.countLogin')}}</el-button>
        <i class="signBtn el-icon-thumb" @click="login" v-else></i>
        <el-select v-model="value" class="langSelect" @change="langChange" v-if="clientWidth > 1400">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-header> -->
      <el-main class="main">
        <!-- <el-menu :default-active="activeIndex" v-if="clientWidth > 1400" mode="horizontal" @select="handleSelect">
          <el-submenu index="1">
            <template slot="title">{{$t('lang.home.transaction')}}</template>
            <el-menu-item index="1-1">{{$t('lang.home.traders')}}</el-menu-item>
            <el-menu-item index="1-2">{{$t('lang.home.tradersOnline')}}</el-menu-item>
          </el-submenu>
          <el-menu-item index="2">{{$t('lang.home.plan')}}</el-menu-item>
          <el-submenu index="3">
            <template slot="title">{{$t('lang.home.copy')}}</template>
            <el-submenu index="3-1">
              <template slot="title">{{$t('lang.home.aboutCopier')}}</template>
              <el-menu-item index="3-1-1">{{$t('lang.home.joinCopy')}}</el-menu-item>
              <el-menu-item index="3-1-2">{{$t('lang.home.getLevel')}}</el-menu-item>
            </el-submenu>
            <el-submenu index="3-2">
              <template slot="title">{{$t('lang.home.aboutTeacher')}}</template>
              <el-menu-item index="3-2-1">{{$t('lang.home.beTeacher')}}</el-menu-item>
            </el-submenu>
            <el-submenu index="3-3">
              <template slot="title">{{$t('lang.home.msg')}}</template>
              <el-menu-item index="3-3-1">{{$t('lang.home.clause')}}</el-menu-item>
            </el-submenu>
          </el-submenu>
        </el-menu> -->
        <el-drawer
            :with-header="false"
            :size="drawSize"
            :visible.sync="showMenu"
            direction="rtl"
            :before-close="handleCloseMenu">
          <div class="msgForm">
            <img src="@/assets/image/picturedw.png" class="cloudImg">
            <el-tree class="msgTree" :data="menuData" @node-click="handleNodeClick"></el-tree>
            <div class="msg" style="margin-top: 10px">{{$t('lang.home.aboutUs')}}</div>
            <div class="msg">{{$t('lang.home.news')}}</div>
            <div class="msg">{{$t('lang.home.problem')}}</div>
          </div>
        </el-drawer>

        <div class="h1">{{$t('lang.common.title')}}</div>
        <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
          <el-tab-pane :label="$t('lang.home.score')" name="first">
            <score />
          </el-tab-pane>
          <el-tab-pane :label="$t('lang.home.follow')" name="second" >
            <follow />
          </el-tab-pane>
          <el-tab-pane :label="$t('lang.home.clause2')" name="third">
            <clause />
          </el-tab-pane>
        </el-tabs>
      </el-main>
    </el-container>

    <el-footer class="foot">
      <message />
    </el-footer>

    <el-drawer
        :with-header="false"
        :size="drawSize"
        :visible.sync="showLogin"
        direction="rtl"
        :before-close="handleClose">
      <login @register="openRegister" v-if="drawerType === 1"/>
      <register v-if="drawerType === 2"/>
    </el-drawer>
  </div>
</template>

<script>
import score from "@/views/score";
import message from "@/views/message";
import options from "@/views/options";
import {getList, getNowList} from '@/api/home'
import clause from "@/views/clause";
import follow from "@/views/follow";
import register from "@/views/homeLogin/register";
import login from "@/views/homeLogin/login";
import commonhead from "@/views/common/header";
export default {
  name: "home",
  mixins: [options],
  components: {
    score,
    message,
    clause,
    register,
    commonhead,
    login,
    follow
  },
  data() {
    return {
      drawerType: 1,
      value: '1',
      options: [
        {
          value: '1',
          label: '中文'
        }, {
          value: '2',
          label: 'English'
        }
      ],
      activeIndex: '0',
      activeName: 'first',
      showLogin: false,
      showMenu: false,
      form: {},
      clientWidth: document.body.clientWidth,
      langue:[
        {value:1,type:"zh-EN"},
        {value:2,type:"zh-CN"},
        {value:3,type:"zh-JP"},
        {value:4,type:"zh-DE"},
        {value:5,type:"zh-FR"},
        {value:6,type:"zh-SP"},
        {value:7,type:"zh-THA"},
        {value:8,type:"zh-RU"}
      ],
      drawSize:null
    }
  },
  computed: {
    // drawSize() {
    //   if(this.clientWidth < 500) {
    //     return '90%'
    //   } else {
    //     return 450
    //   }
    // }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.clientWidth = document.body.clientWidth;
      if(this.clientWidth < 500) this.drawSize = '90%'
    })
  },
  methods: {
    handleSelect() {
      window.location.href= "http://www.baidu.com";
    },
    handleNodeClick() {

    },
    handleClick(tab) {
      if(tab.name=='second')
      {
        getNowList().then(res => {
          if(res.data.code!==200)
          {
            this.$message.error(this.$t('lang.other.notlogin'));
          }
        })
      }
    },
    handleClose() {
      this.showLogin = false
      this.drawerType = 1
    },
    handleCloseMenu() {
      this.showMenu = false
    },
    login() {
      this.showLogin = true
    },
    openMenu() {
      this.showMenu = true
    },
    langChange() {
      switch (this.value) {
        case "1":
          this.$i18n.locale = 'zh-CN'
          break;
        case "2":
          this.$i18n.locale = 'zh-EN'
          break;
      }
    },
    openRegister() {
      this.drawerType = 2
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/style/home/home.scss';
@import '@/assets/style/home/common.scss';
</style>
<style>
.el-table__body-wrapper{
  cursor: pointer;
}
@media only screen and (max-width: 750px){
  #pane-third{padding: 30px;}
  }
</style>