<template>
  <div>
    <strong class="font">{{ $t('lang.clause.caluse1') }}</strong>
    <br>
    <br>
    <p class="clause">
      {{ $t('lang.clause.caluse2') }}
      <br>
      <br>
      <strong>1. {{ $t('lang.clause.caluse3') }}：</strong>
      <br>
      <br>
      1.1. {{ $t('lang.clause.caluse4') }}
      <br>
      <br>
      1.2. {{ $t('lang.clause.caluse5') }}
      <br>
      <br>
      {{ $t('lang.clause.caluse') }}
      <br>
      <br>
      1.2.1. {{ $t('lang.clause.caluse121') }}
      <br>
      <br>
      1.2.2. {{ $t('lang.clause.caluse122') }}
      <br>
      <br>
      1.3. {{ $t('lang.clause.caluse6') }}
      <br>
      <br>
      <strong>2. {{ $t('lang.clause.caluse7') }}：</strong>
      <br>
      <br>
      2.1. {{ $t('lang.clause.caluse8') }}
      <br>
      <br>
      2.2. {{ $t('lang.clause.caluse9') }}
      <br>
      <br>
      2.3 {{ $t('lang.clause.caluse10') }}
      <br>
      <br>
      2.4 {{ $t('lang.clause.caluse11') }}
      <br>
      <br>
      2.5. {{ $t('lang.clause.caluse12') }}
      <br>
      <br>
      2.6. {{ $t('lang.clause.caluse13') }}
      <br>
      <br>
      <strong>3. {{ $t('lang.clause.caluse14') }}</strong>
      <br>
      <br>
      <strong>4. {{ $t('lang.clause.caluse15') }}</strong>
      <br>
      <br>
      4.1. {{ $t('lang.clause.caluse16') }}
      <br>
      <br>
      4.2. {{ $t('lang.clause.caluse17') }}
      <br>
      <br>
      4.3. {{ $t('lang.clause.caluse18') }}
      <br>
      <br>
      4.4.{{ $t('lang.clause.caluse19') }}
      <br>
      <br>
      4.4.1 {{ $t('lang.clause.caluse20') }}
      <br>
      <br>
      4.4.2. {{ $t('lang.clause.caluse21') }}
      <br>
      <br>
      {{ $t('lang.clause.caluse22') }}
      <br>
      <br>
      4.5. {{ $t('lang.clause.caluse24') }}
      <br>
      <br>
      4.6. {{ $t('lang.clause.caluse25') }}
      <br>
      <br>
      4.7.
      {{ $t('lang.clause.caluse26') }}      <br>
      <br>
      <strong>5. {{ $t('lang.clause.caluse27') }}</strong>
      <br>
      <br>
      5.1. {{ $t('lang.clause.caluse28') }}
      <br>
      <br>
      5.2 {{ $t('lang.clause.caluse29') }}
      <br>
      <br>
      5.3.
      {{ $t('lang.clause.caluse30') }}      <br>
      <br>
      5.4.  {{ $t('lang.clause.caluse31') }}
      <br>
      <br>
      <strong>6.  {{ $t('lang.clause.caluse32') }}   </strong>
      <br>
      <br>
      <strong>7. {{ $t('lang.clause.caluse33') }} </strong>
      <br>
      <br>
      <strong>8. {{ $t('lang.clause.caluse34') }} </strong>
      <br>
      <br>
      <strong>9. {{ $t('lang.clause.caluse35') }} </strong>
      <br>
      <br>
      9.1. {{ $t('lang.clause.caluse36') }}
      <br>
      <br>
      9.2. {{ $t('lang.clause.caluse37') }}
      <br>
      <br>
      9.3. {{ $t('lang.clause.caluse38') }}
      <br>
      <br>
      9.4. {{ $t('lang.clause.caluse39') }}
      <br>
      <br>
      9.5. {{ $t('lang.clause.caluse40') }}
      <br>
      <br>
      9.6 {{ $t('lang.clause.caluse41') }}
      <br>
      <br>
      <strong>10. {{ $t('lang.clause.caluse42') }} </strong>
      <br>
      <br>
      <strong>11. {{ $t('lang.clause.caluse43') }} </strong>
      <br>
      <br>
      <strong>12. {{ $t('lang.clause.caluse45') }} </strong>
      <br>
      <br>
      12.1.{{ $t('lang.clause.caluse46') }}
      <br>
      <br>
      12.2.{{ $t('lang.clause.caluse47') }}
      <br>
      <br>
      12.3.{{ $t('lang.clause.caluse48') }}
      <br>
      <br>
      12.4.{{ $t('lang.clause.caluse49') }}
      <br>
      <br>
      12.5.{{ $t('lang.clause.caluse50') }}
      <br>
      <br>
      12.6. {{ $t('lang.clause.caluse51') }}
      <br>
      <br>
      12.7. {{ $t('lang.clause.caluse52') }}
      <br>
      <br>
      12.8. {{ $t('lang.clause.caluse53') }}
      <br>
      <br>
      12.9.{{ $t('lang.clause.caluse54') }}
      <br>
      <br>
      12.10. {{ $t('lang.clause.caluse55') }}
      <br>
      <br>
      12.11. {{ $t('lang.clause.caluse56') }}
      <br>
      <br>
      12.12. {{ $t('lang.clause.caluse57') }}
      <br>
      <br>
      12.13. {{ $t('lang.clause.caluse58') }}
      <br>
      <br>
      <strong> 13.{{ $t('lang.clause.caluse67') }} </strong>
      <br>
      <br>
      <strong> 14. {{ $t('lang.clause.caluse68') }} </strong>
      <br>
      <br>
      <strong> 15. {{ $t('lang.clause.caluse69') }} </strong>
      <br>
      <br>
      <strong> 16. {{ $t('lang.clause.caluse70') }} </strong>
      <br>
      <br>
      <strong> 17. {{ $t('lang.clause.caluse71') }}</strong>
      <br>
      <br>
      <strong>  {{ $t('lang.clause.caluse72') }}</strong>
    </p>
  </div>
</template>

<script>
export default {
  name: "clause"
}
</script>

<style scoped lang="scss">
.clause {
  font-size: 14px;
  color: #666;
}
.font{
  font-size: 16px;
}
</style>
