<template>
  <div>
    <div class="card" v-for="(item, index) in tableData" :key="index">
      <div class="item">
        <div>
          <div class="itemName">{{ $t('lang.balance.orderid') }}</div>
          <div class="itemValue">{{ item.order }}</div>
        </div>
      </div>
      <div class="item">
        <div>
          <div class="itemName">{{ $t('lang.balance.type') }}</div>
          <div class="itemValue">{{ item.cmd }}</div>
        </div>
      </div>
      <div class="item">
        <div>
          <div class="itemName">{{ $t('lang.balance.profit') }}</div>
          <div class="itemValue">{{ item.price==""||item.price==null?"":"$"+item.price }}</div>
        </div>
      </div>
      <div class="longItem">
        <div>
          <div class="itemName">{{ $t('lang.balance.opentime') }}</div>
          <div class="itemValue">{{ item.openTime }}</div>
        </div>
      </div>
    </div>
    <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
                   style="margin-top: 20px"
                   :current-page="currentPage"
                   :page-sizes="[1,5,10,20]"
                   :page-size="pageSize"
                   layout="prev, pager, next"
                   :total="total">
    </el-pagination>
  </div>
</template>

<script>
import { getBlance} from "@/api/home";
export default {
  name: "closed",
  props:['id'],
  data() {
    return {
      zlid:this.id,
      orderType:3,
      currentPage: 1, // 当前页码
      total: 10, // 总条数
      pageSize: 5, // 每页的数据条数
      typedata:["buy","sell","buy limit","sell limit","buy stop","sell stop","balance","credit"],
      tableData: [
        {
          ddh: '5301',
          kcsj: '2022-12-1 13:09:17',
          lx: 'buy',
          ss: '1.00',
        },
      ]
    }
  },
  methods:{
    //每页条数改变时触发 选择一页显示多少行
    getlist(){
      /*this.zlid = this.$route.query.id;*/
      getBlance({login:this.zlid,pageSize:this.pageSize,pageNum:this.currentPage}).then(res=>{
        res.data.rows.forEach((i,x)=>{
          i.cmd =  this.typedata[i.cmd]
          i.openTime = this.gettime(i.openTime)
        })
        this.tableData=res.data.rows
        this.total=res.data.total
      })
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getlist()
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getlist()
    },

    //时间格式转换
    formatTime(row, column) {
      const date = new Date(row[column.property]*1000)
      var Y = date.getFullYear() + "-";
      var M =
          (date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m =
          (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
          ":";
      var s = (date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds() );
      return Y + M + D + h + m + s;
    },
    gettime(t){
      // var _time=new Date((t) * 1000);
      // var   year=_time.getFullYear();//2017
      // var   month=_time.getMonth()+1;//7
      // var   date=_time.getDate();//10
      // var   hour=_time.getHours();//10
      // var   minute=_time.getMinutes();//56
      // var   second=_time.getSeconds();//15
      // return   year+"-"+month+"-"+date+"   "+hour+":"+minute+":"+second;//这里自己按自己需要的格式拼接
      if(!t) return ""
       let offset = -new Date().getTimezoneOffset() * 60 * 1000;
       const date = new Date(t*1000 - offset)
       var Y = date.getFullYear() + "-";
       var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
       var D = date.getDate() + " ";
       var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours() ) + ":";
       var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
       var s = (date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds() );
       return Y + M + D + h + m + s;
    }
  },
  mounted(){
     
  },
  watch: {
    id: function (newData, oldData) {
      this.zlid = newData;
      this.getlist()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/style/home/detailTabs.scss'

</style>
