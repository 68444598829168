<template>
  <div>
    <commonhead></commonhead>
    <el-container class="appContainer">
      <el-card class="box-card" shadow="always" style="margin-top: 10px;">
        <div class="msgBox">
          <div class="baseMsg">
            <el-image
                style="width: 80px; height: 80px; border-radius: 50%"
                :src=this.tableDetail.signalHead
                fit="fit"></el-image>
            <div class="baseName">
              <div style="font-size: 18px; font-weight: bold;margin-bottom: 10px">{{ this.tableDetail.signalName }}</div>
              <i class="el-icon-s-custom" style="color: rgb(0,170,50);"></i>
<!--              <span style="color: #908e8e; margin-left: 3px" >{{this.tableDetail.riskLevelType}}</span>-->
              <span style="color: #908e8e; margin-left: 3px" >{{this.tableDetail.riskLevelType==1?$t('lang.score.newBlood'):this.tableDetail.riskLevelType==2?$t('lang.score.gUp'):this.tableDetail.riskLevelType==3?$t('lang.score.success'):this.tableDetail.riskLevelType==4?$t('lang.score.expert'):$t('lang.score.legend')}}</span>
            </div>
          </div>
          <div class="rickwrap" style="width:72%">
            <div class="rick top_right ricklist lf">
              <div>
                <div style="color: #606266; margin-bottom: 10px" class="title">{{$t('lang.detail.riskScore')}}</div>
                <span style="display: inline-block;background-color: #004fdf; color: #ffffff;padding: 1px 4px; border-radius: 30px;padding:5px 20px">{{ this.tableDetail.riskScore }} risk</span>
              </div>
            </div>
            <div class="count top_right ricklist lf">
              <div>
                <div style="color: #606266;" class="title">{{$t('lang.detail.equity')}}</div>
                <div style="margin-top: 10px">$ {{ this.tableDetail.equity }}</div>
              </div>
            </div>
            <div class="money top_right ricklist lf">
              <div>
                <div style="   " class="title ex"><span class="lf">{{$t('lang.detail.followVal')}}</span>
                <el-popover
                  placement="top-start"
                  width="auto"
                  popper-class="tips-con"
                  trigger="hover"
                  >
                  <div class="wordbreak">
                    <p style="font-weight: 900;display: inline;color: black">{{ $t('lang.explain.byhand') }}：</p>{{ $t('lang.explain.hand') }}<br><br>
                    <p style="font-weight: 900;display: inline;color: black">{{$t('lang.explain.byleft')}}：</p>{{$t('lang.explain.left')}}<br><br>
                    <p style="font-weight: 900;display: inline;color: black">{{ $t('lang.explain.byprofit')}}：</p>{{ $t('lang.explain.profit')}}<br><br>
                    <p style="font-weight: 900;display: inline;color: black">{{$t('lang.explain.bylock')}}：</p>{{$t('lang.explain.lock')}}
<!--                  {{ this.tableDetail.followType===1? $t('lang.explain.hand'):this.tableDetail.followType===2?
                    $t('lang.explain.profit'):this.tableDetail.followType===3?
                        $t('lang.explain.left'):$t('lang.explain.lock')}}-->
                  </div>
                  <i slot="reference" class="el-icon-question" style="color: rgb(0, 79, 223)"></i>
                </el-popover>

                </div>
  <!--              <div style="margin-top: 10px">{{ this.tableDetail.followVal }}/{{$t('lang.other.hand')}}</div>-->
  <!--              <div style="margin-top: 10px">{{ this.tableDetail.followType===1? this.tableDetail.followVal+"/按手数":this.tableDetail.followVal+"%"}}</div>-->
                <div style="margin-top: 10px">{{ this.tableDetail.followType===1? this.tableDetail.followVal+"/"+$t('lang.other.byhand'):this.tableDetail.followType===2?
                    this.tableDetail.followVal+"%/"+$t('lang.other.byprofit'):this.tableDetail.followType===3?
                        this.tableDetail.followVal+"%/"+$t('lang.other.byleft'):this.tableDetail.followVal+"%/"+$t('lang.other.bylock')}}</div>
              </div>
            
            </div>
            <div class="money top_right ricklist lf">
              <div>
                <div style="" class="title ex"><span class="lf">{{$t('lang.score.agency')}}</span>
                <el-popover
                  placement="top-start"
                  width="250"
                  popper-class="tips-con"
                  trigger="hover"
                  >
                  <div class="wordbreak">
                  {{ $t('lang.explain.fake') }}<br>

                  <div class="tt">{{ $t('lang.explain.said') }}</div>
                  </div>

                  <i slot="reference" class="el-icon-question" style="color: rgb(0, 79, 223)"></i>
                </el-popover>

                </div>
  <!--              <div style="margin-top: 10px">{{ this.tableDetail.followVal }}/{{$t('lang.other.hand')}}</div>-->
  <!--              <div style="margin-top: 10px">{{ this.tableDetail.followType===1? this.tableDetail.followVal+"/按手数":this.tableDetail.followVal+"%"}}</div>-->
                <div style="margin-top: 10px">{{ this.tableDetail.agentVal}}</div>
              </div>
            
            </div>
            <div class="time top_right ricklist lf">
            <div>
              <div style="color: #606266;"  class="title">{{$t('lang.detail.regDate')}}</div>
              <div style="margin-top: 10px">{{ this.tableDetail.regDate }}d</div>
            </div>
          </div>
          </div>
          <div class="set" v-if="clientWidth >= 1000">
            <div>
              <el-button v-if="!isfollow" round class="greenBtn" @click="signsetting">{{$t('lang.detail.followsetting')}}</el-button>
              <el-button v-if="isfollow" round class="greenBtn" @click="manageFollow">{{$t('lang.detail.manageFollow')}}</el-button>
              <div class="btnMsg">
                {{$t('lang.detail.minfollow')}}  ${{ this.tableDetail.minBalance }}
              </div>

              <div class="common">

              </div>
              <div class="newlabel">
                <div >{{$t('lang.detail.followway')}}</div>
                <div>{{$t('lang.detail.nr1')}}</div>
              </div>
            </div>
          </div>

          <div class="stMsg top_right">
            <div>
              <div style="color: #606266;" class="title">{{$t('lang.detail.tradingStrategyString')}}</div>
             <div style="margin-top: 10px">{{ this.tableDetail.tradingStrategyString }}</div>
              <!-- <div style="margin-top: 10px;word-wrap:break-word">{{ this.$cookies.get("lang")=="zh-EN"?this.tableDetail.tradingStrategy.en:this.$cookies.get("lang")=="zh-CN"?this.tableDetail.tradingStrategy.zh:this.$cookies.get("lang")=="zh-DE"?this.tableDetail.tradingStrategy.de:this.$cookies.get("lang")=="zh-SP"?this.tableDetail.tradingStrategy.es:this.$cookies.get("lang")=="zh-FR"?this.tableDetail.tradingStrategy.fr:this.$cookies.get("lang")=="zh-JP"?this.tableDetail.tradingStrategy.jp:this.$cookies.get("lang")=="zh-RU"?this.tableDetail.tradingStrategy.ru:this.tableDetail.tradingStrategy.tha}}</div> -->
            </div>
          </div>


          <div class="stMsg top_right">
            <div>
              <div style="color: #606266;" class="title">{{$t('lang.detail.tradingStrategyString1')}}</div>
              <!--              <div style="margin-top: 10px">{{ this.tableDetail.tradingStrategyString }}</div>-->
              <div style="margin-top: 10px">{{ $t('lang.detail.nr')}}</div>
            </div>
          </div>



          <div class="set" v-if="clientWidth < 1000">
            <div>
              <el-button v-if="!isfollow" round class="greenBtn" @click="signsetting">{{$t('lang.detail.followsetting')}}</el-button>
              <el-button v-if="isfollow" round class="greenBtn" @click="manageFollow">{{$t('lang.detail.manageFollow')}}</el-button>
              <div class="btnMsg">

                {{$t('lang.detail.minfollow')}}  ${{ this.tableDetail.minBalance }}
              </div>
              <div class="common">

              </div>
              <div class="newlabel">
                <div >{{$t('lang.detail.followway')}}</div>
                <div>{{ $t('lang.detail.nr1')}}</div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="box-card secbox" shadow="always" style="margin-top: 10px;">
        <h2 class="title commontext">{{$t('lang.detail.bx')}}</h2>
        <div class="bsBox">
          <div class="btn">
            <el-button
                :style="clientWidth < 500? 'margin-left: 2px' : ''"
                :size="clientWidth >= 500? 'medium ' : 'mini'"
                type="primary" round plain @click="changtime(2)">{{$t('lang.detail.onemonth')}}</el-button>
            <el-button
                :style="clientWidth < 500? 'margin-left: 2px' : ''"
                :size="clientWidth >= 500? 'medium ' : 'mini'"
                type="primary" round plain @click="changtime(3)">{{$t('lang.detail.threemonth')}}</el-button>
            <el-button
                :style="clientWidth < 500? 'margin-left: 2px' : ''"
                :size="clientWidth >= 500? 'medium ' : 'mini'"
                type="primary" round plain @click="changtime(4)">{{$t('lang.detail.sixmonth')}}</el-button>
            <el-button
                :style="clientWidth < 500? 'margin-left: 2px' : ''"
                :size="clientWidth >= 500? 'medium ' : 'mini'"
                type="primary" round plain @click="changtime(5)">{{$t('lang.detail.all')}}</el-button>
          </div>
          <div class="winM">
            <div>
              <div class="winMtext">{{$t('lang.detail.profit')}}</div>
              <div style="margin-top: 10px" class="winMdata">{{(Number( this.tableExpression.profitRate*100).toFixed(2)) }}%</div>
            </div>
          </div>
          <div class="copier">
            <div>
              <div class="winMtext">{{$t('lang.detail.copiers')}}</div>
              <div style="margin-top: 10px" class="winMdata">{{ this.tableExpression.followNum }}</div>
            </div>
          </div>
          <div class="lose">
            <div>
              <div style="color: #606266;" class="winMtext">{{$t('lang.detail.profitandloss')}}</div>
              <div style="margin-top: 10px;float: left;display: inline-block" class="winMdata">{{ this.tableExpression.totalHarvest==null || this.tableExpression.totalHarvest==0?"$0":"$+"+this.tableExpression.totalHarvest}}</div>
              <div style="margin-top: 10px;float: right;display: inline-block" class="winMdata">{{ this.tableExpression.totalLose==null || this.tableExpression.totalLose==0?"$0":"$-"+this.tableExpression.totalLose }}</div>
            </div>
          </div>
        </div>
      </el-card>
      <div class="leftAndRight">
        <el-card class="box-card leftCard" shadow="always" style="margin-top: 10px">
          <h2 class="title commontext">{{$t('lang.detail.accountdetail')}}</h2>
          <div class="leftBox">
            <div class="Boxtext">
              <div>
                <div class="winMtext">{{$t('lang.detail.folatprofit')}}</div>
                <div style="margin-top: 10px" class="winMdata">${{ Number(this.tableAccount.profit).toFixed(2) }}</div>
              </div>
            </div>
            <div class="Boxtext">
              <div>
                <div class="winMtext">{{$t('lang.detail.balance')}}</div>
                <div style="margin-top: 10px" class="winMdata">${{ this.tableAccount.balance }}</div>
              </div>
            </div>
            <!-- <div class="Boxtext">
              <div>
                <div class="winMtext">{{$t('lang.detail.givegold')}}</div>
                <div style="margin-top: 10px" class="winMdata">${{ this.tableAccount.giveGold==null?0: this.tableAccount.giveGold }}</div>
              </div>
            </div> -->
            <div class="Boxtext">
              <div>
                <div class="winMtext">{{$t('lang.detail.lever')}}</div>
                <div style="margin-top: 10px" class="winMdata">{{ this.tableAccount.lever }}</div>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card rightCard" shadow="always" style="margin-top: 10px">
          <h2 class="title commontext">{{$t('lang.detail.riskmanage')}}</h2>
          <div class="rightBox">
            <div class="maxText">
              <div>
                <div class="winMtext">{{$t('lang.detail.maxprofit')}}</div>
                <div style="margin-top: 10px" class="winMdata">${{ this.tableAccount.maxProfit }}</div>
              </div>
            </div>
            <div class="maxText">
              <div>
                <div class="winMtext">{{$t('lang.detail.maxmax')}}</div>
                <div style="margin-top: 10px" class="winMdata">{{ this.tableAccount.maximumDrawdownDuration==null?0:this.tableAccount.maximumDrawdownDuration }}d</div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <el-card class="box-card activeCard" shadow="always" style="margin-top: 10px">
        <h2 style="margin: 0" class="commontext">{{$t('lang.detail.active')}}</h2>
        <div id="chart-timeline"></div>


        <div
            class="apexcharts-legend"
            style="max-height: 200px"
        ></div>
        <div
            class="apexcharts-tooltip apexcharts-theme-light"
        >
          <div
              class="apexcharts-tooltip-title"
              style="
																	font-family: Helvetica, Arial, sans-serif;
																	font-size: 12px;
																"
          ></div>
          <div
              class="apexcharts-tooltip-series-group"
              style="order: 1"
          >
																<span
                                    class="apexcharts-tooltip-marker"
                                    style="background-color: rgb(13, 111, 251)"
                                ></span>
            <div
                class="apexcharts-tooltip-text"
                style="
																		font-family: Helvetica, Arial, sans-serif;
																		font-size: 12px;
																	"
            >
              <div class="apexcharts-tooltip-y-group">
																		<span
                                        class="apexcharts-tooltip-text-y-label"
                                    ></span
                                    ><span
                  class="apexcharts-tooltip-text-y-value"
              ></span>
              </div>
              <div class="apexcharts-tooltip-goals-group">
																		<span
                                        class="apexcharts-tooltip-text-goals-label"
                                    ></span
                                    ><span
                  class="apexcharts-tooltip-text-goals-value"
              ></span>
              </div>
              <div class="apexcharts-tooltip-z-group">
																		<span
                                        class="apexcharts-tooltip-text-z-label"
                                    ></span
                                    ><span
                  class="apexcharts-tooltip-text-z-value"
              ></span>
              </div>
            </div>
          </div>
        </div>
        <div
            class="apexcharts-yaxistooltip apexcharts-yaxistooltip-0 apexcharts-yaxistooltip-left apexcharts-theme-light"
        >
          <div class="apexcharts-yaxistooltip-text"></div>
        </div>
      </el-card>
      <el-card class="box-card activeCard" shadow="always" style="margin-top: 10px">
        <h2 class="commontext">{{$t('lang.detail.transferhistory')}}</h2>
        <el-tabs v-model="activeName" @tab-click="handleClick" class="tablist">
          <el-tab-pane :label="$t('lang.detail.historyorder')" name="first">
            <closed v-if="clientWidth > 1000" :id="signalLogin" ref="closedData"/>
            <closedCard :id="signalLogin" v-else ref="closedDataCard"/>
          </el-tab-pane>
          <el-tab-pane :label="$t('lang.detail.holdorder')" name="second">
            <exposure v-if="clientWidth > 1000" :id="signalLogin" ref="getExposureData"/>
            <exposureCard :id="signalLogin" v-else ref="getExposureDataCard"/>
          </el-tab-pane>
          <el-tab-pane :label="$t('lang.detail.leftoperration')" name="third">
            <balance v-if="clientWidth > 1000" :id="signalLogin" ref="getBalanceData"/>
            <balanceCard :id="signalLogin" v-else ref="getBalanceDataCard"/>
          </el-tab-pane>
          <el-tab-pane :label="$t('lang.detail.limitorder')"  name="fourth">
            <limit v-if="clientWidth > 1000" :id="signalLogin" ref="getLimitData"/>
            <limitCard :id="signalLogin" v-else ref="getLimitDataCard"/>
          </el-tab-pane>
        </el-tabs>
      </el-card>

    </el-container>
    <el-footer class="foot">
      <message />
    </el-footer>
    <detailTc :datainfo="tableDetail" v-if="tcshow" @tcshow="tcdata"></detailTc>
    <el-drawer
        :with-header="false"
        :size="drawSize"
        :visible.sync="showLogin"
        direction="rtl"
        :before-close="handleClose">
      <login />
      <register />
    </el-drawer>
    <follow-notice-modal ref="modalRef" @nextStep="() => {tcshow = true}"></follow-notice-modal>
  </div>
</template>

<script>
import score from "@/views/score";
import message from "@/views/message";
import options from "@/views/options";
import clause from "@/views/clause";
import activity from "@/views/activity";
import closed from "@/views/detailTabs/closed";
import exposure from "@/views/detailTabs/exposure";
import balance from "@/views/detailTabs/balance";
import limit from "@/views/detailTabs/limit";
import closedCard from "@/views/detailTabs/closedCard";
import commonhead from "@/views/common/header";
import exposureCard from "@/views/detailTabs/exposureCard";
import balanceCard from "@/views/detailTabs/balanceCard";
import limitCard from "@/views/detailTabs/limitCard";
import detailTc from "@/views/detailTabs/detailTc";
// import followNoticeModal from "@/views/detailTabs/followNoticeModal";
import register from "@/views/homeLogin/register";
import login from "@/views/homeLogin/login";
import {getDetail,getExpression,getAccount,getLineChart,stopFollow,getNowList} from "@/api/home";
import {getToken} from '@/api/auth'
import errorImg from "@/assets/image/user.png"
import followNoticeModal from "@/views/detailTabs/followNoticeModal"
export default {
  name: "home",
  mixins: [options],
  components: {
    score,
    message,
    clause,
    activity,
    closed,
    exposure,
    balance,
    limit,
    closedCard,
    exposureCard,
    balanceCard,
    limitCard,
    register,
    detailTc,
    login,
    commonhead,
    followNoticeModal
  },
  data() {
    return {
      signalLogin:"",
      chartdata:[],
      id:this.$route.query.id,
      rangTime:1,
      tableDetail:{},
      tableExpression:{},
      tableAccount:{},
      tablePress:{},
      value: '1',
      tcshow:false,
      options: [
        {
          value: '1',
          label: '中文'
        }, {
          value: '2',
          label: 'English'
        }
      ],
      /*levelOptions: [
        {
          value: '5',
          label: this.$t('lang.score.legend')
        },
        {
          value: '4',
          label: this.$t('lang.score.expert')
        },
        {
          value: '3',
          label: this.$t('lang.score.success')
        },
        {
          value: '2',
          label: this.$t('lang.score.gUp')
        },
        {
          value: '1',
          label: this.$t('lang.score.newBlood')
        },
        {
          value: '6',
          label: this.$t('lang.score.all')
        }
      ],*/
      activeName: 'first',
      activeIndex: '0',
      showLogin: false,
      showMenu: false,
      form: {},
      clientWidth: document.body.clientWidth,
      isfollow: false,
    }
  },
  computed: {
    drawSize() {
      if(this.clientWidth < 500) {
        return '90%'
      } else {
        return 450
      }
    },
    scoreOptions(){
      return[
        {
          value: '1',
          label: this.$t('lang.score.risk')
        },
        {
          value: '2',
          label: this.$t('lang.score.winner')
        },
        {
          value: '3',
          label: this.$t('lang.score.welcome')
        }
      ]},
    timeOptions(){
      return[
        {
          value: '1',
          label: this.$t('lang.score.weeks2')
        },
        {
          value: '2',
          label: `${this.$t('lang.score.month1')}`
        },
        {
          value: '3',
          label: this.$t('lang.score.month3')
        },
        {
          value: '4',
          label: this.$t('lang.score.month6')
        },
        {
          value: '5',
          label:  this.$t('lang.score.all')
        },
      ]
    },
    levelOptions(){
      return[
        {
          value: '5',
          label: this.$t('lang.score.legend')
        },
        {
          value: '4',
          label: this.$t('lang.score.expert')
        },
        {
          value: '3',
          label: this.$t('lang.score.success')
        },
        {
          value: '2',
          label: this.$t('lang.score.gUp')
        },
        {
          value: '1',
          label: this.$t('lang.score.newBlood')
        },

      ]
    }

  },
  created(){
    getDetail(this.id).then(res=>{
      this.signalLogin=res.data.data.signalLogin
      this.tableDetail=res.data.data
    })
     getNowList().then(res=>{
       if(res.data.code==200){
        res.data.data.map(item=>{
          if(item.id==this.$route.query.id){
            this.isfollow=true
          }
        })
       }
    })
  },
  mounted() {
    this.$i18n.locale=this.$cookies.get("lang")
    this.id = this.$route.query.id;
    this.isfollow = this.$route.query && this.$route.query.isfollow == 1 ? true : false;
    
    getExpression({rangeTime: this.rangTime,id:this.id}).then(res=>{
      this.tableExpression=res.data.data;
    })
    getAccount(this.id).then(res=>{
      if(res.data.code==500){
        this.tableAccount={}
        return false
      }
      this.tableAccount=res.data.data

    })
    window.addEventListener('resize', () => {
      this.clientWidth = document.body.clientWidth;
    })
    this.getchart(this.id,5)
  },
  methods: {
    //当前页改变时触发 跳转其他页
    tcdata(status){
      this.tcshow=false
      if(status) {
        this.$router.replace({path: '/detail',query: {id: this.id, isfollow: '1'}})
        window.location.reload()
      }
    },
    changtime(data){
      this.rangTime=data;
      getExpression({rangeTime: this.rangTime,id:this.id}).then(res=>{
        this.tableExpression=res.data.data;
      })
    },
    async getcharlist(id,type){
      this.chartdata = []
      await getLineChart({id:id,rangeTime:type}).then(res=>{
        var num = res.data.data.profit
        var data = []
        $.each(res.data.data.yaxis,function(index,item){
          num = parseInt(num) +parseInt(item.profit)
          data.push({x:item.closeTime*1000,y:num,z:item.symbol+'<br>'+'Volume:'+item.volume/100+'<br>'+'Profit:'+item.profit+'<br>'+'Total:'+num})
        })
        this.chartdata = data
      })
    },
    async getchart(id,type){
      await this.getcharlist(id,type)
      var options = {
          series: [{
            name:"",
            data: this.chartdata,
          }
          ],
          chart: {
            id: 'area-datetime',
            type: 'area',
            height: 350,
            zoom: {
              autoScaleYaxis: true
            }
          },
          annotations: {

          },
          dataLabels: {
            enabled: false
          },
          markers: {
            size: 0,
            style: 'hollow',
          },
          xaxis: {
            type: 'datetime',
            tickAmount: 6,
          },
          tooltip: {
            x: {
              format: 'dd MMM yyyy'
            },
            y:{show:false,title:''},
            z: {
              formatter:undefined,
              title: ''
            },
          },

          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 100]
            }
          },
        };
        let chart = new ApexCharts(document.getElementById("chart-timeline"), options);
        chart.render();
    },
    handleSelect() {
      window.location.href= "http://www.baidu.com";
    },
    handleClose() {
      this.showLogin = false
    },
    login() {
      this.showLogin = true
    },
    openMenu() {
      this.showMenu = true
    },
    handleCloseMenu() {
      this.showMenu = false
    },
    handleClick(row) {
      if (row.index == 0) {
        if (this.clientWidth > 1000) {
          this.$refs.closedData.getData()
        } else {
          this.$refs.closedDataCard.getlist()
        }
      }
      if (row.index == 1) {
        if (this.clientWidth > 1000) {
          this.$refs.getExposureData.getData()
        } else {
          this.$refs.getExposureDataCard.getData()
        }
      }
      if (row.index == 2) {
        if (this.clientWidth > 1000) {
          this.$refs.getBalanceData.getData()
        } else {
          this.$refs.getBalanceDataCard.getlist()
        }
      }
      if (row.index == 3) {
        if (this.clientWidth > 1000) {
          this.$refs.getLimitData.getData()
        } else {
          this.$refs.getLimitDataCard.getData()
        }
      }
    },
    handleNodeClick() {

    },
    signsetting(){
        if(getToken()==null || getToken()=='' || getToken()== undefined ){
          this.$message({
            message: this.$t('lang.other.notlogin'),
            type: 'error'
          });
          //  $('.loginin').addClass('_39wfV')
          setTimeout(()=>{
            window.open(dataurlhost.apihost.link+'/login')
          }, 800)
          return false 
        }
        let showModal = window.localStorage.getItem('hideModal') || 'false';
        if(showModal == 'false') {
          this.$nextTick(() => {
            this.$refs.modalRef.init()
          })
        } else {
          this.tcshow=true
        }
  },
  manageFollow() {
    this.$confirm(this.$t('lang.detail.followMsg'), this.$t('lang.detail.careful'), {
      confirmButtonText: this.$t('lang.detail.endFollow'),
      cancelButtonText: this.$t('lang.detail.cancel'),
      type: 'warning'
    }).then(() => {
      stopFollow({id: this.id}).then(res => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          this.$router.replace({path: '/detail',query: {id: this.id}})
          window.location.reload()
        } else {
          this.$confirm(res.data.msg, '', {
            confirmButtonText: this.$t('lang.detail.confirm'),
            showCancelButton: false,
            type: 'warning'
          }).then(() => {})
        }
      })
    }).catch(() => {});
    
  },
    langChange() {
      switch (this.value) {
        case "1":
          this.$i18n.locale = 'zh-CN'
          break;
        case "2":
          this.$i18n.locale = 'zh-EN'
          break;
      }
    }
  }
}
</script>
<style>
@import '@/assets/style/home/apexcharts.css';
@import '@/assets/style/home/detailtc.css';
</style>
<style scoped lang="scss">
@import '@/assets/style/home/detail.scss';
</style>

<style scoped lang="scss">
html{background: #f8f8f8;}
.appContainer{display: block; margin-top: 150px;
  margin:160px auto;max-width:1200px;
  /deep/ .el-card__body{
    position: relative;
    padding: 30px !important;
  }
}
.tips {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 12px 0 16px;
  font-size: 12px;
  line-height: 25px;
  color: #ffffff;
  background-color: #004fdf;
  border-bottom-left-radius: 20px;
}
.top_right{
  .title{font-size: 18px;}
  div:nth-child(2){
    color: #666;
    font-size: 16px;
    font-weight: bold;
  }
}

.winMtext{
  color:#828ca0;font-size: 14px;font-weight: 600;
}
.winMdata{
  color: #666;font-size: 12px;font-weight: bold;
}
.Boxtext{
  width: 50%;
  text-align: left;
  margin-bottom: 30px;
}
.maxText{
  width: 100%;
  text-align: left;
  margin-bottom: 30px;
}
.commontext{
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #666;
}
@media only screen and (max-width: 1000px) {
  .appContainer{margin-top: 75px;}
  .winMtext{font-weight: normal;font-size: 14px;color: #333;}
  .winMdata{font-weight: normal;}
  .top_right{
    // &:nth-child(odd){
    //   text-align: right;
    // }
    .title{font-size:14px}
    div:nth-child(2){
      font-size: 12px;
      font-weight: normal;
    }
  }

  .Boxtext{

    &:nth-child(even){
      text-align: right;
    }
  }
  .rightBox{
    .maxText{width: 50%;
      &:nth-child(even){
        text-align: right;
      }
    }

  }
  .tablist{
    /deep/.el-tabs__header{
      margin: 0 !important;
    }
  }
}
.money .title /deep/.el-popover__reference-wrapper{
      position: relative;
    width: 14px;
    float: left;
    display: block;
    height: 14px;
    text-align: center;
    border: 1px solid #000;
    border-radius: 100px;
    font-size: 12px;
    margin-left: 7px;
    line-height: 14px;
    vertical-align: middle;
    margin-top: 4px;
}
</style>
<style>
.tips-con{
  max-width: 350px;
}
.el-popover .tips-con{
  min-width: 80px
}
.tt{
  background-color: #ECF5FF;
  color: #0077E1;
  margin-top: 10px;
  padding: 10px;
}
.wordbreak{

 /* word-break: keep-all;*/
  word-wrap: break-word;
}
@media only screen and (max-width: 1000px){
  .ex{
    color: #606266;overflow:hidden;
  }
}
@media only screen and (min-width: 1001px){
  .ex{
    color: #606266;overflow:hidden;
    display: flex;
    justify-content: center;
  }
}
</style>