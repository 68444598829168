export const lang = {
    home: {
        countLogin: 'アカウントログイン',
        transaction: 'トレーディング・プラットフォーム',
        traders: 'メタトレーダー4',
        tradersOnline: 'Meta Trader 4 - オンライントレーディング',
        plan: '推奨プログラム',
        copy: 'コピートレード',
        aboutCopier: 'コピーの場合',
        joinCopy: 'コピートレードに参加する',
        getLevel: 'マスタートレーダーの評価',
        aboutTeacher: 'マスタートレーダー向け',
        beTeacher: 'マスタートレーダーになる',
        msg: 'インフォメーション',
        clause: 'ご利用条件',
        aboutUs: '会社概要',
        news: '企業ニュース',
        problem: 'よくある質問',
        score: 'ポリシー',
        follow: 'コピー中です',
        clause2: 'ご利用条件',
        login: 'ログイン',
        email: 'メールボックス',
        password: 'パスワード',
        forgetPassword: 'パスワードをお忘れの方？',
        code: '検証コード',
        login2: 'ログイン',
        register: '登録',
        google: 'グーグルキャプチャ',
        qlgoogle: 'Google認証コードを入力してください。',
        center: 'パーソナルセンター',
        quit: 'ログアウト',
        quitMsg: 'ログインが無効です、再度ログインしてください',
    },
    message: {
        choose: '選ぶ理由',
        changeMarket: '取引市場',
        changeTool: '取引プラットフォームとツール',
        community: 'BlaFX',
        companies: 'パートナー',
        supervise: '私の監修',
        VIP: 'プレミアムVIPサービス',
        active: 'アクティブトレーダープログラム',
        out: '外国為替取引',
        contract: 'インデックスCFD',
        goods: 'コモディティ',
        CFD: '通貨インデックスCFD（CFD）',
        money: 'スプレッド、スワップ、コミッション',
        time: '取引時間',
        count: '取引口座',
        inAndOut: '入出金について',
        begin: '今すぐ始める',
        MetaTrader: 'MetaTrader 4',
        MetaTraderChange: 'MetaTrader 4-オンライントレード',
        study: 'トレードを学ぶ',
        news: '経済情報',
        com: 'BlaFX ドキュメンタリーコミュニティ',
        proxy: '代理店サービス',
        friend: '友人紹介プログラム',
        title: '当社が提供するサービスには、OTC商品や外国為替商品の取引が含まれ、当初投入した資金以上の損失が発生するリスクがあります。 これらの商品は、すべての投資家に適しているわけではありませんので、そのリスクを十分にご理解の上、必要に応じて第三者のアドバイスを受けてください。',
        p1: 'リスク警告：CFDやFXの取引にはリスクが伴います。 すべての人に適しているわけではありませんし、損失が初期投資額をはるかに上回る可能性もあります。 お客様は、原資産の所有権や権利を有するものではありません。 過去の実績は将来の業績を示唆するものではなく、税法は変更される可能性があります。 本ウェブサイトに掲載されている情報は一般的なものであり、お客様またはお客様の顧客の個人的な目的、財務状況、ニーズなどを考慮したものではありません。 取引に際しては、「リスク開示通知書」等の法的文書をお読みいただき、リスクを十分にご理解の上、ご判断くださいますようお願いいたします。 お客様には、独立した立場のアドバイスを求めていただくことをお勧めします。',
        p2: 'BlaFX Capital PTY LTDの所在地はSuite 280 156 Burwood Road Bureood,NSW 2134 Australia。 オーストラリア証券委員会の認可を受け、規制を受けています。本ウェブサイト上の情報および提供される製品・サービスは、いかなる国・地域においても、いかなる者に対しても販売されるものではありません。',
        p3: '  © 2020 BlaFX Capital PTY LTD｜会社登録番号 661 715 966｜AFS-001298638。'
    },
    score: {
        invested: '投資しましたか？',
        signUp: '登録し、マスタートレーダーのトレーダーをコピーして、あなたの投資により高いリターンと安定したリターンを加えましょう。',
        score: '専門家による評価',
        traders: 'トレーダー',
        agency: '代理店手数料',
        risk: 'リスクスコアリング',
        gain: '収量',
        cycle: 'ロックアップサイクル',
        remainingCycle: '残サイクル',
        remaining: '合計度',
        amount: 'クレジット残数',
        profit: '利益および損失',
        copiers: 'レプリケーター',
        commission: 'コピーコミッション%',
        winner: 'トップウィナー',
        welcome: '最も人気のあるトレーダー',
        weeks2: '2週間',
        month1: '1ヶ月',
        month3: '3ヶ月',
        month6: '6ヶ月',
        all: 'すべて',
        legend: 'サガ',
        expert: '専門家',
        success: '後継者',
        gUp: '成長すること',
        newBlood: '新米',
        showFrist: '最初に表示する',
        rangTime: '期間',
        mixBalance: '最低投資額',
        mixknow: '最低限の専門知識',
        nickName: 'ニックネーム',
        null: 'なし',
        nolimit: '無制限'
    },
    detail: {
        riskScore: 'リスクスコアリング',
        equity: '正味口座価額',
        followVal: '手数料をフォローします',
        regDate: '取引時間',
        followsetting: 'フォローアップの設定',
        mintz: '最低投資額',
        tradingStrategyString: 'ストラテジーノート',
        tradingStrategyString1: 'ポリシー の例の説明',
        followway: 'ポリシーをフォローします',
        bx: 'パフォーマンス',
        onemonth: '1ヶ月',
        threemonth: '3ヶ月',
        sixmonth: '6ヶ月',
        all: 'すべて',
        profit: '利益',
        copiers: 'レプリケーター',
        profitandloss: '利益および損失',
        accountdetail: 'アカウント詳細',
        folatprofit: '浮動株利益',
        balance: 'バランス',
        givegold: '助成金',
        lever: 'レバレッジ',
        riskmanage: 'リスクマネジメント',
        maxprofit: '最大浮遊損失',
        maxmax: '最大リトレースメント時間',
        active: 'イベント情報',
        transferhistory: '取引履歴',
        historyorder: '歴史的経緯',
        holdorder: 'ポジションオーダー',
        leftoperration: 'バランスオペレーション',
        limitorder: '指値注文',
        minfollow: '最小支払金額',
        nr: 'シグナルアカウントの残高が100,000 USD、コピーアカウントの残高が10,000 USD、シグナルアカウントが10回の注文、コピーアカウントが1ロットであるとします。 シグナル口座の残高が20,000 USD、コピー口座の残高が200,000 USD、シグナル口座が1ロットの注文、アカウントが10ロットの注文をしたとします。 計算式は、コピー注文のロットサイズ=コピーアカウントの残高÷シグナルソースアカウントの残高xシグナルソースアカウントのロットサイズです。',
        nr1: '採用残高比例1:1自動計算持倉倉位進行追單',
        manageFollow: '受注管理',
        followMsg: '注文をクローズしたら、コピーしたシグナルソースの未決済取引がある場合は、すべてのポジションを手動でクローズする必要があります。',
        careful: 'ご注意ください',
        endFollow: '注文を終了する',
        cancel: 'キャンセル',
        confirm: '確認事項',
        remind: 'リマインダー',
        inCash: 'デポジット',
        outCash: '退会',
        desc1: 'より大きなポジションサイズでシグナルに従いたい場合は、以下のようにお願いします。',
        desc2: '少ないポジションでシグナルを追いかけたい場合は',
        modalTitle: '注文フォローのための指示',
        modalHtml: `<div>
            1.BlaFXは、コピートレード機能を提供しています。 1人または複数のトレーダーをコピーすること、または特定の戦略を実行することを決定する場合、お客様は、資金計画を含む総合的な財務状況を考慮し、コピー取引機能の利用が非常に投機的であり、トレーダーをフォローするために使用した金額よりも大きな損失にさらされる場合があることを理解しなければなりません。 コピー取引機能に関連するリスクには、お客様の口座がお客様の手動介入なしに取引を開始および終了できる自動取引操作が含まれますが、これらに限定されません。<br /><br />
            2.自動化された取引執行およびその他の関連する取引操作は、お客様の口座において、お客様の手動によるポジションの開設および決済の介入なしに完了することができます。<br /><br />
            3.BlaFX投資顧問サービスは、お客様の選択により、特定のトレーダーやトレーダーをフォローし、特定の取引戦略に従うことを決定することができます。 かかる決定を行うにあたり、お客様は、ファイナンシャルプランニングを含むお客様の全体的な財務状況を考慮し、コピートレード機能の利用が高度に投機的であり、トレーダーをフォローするために使用した金額を大幅に上回る損失を被る可能性があることを理解しています。<br /><br />
            4.BlaFXおよびその関連会社の従業員および代理人は、投資または財務アドバイザーではありません。 お客様が当社ウェブサイト上で提供される情報またはコピートレード機能を通じてアクセスした情報に基づいて投資判断を行う場合、お客様はご自身の責任において行うものとし、BlaFX、その関連会社、その従業員および代理人は、いかなる損失に対しても責任を負わないものとします。<br /><br />
            5.投資判断は、ご自身の判断で行ってください。 投資、戦略、その他の商品またはサービスが、お客様自身の投資目的、個人的および経済的状況に照らして、お客様自身のニーズに合っているかどうか、お客様自身が独自に判断する必要があります。<br /><br />
            6.当社のウェブサイトおよびアプリに表示されるトレーダーの過去の実績、リスク率、統計およびそのトレーダーに関するすべての情報の利用は、将来のパフォーマンスを示すものではなく、仮説として扱われるべきです。<br /><br />
            7.過去のパフォーマンスとリスク率は、仮説的なパフォーマンスとして考慮されるべきです。 仮想的なパフォーマンスには多くの固有の限界があります。 いかなる口座も、過去のパフォーマンスや表示されたリスク率と同様の損益を達成する、または達成する可能性があることを表明または保証するものではありません。 実際の損益率は、取引時の口座残高（入出金）、市場動向、投資家の口座設定、注文を受けた者の実際のパフォーマンスなど、多くの要因によって投資家ごとに異なる可能性があります（ただし、これらに限定されません）。<br /><br />
            8.お客様は、お客様の口座がお客様の手動介入なしに取引を開始・終了できる自動取引操作、注文追従取引の遅延に伴う高コストの可能性、注文追従サービスの失敗の可能性など、注文追従サービスに関連するリスクを理解し承諾します。 お客様は、注文追従サービスを利用した操作の自動実行の結果、お客様が被ったすべての損失について、単独で責任を負うものとします。
        </div>`,
        modalBtn1: 'キャンセルについて',
        modalBtn2: 'アグリー',
    },
    clause: {
        caluse1: 'BETHLE ASTER COPY 取引条件',
        caluse2: 'BETHLE ASTER（以下「BlaFX」）コピー取引（以下「サービス」）は、トレーダー（以下「コピー業者」）が他のトレーダーの取引をコピーできるサービスです（ ここでは「コピー機」と呼びます) サービスであり、「レプリケーションの開始」を選択することでアクティブ化できます。',
        caluse3: '1. 複写機は、本サービスに申し込むために以下のことを行うことを約束します',
        caluse4: '1.1。 BETHLE ASTER Web サイトでクライアントとして登録およびログインします。',
        caluse5: 'アカウントにサインアップしたときにデフォルトで開く最初の MT アカウントは、デフォルトのコミッション ウォレットであり、取引と入金と送金のみが可能であり、注文に従うことはできないことに注意してください。 最大 5 つの MT アカウントを作成でき、新しい MT アカウントごとに注文、引き出し、送金、取引を個別に行うことができます。',
        caluse: '特別リマインダー',
        caluse121: '1.2.1. 保留中のアカウントまたは自己主導で取引されているアカウントがある場合、このアカウントの他の関連する資金調達機能は制限され、送金、引き出し、および新しい保留中または自己主導の取引は行われず、 このアカウントが保留中または自己主導の取引のために閉鎖されている場合は再開されます。 もちろん、取引を行っていないその他の MT アカウントは、この影響を受けません。',
        caluse122: '1.2.2. BETHLE ASTER は常に、ユーザーが意思決定を行わないリスクを軽減するよう努めています。 MT 口座にプロプライエタリ ポジションがある場合は、追加注文を行う前にプロプライエタリ オーダーをクローズする必要があります。 MT口座にフォローオンポジションがある場合。 MT 口座で取引する前に、注文のフォローを停止し、手動でポジションを閉じる必要があります。',
        caluse6: '1.3。 コピーを開始します。',
        caluse7: '2. 複写者は、次のことを行う権利があります。',
        caluse8: '2.1. 任意の数のマスターをコピーします (コピー領域には、現在のすべてのサブスクリプションが一覧表示されます)。',
        caluse9: '2.2. [注文に従う] エリアでは、いつでも 1 つの注文で取引を手動で閉じることができます (ロックアウト期間のあるシグナル ソースを除く)。',
        caluse10: '2.3. マスターから登録を解除し、[コピーの停止] をクリックしてマスターの取引のコピーを停止します。登録を解除するには、コピー機ですべての取引をクローズする必要があります。 サブスクリプションを解除すると、マスターに投資されたすべての資金と利益がコピアーズ ウォレットに返されます。',
        caluse11: '2.4. 特定のマスターごとにコピー比率のサイズを設定します。 このオプションについては、4.4 節で詳しく説明します。 これらの利用規約の。',
        caluse12: '2.5。 予想外の市場の動きから投資を保護するために、サポート ファンドを追加します。 これらの資金は、市場が変動し、利益に直接影響を与えない場合に、Copier の取引戦略をサポートするために使用されます。',
        caluse13: '2.6. 各特定のマスター トレーダーの投資操作に参加する場合、レプリケーターによる投資額の計算は、レプリケーターの資金に比例して設定されます。 これは、利用規約の第 4 条第 5 項で明示的に説明されています。',
        caluse14: '3. サービスは Metatrader 4 プラットフォームで利用できます。',
        caluse15: '4.コピーされた取引ルーチンを開く',
        caluse16: '4.1. コピー機は、サービス内でのマスターのサブスクリプション後にマスターによって開かれた取引のみをコピーします。',
        caluse17: '4.2. ストップ ロス/テイク プロフィット注文はコピー エリアでは表示されませんが、これらの注文がマスターのアカウントでトリガーされた場合、コピーされた取引もクローズされます。',
        caluse18: '4.3. マスターへのサブスクリプション時に、コピアは、ウォレットから差し引かれ、選択されたマスターに投資される資金の額を指定します。 マスターのコピーを停止すると、これらの資金と利益はウォレットに返されます。',
        caluse19: '4.4. レプリケーターは、次のアカウントと購読しているシグナル ソース マスター トレーダー アカウントの資金に比例して、マスター トレーダーの取引を複製します。',
        caluse20: '4.4.1. 比率オプションでは、Trader Master トレーダーとフォロワーのレバレッジ比率と純資産に基づいて取引量が計算されます。 使用した計算式は以下の通りです。',
        caluse21: 'ボリューム (フォロワー) = ネット (フォロワー) / ネット (マスター トレーダー) × レバレッジ (フォロワー) / レバレッジ (マスター トレーダー) × ボリューム (マスター トレーダー)。 たとえば、Master Trader の純資産は $2,000 で、レバレッジは 1:200 です。 フォロワーの純資産は $200 で、レバレッジは 1:500 です。 マスター口座で 1 ロットが開かれます。 すると、後続の取引量は 200/2000 x 500/200 x 1 = 0.25 ロットとなります。',
        caluse22: '4.5. 複写機のレバレッジ比率は 1:100 に設定されています。 調整を希望する複写機は、BETHLE ASTER のカスタマー サポートに連絡する必要があります。',
        caluse24: '4.6. サービスが有効になると、アカウントの所有者がサインインしているかどうかに関係なく、取引がコピー者のアカウントにコピーされます。',
        caluse25: '4.7. Copier の注文は、Master のアカウントで行われた注文 (Buy または Sell) に従って実行されます。 マスターが注文を出すと、注文を開くための信号がコピー機のアカウントでトリガーされます。 コピー機の注文は市場価格で実行されます。 同じメカニズムが注文の決済をトリガーします。 したがって、これらの注文の約定価格は異なる場合があります。 さらに、このマスターに続くコピアの数が実行時間に影響を与える可能性があります。',
        caluse26: '5.適用される制限',
        caluse27: '5.1. コピーされた取引の最小量は 0.01 ロットで、コピーされた取引の最大量は 100 ロットです。',
        caluse28: '5.2. コピーされた最小注文量は 0.01 ロットです。 ただし、0.005 ロット未満のコピー注文は拒否され、0.005 ロット以上のコピー注文は 0.01 ロットに丸められます。',
        caluse29: '5.3 任意の注文のボリュームは、最も近い小数点第 100 位 (小数点以下の 2 桁目) に丸められます。 たとえば、Copier が 0.324 ロットの注文をコピーする場合、注文は 0.32 ロットに切り捨てられます。 逆に、Copier が 0.325 ロットの注文をコピーする場合、注文は 0.33 ロットに切り上げられます。',
        caluse30: '5.4. 計算後にコピーされた取引量が 100 ロットを超える場合、その取引は Copier アカウントで開かれません。',
        caluse31: '6. マスターが (預金または引き出しを行うことによって) エクイティまたはレバレッジを変更した場合、コピーされたすべての取引はコピー者のアカウントで最初のボリュームを維持します。',
        caluse32: '7. Copier のすべての取引条件 (レバレッジ、スワップ、スプレッド) は、BETHLE ASTER MT4 アカウントの条件と同様です。',
        caluse33: '8. Master Trader アカウントは MT4 Micro タイプでなければなりません。',
        caluse34: '9. 本サービスは、次のことを行う権利を有します。',
        caluse35: '9.1. マスターが独自の裁量で、事前の通知なしにいつでも作成できるマスター アカウントの数を制限します。',
        caluse36: '9.2. 事前の通知なしに、コピー機をマスターから登録解除します。',
        caluse37: '9.3. マスター トレーダーが設定したコミッションを引き下げ、事前の通知や説明なしに、すべてのマスター アカウントの最大値を制限する。',
        caluse38: '9.4. 複写者またはマスター トレーダーへの事前の通知なしに、いつでもこれらの利用規約を修正してください。 このような修正は、本利用規約のサービス サイトに公開された時点で有効になります。',
        caluse39: '9.5. プラットフォームでのマスター トレーダーの活動を監視し、マスター トレーダーが統計を操作している場合は、マスター トレーダーのアカウントに「疑わしい活動」の警告をマークし、デフォルトでフィルタリングされたマスター レーティングからそのようなアカウントを除外します (変更するコピー者が利用できるようにします)。 それに応じてフィルター設定を行います)。',
        caluse40: '9.6 かかるマスターが他のマスターのニックネームおよび (または) 画像を故意にコピーまたは模倣し、マスターの不正な表現につながる可能性があるとサービスが合理的に疑う場合、マスターのニックネームおよび (または) ユーザーの写真を事前の通知なしに修正または削除する。',
        caluse41: '10. マスター トレーダーは、次の注文の手数料額を決定します。 手数料は、発注された注文からの利益の 0% から 50% の範囲です。 手数料は、各取引がクローズされた後、マスター トレーダーの電子ウォレットに支払われます。',
        caluse42: '11. コピー機がマスターに支払うコミッション額は、コピー機が「コピー開始」を押した時点で設定されます。マスターがコミッション額を変更した場合、このサブスクリプションに基づいてマスターに支払うべき金額には影響しません。',
        caluse43: '12. Copier の取引統計は、Copier のみが閲覧できます。',
        caluse45: '13. マスターの取引統計は公開されています。',
        caluse46: '14. コピー機は取引端末にアクセスできません。 サブスクリプションと取引に関するすべてのアクションは、コピー エリアの下で行われます。',
        caluse47: '15. サービスが、コピー機が顧客契約またはコピー機の居住国の法律に基づいて設定された入出金規則に違反したことを合理的に疑う場合、サービスは、そのようなコピー機へのサービスの提供を一時停止する権利を有します。',
        caluse48: '16. マスターは、有利な取引と不利な取引の両方を行うことができることに注意してください。',
        caluse49: 'BETHLE ASTER は、これらの条件を最終的に解釈する権利を留保します。',
        caluse50: '',
        caluse51: '',
        caluse52: '',
        caluse53: '',
        caluse54: '',
        caluse55: '',
        caluse56: '',
        caluse57: '',
        caluse58: '',
        caluse67: '',
        caluse68: '',
        caluse69: '',
        caluse70: '',
        caluse71: '',
        caluse72: '',
    },
    join: {
        copy: 'プロトレーダーの成功を再現して利益を得る',
        wenan: 'BlaFX-FXのコピートレードは、お客様がご自身の取引戦略の開発に長い時間を費やすことなく、一流のトレーダーの戦略を自動的にコピーする機会を提供します。最高のFX取引の達人から選択し、あなたの取引ポートフォリオを多様化しましょう。',
        join: 'Copytradingの追加',
        become: 'あるいは、マスタートレーダーになる',
        way: '仕組み',
        creat: 'アカウントを作成し、入金する',
        simpleway: '簡単な手順で登録し、お好きな支払い方法でウォレットへの入金を処理します。 ご自身のBlaFX-FX取引口座にすでに資金がある場合、内部振替を利用して口座からウォレットに資金を追加することができます。 ウォレット残高には、投資していない資金が表示されます。',
        followbest: 'ベストトレーダーをフォローする',
        followandcopy: 'フォローしたいグルを見つけて、「コピー」をクリックします。その位置は自動的にコピーされます。預け入れ割合の設定は、ポートフォリオの管理に役立ちます。いろいろな戦略を真似てみて、ベストなものを決めましょう',
        moandprofit: 'モニタリングと収益性！',
        copycopy: 'コピーできるマスタートレーダーの数に制限はありませんので、バランスよく分散されたトレードのポートフォリオを作成し、安定した収入を得ることができます。また、お客様はプロセス全体を完全に制御することができ、いつでもコピートレードを修正/停止することができます。コピーしたマスタートレーダーの詳細な取引データは、コピー機エリアで確認することができます。',
        followprofitbest: '最も収益性の高いトレーダーをフォローする',
        noneed: 'FXの専門家である必要はありません',
        manyways: '分散されたポートフォリオによる安定したリターン',
        new: 'この新しいツールは、経験豊富なトレーダーの真似をすることで、より安定したリターンを得ることができるようになります。。',
        learnbest: 'ベストトレーダーから学ぶ',
        easytoin: 'FX初心者にもわかりやすいように',
        spread: '分散されたポートフォリオ',
        choosebig: 'マスタートレーダーを選び、大勢の専門家に従う。',
        enjoyfast: '迅速な注文処理',
        infive: '注文はポジション開設後5分以内に執行されます。',
        inhand: 'すべてをコントロールする',
        anytimestop: 'コピーの停止、購読、トランザクションの終了はいつでも可能です。',
        easyway: ' 簡単な手順ですぐに実行可能',
        noneedother: '追加のバリデーションは必要ありません。',
        safe: '安全かつ迅速な投資・出金',
        mangmain: '主要な支払方法を幅広く選択可能。',
        alluseful: 'モバイルアプリでトランザクションを再現するあらゆる利点を享受できる',
        foces: '使いやすいBlaFX-FXコピートレーディングアプリで投資に集中する',
        manage: '移動中にポートフォリオや投資をコントロール',
        follow: 'スマートな投資のためのマスタートレーダーとそのリアルタイムのパフォーマンスを追跡する。',
        seerisk: '資金の運用状況をリアルタイムで確認し、リスクを管理することができます。',
        howto: 'フォローするマスタートレーダーはどのように選べばよいのでしょうか？',
        firstcheck: 'まず、トレーダーのマスタートレーダー・データを確認します。 リスクスコア、リターン、損益、フォロワー数、手数料、注文履歴などの統計情報が含まれており、十分な情報に基づいて判断することができます。 注文の追跡を開始するには、アカウントに入金してください。',
        anyuseful: 'どのようなフォローアップのオプションがあるか？',
        ways: 'トレーダーを申し込む際に、コールパーセンテージの指定と、サポート資金を追加するかどうかの判断が必要です。 通話開始を押すと、ウォレットから資金が引き落とされ、通話が開始されます。 マスタートレーダーは、同時に何人でも加入することができます。',
        did: '注文に従うと手数料がかかるのですか？',
        didas: 'お客様のブローカーとして、手数料はいただきません。 お客様は、あらかじめ個別に定められた手数料をMaster Traderに支払うだけです。 各マスタートレーダーの統計には、手数料がいくらなのかが表示されています。',
        howstop: 'マスタートレーダーへの追従をやめるには？',
        anytimecancle: 'お客様は、いつでもトレーダー・トレーダーの購読を中止し、注文の追跡を停止することができます。 解約時には、トレーダー・トレーダーに投資した資金とフォローオーダーで得た利益は、すべてお客様のウォレットに入金されます。',
        howprofit: '利益の引き出し方法？',
        wallet: 'ウォレットから引き出すことができます。 トレーダー・トレーダーの購読を終了し、投資額と利益をウォレットに移す。 損失が発生した状態でトレーダー・トレーダーの購読を終了した場合、初期投資額を回収することはできませんので、ご注意ください。 損失額は、初期投資額から差し引かれます。',
        getprofit: 'マスタートレーダーになり、安定したリターンを得る。',
        becomaster: 'マスタートレーダーになる',
        rules: 'ご利用条件'
    },
    balance: {
        orderid: '注文番号',
        opentime: '開場時間',
        balantime:'ざんりゅうじかん',
        type: 'タイプ',
        profit: 'メリット',
    },
    close: {
        openprice: 'オープニング価格',
        hand: '針数',
        symbol: '取引品種',
        closetime: 'クローズアウトタイム',
        closeprice: 'クローズアウト価格',
        comission: '取扱手数料',
        storage: '棚卸資産手数料',
        profit: 'メリット',
        nowprice: '現在の価格'
    },
    joinmaster: {
        good: 'トレードが得意な方 あなたのトレードに付いてきてもらい、その結果、お金を稼ぐことができる。',
        one: '通常の取引を行いながら、他のトレーダーがあなたの真似をして副収入を得る。マスタートレーダーのプロフィールには、あなたのトレードの日間および月間パフォーマンスが表示されます。',
        become: 'マスタートレーダーになる',
        runway: '仕組み',
        creat: 'マスタートレーダー口座の開設',
        click: 'Master Traderエリアをクリックし、Master Traderアカウントを作成 - 新規にアカウントを開くか、既存のアカウントをMaster Traderアカウントに割り当てます。',
        accountsetting: 'アカウント設定',
        beready: 'マスタートレーダー口座の準備：手数料の金額を設定し、ストラテジーを記述します。',
        use: 'トレーダーズコーナーでは、取引の詳細データの閲覧、口座設定の変更、獲得した手数料の確認ができます。',
        offer: 'BlaFX-FX COPYTRADINGはあなたに追加の収入源を与えます:マスタートレーダー口座を開設し、あなたの戦略を説明し、そして他の人があなたの取引をコピーするための手数料を設定します。',
        different: 'ストラテジーごとに異なるマスタートレーダー口座の作成',
        detail: 'マスタートレーダー領域で注文の詳細を追跡し、マスタートレーダー手数料を得ることができます。',
        enjoy: 'BlaFX-FXに追加手数料を支払うことなく、様々な特典を享受できます。',
        android: 'Android用BlaFX-FX取引アプリのMaster Traderセクションにいます。',
        mobile: 'モバイルでマスタートレーダーのアカウントを作成する',
        anywhere: 'どこにいても自分のレプリケーターの統計やコミッションを見ることができます。',
        now: '新規フォロワーの複製条件をオンサイトで管理します。',
        how: ' マスタートレーダーになるには？',
        get: 'MT4口座をお持ちのBlaFX-FXのお客様であれば、どなたでもマスタートレーダーになることができます。10万円を入金し、3ヶ月間取引して安定した利益を出すことでカスタマーサービスに応募することができます。',
        howmanage: 'レプリケーターに請求する手数料の金額を調整する方法？',
        go: 'マスタートレーダー領域で設定を確認し、スライダーで手数料を調整した後、保存してください。 新手数料は、調整後に加入したレプリケーターにのみ適用されます。それ以外のリプリケータについては、手数料の金額は変わりません。',
        time: 'レプリケーターからコミッションが支払われるのはいつですか？',
        american: '毎週日曜日、米国時間午後6時にお支払い。',
        when: 'レプリケーターに手数料を請求するタイミング？',
        comission: 'クローズドオーダーの手数料は毎週土曜日に請求されます。',
        whencanget: 'コミッションを得ることができる時期？',
        wallet: '電子ウォレットに手数料を振り込みます。 ウォレットから、どの取引口座にも資金を追加したり、資金を引き出したりすることができます。',
        copy: '独自の取引戦略を長時間かけて開発することなく、一流トレーダーの戦略を自動的に再現することができます。',
        or: 'レプリケーターになることも',
    },
    other: {
        signalaccount: '信号機アカウント',
        signalname: '信号のニックネーム',
        wallet: 'ウォレット資金',
        hold: '支援金額',
        need0: '必要投資額の0%',
        zj: '概要',
        follow: '注文倍率',
        followmoney: 'フォローアップ費用',
        begin: '次の順序で開始します。',
        hand: 'ハンド',
        more: '25以上',
        choose: '選択してください',
        notlogin: 'まだサインインしていません',
        byhand: 'ロット',
        byprofit: '利益',
        byleft: '残高',
        bylock: '倉庫をロックします',
        day: '日',
        sure: '確認',
        not: 'トランザクションをコピーしていません',
        receiptaccount: '口座開設',
        balance: '利用可能な残高',
        nodata: '現時点ではデータがありません',
        notlogin1: 'ログインしていません',
        socity: '社交的',
        dl: 'ディーラープログラム',
        help: 'ヘルプセンター',
        privicy: 'プライバシーポリシー',
        nochoose: 'オプションなし',
        nonum: '有効なコピーアカウントを開設していません'
    },
    explain: {
        hand: '固定料金制で課金。 ロットあたり1ドルと仮定すると、あなたのフォロワー口座に10ロットを配置した場合、10ドルは、あなたのアカウントから信号源口座に取られます。',
        left: '課金は、フォロワー口座の残高に対する割合で行われます。 残高の0.2%の手数料がかかると仮定すると、あなたのフォロワー口座の残高は1万ドルで、20ドルがあなたの口座から信号源口座に取られることになります。',
        profit: '料金はコピーされた注文の利益のパーセンテージとして取得され、利益のない料金は発生しません。利益の5%が請求され、コピーした注文アカウントが注文で1000ドルの利益を上げた場合、50ドルがアカウントからシグナルソースアカウントに差し引かれるとします。',
        lock: '料金はロックアウト期間中の総利益の割合に基づき、利益は請求されない。 利益総額に8％の手数料がかかると仮定すると、ロックイン期間中のフォロー口座の利益総額は2000ドルで、160ドルがあなたの口座からシグナルソース口座に取られることになります。',
        fake: '前提：レベル1紹介：30%、レベル2紹介：20%、レベル3紹介：10%、AはBを、BはCを、CはDを紹介し、ユーザーDが100ドルのフォローアップコミッションが発生する注文をした場合、Aには10ドル、Bには20ドル、Cには30ドル支払われる。',
        said: 'クラウドブリッジでは、多くのフォロワーがコピーコミッションの利益の70%以上を還元しており、中には90%に達するフォロワーもいます。',
        byhand: '手書きで',
        byleft: 'バランスによる',
        byprofit: '利益による',
        bylock: 'ロックアップ期間別利益'
    },
    common: {
        title: 'BlaFX フォローオン取引',
        update: 'ウェブサイトはリニューアル中ですので、ご不便をおかけいたしますのでご了承ください。',
        back: '帰る',
        nosm: '実名認証を完了してから行ってください。',
        fail: '操作に失敗しました',
        rz: '認定に進む',
        ok:'手術は成功した。'
    }
}
