export const lang = {
    home: {
        countLogin: 'เข้าสู่ระบบบัญชี',
        transaction: 'แพลตฟอร์มการซื้อขาย',
        traders: 'เมตาเทรดเดอร์ 4',
        tradersOnline: 'Metatrader 4 - การซื้อขายออนไลน์',
        plan: 'โปรแกรมแนะนําเพื่อน',
        copy: 'คัดลอกการซื้อขาย',
        aboutCopier: 'สําหรับสําเนา',
        joinCopy: 'เข้าร่วมคัดลอกการซื้อขาย',
        getLevel: 'การจัดอันดับของผู้เชี่ยวชาญด้านการซื้อขาย',
        aboutTeacher: 'สําหรับผู้เชี่ยวชาญด้านการซื้อขาย',
        beTeacher: 'มาเป็นเทรดเดอร์หลัก',
        msg: 'ข้อมูล',
        clause: 'ข้อกําหนดและเงื่อนไข',
        aboutUs: 'เกี่ยวกับเรา',
        news: 'ข่าวบริษัท',
        problem: 'คําถามที่พบบ่อย',
        score: 'กลวิธี',
        follow: 'การจําลองแบบ',
        clause2: 'ข้อกําหนดและเงื่อนไข',
        login: 'เข้าสู่ระบบ',
        email: 'ตู้จดหมาย',
        password: 'รหัสผ่าน',
        forgetPassword: 'ลืมรหัสผ่าน？',
        code: 'แคปต์ชา',
        login2: 'เข้าสู่ระบบ',
        register: 'ลงทะเบียน',
        google: 'กูเกิลแคปต์ชา',
        qlgoogle: 'โปรดป้อนรหัสยืนยันของ Google',
        center: 'ศูนย์ส่วนบุคคล',
        quit: 'ลงชื่อออก',
        quitMsg: 'เข้าสู่ระบบไม่ถูกต้อง กรุณาเข้าสู่ระบบอีกครั้ง',
    },
    message: {
        choose: 'ทําไมต้องเลือก',
        changeMarket: 'ตลาดซื้อขาย',
        changeTool: 'แพลตฟอร์มและเครื่องมือการซื้อขาย',
        community: 'BlaFX',
        companies: 'คู่ ค้า',
        supervise: 'กฎระเบียบของฉัน',
        VIP: 'บริการวีไอพีระดับพรีเมียม',
        active: 'โปรแกรมผู้ซื้อขายที่ใช้งานอยู่',
        out: 'การซื้อขายฟอเร็กซ์',
        contract: 'ดัชนี CFD',
        goods: 'สินค้า โภคภัณฑ์',
        CFD: 'CFD ของดัชนีสกุลเงิน（CFD）',
        money: 'สเปรด สวอป และคอมมิชชั่น',
        time: 'ชั่วโมงการซื้อขาย',
        count: 'บัญชีเทรด',
        inAndOut: 'การฝากและถอนเงิน',
        begin: 'เริ่มต้นตอนนี้เลย',
        MetaTrader: 'MetaTrader 4',
        MetaTraderChange: 'MetaTrader 4-การซื้อขายออนไลน์',
        study: 'เรียนรู้การเทรด',
        news: 'ข้อมูลทางเศรษฐกิจ',
        com: 'ชุมชนสารคดีสะพานเมฆ',
        proxy: 'บริการตัวแทน',
        friend: 'โปรแกรมแนะนําเพื่อน',
        title: 'บริการที่เรามีให้รวมถึงการซื้อขายผลิตภัณฑ์ OTC และผลิตภัณฑ์แลกเปลี่ยนเงินตราต่างประเทศและมีความเสี่ยงที่การสูญเสียจะมากกว่าการอัดฉีดเงินทุนครั้งแรก ผลิตภัณฑ์เหล่านี้อาจไม่เหมาะสําหรับนักลงทุนทุกคนดังนั้นโปรดตรวจสอบให้แน่ใจว่าคุณเข้าใจความเสี่ยงที่เกี่ยวข้องอย่างถ่องแท้และขอคําแนะนําจากบุคคลที่สามหากจําเป็น',
        p1: 'คําเตือนความเสี่ยง: การซื้อขาย CFD และ Forex มีความเสี่ยง ไม่ใช่สําหรับทุกคนและการสูญเสียของคุณอาจมากกว่าการลงทุนครั้งแรกของคุณ คุณไม่ได้เป็นเจ้าของหรือสิทธิ์ในสินทรัพย์อ้างอิง ผลการดําเนินงานในอดีตไม่ได้บ่งบอกถึงผลการดําเนินงานในอนาคตและกฎหมายภาษีอาจมีการเปลี่ยนแปลง ข้อมูลในเว็บไซต์นี้มีลักษณะทั่วไปและไม่คํานึงถึงวัตถุประสงค์ส่วนบุคคลสถานการณ์ทางการเงินหรือความต้องการของคุณหรือลูกค้าของคุณ โปรดอ่านประกาศเกี่ยวกับการเปิดเผยความเสี่ยงและเอกสารทางกฎหมายอื่น ๆ ของเราก่อนตัดสินใจซื้อขายเพื่อให้แน่ใจว่าคุณเข้าใจความเสี่ยงอย่างถ่องแท้ เราขอแนะนําให้คุณขอคําแนะนําที่เป็นอิสระ',
        p2: 'BlaFX Capital PTY LTD ตั้งอยู่ที่ห้องสวีท 280 156 Burwood Road Bureood, NSW 2134 ออสเตรเลีย ได้รับอนุญาตและควบคุมโดยสํานักงานคณะกรรมการกํากับหลักทรัพย์ของออสเตรเลีย ข้อมูลในเว็บไซต์นี้และผลิตภัณฑ์และบริการที่นําเสนอไม่ได้มีไว้สําหรับการตลาดกับทุกคนในประเทศหรือเขตอํานาจศาลใด ๆ',
        p3: ' © 2020 BlaFX Capital PTY LTD| เลขทะเบียนบริษัท 661 715 966 | AFS - 001298638'
    },
    score: {
        invested: 'คุณได้ลงทุนหรือไม่？',
        signUp: 'ลงทะเบียนและเริ่มคัดลอกเทรดเดอร์ต้นแบบโดยพลการเพื่อเพิ่มผลกำไรที่สูงขึ้นและมั่นคงสำหรับการลงทุนของคุณ',
        score: 'คะแนนผู้เชี่ยวชาญ',
        traders: 'เทรด เดอร์',
        agency: 'ค่าคอมมิชชั่นพันธมิตร%',
        risk: 'คะแนนความเสี่ยง',
        gain: 'ยอม',
        cycle: 'ระยะเวลาการป้องกันความเสี่ยง',
        remainingCycle: 'รอบที่เหลือ',
        remaining: 'ขนาดรวม',
        amount: 'เครดิตคงเหลือ',
        profit: 'กําไรและขาดทุน',
        copiers: 'ตัวจําลอง',
        commission: 'คัดลอก % ค่าคอมมิชชั่น',
        winner: 'ผู้ชนะอันดับหนึ่ง',
        welcome: 'เทรดเดอร์ที่ได้รับความนิยมมากที่สุด',
        weeks2: '2 สัปดาห์',
        month1: '1 เดือน',
        month3: '3 เดือน',
        month6: '6 เดือน',
        all: 'ทั้งหมด',
        legend: 'ตำนาน',
        expert: 'ผู้เชี่ยวชาญ',
        success: 'แชมป์',
        gUp: 'พัฒนาการ',
        newBlood: 'ไก่อ่อน',
        showFrist: 'วัตถุจะแสดงขึ้นก่อน',
        rangTime: 'ช่วงเวลา',
        mixBalance: 'การลงทุนขั้นต่ํา',
        mixknow: 'ความเชี่ยวชาญขั้นต่ํา',
        nickName: 'ชื่อเล่น',
        null: 'ไม่',
        nolimit: 'ไม่มีที่สิ้นสุด'
    },
    detail: {
        riskScore: 'คะแนนความเสี่ยง',
        equity: 'อิควิตี้ของบัญชี',
        followVal: 'คัดลอกคอมมิชชั่น',
        regDate: 'ชั่วโมงการซื้อขาย',
        followsetting: 'การตั้งค่าการทําสําเนา',
        mintz: 'การลงทุนขั้นต่ํา',
        tradingStrategyString: 'คําอธิบายนโยบาย',
        tradingStrategyString1: 'คําอธิบายกรณีนโยบาย',
        followway: 'คัดลอกกลยุทธ์',
        bx: 'การสําแดง',
        onemonth: '1 เดือน',
        threemonth: '3 เดือน',
        sixmonth: '6 เดือน',
        all: 'ทั้งหมด',
        profit: 'กําไร',
        copiers: 'ตัวจําลอง',
        profitandloss: 'กําไรและขาดทุน',
        accountdetail: 'รายละเอียดบัญชี',
        folatprofit: 'ลอย',
        balance: 'ยอดคงเหลือ',
        givegold: 'โบนัส',
        lever: 'ชะแลง',
        riskmanage: 'การบริหารความเสี่ยง',
        maxprofit: 'การสูญเสียลอยตัวสูงสุด',
        maxmax: 'ระยะเวลาการเบิกถอนสูงสุด',
        active: 'กิจกรรม',
        transferhistory: 'ประวัติการซื้อขาย',
        historyorder: 'คําสั่งทางประวัติศาสตร์',
        holdorder: 'เปิดคําสั่งซื้อขาย',
        leftoperration: 'การดําเนินการปรับสมดุล',
        limitorder: 'จํากัดคําสั่งซื้อ',
        minfollow: 'จํานวนสําเนาขั้นต่ํา',
        nr: 'สมมติว่ายอดคงเหลือของบัญชีสัญญาณคือ 100,000 USD ยอดคงเหลือในบัญชีสําเนาของคุณคือ 10,000 USD บัญชีสัญญาณวางคําสั่งซื้อ 10 รายการและบัญชีคัดลอกของคุณวาง 1 ล็อต สมมติว่ายอดคงเหลือของบัญชีสัญญาณคือ 20,000 USD ยอดคงเหลือในบัญชีสําเนาของคุณคือ 200,000 USD บัญชีสัญญาณวางคําสั่งซื้อ 1 ล็อตและบัญชีของคุณวาง 10 ล็อต สูตรการคํานวณคือ: ขนาดล็อตคําสั่งคัดลอกของคุณ = ยอดเงินในบัญชีคัดลอกของคุณ÷ ยอดเงินในบัญชีแหล่งสัญญาณ x ขนาดล็อตบัญชีต้นทางสัญญาณ',
        nr1: 'อัตราส่วนยอดคงเหลือ 1: 1 ใช้เพื่อคํานวณตําแหน่งของตําแหน่งสําหรับการคัดลอกโดยอัตโนมัติ',
        manageFollow: 'การจัดการสารคดี',
        followMsg: 'หลังจากสิ้นสุดสารคดีแหล่งสัญญาณที่คุณคัดลอกหากมีการซื้อขายที่เปิดอยู่คุณจะต้องปิดโพซิชั่นที่เปิดอยู่ทั้งหมดของแหล่งสัญญาณนั้นด้วยตนเอง',
        careful: 'โปรดทราบ',
        endFollow: 'จบสารคดี',
        cancel: 'ยกเลิก',
        confirm: 'ยืนยัน',
        remind: 'แจ้งเตือน',
        inCash: 'ลงทะเบียน',
        outCash: 'ออกเงิน',
        desc1: 'หากคุณต้องการติดตามสัญญาณที่มีตำแหน่งมากขึ้นโปรด',
        desc2: 'หากคุณต้องการติดตามสัญญาณที่มีตำแหน่งน้อยกว่าโปรด',
        modalTitle: 'หมายเหตุสารคดี',
        modalHtml: `<div>
            1. BlaFX มีฟังก์ชั่นคัดลอกการซื้อขาย เมื่อตัดสินใจที่จะคัดลอกผู้ค้าอย่างน้อยหนึ่งรายหรือใช้กลยุทธ์เฉพาะคุณต้องพิจารณาสถานการณ์ทางการเงินโดยรวมของคุณรวมถึงการวางแผนทางการเงินและเข้าใจว่าการใช้คุณสมบัติการคัดลอกการซื้อขายเป็นการเก็งกําไรอย่างมากและคุณอาจต้องเผชิญกับการสูญเสียที่ใหญ่กว่าจํานวนเงินที่ใช้สําหรับผู้ค้าคัดลอก ความเสี่ยงที่เกี่ยวข้องกับคุณสมบัติการคัดลอกการซื้อขายรวมถึง แต่ไม่ จํากัด เพียงการดําเนินการซื้อขายอัตโนมัติเช่นบัญชีของคุณสามารถเริ่มและปิดการซื้อขายได้โดยไม่ต้องมีการแทรกแซงด้วยตนเอง<br /><br />
            2. การดําเนินการธุรกรรมอัตโนมัติและการดําเนินการซื้อขายอื่น ๆ ที่เกี่ยวข้องสามารถทําธุรกรรมการเปิดและปิดตําแหน่งในบัญชีของคุณได้โดยไม่ต้องดําเนินการด้วยตนเอง<br /><br />
            3. บริการจัดการการลงทุนของ BlaFX เป็นทางเลือกของคุณเองและคุณตัดสินใจที่จะคัดลอกผู้ค้าเฉพาะหรือผู้ค้าหลายรายและปฏิบัติตามกลยุทธ์การซื้อขายเฉพาะ ในการตัดสินใจเหล่านี้คุณได้พิจารณาสถานการณ์ทางการเงินโดยรวมของคุณรวมถึงการวางแผนทางการเงินและเข้าใจว่าการใช้คุณสมบัติการคัดลอกการซื้อขายเป็นการเก็งกําไรสูงและคุณอาจขาดทุนมากกว่าจํานวนเงินที่คุณจะใช้ในการคัดลอกเทรดเดอร์<br /><br />
            4. ฟังก์ชั่นคัดลอกการซื้อขายที่เรามีให้ใช้สําหรับการอ้างอิงเท่านั้น พนักงานและตัวแทนของ BlaFX และบริษัทในเครือไม่ใช่ที่ปรึกษาการลงทุนหรือที่ปรึกษาทางการเงิน หากคุณตัดสินใจลงทุนตามข้อมูลที่ให้ไว้ในเว็บไซต์ของเราหรือได้รับผ่านฟังก์ชั่นคัดลอกการซื้อขายคุณต้องยอมรับความเสี่ยงของคุณเองและ BlaFX และ บริษัท ในเครือพนักงานและตัวแทนจะไม่รับผิดชอบต่อความสูญเสียใด ๆ<br /><br />
            5. คุณควรตัดสินใจลงทุนด้วยตัวเอง คุณควรตัดสินอย่างอิสระว่าการลงทุนกลยุทธ์หรือผลิตภัณฑ์และบริการอื่น ๆ เหมาะสมกับความต้องการของคุณเองตามวัตถุประสงค์การลงทุนของคุณเองและสถานการณ์ส่วนตัวและการเงิน<br /><br />
            6.การใช้ผลการดําเนินงานในอดีตอัตราส่วนความเสี่ยงข้อมูลทางสถิติและข้อมูลทั้งหมดเกี่ยวกับเทรดเดอร์ที่แสดงบนเว็บไซต์และแอปพลิเคชันของเราไม่ได้บ่งบอกถึงผลการดําเนินงานในอนาคตและควรถือเป็นสมมติฐาน<br /><br />
            7. ผลการดําเนินงานในอดีตและอัตราส่วนความเสี่ยงควรได้รับการพิจารณาว่าเป็นผลการดําเนินงานตามสมมุติฐาน สมมติว่าประสิทธิภาพมีข้อ จํากัด โดยธรรมชาติมากมาย ไม่มีการรับรองหรือรับประกันว่าบัญชีใด ๆ จะหรือมีแนวโน้มที่จะทํากําไรหรือขาดทุนคล้ายกับผลการดําเนินงานในอดีตหรืออัตราส่วนความเสี่ยงที่แสดง เปอร์เซ็นต์กําไร/ขาดทุนที่แท้จริงอาจแตกต่างกันไปในแต่ละนักลงทุน ขึ้นอยู่กับปัจจัยหลายประการ ซึ่งรวมถึงแต่ไม่จํากัดเพียง: ยอดคงเหลือในบัญชี (การฝากและถอนเงิน) ณ เวลาทําการซื้อขาย พฤติกรรมของตลาด การตั้งค่าบัญชีของนักลงทุน และประสิทธิภาพที่แท้จริงของบุคคลที่จะคัดลอก<br /><br />
            8. คุณเข้าใจและยอมรับความเสี่ยงที่เกี่ยวข้องกับบริการคัดลอกการซื้อขายรวมถึง แต่ไม่ จํากัด เพียงการดําเนินการซื้อขายอัตโนมัตินั่นคือบัญชีของคุณสามารถเริ่มต้นและสิ้นสุดการทําธุรกรรมโดยไม่ต้องมีการแทรกแซงด้วยตนเองความล่าช้าของการคัดลอกการซื้อขายอาจมีค่าใช้จ่ายสูงและบริการคัดลอกการซื้อขายอาจล้มเหลว คุณจะต้องรับผิดชอบแต่เพียงผู้เดียวสําหรับความสูญเสียใด ๆ และทั้งหมดที่เกิดขึ้นอันเป็นผลมาจากการดําเนินการอัตโนมัติของคุณโดยใช้บริการซื้อขาย MAM
        </div>`,
        modalBtn1: 'การยกเลิก',
        modalBtn2: 'ความยินยอม',
    },
    clause: {
        caluse1: 'ข้อกำหนดและเงื่อนไขการซื้อขายสำเนาของ BETHLE ASTER',
        caluse2: 'BETHLE ASTER (ต่อไปนี้เรียกว่า "BlaFX") การคัดลอกการซื้อขาย (ต่อไปนี้เรียกว่า "บริการ") เป็นบริการที่ช่วยให้ผู้ค้า (ต่อไปนี้เรียกว่า "ผู้คัดลอก") เพื่อคัดลอกธุรกรรมจากผู้ค้ารายอื่น ( ต่อไปนี้เรียกว่าบริการ "เครื่องถ่ายเอกสาร") และเปิดใช้งานได้โดยเลือก "เริ่มการจำลองแบบ"',
        caluse3: '1. ผู้คัดลอกตกลงที่จะปฏิบัติดังต่อไปนี้เพื่อสมัครใช้บริการ',
        caluse4: '1.1. ลงทะเบียนและเข้าสู่ระบบในฐานะลูกค้าบนเว็บไซต์ BETHLE ASTER',
        caluse5: 'โปรดทราบว่าบัญชี MT แรกที่คุณเปิดตามค่าเริ่มต้นเมื่อคุณสมัครใช้งานบัญชีคือกระเป๋าเงินค่าคอมมิชชันเริ่มต้น ซึ่งสามารถซื้อขาย ฝากและโอนเงินได้เท่านั้น แต่ไม่สามารถทำตามคำสั่งได้ คุณสามารถสร้างบัญชี MT ได้สูงสุด 5 บัญชี และบัญชี MT ใหม่แต่ละบัญชีจะสามารถสั่งซื้อ ถอนเงิน โอน และซื้อขายได้อย่างอิสระ',
        caluse: 'คำเตือนพิเศษ',
        caluse121: '1.2.1. หากมีบัญชีที่เทรดในบัญชีที่รอดำเนินการหรือกำกับตนเอง ฟังก์ชันการระดมทุนอื่นๆ ที่เกี่ยวข้องของบัญชีนี้จะถูกจำกัด จะไม่มีการโอน ถอนออก และการซื้อขายใหม่ที่ค้างอยู่หรือกำกับตนเองจะทำได้ และจะมีเพียง ดำเนินการต่อหากบัญชีนี้ถูกปิดสำหรับการซื้อขายที่รอดำเนินการหรือการซื้อขายด้วยตนเอง แน่นอนว่า บัญชี MT อื่นๆ ของคุณที่ไม่ได้ซื้อขายด้วยวิธีใดๆ จะไม่ได้รับผลกระทบจากสิ่งนี้',
        caluse122: '1.2.2. BETHLE ASTER มุ่งมั่นที่จะลดความเสี่ยงของการไม่ตัดสินใจโดยผู้ใช้ของเรา หากบัญชี MT ของคุณมีตำแหน่งที่เป็นกรรมสิทธิ์ คุณจะต้องปิดคำสั่งที่เป็นกรรมสิทธิ์ของคุณก่อนจึงจะสามารถวางคำสั่งตามมาได้ หากบัญชี MT ของคุณมีตำแหน่งที่ตามมา คุณต้องหยุดทำตามคำสั่งและปิดสถานะของคุณด้วยตนเองก่อนจึงจะสามารถซื้อขายในบัญชี MT ของคุณได้',
        caluse6: '1.3. เริ่มต้นการคัดลอก',
        caluse7: '2. ผู้คัดลอกมีสิทธิ์ดำเนินการดังต่อไปนี้',
        caluse8: '2.1. คัดลอก Masters จำนวนเท่าใดก็ได้ (พื้นที่ Copier แสดงรายการการสมัครสมาชิกปัจจุบันทั้งหมด)',
        caluse9: '2.2. คุณสามารถปิดการซื้อขายด้วยคำสั่งเดียวได้ทุกเมื่อในพื้นที่ติดตามคำสั่งซื้อ (ยกเว้นแหล่งสัญญาณที่มีช่วงล็อกเอาต์)',
        caluse10: "2.3. ยกเลิกการสมัครจาก Master และหยุดการคัดลอกการซื้อขายของ Master โดยคลิก 'หยุดการคัดลอก' หากต้องการยกเลิกการสมัคร ผู้คัดลอกจำเป็นต้องปิดการซื้อขายทั้งหมด เมื่อยกเลิกการสมัคร เงินทุนทั้งหมดที่ลงทุนกับ Master และผลกำไรจะคืนสู่ Wallet ของผู้คัดลอก",
        caluse11: '2.4. กำหนดขนาดของสัดส่วนการคัดลอกสำหรับ Master ทุกตัว ตัวเลือกนี้มีคำอธิบายโดยละเอียดในข้อย่อย 4.4 ของข้อกำหนดและเงื่อนไขเหล่านี้',
        caluse12: '2.5. เพิ่มเงินสนับสนุนเพื่อปกป้องการลงทุนจากการเคลื่อนไหวของตลาดที่ไม่คาดคิด เงินเหล่านี้ใช้เพื่อสนับสนุนกลยุทธ์การซื้อขายของผู้คัดลอกเมื่อตลาดมีความผันผวนและไม่มีอิทธิพลต่อกำไรโดยตรง',
        caluse13: '2.6. ในกรณีของการเข้าร่วมในการดำเนินการลงทุนของ Master Trader แต่ละราย การคำนวณจำนวนเงินที่ Replicator ลงทุนจะถูกกำหนดตามสัดส่วนของเงินทุนของ Replicator สิ่งนี้อธิบายไว้อย่างชัดเจนในข้อ 4 อนุวรรค 5 ของข้อกำหนดและเงื่อนไข',
        caluse14: '3. บริการนี้มีอยู่บนแพลตฟอร์ม Metatrader 4',
        caluse15: '4. เปิดรูทีนการเทรดที่คัดลอก',
        caluse16: '4.1. ผู้คัดลอกคัดลอกเฉพาะการซื้อขายที่เปิดโดยมาสเตอร์หลังจากการสมัครสมาชิกสำหรับมาสเตอร์ภายในบริการ',
        caluse17: '4.2. คำสั่ง Stop Loss/Take Profit จะไม่สามารถมองเห็นได้ในพื้นที่คัดลอก แต่ถ้าคำสั่งเหล่านี้ถูกเรียกใช้ในบัญชีของ Master การซื้อขายที่คัดลอกจะถูกปิดด้วย',
        caluse18: '4.3. เมื่อสมัครสมาชิกมาสเตอร์ ผู้คัดลอกจะระบุจำนวนเงินที่จะหักออกจากกระเป๋าเงินและลงทุนกับมาสเตอร์ที่เลือก เงินเหล่านี้และกำไรของคุณจะกลับมาที่กระเป๋าเงินของคุณเมื่อคุณหยุดคัดลอก Master',
        caluse19: '4.4. ตัวจำลองจะทำซ้ำการซื้อขายของ Master Trader ตามสัดส่วนของเงินทุนของบัญชีต่อไปนี้และบัญชี Master Trader แหล่งที่มาของสัญญาณที่สมัคร',
        caluse20: '4.4.1. ในตัวเลือกอัตราส่วน ปริมาณการซื้อขายจะคำนวณตามอัตราส่วนเลเวอเรจและมูลค่าสุทธิของเทรดเดอร์มาสเตอร์และผู้ติดตาม สูตรที่ใช้มีดังนี้',
        caluse21: 'ปริมาณ (ผู้ติดตาม) = สุทธิ (ผู้ติดตาม) / สุทธิ (ผู้ซื้อขายหลัก) × เลเวอเรจ (ผู้ติดตาม) / เลเวอเรจ (ผู้ซื้อขายหลัก) × ปริมาณ (ผู้ซื้อขายหลัก) ตัวอย่างเช่น Master Trader มีมูลค่าสุทธิ $2,000 และเลเวอเรจ 1:200 ผู้ติดตามมีมูลค่าสุทธิ $200 และเลเวอเรจ 1:500 1 ล็อตถูกเปิดในบัญชีมาสเตอร์ จากนั้น ปริมาณการซื้อขายที่ตามมาคือ 200/2000 x 500/200 x 1 = 0.25 ล็อต',
        caluse22: '4.5. อัตราส่วนเลเวอเรจของเครื่องถ่ายเอกสารตั้งไว้ที่ 1:100 ผู้คัดลอกยินดีที่จะปรับเปลี่ยนจำเป็นต้องติดต่อฝ่ายสนับสนุนลูกค้าของ BETHLE ASTER',
        caluse24: '4.6. เมื่อเปิดใช้งานบริการแล้ว การเทรดจะถูกคัดลอกไปยังบัญชีของผู้คัดลอก ไม่ว่าเจ้าของบัญชีจะลงชื่อเข้าใช้หรือไม่ก็ตาม',
        caluse25: '4.7. คำสั่งของผู้คัดลอกจะดำเนินการตามคำสั่ง (ซื้อหรือขาย) ที่ทำในบัญชีของมาสเตอร์ เมื่อมาสเตอร์ออกคำสั่ง สัญญาณสำหรับการเปิดคำสั่งจะทริกเกอร์ในบัญชีของผู้คัดลอก คำสั่งของผู้คัดลอกจะดำเนินการตามราคาตลาด กลไกเดียวกันจะทริกเกอร์การปิดคำสั่ง ดังนั้น ราคาดำเนินการของคำสั่งเหล่านี้อาจแตกต่างกัน นอกจากนี้ จำนวนของผู้คัดลอกที่ติดตามมาสเตอร์นี้อาจส่งผลต่อการดำเนินการเวลา.',
        caluse26: '5. ใช้ขีดจำกัด',
        caluse27: '5.1. ปริมาณขั้นต่ำของการซื้อขายที่คัดลอกคือ 0.01 ล็อต ปริมาณสูงสุดของการซื้อขายที่คัดลอกคือ 100 ล็อต',
        caluse28: '5.2. ปริมาณคำสั่งคัดลอกขั้นต่ำคือ 0.01 ล็อต อย่างไรก็ตาม คำสั่งคัดลอกที่มีปริมาณต่ำกว่า 0.005 ล็อตจะถูกปฏิเสธ ในขณะที่การซื้อขายที่คัดลอกตั้งแต่ 0.005 ล็อตขึ้นไปจะถูกปัดเศษเป็น 0.01 ล็อต',
        caluse29: '5.3 ปริมาณของคำสั่งซื้อใด ๆ จะถูกปัดเศษให้เป็นทศนิยมตำแหน่งที่ใกล้เคียงที่สุดในร้อย (หลักที่สองหลังทศนิยม) ตัวอย่างเช่น หากผู้คัดลอกคัดลอกคำสั่งซื้อสำหรับ 0.324 ล็อต คำสั่งซื้อจะถูกปัดเศษลงเป็น 0.32 ล็อต ในทางกลับกัน หากผู้คัดลอกคัดลอกคำสั่งสำหรับ 0.325 ล็อต คำสั่งนั้นจะถูกปัดขึ้นเป็น 0.33 ล็อต',
        caluse30: '5.4. หากปริมาณการซื้อขายที่คัดลอกมากกว่า 100 ล็อตหลังจากทำการคำนวณแล้ว การซื้อขายจะไม่ถูกเปิดในบัญชีผู้คัดลอก',
        caluse31: '6. หากมาสเตอร์เปลี่ยนอิควิตี้ (โดยการฝากหรือถอน) หรือเลเวอเรจ การเทรดที่คัดลอกทั้งหมดจะรักษาปริมาณเริ่มต้นในบัญชีของผู้คัดลอก',
        caluse32: '7. เงื่อนไขการซื้อขายทั้งหมด (เลเวอเรจ สวอป สเปรด) ของผู้คัดลอกนั้นคล้ายคลึงกับเงื่อนไขสำหรับบัญชี BETHLE ASTER MT4',
        caluse33: '8. บัญชี Master Trader ต้องเป็นประเภท MT4 Micro',
        caluse34: '9. บริการมีสิทธิ์ดำเนินการดังต่อไปนี้',
        caluse35: '9.1. จำกัดจำนวนบัญชีมาสเตอร์ที่มาสเตอร์สามารถสร้างได้ตลอดเวลาโดยไม่ต้องแจ้งให้ทราบล่วงหน้าตามดุลยพินิจของมาสเตอร์แต่เพียงผู้เดียว',
        caluse36: '9.2. ยกเลิกการสมัคร Copier จาก Master โดยไม่ต้องแจ้งให้ทราบล่วงหน้า',
        caluse37: '9.3. ลดค่าคอมมิชชันที่กำหนดโดย Master Trader และจำกัดมูลค่าสูงสุดสำหรับบัญชี Master ทั้งหมดของเธอหรือของเขาโดยไม่ต้องแจ้งให้ทราบล่วงหน้าหรือให้คำอธิบายใดๆ',
        caluse38: '9.4. แก้ไขข้อกำหนดและเงื่อนไขเหล่านี้ได้ทุกเมื่อโดยไม่ต้องแจ้งให้ผู้คัดลอกหรือ Master Trader ทราบล่วงหน้า การแก้ไขดังกล่าวจะมีผลทันทีที่เผยแพร่ในเว็บไซต์บริการในข้อกำหนดและเงื่อนไขเหล่านี้',
        caluse39: "9.5. ตรวจสอบกิจกรรมของ Master Trader บนแพลตฟอร์ม ทำเครื่องหมายบัญชีของ Master Trader ด้วยคำเตือน 'กิจกรรมที่น่าสงสัย' หาก Master Trader บิดเบือนสถิติของพวกเขา และไม่รวมบัญชีดังกล่าวจากการจัดอันดับของ Master ที่กรองโดยค่าเริ่มต้น (จะทำให้ผู้คัดลอกที่เปลี่ยนแปลง การตั้งค่าตัวกรองตามนั้น)",
        caluse40: '9.6 แก้ไขหรือลบชื่อเล่นของ Master และ (หรือ) รูปภาพของผู้ใช้โดยไม่ต้องแจ้งให้ทราบล่วงหน้า หากบริการมีเหตุอันควรสงสัยว่า Master ดังกล่าวจงใจคัดลอกหรือเลียนแบบชื่อเล่นของ Master และ (หรือ) รูปภาพของ Master ซึ่งอาจนำไปสู่การเป็นตัวแทนของ Master ที่ไม่ซื่อสัตย์',
        caluse41: '10. เทรดเดอร์หลักกำหนดจำนวนค่าคอมมิชชันสำหรับคำสั่งซื้อต่อไปนี้ ค่าคอมมิชชั่นสามารถอยู่ในช่วงตั้งแต่ 0% ถึง 50% ของกำไรจากการสั่งซื้อ ค่าคอมมิชชั่นจะจ่ายให้กับ e-wallet ของผู้ซื้อขายหลักหลังจากปิดการซื้อขายแต่ละครั้ง',
        caluse42: "11. จำนวนค่าคอมมิชชั่นที่ผู้คัดลอกจ่ายให้กับมาสเตอร์จะถูกตั้งค่าในขณะที่ผู้คัดลอกกด 'เริ่มการคัดลอก' หากมาสเตอร์เปลี่ยนจำนวนค่าคอมมิชชั่น จะไม่ส่งผลต่อจำนวนเงินที่ต้องชำระภายใต้การสมัครสมาชิกมาสเตอร์นี้",
        caluse43: '12. สถิติการซื้อขายของผู้คัดลอกสามารถดูได้โดยผู้คัดลอกเท่านั้น',
        caluse45: '13. สถิติการซื้อขายของ Master เปิดเผยต่อสาธารณะ',
        caluse46: '14. ผู้คัดลอกไม่สามารถเข้าถึงเทอร์มินัลการซื้อขายได้ การดำเนินการทั้งหมดกับการสมัครรับข้อมูลและการซื้อขายจะดำเนินการภายใต้พื้นที่ผู้คัดลอก',
        caluse47: '15. หากบริการมีเหตุอันควรสงสัยว่าผู้คัดลอกละเมิดกฎการฝากและถอนเงินที่กำหนดไว้ภายใต้ข้อตกลงลูกค้าหรือกฎหมายของประเทศที่ผู้คัดลอกพำนัก บริการมีสิทธิ์ระงับการให้บริการแก่ผู้คัดลอกดังกล่าว',
        caluse48: '16. โปรดทราบว่า Master สามารถทำการซื้อขายได้ทั้งกำไรและขาดทุน',
        caluse49: 'BETHLE ASTER ขอสงวนสิทธิ์ในการตีความข้อกำหนดและเงื่อนไขเหล่านี้ขั้นสุดท้าย',
        caluse50: '',
        caluse51: '',
        caluse52: '',
        caluse53: '',
        caluse54: '',
        caluse55: '',
        caluse56: '',
        caluse57: '',
        caluse58: '',
        caluse67: '',
        caluse68: '',
        caluse69: '',
        caluse70: '',
        caluse71: '',
        caluse72: '',
    },
    join: {
        copy: 'กําไรจากการคัดลอกความสําเร็จของเทรดเดอร์มืออาชีพ',
        wenan: 'BlaFX-FX Copytrading เปิดโอกาสให้ลูกค้าคัดลอกกลยุทธ์ของเทรดเดอร์ชั้นนําโดยอัตโนมัติโดยไม่ต้องใช้เวลานานในการพัฒนากลยุทธ์การซื้อขายของตนเอง เลือกจากผู้เชี่ยวชาญด้านการซื้อขายฟอเร็กซ์ที่ดีที่สุดและกระจายพอร์ตการซื้อขายของคุณ',
        join: 'เข้าร่วมก๊อปปี้เทรด',
        become: 'หรือเป็นเทรดเดอร์หลัก',
        way: 'วิธีการทํางาน',
        creat: 'สร้างบัญชีและทําการฝากเงิน',
        simpleway: 'ลงทะเบียนในขั้นตอนง่ายๆ เพียงขั้นตอนเดียวและเติมเงินในกระเป๋าเงินของคุณโดยใช้วิธีการชําระเงินที่คุณต้องการ หากคุณมีเงินในบัญชีซื้อขาย BlaFX-FX อยู่แล้ว คุณสามารถใช้การโอนเงินภายในเพื่อเพิ่มเงินจากบัญชีของคุณไปยังกระเป๋าเงินของคุณได้ ยอดคงเหลือในกระเป๋าเงินของคุณแสดงเงินที่คุณยังไม่ได้ลงทุน',
        followbest: 'ติดตามเทรดเดอร์ที่ดีที่สุด',
        followandcopy: 'ค้นหาต้นแบบที่คุณต้องการติดตามและคลิก \'คัดลอก\' ตําแหน่งของมันจะถูกคัดลอกโดยอัตโนมัติ การตั้งค่าเปอร์เซ็นต์เงินฝากจะช่วยให้คุณจัดการพอร์ตโฟลิโอของคุณได้ ลองคัดลอกกลยุทธ์ที่แตกต่างกันและตัดสินใจเลือกกลยุทธ์ที่ดีที่สุดจากพวกเขา!',
        moandprofit: 'ตรวจสอบและสร้างรายได้จาก！',
        copycopy: 'ไม่มีการจํากัดจํานวนมาสเตอร์เทรดเดอร์ที่คุณสามารถคัดลอกได้ดังนั้นคุณสามารถสร้างพอร์ตการซื้อขายที่สมดุลและหลากหลายพร้อมรายได้ที่มั่นคง นอกจากนี้คุณยังสามารถควบคุมกระบวนการทั้งหมดได้อย่างเต็มที่แก้ไข / หยุดคัดลอกการซื้อขายในเวลาใดก็ตาม คุณสามารถดูข้อมูลการซื้อขายโดยละเอียดของ Copy Master Trader ได้ในพื้นที่ผู้คัดลอกของคุณ',
        followprofitbest: '跟随盈利最高的交易者',
        noneed: 'ไม่จําเป็นต้องเป็นผู้เชี่ยวชาญฟอเร็กซ์',
        manyways: 'รายได้ที่มั่นคงจากพอร์ตการลงทุนที่หลากหลาย',
        new: 'เครื่องมือใหม่นี้ช่วยให้ผู้ค้าได้รับรายได้ที่มั่นคงมากขึ้นโดยการคัดลอกผู้ค้าที่มีประสบการณ์มากขึ้น',
        learnbest: 'เรียนรู้จากเทรดเดอร์ที่ดีที่สุด',
        easytoin: 'มันง่ายมากสําหรับผู้มาใหม่ที่จะเข้าสู่ตลาด Forex',
        spread: 'กระจายพอร์ตการลงทุนของคุณ',
        choosebig: 'เลือกเทรดเดอร์หลักและติดตามกลุ่มผู้เชี่ยวชาญ',
        enjoyfast: 'เพลิดเพลินกับการดําเนินการคําสั่งที่รวดเร็ว',
        infive: 'หลังจากเปิดตําแหน่งคําสั่งจะถูกดําเนินการภายใน 5 นาที',
        inhand: 'ควบคุมทุกอย่างให้อยู่ในการควบคุม',
        anytimestop: 'คุณสามารถหยุดคัดลอก สมัครใช้งาน หรือปิดธุรกรรมได้ตลอดเวลา',
        easyway: ' มันสามารถทํางานในไม่กี่ขั้นตอนง่ายๆ',
        noneedother: 'ไม่จําเป็นต้องมีการยืนยันเพิ่มเติม。',
        safe: 'การลงทุนและการถอนเงินที่ปลอดภัยและรวดเร็ว',
        mangmain: 'เลือกจากวิธีการชําระเงินหลักที่หลากหลาย。',
        alluseful: 'เพลิดเพลินไปกับประโยชน์ทั้งหมดของการคัดลอกการซื้อขายในแอพมือถือ',
        foces: 'มุ่งเน้นไปที่การลงทุนโดยใช้แอป BlaFX-FX Copytrading ที่สะดวกสบาย',
        manage: 'ควบคุมพอร์ตการลงทุนและการลงทุนของคุณในขณะเดินทาง',
        follow: 'ติดตามเทรดเดอร์ Master Trader และประสิทธิภาพแบบเรียลไทม์เพื่อลงทุนอย่างชาญฉลาด',
        seerisk: 'ดูว่าเงินของคุณถูกลงทุนและจัดการความเสี่ยงแบบเรียลไทม์อย่างไร',
        howto: 'ฉันจะเลือกมาสเตอร์เทรดเดอร์ที่จะคัดลอกได้อย่างไร？',
        firstcheck: 'ก่อนอื่นให้ตรวจสอบข้อมูลของเทรดเดอร์หลัก ประกอบด้วยคะแนนความเสี่ยงผลประโยชน์กําไรและขาดทุนจํานวนเครื่องถ่ายเอกสารค่าคอมมิชชั่นประวัติการสั่งซื้อและสถิติอื่น ๆ เพื่อช่วยให้คุณตัดสินใจได้อย่างมีข้อมูล ในการเริ่มคัดลอกให้เติมเงินในบัญชีของคุณ',
        anyuseful: 'มีตัวเลือกการทําสําเนาอะไรบ้าง？',
        ways: 'เมื่อคุณสมัครสมาชิกมาสเตอร์เทรดเดอร์ คุณจะต้องระบุอัตราส่วนการคัดลอกและตัดสินใจว่าจะเพิ่มเงินทุนสนับสนุนหรือไม่ เมื่อคุณกดเริ่มคัดลอกเงินจะถูกหักออกจากกระเป๋าเงินของคุณและการคัดลอกจะเริ่มขึ้น คุณสามารถสมัครสมาชิกมาสเตอร์เทรดเดอร์ได้ไม่จํากัดจํานวนในเวลาเดียวกัน',
        did: 'คุณคิดค่าคอมมิชชั่นสําหรับการคัดลอกหรือไม่?',
        didas: 'ในฐานะโบรกเกอร์ของคุณเราไม่คิดค่าคอมมิชชั่น คุณจ่ายค่าคอมมิชชั่นให้กับมาสเตอร์เทรดเดอร์ซึ่งกําหนดไว้ล่วงหน้าและระบุแยกต่างหาก สถิติของเทรดเดอร์หลักแต่ละรายจะแสดงจํานวนค่าคอมมิชชั่น',
        howstop: 'วิธีหยุดคัดลอกมาสเตอร์เทรดเดอร์？',
        anytimecancle: 'คุณสามารถยกเลิกการสมัครสมาชิกมาสเตอร์เทรดเดอร์และหยุดการคัดลอกได้ตลอดเวลา เมื่อคุณยกเลิกการสมัครสมาชิกเงินทั้งหมดที่ลงทุนใน Master Trader และผลกําไรของคุณจากการคัดลอกจะถูกโอนเข้ากระเป๋าเงินของคุณ',
        howprofit: 'ฉันจะถอนกําไรได้อย่างไร？',
        wallet: 'คุณสามารถถอนออกจากกระเป๋าเงินของคุณได้ สิ้นสุดการสมัครสมาชิก Master Trader และโอนการลงทุนและผลกําไรของคุณไปยังกระเป๋าเงินของคุณ โปรดทราบว่าหากคุณสมัครสมาชิก Master Trader ที่ขาดทุน คุณจะไม่สามารถชดใช้เงินลงทุนเริ่มแรกของคุณได้ จํานวนการสูญเสียจะถูกหักออกจากการลงทุนครั้งแรกของคุณ',
        getprofit: 'มาเป็นเทรดเดอร์หลักและรับผลกําไรที่มั่นคง',
        becomaster: 'มาเป็นเทรดเดอร์หลัก',
        rules: 'ข้อกําหนดและเงื่อนไข'
    },
    balance: {
        orderid: 'หมายเลขคําสั่งซื้อ',
        opentime: 'เวลาเปิดตําแหน่ง',
        balantime:'เวลาที่เหลือ',
        type: 'ประเภท',
        profit: 'กําไร',
    },
    close: {
        openprice: 'ราคาเปิด',
        hand: 'ขนาดล็อต',
        symbol: 'สัญลักษณ์การซื้อขาย',
        closetime: 'เวลาปิดทําการ',
        closeprice: 'ปิดราคา',
        comission: 'รางวัล',
        storage: 'ค่าธรรมเนียมสินค้าคงคลัง',
        profit: 'กําไร',
        nowprice: 'ราคาปัจจุบัน'
    },
    joinmaster: {
        good: 'เก่งในการเทรด? สร้างรายได้โดยให้ผู้อื่นคัดลอกการเทรดของคุณ',
        one: 'ซื้อขายตามปกติในขณะที่ได้รับเงินพิเศษจากผู้ค้ารายอื่นที่คัดลอกคุณ โปรไฟล์มาสเตอร์เทรดเดอร์ของคุณจะแสดงประสิทธิภาพการซื้อขายรายวันและรายเดือนของคุณ – ส่งเสริมและดึงดูดผู้ติดตามใหม่!',
        become: 'มาเป็นเทรดเดอร์หลัก',
        runway: 'วิธีการทํางาน',
        creat: 'สร้างบัญชีมาสเตอร์เทรดเดอร์',
        click: 'คลิกที่พื้นที่มาสเตอร์เทรดเดอร์เพื่อสร้างบัญชีมาสเตอร์เทรดเดอร์ - เปิดบัญชีใหม่หรือกําหนดบัญชีที่มีอยู่ให้กับบัญชีมาสเตอร์ของคุณ',
        accountsetting: 'การตั้งค่าบัญชี',
        beready: 'เตรียมบัญชี Master Trader ของคุณเพื่อให้ผู้จําลองทําตาม: กําหนดจํานวนค่าคอมมิชชั่นของคุณและอธิบายกลยุทธ์ของคุณ',
        use: 'ใช้พื้นที่มาสเตอร์เทรดเดอร์เพื่อดูข้อมูลโดยละเอียดเกี่ยวกับการซื้อขายของคุณเปลี่ยนการตั้งค่าบัญชีของคุณและดูจํานวนค่าคอมมิชชั่นที่คุณได้รับ',
        offer: 'BlaFX-FX Copytrading ให้แหล่งรายได้เพิ่มเติมแก่คุณ: เปิดบัญชีมาสเตอร์เทรดเดอร์อธิบายกลยุทธ์ของคุณจากนั้นกําหนดค่าคอมมิชชั่นให้ผู้อื่นคัดลอกการซื้อขายของคุณ',
        different: 'สร้างบัญชีหลักที่แตกต่างกันสําหรับกลยุทธ์ที่แตกต่างกัน',
        detail: 'ติดตามรายละเอียดคําสั่งซื้อขายของคุณในพื้นที่มาสเตอร์เทรดเดอร์และรับค่าคอมมิชชั่นมาสเตอร์เทรดเดอร์',
        enjoy: 'เพลิดเพลินกับสิทธิประโยชน์มากมายโดยไม่ต้องจ่ายค่าคอมมิชชั่นเพิ่มเติมให้กับ BlaFX-FX',
        android: 'คุณอยู่ในพื้นที่มาสเตอร์เทรดเดอร์ของแอปซื้อขาย BlaFX-FX สําหรับ Android',
        mobile: 'สร้างบัญชีมาสเตอร์เทรดเดอร์ขณะเดินทาง',
        anywhere: 'ไม่ว่าคุณจะอยู่ที่ไหนคุณสามารถดูสถิติและค่าคอมมิชชั่นของผู้ทําซ้ําได้',
        now: 'จัดการเงื่อนไขการจําลองแบบสําหรับผู้ติดตามใหม่ของคุณได้ทันที',
        how: ' วิธีการเป็นเทรดเดอร์หลัก？',
        get: 'ลูกค้า BlaFX-FX ทุกคนที่มีบัญชี MT4 สามารถเป็นมาสเตอร์เทรดเดอร์ได้ ฝากเงิน 100,000 ดอลลาร์สหรัฐและซื้อขายเป็นเวลาสามเดือนเพื่อทํากําไรที่มั่นคงสามารถนําไปใช้กับฝ่ายบริการลูกค้าได้',
        howmanage: 'วิธีปรับจํานวนค่าคอมมิชชั่นที่ฉันเรียกเก็บจากผู้คัดลอก？',
        go: 'ไปที่ Master Trader Area ตรวจสอบการตั้งค่าปรับค่าคอมมิชชั่นโดยใช้แถบเลื่อนและบันทึก ค่าคอมมิชชันใหม่จะใช้กับผู้ทําซ้ําที่สมัครสมาชิกหลังจากการปรับเท่านั้น สําหรับผู้คัดลอกอื่น ๆ ทั้งหมดจํานวนค่าคอมมิชชั่นจะยังคงไม่เปลี่ยนแปลง',
        time: 'ฉันจะได้รับค่าคอมมิชชั่นจากผู้คัดลอกของฉันเมื่อใด？',
        american: 'การชําระเงินจะทําทุกวันอาทิตย์เวลา 18.00 น. ตามเวลาสหรัฐ',
        when: 'เมื่อใดที่ควรเรียกเก็บค่าคอมมิชชั่นจากผู้ทําซ้ํา？',
        comission: 'ค่าคอมมิชชั่นจะถูกเรียกเก็บทุกวันเสาร์สําหรับคําสั่งปิด',
        whencanget: 'ฉันจะได้รับค่าคอมมิชชั่นเมื่อใด？',
        wallet: 'เราโอนค่าคอมมิชชั่นไปยัง e-wallet จากกระเป๋าเงินของคุณคุณสามารถเพิ่มเงินไปยังบัญชีซื้อขายใด ๆ ของคุณหรือถอนเงิน',
        copy: 'คัดลอกกลยุทธ์ของเทรดเดอร์ชั้นนําโดยอัตโนมัติโดยไม่ต้องใช้เวลานานในการพัฒนากลยุทธ์การซื้อขายของคุณเอง',
        or: 'หรือเป็นผู้ทําซ้ํา',
    },
    other: {
        signalaccount: 'บัญชีสัญญาณ',
        signalname: 'ชื่อเล่นสัญญาณ',
        wallet: 'เงินในกระเป๋าเงิน',
        hold: 'จํานวนการสนับสนุน',
        need0: '0% ของเงินลงทุนที่ต้องการ',
        zj: 'สรุป',
        follow: 'คัดลอกหลายรายการ',
        followmoney: 'ค่าเอกสาร',
        begin: 'เริ่มการคัดลอก',
        hand: 'มือ',
        more: '25 หรือมากกว่า',
        choose: 'กรุณาเลือก',
        notlogin: 'คุณยังไม่ได้เข้าสู่ระบบโปรดเข้าสู่ระบบก่อน',
        byhand: 'ขนาดล็อต',
        byprofit: 'กําไร',
        byleft: 'ตาชั่ง',
        bylock: 'การป้องกันความเสี่ยง',
        day: 'ฟ้า',
        sure: 'ยืนยัน',
        not: 'คุณยังไม่ได้คัดลอกการซื้อขาย',
        receiptaccount: 'เปิดบัญชี',
        balance: 'ยอดเงินคงเหลือ',
        nodata: 'ยังไม่มีข้อมูล',
        notlogin1: 'คุณยังไม่ได้เข้าสู่ระบบ',
        socity: 'การเข้าสังคม',
        dl: 'โปรแกรมเอเจนซี่',
        help: 'ศูนย์ช่วยเหลือ',
        privicy: 'นโยบายความเป็นส่วนตัว',
        nochoose: 'ไม่มีตัวเลือก',
        nonum: 'คุณยังไม่ได้เปิดบัญชีสําเนาที่ถูกต้อง'
    },
    explain: {
        hand: 'เรียกเก็บค่าธรรมเนียมคงที่ สมมติว่า $1/ล็อต บัญชีคัดลอกของคุณวาง 10 ล็อต และคุณต้องถอนเงิน $10 จากบัญชีของคุณไปยังบัญชีต้นทาง',
        left: 'มันจะถูกเรียกเก็บเงินตามสัดส่วนของยอดคงเหลือของบัญชีสําเนา สมมติว่ามีค่าธรรมเนียม 0.2% ของยอดคงเหลือยอดคงเหลือในบัญชีสําเนาของคุณคือ $ 10,000 และคุณต้องนํา $ 20 จากบัญชีของคุณไปยังบัญชีต้นทาง',
        profit: 'ค่าใช้จ่ายจะถูกนํามาเป็นเปอร์เซ็นต์ของกําไรในคําสั่งซื้อที่คัดลอกโดยไม่มีค่าใช้จ่ายสําหรับผลกําไร สมมติว่ามีการเรียกเก็บเงิน 5% ของกําไรและหากบัญชีคําสั่งซื้อที่คัดลอกของคุณทํากําไรได้ $ 1,000 ในคําสั่งซื้อ $ 50 จะถูกนําจากบัญชีของคุณไปยังบัญชีแหล่งสัญญาณ',
        lock: 'มันถูกเรียกเก็บเป็นเปอร์เซ็นต์ของกําไรทั้งหมดในช่วงระยะเวลาล็อคอัพและไม่มีการเรียกเก็บกําไร สมมติว่ามีค่าธรรมเนียม 8% ของกําไรทั้งหมด MAM ของคุณมีกําไรรวม $ 2000 ในช่วงระยะเวลาล็อคและคุณต้องถอน $ 160 จากบัญชีของคุณไปยังบัญชีต้นทาง',
        fake: 'สมมติฐาน: คําแนะนําระดับ 1: 30%, คําแนะนําระดับ 2: 20%, คําแนะนําระดับ 3: 10%, A แนะนํา B, B แนะนํา C, C แนะนํา D ผู้ใช้ D วางคําสั่งซื้อและสร้างค่าคอมมิชชั่นการคัดลอก $ 100 จากนั้น A จะได้รับ $ 10, B ได้รับ $ 20 และ C ได้รับ $ 30',
        said: 'ใน BlaFX ผู้คัดลอกจํานวนมากส่งคืนมากกว่า 70% ของผลกําไรค่าคอมมิชชั่นการคัดลอกและบางคนถึงกับ 90%',
        byhand: 'โดยมาก',
        byleft: 'โดยยอดคงเหลือ',
        byprofit: 'ตามกําไร',
        bylock: 'กําไรตามระยะเวลาล็อคอัพ'
    },
    common: {
        title: 'การซื้อขายสารคดี BlaFX',
        update: 'เว็บไซต์กําลังได้รับการอัพเกรดโปรดเข้าใจในความไม่สะดวกใด ๆ',
        back: 'กลับ',
        nosm: 'โปรดกรอกการยืนยันชื่อจริงก่อนดําเนินการดังกล่าว',
        fail: 'การดําเนินการล้มเหลว',
        rz: 'ไปที่การรับรอง',
        ok: 'ความสำเร็จในการดำเนินงาน'
    }
}
